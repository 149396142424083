
import { uniqueId } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import BuilderEditor from '../BuilderEditor.vue';

export enum SupportMode {
  READ = 'READ',
  EDIT = 'EDIT',
}

@Component({
  components: { BuilderEditor },
})
export default class SupportRow extends Vue {
  @Prop({ required: true }) value: string;
  @Prop({ default: () => SupportMode.EDIT }) mode: SupportMode;
  @Prop({ default: false }) autoFocus: boolean;
  @Prop({ default: false }) disabled: boolean;

  SupportMode = SupportMode;

  editorId = uniqueId('tinymce');

  get support(): string {
    return this.value;
  }

  set support(value: string) {
    this.$emit('input', value);
  }
}
