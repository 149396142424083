import { Module } from 'vuex';
import { mutations } from './mutations';
import { getters } from './getters';
import { NotificationState } from './types';
import { RootState } from '../types';

export const state: NotificationState = {
  notifications: [],
  alertMessages: [],
  counter: 0,
};

const namespaced = true;

export const notifications: Module<NotificationState, RootState> = {
  namespaced,
  state,
  mutations,
  getters,
};
