import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { AttributionState } from './types';
import { AttributionDefinition } from '@/domain/Attribution';
import { getAttributionDefinitions } from '@/api/core/attributions.api';

export const actions: ActionTree<AttributionState, RootState> = {
  requestAttributions(context): Promise<AttributionDefinition[]> {
    if (
      !context.state.isDownloadingAttributions &&
      !context.state.hasDownloadedAttributions
    ) {
      context.commit('setIsDownloadingAttributions', true);
      context.commit('setHasDownloadedAttributions', false);

      return getAttributionDefinitions()
        .then((definitions: AttributionDefinition[]) => {
          context.commit('setHasDownloadedAttributions', true);
          context.commit('setDownloadedDefinitions', definitions);
          return Promise.resolve(definitions);
        })
        .catch((e) => {
          return Promise.reject(e);
        })
        .finally(() => {
          context.commit('setIsDownloadingAttributions', false);
        });
    }
    return Promise.resolve(context.state.downloadedDefinitions);
  },
};
