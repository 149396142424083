
import { Component, Vue } from 'vue-property-decorator';
import SettingsSideBarCard from './SettingsSideBarCard.vue';

export interface SettingsSideBarItem {
  title: string;
  description: string;
  icon: string;
  path: string;
}

@Component({
  components: {
    SettingsSideBarCard,
  },
})
export default class SettingsSideBar extends Vue {
  sideBarItems: Array<SettingsSideBarItem> = [
    {
      title: 'Account Settings',
      description: 'Personal Information, Roles',
      icon: 'account',
      path: 'account-settings',
    },
    {
      title: 'Preferences',
      description: 'School, Grade(s) you teach...',
      icon: 'cogs',
      path: 'preferences',
    },
    {
      title: 'Feature Settings',
      description: 'Test mode, Student supports...',
      icon: 'puzzle',
      path: 'feature-settings',
    },
  ];
}
