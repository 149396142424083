
import { Component, Vue, Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

@Component
export default class TimeFromNow extends Vue {
  @Prop({ required: true }) updatedAt: number;
  @Prop({ required: false, default: '' }) classes: string;
  @Prop({ default: true }) showIcon: boolean;

  get timeFromNow(): string {
    return dayjs(this.updatedAt).fromNow();
  }
}
