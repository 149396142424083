import { ActionTree } from 'vuex';
import { SearchState } from './types';
import { RootState } from '../types';
import { ProblemDefinition } from '@/domain/Problem';
import { ProblemSetDefinition, ProblemSetType } from '@/domain/ProblemSet';
import { CancelToken } from 'axios';
import {
  searchForProblems,
  searchForProblemSets,
  ProblemsFilterParams,
  ProblemSetsFilterParams,
} from '@/api/core/content.api';
import {
  DefinitionParams,
  ObjectList,
  PagingParams,
} from '@/api/core/base.api';

export const actions: ActionTree<SearchState, RootState> = {
  requestSkillBuildersBySkill(
    context,
    payload: {
      filterParams: ProblemSetsFilterParams;
      pagingParams: PagingParams;
      definitionParams: DefinitionParams;
      cancelToken: CancelToken;
    }
  ): Promise<ObjectList<ProblemSetDefinition>> {
    const { filterParams, pagingParams, definitionParams, cancelToken } =
      payload;
    return searchForProblemSets(
      filterParams,
      pagingParams,
      definitionParams,
      cancelToken
    ).then((objectList) => {
      const skillBuilders = objectList.data;
      context.commit('setSkillBuilders', skillBuilders);
      context.commit('setSkillBuilderNextPageToken', objectList.nextPageToken);
      return objectList;
    });
  },
  async searchProblemsBySkill(
    context,
    payload: {
      filterParams: ProblemsFilterParams;
      pagingParams: PagingParams;
      definitionParams: DefinitionParams;
      cancelToken: CancelToken;
    }
  ): Promise<string[]> {
    const { filterParams, pagingParams, definitionParams, cancelToken } =
      payload;
    // If this is the first search, then reset before requesting problems
    if (!pagingParams.nextPageToken) {
      context.commit('resetProblemSearch');
    }

    let problems: ProblemDefinition[] = [];
    let psXrefs: string[] = [];

    await searchForProblems(
      filterParams,
      pagingParams,
      definitionParams,
      cancelToken
    )
      .then((result) => {
        problems = result.data;

        context.commit('setNextPageToken', result.nextPageToken);
        if (result.count !== undefined) {
          context.commit('setProblemCount', result.count);
        }
      })
      .catch(() => {
        const errorResponse: ObjectList<ProblemDefinition> = {
          data: [],
        };
        // If any errors are thrown, disable further redownloading
        context.commit('setShouldDownloadMoreProblems', false);

        return errorResponse;
      });

    const psPagingParams = { ...pagingParams };
    psPagingParams.nextPageToken = context.state.problemSetsNextPageToken;

    await searchForProblemSets(
      {
        skills: filterParams.skills,
        isResearch: false,
        isCertified: true,
        types: [ProblemSetType.MULTI_PART_PROBLEM_SET],
        problemTypes: filterParams.types,
      },
      psPagingParams,
      definitionParams,
      cancelToken
    )
      .then((result) => {
        psXrefs = result.data.map((ps) => {
          return ps.xref;
        });

        context.commit('setProblemSetsNextPageToken', result.nextPageToken);
        if (result.count !== undefined) {
          context.commit('setProblemSetCount', result.count);
        }
      })
      .catch(() => {
        const errorResponse: ObjectList<ProblemSetDefinition> = {
          data: [],
        };
        // If any errors are thrown, disable further redownloading
        context.commit('setShouldDownloadMoreProblems', false);

        return errorResponse;
      });

    context.commit('setCombinedSearchResults', problems);

    return Promise.resolve(psXrefs);
  },
};
