
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DeleteStudentProgressDialog extends Vue {
  @Prop() value: boolean;
  @Prop() studentName: string;

  get computedValue(): boolean {
    return this.value;
  }

  set computedValue(val: boolean) {
    this.$emit('input', val);
  }
}
