
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { LmsProviderType } from '@/domain/LmsProviderType';
import { getClasses, importClasses, synchronize } from '@/api/class.api';
import { ImportFilter, LmsClass } from '@/domain/Class';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';

@Component
export default class ImportSyncDialog extends Vue {
  @Prop() value: boolean;
  @Prop() defaultLms: number;

  lmsClassList: LmsClass[] = [];
  downloadingLmsClassList = false;
  importDialog = false;
  selectedImportClasses: string[] = [];
  importing = false;
  synchronizing = false;

  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(val: boolean) {
    if (val === false) this.$emit('closeImportSyncDialog');
  }

  get chosenLms(): number {
    return this.defaultLms
      ? this.defaultLms
      : this.$store.state.auth.user.lmsProviderType.id;
  }

  get chosenLmsName(): string {
    const lms = LmsProviderType.find(this.chosenLms);
    if (lms) {
      return lms.displayName;
    } else {
      return '';
    }
  }

  get hasSelectedClasses(): boolean {
    return this.selectedImportClasses.length > 0 ? false : true;
  }

  getImportClasses(): void {
    if (!this.chosenLms) {
      return;
    }
    this.lmsClassList = [];
    this.downloadingLmsClassList = true;
    getClasses({ lmsid: this.chosenLms, importFilter: ImportFilter.UNIMPORTED })
      //rename unimportedClasses to classes
      .then(({ unimportedClasses: classes }) => {
        if (classes && classes.length > 0) {
          this.lmsClassList = classes;
          this.importDialog = true;
        } else {
          this.$emit('showAlert', 'warning', 'No classes found to import');
        }
        this.downloadingLmsClassList = false;
      })
      .catch((error) => {
        error.handleGlobally &&
          error
            .handleGlobally()
            .then(() => {
              this.downloadingLmsClassList = false;
              this.$notify('Authorization updated. Please try again');
            })
            .catch(() => {
              this.$emit('showAlert', 'error', 'Import Failed');
              //our popup was blocked
              this.$emit('popupBlocked', error);
            });
      });
  }

  synchronize(): void {
    if (!this.chosenLms) {
      return;
    }
    this.synchronizing = true;

    synchronize(this.chosenLms)
      .then(() => {
        // FIXME: Make another request for list of imported classes or
        // trust that what we have sent to the server is the truthy?
        // Update cached classes in store
        this.$store.dispatch('classList/requestClasses', true).then(() => {
          this.$emit('showAlert', 'success', 'Synchronization was successful.');
          this.synchronizing = false;
          this.showDialog = false;
          this.syncClassesSuccessful();
        });
      })
      .catch((error) => {
        error.handleGlobally &&
          error
            .handleGlobally()
            .then(() => {
              this.synchronizing = false;
              this.$notify('Authorization updated. Please try again');
            })
            .catch(() => {
              this.$emit('showAlert', 'error', 'Synchronization failed.');
              //our popup was blocked
              this.$emit('popupBlocked', error);
            });
      });
  }

  importClasses(): void {
    if (!this.selectedImportClasses) {
      return;
    }
    const classesToImport = this.lmsClassList.filter((lmsClass) => {
      return this.selectedImportClasses.includes(lmsClass.id);
    });
    const lmsPtype = LmsProviderType.find(this.chosenLms);
    if (lmsPtype) {
      this.importing = true;
      importClasses(classesToImport, lmsPtype)
        .then(() => {
          // FIXME: Make another request for list of imported classes or
          // trust that what we have sent to the server is the truthy?
          // Update cached classes in store
          this.$store.dispatch('classList/requestClasses', true).then(() => {
            this.$emit(
              'showAlert',
              'success',
              'Class(es) successfully imported.'
            );
            this.importing = false;
            this.importDialog = false;
            this.showDialog = false;
            this.importClassesSuccessful();
          });
        })
        .catch((error) => {
          error.handleGlobally &&
            error
              .handleGlobally()
              .then(() => {
                this.importing = false;
                this.$notify('Authorization updated. Please try again.');
              })
              .catch(() => {
                this.$emit('showAlert', 'error', 'Import failed.');
                //our popup was blocked
                this.$emit('popupBlocked', error);
              });
        });
    }
  }

  @Watch('importDialog')
  resetValues(): void {
    this.selectedImportClasses = [];
  }

  importClassesSuccessful(): void {
    trackMixpanel(EventType.importClassesSuccessful);
  }

  syncClassesSuccessful(): void {
    trackMixpanel(EventType.syncClassesSuccessful);
  }
}
