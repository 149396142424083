// Want to change or update something?
// Stashing changes synchronously commit mutation
import { CurriculumDefinition } from '@/domain/Curriculum';
import { MutationTree } from 'vuex';
import { CurriculumState } from './types';

export const mutations: MutationTree<CurriculumState> = {
  setIsDownloadingCurricula(state: CurriculumState, flag: boolean) {
    state.isDownloadingCurricula = flag;
  },
  setHasDownloadedCurricula(state: CurriculumState, flag: boolean) {
    state.hasDownloadedCurricula = flag;
  },
  setDownloadedDefinitions(
    state: CurriculumState,
    definitions: CurriculumDefinition[]
  ) {
    state.downloadedDefinitions = definitions;
  },
};
