
import { Component, Vue, Prop } from 'vue-property-decorator';
import { AnswerSet, AnswerType } from '@/domain/Problem';
import { shuffle } from 'lodash';
import { getChooseLabel } from '@/utils/builder.util';
import { getAnswerParts } from '@/utils/problem.util';

// FIXME: Make this a Problem response generator so we can probably reuse for authoring SRFs.
@Component({
  components: {
    AnswersViewSDK3: () => import('./AnswersViewSDK3.vue'),
  },
})
export default class AnswersViewSDK3 extends Vue {
  @Prop({ required: true }) answersSDK3: AnswerSet;
  @Prop({ required: false, default: () => ({}) }) response: Record<
    number,
    string[]
  >;
  @Prop({ default: true }) showAnswerPool: boolean;

  AnswerType = AnswerType;
  shuffle = shuffle;
  getChooseLabel = getChooseLabel;

  // FIXME: Account for drop correct map?
  get multipleResponses(): boolean {
    return Object.values(this.response).some((vs) => vs.length > 1);
  }

  // FIXME: Handle invalid markers/parts?
  get invalidResponse(): Record<number, string[]> {
    const ir: Record<number, string[]> = {};
    const aps = getAnswerParts(this.answersSDK3);
    for (const marker in this.response) {
      const res = this.response[marker];
      const ap = aps[marker];
      const apvs = ap?.answerValues?.map((av) => av.value) ?? [];
      ir[marker] = res.filter((r) => !apvs.includes(r));
    }
    return ir;
  }

  mounted(): void {
    window.com.wiris.js.JsPluginViewer.parseDocument();
  }
}
