
import { Component, Vue } from 'vue-property-decorator';
import PrintProblemSetsView from '@/components/Print/PrintProblemSetsView.vue';

@Component({
  components: {
    PrintProblemSetsView,
  },
})
export default class PrintMyProblemSetPage extends Vue {
  created() {
    this.printAssignment();
  }
  printAssignment(): void {
    const xref = Number(this.$route.params.xref);
    this.$store.commit('print/setPrintData', {
      psId: xref,
      contentType: 'PROBLEM_SET',
    });
    this.$store.dispatch('print/requestPrintProblems');
  }
}
