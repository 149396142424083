
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import axios, { CancelTokenSource } from 'axios';
import { isEqual } from 'lodash';
import { AssignmentDefinition } from '@/domain/Assignment';
import { getAssignmentDefinition } from '@/api/core/assignment.api';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import { getProblemSetDefinition } from '@/api/core/content.api';

@Component
export default class SelectAssignmentIdDialog extends Vue {
  @Prop({ default: [] }) assignmentXrefs: string[];
  @Prop() value: boolean;

  selectedXref: string | null = null;
  dialog = false;
  downloading = false;
  assignmentIndex: number | null = null;
  downloaded: { [xref: string]: string | null } = {};
  assignmentXrefToPSName: { [xref: string]: string | null } = {};
  source: CancelTokenSource | null = null;

  get showDialog(): boolean {
    return this.value ? this.value : this.dialog;
  }

  set showDialog(val: boolean) {
    this.dialog = val;

    this.$emit('input', val);
  }

  get hasLoadedSome(): boolean {
    return Object.values(this.assignmentXrefToPSName).some((x) => x != null);
  }

  setSelectedXref(xref: string): void {
    this.selectedXref = xref;
  }

  downloadAssignments(xrefs: string[]): Promise<void> {
    // Stop whatever was previously not downloaded (or downloading)
    if (this.source) {
      // Cancel prior requests with this cancel token
      this.source.cancel();
    }

    // Download assignment problem sets
    this.downloading = true;

    // Clear previously downloaded assignments
    this.assignmentXrefToPSName = {};

    // Use share cancel token for Assignment and Problem set requests
    this.source = axios.CancelToken.source();

    const promises: Promise<unknown>[] = [];

    for (const assignmentXref of xrefs) {
      // Download Assignment
      const aPromise = getAssignmentDefinition(
        assignmentXref,
        {
          isActive: 'ENABLED',
          details: true,
        },
        this.source?.token
      )
        .then((assignment: AssignmentDefinition) => {
          // Download Problem Set
          return getProblemSetDefinition(assignment.problemSetCeri)
            .then((problemSet: ProblemSetDefinition) => {
              // Record Problem Set Name for Assignment
              this.$set(
                this.assignmentXrefToPSName,
                assignmentXref,
                problemSet.name
              );
              return;
            })
            .catch((e) => {
              if (axios.isCancel(e)) {
                return Promise.reject(e);
              } else {
                // Failed to download Problem Set
                this.$set(this.assignmentXrefToPSName, assignmentXref, null);
              }
              return;
            });
        })
        .catch((e) => {
          if (axios.isCancel(e)) {
            return Promise.reject(e);
          } else {
            // Failed to download Assignment
            this.$set(this.assignmentXrefToPSName, assignmentXref, null);
          }
          return;
        });

      promises.push(aPromise);
    }

    // Finished downloading
    return Promise.all(promises).then(() => {
      // Everything has been handled above. Nothing was canceled.
      this.downloading = false;

      const res = xrefs.filter(
        (xref) => this.assignmentXrefToPSName[xref] === null
      );

      if (res.length > 0) {
        this.$notify(`${res.length} Assignments failed to download.`);
      }
    });
  }

  @Watch('showDialog')
  onShowDialog(value: boolean) {
    if (value) {
      // Clear prior selection
      this.selectedXref = null;

      // Download assignments
      if (
        !isEqual(Object.keys(this.assignmentXrefToPSName), this.assignmentXrefs)
      ) {
        this.downloadAssignments(this.assignmentXrefs);
      }
    }
  }

  @Watch('assignmentXrefs')
  onAssignmentXrefs(newValue: string[], oldValue: string[]): void {
    if (isEqual(newValue, oldValue)) {
      return;
    }

    this.downloadAssignments(newValue);
  }
}
