import { VnextAssignData } from '@/domain/AssignData';
import { LTILaunch } from '@/domain/LTI';
import { coreAxios } from '@/plugins/axios';

export interface DeepLinkResponseFormData {
  redirectUrl: string;
  jwt: string;
}

const END_POINT = '/lti';

export const getDeepLinkResponse = (
  assignData: VnextAssignData
): Promise<DeepLinkResponseFormData> => {
  return coreAxios.post(`${END_POINT}/assign`, assignData).then((res) => {
    return res.data;
  });
};

export const getLaunch = (): Promise<LTILaunch> => {
  return coreAxios.get(`${END_POINT}/launch`).then((res) => {
    return res.data;
  });
};
