
import { Component, Vue, Prop } from 'vue-property-decorator';
import SkillBuilderInfoDialog from '@/components/FindProblems/SkillBuilderInfoDialog.vue';
import { FolderDefinition } from '@/domain/Folder';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import { RETURN_TEXT, RETURN_URL } from '@/domain/PageParams';

@Component({
  components: {
    SkillBuilderInfoDialog,
  },
})
export default class LessonPageTopBar extends Vue {
  @Prop() value: string;
  @Prop() path: FolderDefinition[];
  @Prop() title: string;
  @Prop({ default: () => [] }) tabs: ProblemSetDefinition[];
  @Prop({ default: false }) isSkillBuilder: boolean;

  skillBuilderDialog = false;

  get selected(): number {
    const index = this.tabs.findIndex((tab) => tab.xref == this.value);
    if (index == -1) {
      this.selected = 0;
      return 0;
    } else {
      return index;
    }
  }

  set selected(index: number) {
    const tab = this.tabs[index];
    if (tab) {
      this.$emit('input', tab.xref);
    }
  }

  get returnUrl(): string | undefined {
    return this.$route.query[RETURN_URL] as string;
  }

  get returnText(): string | undefined {
    return this.$route.query[RETURN_TEXT] as string;
  }

  getTabPath(index: number): string {
    const elements = this.path.slice(0, index + 1).map((element) => {
      return element.xref;
    });
    return elements.join(',');
  }
}
