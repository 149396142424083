import { ActionTree } from 'vuex';
import { TutorState } from './types';
import { RootState } from '../types';
import { User } from '@/domain/User';
import { findCourses, getCourseRoster } from '@/api/core/course.api';
import { AssignmentDefinition } from '@/domain/Assignment';
import {
  getAssignmentDefinitions,
  getAssignmentReportData,
} from '@/api/core/assignment.api';
import { StudentData } from '@/domain/ReportData/AssignmentData';

export const actions: ActionTree<TutorState, RootState> = {
  /**
   * Downloads this users' students
   * only if not already downloaded/downloading
   */
  requestTutees(context): Promise<Array<User>> {
    if (
      !context.state.hasDownloadedTutees &&
      !context.state.isDownloadingTutees
    ) {
      findCourses({
        teacher: 'me',
      }).then(async (menteeCourses) => {
        const promises: Array<Promise<Array<User>>> = [];

        menteeCourses.forEach((course) => {
          const promise = context.dispatch(
            'getRosterForCourse',
            course.courseXref
          );

          promises.push(promise);
        });

        return Promise.all(promises).then((rosters) => {
          // Want a flat list of users, so creating a map to get unique users, then converting to array
          const userMap = new Map();

          rosters.forEach((roster) => {
            roster.forEach((user) => {
              if (!userMap.has(user.xref)) {
                userMap.set(user.xref, user);
              }
            });
          });

          context.commit('setTutees', Array.from(userMap.values()));
          context.commit('setHasDownloadedTutees', true);
          context.commit('setIsDownloadingTutees', false);
        });
      });
    }

    return Promise.resolve(context.state.tutees);
  },

  async requestAssignmentDefinitions(
    context,
    { tuteeXrefs, currentUserXref, weekSelected, skills }
  ): Promise<void> {
    const startDateTime = weekSelected.startDateTime;
    const endDateTime = weekSelected.endDateTime;

    // Set downloading assignments to true
    context.commit('setIsDownloadingStudentAssignments', true);
    context.commit('setHasDownloadedStudentAssignments', false);

    // Get a list of assignments for each student
    await Promise.all(
      context.state.selectedTuteeXrefs.map((studentXref) => {
        return getAssignmentDefinitions({
          userXref: studentXref,
          isActive: 'ENABLED',
          releaseDate: `LT:${endDateTime}[AND]GT:${startDateTime}`,
          skills,
        }).then((assignmentList) => {
          context.commit('setStudentToAssignmentDefinitionsMap', {
            studentXref,
            assignmentList: assignmentList.data,
          });
        });
      })
    )
      .then(() => {
        context.commit('setIsDownloadingStudentAssignments', false);
        context.commit('setHasDownloadedStudentAssignments', true);
      })
      .catch((error) => {
        context.commit('setIsDownloadingStudentAssignments', false);
        context.commit('setHasDownloadedStudentAssignments', false);
        context.commit('setNoAssignmentDataFound', true);
        Promise.reject(error);
      });

    // Since the student assignments will probably heavily overlap, condense down to a single flat list
    const uniqueAssignments: Map<string, AssignmentDefinition> = new Map();

    // Filter out assignments owned by other teachers
    context.state.studentToAssignmentDefinitionsMap.forEach(
      (assignmentDefArray) => {
        assignmentDefArray.forEach((assignmentDef) => {
          if (
            assignmentDef.ownerXref === currentUserXref &&
            !uniqueAssignments.has(assignmentDef.xref)
          ) {
            uniqueAssignments.set(assignmentDef.xref, assignmentDef);
          }
        });
      }
    );

    // Get report data for each of the unique assignments
    const reportData =
      context.state.assignmentReportData.size > 0
        ? context.state.assignmentReportData
        : new Map();

    // Set downloading reports to true
    context.commit('setIsDownloadingAssignmentReports', true);
    context.commit('setHasDownloadedAssignmentReports', false);

    await Promise.all(
      Array.from(uniqueAssignments.values()).map((assignmentDef) => {
        if (!reportData.has(assignmentDef.xref)) {
          return getAssignmentReportData(assignmentDef.xref, {
            usersFilter: tuteeXrefs,
          }).then((assignmentReportData: StudentData) => {
            if (assignmentReportData) {
              reportData.set(assignmentDef.xref, assignmentReportData);
            }
          });
        }
      })
    )
      .then(() => {
        // Set down downloading to true
        context.commit('setIsDownloadingAssignmentReports', false);
        context.commit('setHasDownloadedAssignmentReports', true);
      })
      .catch((error) => {
        context.commit('setIsDownloadingAssignmentReports', false);
        context.commit('sethasDownloadedAssignmentReports', false);
        context.commit('setNoAssignmentDataFound', true);
        Promise.reject(error);
      });

    context.commit('setAssignmentReportData', reportData);
    context.commit(
      'setAssignmentDefinitions',
      Array.from(uniqueAssignments.values())
    );
  },

  getRosterForCourse(context, xref): Promise<Array<User>> {
    return getCourseRoster(xref).then((roster) => {
      return roster;
    });
  },
};
