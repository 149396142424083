// Computed properties that are shared
import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { ContentState } from './types';
import { ITutorStrategy } from '@/domain/Tutoring';

export const getters: GetterTree<ContentState, RootState> = {
  targetToTutorStrategiesMap(
    state: ContentState
  ): Record<string, ITutorStrategy[]> {
    const res: Record<string, ITutorStrategy[]> = {};
    const tutorStrategyMap = state.tutorStrategyMap;
    for (const tsCeri in tutorStrategyMap) {
      const tutorStrategy = tutorStrategyMap[tsCeri];
      const targetCeri = tutorStrategy.tutoringTarget;
      if (res[targetCeri]) {
        res[targetCeri].push(tutorStrategy);
      } else {
        res[targetCeri] = [tutorStrategy];
      }
    }
    return res;
  },
};
