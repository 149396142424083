
import { Vue, Component, Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { buildTimeFrame } from '@/utils/buildTimeFrame.util';

dayjs.extend(customParseFormat);

@Component
export default class DateRangeSelectionDialog extends Vue {
  @Prop({ required: true }) value: boolean;

  calendarDateFormat = 'YYYY-MM-DD';
  displayDateformat = 'MM/DD/YYYY';

  dates = [
    dayjs().subtract(7, 'days').format(this.calendarDateFormat),
    dayjs().format(this.calendarDateFormat),
  ];

  // Controls which months the calendars are on initially. Left current month, right current + 1
  leftPickerMonth = dayjs().startOf('month').format(this.calendarDateFormat);
  rightPickerMonth = dayjs(this.leftPickerMonth)
    .add(1, 'month')
    .format(this.calendarDateFormat);

  get sortedDates(): Array<string> {
    return this.dates.sort();
  }

  // Calendars want yyyy-mm-dd, but we want to display mm/dd/yyyy
  get selectedStartDate(): string {
    return this.sortedDates[0]
      ? dayjs(this.sortedDates[0]).format(this.displayDateformat)
      : '';
  }

  set selectedStartDate(newDate) {
    if (this.validateDisplayDate(newDate)) {
      this.$set(this.dates, 0, dayjs(newDate).format(this.calendarDateFormat));
    }
  }

  get selectedEndDate(): string {
    return this.sortedDates[1]
      ? dayjs(this.sortedDates[1]).format(this.displayDateformat)
      : '';
  }

  set selectedEndDate(newDate) {
    if (this.validateDisplayDate(newDate)) {
      this.$set(this.dates, 1, dayjs(newDate).format(this.calendarDateFormat));
    }
  }

  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(val: boolean) {
    this.$emit('input', val);
  }

  closeDialog(): void {
    const currentSelectedTimeFrame = this.$store.state.tutor.selectedTimeFrame;
    if (currentSelectedTimeFrame) {
      this.dates = [
        dayjs(currentSelectedTimeFrame.startDateTime).format(
          this.calendarDateFormat
        ),
        dayjs(currentSelectedTimeFrame.endDateTime).format(
          this.calendarDateFormat
        ),
      ];
    }
    this.showDialog = false;
  }

  // Without this when a user types it will break the text-field, also doubles as validation
  validateDisplayDate(dateToValidate: string): boolean {
    return dayjs(dateToValidate, this.displayDateformat, true).isValid();
  }

  // Change the other calendar month by 1 when the arrows are clicked
  updateLeftPickerMonth(event: string): void {
    this.leftPickerMonth = dayjs(event)
      .subtract(1, 'month')
      .format(this.calendarDateFormat);
  }

  updateRightPickerMonth(event: string): void {
    this.rightPickerMonth = dayjs(event)
      .add(1, 'month')
      .format(this.calendarDateFormat);
  }

  setDateRange() {
    this.$store.commit(
      'tutor/setSelectedTimeFrame',
      buildTimeFrame(this.dates)
    );
    this.showDialog = false;
  }

  goToToday() {
    this.leftPickerMonth = dayjs().format(this.calendarDateFormat);
  }

  resetDateSelections() {
    this.dates = [
      dayjs().subtract(7, 'days').format(this.calendarDateFormat),
      dayjs().format(this.calendarDateFormat),
    ];
    this.goToToday();
  }
}
