
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class CopyProblemDialog extends Vue {
  @Prop() value: boolean;
  @Prop() copyData: {
    ceri: string;
    type: string;
    replace: boolean;
  };

  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(value: boolean) {
    this.$emit('input', value);
  }

  copy(): void {
    if (this.copyData) {
      if (this.copyData.type == 'PS') {
        this.$emit('copy-problem-set');
      } else {
        this.$emit('copy-problem', this.copyData.replace);
      }
    }
    this.showDialog = false;
  }
}
