import { Route, NavigationGuardNext } from 'vue-router';
import store from '@/store';

export default (to: Route, from: Route, next: NavigationGuardNext): void => {
  const shouldReload = store.getters['meta/getShouldRefreshOnRouterChange'];
  if (shouldReload) {
    store.commit('meta/setShouldReloadOnRouterChange', false);
    window.location.pathname = to.fullPath;
    return next(false);
  }
  return next();
};
