// Computed properties that are shared
import { GetterTree } from 'vuex';
import { CurriculumState } from './types';
import { RootState } from '../types';
import { CurriculumDefinition } from '@/domain/Curriculum';

export const getters: GetterTree<CurriculumState, RootState> = {
  getTypeToCurriculaMap(
    state: CurriculumState
  ): Map<string, CurriculumDefinition[]> {
    const curriculaTypeMap = new Map();

    state.downloadedDefinitions.forEach((curriculum: CurriculumDefinition) => {
      const sameTypeCurricula = curriculaTypeMap.get(
        curriculum.curriculumCategoryType
      );

      if (sameTypeCurricula) {
        sameTypeCurricula.push(curriculum);
      } else {
        curriculaTypeMap.set(curriculum.curriculumCategoryType, [curriculum]);
      }
    });

    return curriculaTypeMap;
  },
};
