// Computed properties that are shared
import { GetterTree } from 'vuex';
import { AlertMessage } from '@/domain/AlertMessage';
import { NotificationState } from './types';
import { RootState } from '../types';

export const getters: GetterTree<NotificationState, RootState> = {
  getAlertMessages(state: NotificationState): Array<AlertMessage> {
    return state.alertMessages;
  },
};
