
import { Component, Vue, Prop } from 'vue-property-decorator';
import { User } from '@/domain/User';

@Component
export default class NoStudentSelectedView extends Vue {
  @Prop() students!: Array<User>;
  @Prop() value: string;

  userSelected: string | null = null;

  get studentSelected(): string | null {
    return this.userSelected;
  }

  set studentSelected(val: string | null) {
    this.userSelected = val;

    this.$emit('input', val);
  }
}
