
import {
  AnswerPart,
  AnswerSet,
  AnswerSetProperties,
  AnswerType,
  AnswerValue,
  ShowCorrectnessType,
} from '@/domain/Problem';
import BuilderEditor from '@/components/Builder/BuilderEditor.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  ResponseBox,
  ResponseBoxType,
} from '@/utils/tinyMCE/problemBuildertinyMCEConfig';
import {
  getAnswerTypeDescription,
  getAnswerTypeItems,
} from '@/utils/problem.util';
import { cloneDeep } from 'lodash';

@Component({
  components: { BuilderEditor },
})
export default class FillInAnswers extends Vue {
  @Prop() answerSet: AnswerSet | null;
  @Prop({ default: () => [] }) responseBoxes: ResponseBox[];
  @Prop({ default: false }) disabled: boolean;

  AnswerType = AnswerType;
  ResponseBoxType = ResponseBoxType;
  getAnswerTypeDescription = getAnswerTypeDescription;

  isMultipleCorrect: Record<number, boolean> = {};

  get answerParts(): AnswerPart[] {
    return this.encode((this.answerSet?.members as AnswerPart[]) ?? [], false);
  }

  set answerParts(value: AnswerPart[]) {
    this.updateParts(value);
  }

  get properties(): AnswerSetProperties {
    return this.answerSet?.properties ?? {};
  }

  set properties(value: AnswerSetProperties) {
    this.$emit('answersChanged', {
      memberType: 'ANSWER_SET',
      ...this.answerSet,
      properties: value,
    });
  }

  get fillInAnswerTypes(): AnswerType[] {
    return [
      AnswerType.EXACT_MATCH,
      // AnswerType.PURE_EQUIVALENCE,
      // AnswerType.SYMBOLIC_EQUIVALENCE,
      AnswerType.ALGEBRA,
      AnswerType.EXACT_FRACTION,
      AnswerType.NUMERIC,
      AnswerType.NUMERIC_EXPRESSION,
    ];
  }

  get answerTypes(): { text: string; value: AnswerType }[] {
    return getAnswerTypeItems(this.fillInAnswerTypes);
  }

  get isInterchangeable(): boolean {
    // All Answer Parts MUST be of the same type.
    return (
      this.answerParts.length > 1 &&
      this.answerParts.every(
        (part) => part.answerType === this.answerParts[0].answerType
      )
    );
  }

  get interchangeable(): boolean {
    return this.properties?.INTERCHANGEABLE ?? false;
  }

  set interchangeable(value: boolean) {
    this.properties = { ...this.properties, INTERCHANGEABLE: value };
  }

  get partialCorrectness(): boolean {
    return this.properties?.SHOW_CORRECTNESS === ShowCorrectnessType.PARTIAL;
  }

  set partialCorrectness(value: boolean) {
    this.properties = {
      ...this.properties,
      SHOW_CORRECTNESS: value
        ? ShowCorrectnessType.PARTIAL
        : ShowCorrectnessType.FULL,
    };
  }

  get selectedAnswerIndex(): Record<number, number> {
    const partsToAnswer: Record<number, number> = {};

    this.answerParts.forEach((answerPart, partIndex) => {
      // default to 0, i.e the first answer
      partsToAnswer[partIndex] = 0;

      answerPart.answerValues?.forEach((value, index) => {
        if (value.isCorrect) {
          partsToAnswer[partIndex] = index;
        }
      });
    });

    return partsToAnswer;
  }

  addAnswerValueToAnswerPart(answerPartIndex: number) {
    const answerPart: AnswerPart = this.answerParts[answerPartIndex];
    const answerValue = this.getDefaultAnswerValue(answerPart);
    answerPart.answerValues?.push(answerValue);
    this.notifyUpdate();
  }

  removeAnswerValueFromAnswerPart(
    answerPartIndex: number,
    answerValueIndex: number
  ): void {
    const answerPart: AnswerPart = this.answerParts[answerPartIndex];
    answerPart.answerValues?.splice(answerValueIndex, 1);
    this.notifyUpdate();
  }

  getDefaultAnswerValue(answerPart: AnswerPart): AnswerValue {
    const answerType = answerPart.answerType;
    let isCorrect = false;
    if (this.fillInAnswerTypes.includes(answerType)) {
      isCorrect = true;
    }
    return { value: '', isCorrect };
  }

  getNewAnswerPartFromResponseBox(responseBox: ResponseBox): AnswerPart | null {
    switch (responseBox.type) {
      case ResponseBoxType.TEXT: {
        const answerType = AnswerType.NUMERIC;
        const answerValues = [{ value: '', isCorrect: true }];
        return {
          memberType: 'ANSWER_PART',
          answerType,
          htmlMarker: responseBox.marker,
          answerValues,
        };
      }
      case ResponseBoxType.DROPDOWN: {
        const answerType = AnswerType.DROP_DOWN;
        const answerValues = Array.from({ length: 4 }, (_, index) => ({
          value: '',
          isCorrect: index === 0,
        }));
        return {
          memberType: 'ANSWER_PART',
          answerType,
          htmlMarker: responseBox.marker,
          answerValues,
        };
      }
      default:
        return null;
    }
  }

  initialize(): void {
    const answerParts = (this.answerSet?.members as AnswerPart[]) ?? [];
    // Validate Answer Parts and clean any if needed.
    const markerToPart: Map<number, AnswerPart> = new Map();
    for (const part of answerParts) {
      markerToPart.set(part.htmlMarker, part);
    }
    const validParts = [];
    for (const responseBox of this.responseBoxes) {
      const part = markerToPart.get(responseBox.marker);
      if (part) {
        // Existing Answer Part.
        // FIXME: What if answerType does NOT match that of response box?
        validParts.push(part);
      } else {
        // Newly-added Answer Part. HTML marker needs to be accounted for.
        const answerPart = this.getNewAnswerPartFromResponseBox(responseBox);
        if (answerPart) {
          validParts.push(answerPart);
        }
      }
    }
    this.answerParts = validParts;
  }

  notifyUpdate(): void {
    if (this.interchangeable) {
      this.setAnswerTypesToMatchFirst();
    }
    this.answerParts = this.encode(this.answerParts, true);
  }

  encode(answerParts: AnswerPart[], encode: boolean): AnswerPart[] {
    const convertedParts = [];
    const textArea = this.getTextArea();
    for (const answerPart of answerParts) {
      const copy = cloneDeep(answerPart);
      if (this.fillInAnswerTypes.includes(answerPart.answerType)) {
        const values = copy.answerValues ?? [];
        for (const value of values) {
          if (encode) {
            textArea.innerText = value.value;
            value.value = textArea.innerHTML;
          } else {
            textArea.innerHTML = value.value;
            value.value = textArea.innerText;
          }
        }
      }
      convertedParts.push(copy);
    }
    return convertedParts;
  }

  getTextArea(): HTMLElement {
    let textArea = document.getElementById('fill-in-value-conversion');
    if (!textArea) {
      textArea = document.createElement('textarea');
      textArea.id = 'fill-in-value-conversion';
    }
    return textArea;
  }

  setAnswerTypesToMatchFirst(): void {
    let firstPartAsDefault;
    if (this.answerParts) {
      for (let [index, entry] of this.answerParts.entries()) {
        if (entry.memberType === 'ANSWER_PART') {
          if (!firstPartAsDefault) {
            firstPartAsDefault = entry;
          }
          (this.answerParts[index] as AnswerPart).answerType =
            firstPartAsDefault.answerType;
        }
      }
    }
  }

  updateParts(value: AnswerPart[]): void {
    // IF no Answer Part(s), AnswerSet is NULL.
    if (value.length) {
      this.$emit('answersChanged', {
        memberType: 'ANSWER_SET',
        ...this.answerSet,
        members: value,
      });
    } else {
      this.$emit('answersChanged', null);
    }
  }

  setCorrectAnswerPartValue(
    answerPartIndex: number,
    answerValueIndex: number
  ): void {
    const answerParts = cloneDeep(this.answerParts);
    const answerPart: AnswerPart = answerParts[answerPartIndex];

    if (this.isMultipleCorrect[answerPartIndex] && answerPart.answerValues) {
      const answer = answerPart.answerValues[answerValueIndex];
      answer.isCorrect = !answer.isCorrect;
    } else {
      answerPart.answerValues?.forEach((answer, index) => {
        answer.isCorrect = index === answerValueIndex;
      });
    }

    this.updateParts(answerParts);
  }

  toggleMultipleCorrect(checkboxChecked: boolean, partIndex: number) {
    // Reset back to a single answer when multiple correct is toggled off
    if (!checkboxChecked) {
      this.setCorrectAnswerPartValue(partIndex, 0);
    }
  }

  created() {
    this.answerParts.forEach((part, partIndex) => {
      const correctAnswers = (part.answerValues ?? []).filter(
        (answer: AnswerValue) => answer.isCorrect
      ).length;

      Vue.set(this.isMultipleCorrect, partIndex, correctAnswers > 1);
    });
  }

  @Watch('responseBoxes')
  onUpdateResponseBoxes(): void {
    // Make sure response boxes and Answer Parts in sync.
    if (this.responseBoxes.length !== this.answerParts.length) {
      this.initialize();
    }
  }

  @Watch('isInterchangeable')
  onAllowInterchanegable(): void {
    // IF not interchangeable (Answer Parts not of the same answerType), unset interchangeable prop
    // if set to TRUE previously.
    if (!this.isInterchangeable) {
      this.interchangeable = false;
    }
  }

  //To default Show partial correctness to true when more than 1 answerPart
  @Watch('answerParts')
  onAnswerPartsChanged(newVal: AnswerPart[], oldVal: AnswerPart[]): void {
    if (newVal.length === 2 && oldVal.length === 1) {
      this.partialCorrectness = true;
    } else if (newVal.length === 1 && oldVal.length === 2) {
      this.partialCorrectness = false;
    }
  }
}
