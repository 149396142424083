
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class NoAssignmentView extends Vue {
  message =
    "You haven't assigned any problems yet. Once you do, your assignments will appear here.";

  goToFindProblems(): void {
    this.$router.push({
      name: 'findProblems',
    });
  }
}
