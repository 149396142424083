import { IFolderMember } from '@/domain/Folder';
import { IProblemSetMember } from '@/domain/ProblemSet';

export enum PageView {
  LESSON_VIEW = 1,
  SEARCH_RESULTS = 2,
  MY_PROBLEM_SETS = 3,
  EDIT_MY_PROBLEM_SET = 4,
  MAIN_HIERARCHY = 5,
}

export function getPathParam(
  path: (IFolderMember | IProblemSetMember)[]
): string {
  return path?.map((element) => element.xref).join(',');
}
