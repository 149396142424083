
import {
  AnswerPart,
  AnswerSet,
  AnswerType,
  AnswerValue,
} from '@/domain/Problem';
import BuilderEditor from '@/components/Builder/BuilderEditor.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';

type LabelingOptions = 'numbers' | 'letters' | 'shuffle';

@Component({
  components: { BuilderEditor },
})
export default class ChooseAnswers extends Vue {
  @Prop() type: AnswerType.CHOOSE_N | AnswerType.CHOOSE_ONE;
  @Prop() answerSet: AnswerSet | null;
  @Prop({ default: false }) disabled: boolean;

  AnswerType = AnswerType;

  isMultipleCorrectManual = false;

  localAnswerSet: AnswerSet | null = null;

  get answerPart(): AnswerPart {
    return (
      (this.localAnswerSet?.members?.at(0) as AnswerPart) ??
      (this.getDefaultAnswerSet().members?.at(0) as AnswerPart)
    );
  }

  set answerPart(value: AnswerPart) {
    this.localAnswerSet = {
      memberType: 'ANSWER_SET',
      ...this.localAnswerSet,
      members: [value],
    };
    this.$emit('answersChanged', this.localAnswerSet);
  }

  // What SHOULD be manipulated in this component.
  get answerValues(): AnswerValue[] {
    return this.answerPart.answerValues ?? [];
  }

  set answerValues(value: AnswerValue[]) {
    this.answerPart = { ...this.answerPart, answerValues: value };
  }

  get shuffle(): boolean | null {
    return this.answerPart.properties?.SHUFFLE ?? true;
  }

  get labelStart(): number | string | null {
    return this.answerPart.properties?.LABEL_START ?? null;
  }

  get labelMode(): LabelingOptions | undefined {
    if (this.shuffle) {
      return 'shuffle';
    } else if (typeof this.labelStart === 'number') {
      return 'numbers';
    } else if (typeof this.labelStart === 'string') {
      return 'letters';
    } else {
      // Undefined. No labels maintaining existing order.
      return undefined;
    }
  }

  // Make sure we override SHUFFLE in each case otherwise backend will default to TRUE.
  set labelMode(value: LabelingOptions | undefined) {
    const properties = { ...this.answerPart.properties };
    if (value === 'shuffle') {
      properties.SHUFFLE = true;
      properties.LABEL_START = null;
    } else if (value == 'numbers') {
      // TODO: Support custom labeling.
      properties.LABEL_START = 1;
      properties.SHUFFLE = false;
    } else if (value == 'letters') {
      // TODO: Support custom labeling.
      properties.LABEL_START = 'A';
      properties.SHUFFLE = false;
    } else {
      // Undefined. No labels maintaining existing order.
      properties.SHUFFLE = false;
      properties.LABEL_START = null;
    }
    this.answerPart = { ...this.answerPart, properties };
  }

  // FIXME: Figure out how to handle "wrapping". IF we want to support AA, BB, etc.
  // For now, lets leave it as it is now since the backend only expects this to be
  // a digit OR a letter.
  get answerLabels(): (number | string)[] {
    const labels = [];
    for (let i = 0; i < this.answerValues.length; i++) {
      let start = null;
      let next = null;
      if (this.labelMode == 'numbers') {
        start = Number(this.labelStart);
        next = start + i;
      } else if (this.labelMode == 'letters') {
        start = String(this.labelStart);
        next = String.fromCharCode(start.charCodeAt(0) + i);
      }
      if (next) {
        labels.push(next);
      }
    }
    return labels;
  }

  get isMultipleCorrect(): boolean {
    return (
      this.answerValues.filter((answer) => answer.isCorrect).length > 1 ||
      this.isMultipleCorrectManual
    );
  }

  set isMultipleCorrect(value: boolean) {
    if (!value) {
      this.answerValues = this.answerValues.map((answerValue) => ({
        value: answerValue.value,
        isCorrect: false,
      }));
    }
    this.isMultipleCorrectManual = value;
  }

  created(): void {
    // Initialize
    if (this.answerSet) {
      this.localAnswerSet = cloneDeep(this.answerSet);
    } else {
      this.localAnswerSet = this.getDefaultAnswerSet();
      this.labelMode = 'letters';
    }
  }

  getDefaultAnswerSet(): AnswerSet {
    return {
      memberType: 'ANSWER_SET',
      members: [
        {
          memberType: 'ANSWER_PART',
          htmlMarker: 1,
          answerType: this.type,
          // What SHOULD be manipulated in this component.
          answerValues: [
            { isCorrect: this.type == AnswerType.CHOOSE_ONE, value: '' },
            { isCorrect: false, value: '' },
            { isCorrect: false, value: '' },
            { isCorrect: false, value: '' },
          ],
        },
      ],
    };
  }

  addAnswer(): void {
    let isCorrect = false;
    if (this.type == AnswerType.CHOOSE_N) {
      isCorrect = true;
    }
    this.answerPart = {
      ...this.answerPart,
      answerValues: [...this.answerValues, { isCorrect, value: '' }],
    };
  }

  removeAnswer(index: number): void {
    const answerValues = [...this.answerValues];
    answerValues.splice(index, 1);
    this.answerPart = {
      ...this.answerPart,
      answerValues,
    };
  }

  setCorrectAnswer(index: number, isChecked: boolean): void {
    if (!this.isMultipleCorrect) {
      this.answerValues.forEach((answer, i) => {
        answer.isCorrect = i === index && isChecked;
      });
    } else {
      this.answerValues[index].isCorrect = isChecked;
    }
    this.answerPart = {
      ...this.answerPart,
      answerValues: this.answerValues,
    };
  }
}
