
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataOptions } from 'vuetify';
import TreeGradientTable, {
  GradientHeaders,
  GradientRows,
} from '../base/TreeGradientTable.vue';

@Component({
  components: { TreeGradientTable },
})
export default class HeatMap extends Vue {
  @Prop({ required: true }) color: string;
  @Prop({ required: true }) headerTitle: string;
  @Prop({ required: true }) rowHeaders: GradientHeaders;
  @Prop({ required: true }) rows: GradientRows;
  @Prop({ default: [] }) collapsedPaths: Set<string>;
  @Prop({ required: false }) options: DataOptions;
  @Prop({ default: false }) anonymized: boolean;
  @Prop({ default: false }) link: boolean;
  @Prop({ default: false }) collapsibleHeaders: boolean;
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: true }) disablePopoutGraphs: boolean;
  @Prop({ required: false }) highlightedColumnHeader: string;
  @Prop({ default: false }) hasStudentLevel: boolean;
  @Prop({ required: false }) tooltips: {
    [key: string]: string;
  };

  get columnSpacing(): Map<string, string[]> {
    const columnSpacing = new Map<string, string[]>();

    // Add spacing to average column by default
    columnSpacing.set(this.rowHeaders.averageHeader.value, ['mr-3']);

    this.rowHeaders.itemHeaders.forEach((header) => {
      const spacing = columnSpacing.get(header.value) || [];

      // If there is a highlighted column, then every column gets space on the right
      // And the highlighted column gets double space on both sides
      if (this.highlightedColumnHeader) {
        if (header.text === this.highlightedColumnHeader) {
          spacing.push('highlighted-column');
          spacing.push('mr-4');
          spacing.push('ml-2');
        } else {
          spacing.push('mr-2');
        }
      }

      // If collapsible headers is set, then only the headers that start with numbers or HS get space on the left
      if (this.collapsibleHeaders && this.isStrandHeader(header.text)) {
        spacing.push('ml-2');

        columnSpacing.delete(this.rowHeaders.averageHeader.value);
      }
      columnSpacing.set(header.value, spacing);
    });

    return columnSpacing;
  }

  isStrandHeader(header: string): boolean {
    // Strands all match these patterns like 7.NS or K.G, standards all start with a letter, like A.1 or D.2
    return (
      /^[0-9]\./.test(header) || // = starts with a number then a period (7., 8., etc.)
      /^HS[A-Z]\./.test(header) || // = starts with HS, then any capital letter, then a period (HSA., HSB., etc.)
      /^K\./.test(header) // = starts with K and a period (K.)
    );
  }

  togglePath(path: string): void {
    const collapsedPaths = new Set(this.collapsedPaths); // Shallow copy so we aren't mutating props

    collapsedPaths.has(path)
      ? collapsedPaths.delete(path)
      : collapsedPaths.add(path);

    this.$emit('updateCollapsedPaths', collapsedPaths);
  }
}
