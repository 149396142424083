import {
  StatisticsForType,
  StudentDataReturnType,
  StudentData,
} from '@/domain/ReportData/AssignmentData';
import { coreAxios } from '@/plugins/axios';
import {
  StandardOverTimeDateData,
  TotalStandardStats,
} from '@/domain/ReportData/InsightsHub';
import { TimeSelector } from '@/domain/Time';
import { CancelToken } from 'axios';
import { StudentAndPeerDataPerSkill } from '@/domain/ReportData/Tutor';

const END_POINT = '/sdata';

type IsActiveFilter = 'ENABLED' | 'DISABLED' | 'IGNORE';

interface RequestParams {
  dTypes: Array<StudentDataReturnType>;
  isActive?: IsActiveFilter;
  statisticsFor?: Array<StatisticsForType>;
  groupsScope?: Array<string>;
  assignmentsScope?: Array<string>;
  groupsSubset?: Array<string>;
  usersSubset?: Array<string>;
  timeSelector?: Array<string>;
}

interface StandardOverTimeParams {
  xrefs: Array<string>;
  gradeLevelXref: string;
  overTimeGraphType: string;
  timeSelector: TimeSelector;
}

interface StandardCurrentTimeParams {
  primaryTeachers: boolean;
  mentor: string;
  gradeLevel: string;
  timeSelector: TimeSelector;
}
interface StandardCoherenceParams {
  primaryTeachers: boolean;
  mentor: string;
  gradeLevel: string;
  timeSelector: TimeSelector;
  skillXrefs: Array<string>;
}

const getStandardOverTime = (
  standardHierarchyNodeId: number,
  params: StandardOverTimeParams
): Promise<StandardOverTimeDateData[]> => {
  return coreAxios
    .get(`${END_POINT}/standard-overtime-data/${standardHierarchyNodeId}`, {
      params,
    })
    .then((res) => {
      return res.data;
    });
};

const getStandardCurrentTime = (
  skillStrandId: number,
  params: StandardCurrentTimeParams,
  cancelToken?: CancelToken
): Promise<TotalStandardStats> => {
  const { timeSelector, ...rest } = params;
  return coreAxios
    .get(`${END_POINT}/standard-current-data/${skillStrandId}`, {
      params: { timeSelector: timeSelector?.toArray(), ...rest },
      cancelToken,
    })
    .then((res) => {
      return res.data;
    });
};

const getStandardCoherencyData = (
  standardId: number,
  params: StandardCoherenceParams,
  cancelToken?: CancelToken
): Promise<TotalStandardStats> => {
  const { timeSelector, ...rest } = params;
  return coreAxios
    .get(`${END_POINT}/standard-coherency-data/${standardId}`, {
      params: { timeSelector: timeSelector?.toArray(), ...rest },
      cancelToken,
    })
    .then((res) => {
      return res.data;
    });
};

const getStudentDataForProblemSet = (
  psCeri: string,
  params: RequestParams
): Promise<StudentData> => {
  if (!params.isActive) params.isActive = 'ENABLED';

  return coreAxios
    .get(`${END_POINT}/content/${psCeri}`, {
      params,
    })
    .then((res) => {
      return res.data;
    });
};

const getStudentAndPeerDataPerSkill = (
  studentXrefs: string[],
  startTime: number,
  endTime: number
): Promise<StudentAndPeerDataPerSkill[]> => {
  return coreAxios
    .get(`${END_POINT}/skillData`, {
      params: {
        studentXrefs: studentXrefs,
        startTime: startTime,
        endTime: endTime,
      },
    })
    .then((result) => {
      return result.data;
    });
};

export {
  getStudentAndPeerDataPerSkill,
  getStudentDataForProblemSet,
  getStandardOverTime,
  getStandardCurrentTime,
  getStandardCoherencyData,
};
