
import { QCStatus } from '@/views/MyAssignments/EssayScoringPage.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

interface TextAndIcon {
  text: string;
  color: string;
  icon?: string;
}

@Component({
  components: {},
})
export default class QuickCommentsStatus extends Vue {
  @Prop() qc: QCStatus;

  get textAndIcon(): TextAndIcon | object {
    switch (this.qc) {
      case 'SUCCESS':
        return {
          text: 'Quick comments loaded',
          color: 'primary',
          icon: 'mdi-check',
        };
      case 'FAILURE':
        return {
          text: 'Quick comments not loaded',
          color: 'incorrect',
          icon: 'mdi-close',
        };
      case 'LOADING':
        return {
          text: 'Quick comments loading',
          color: 'primary',
        };
      default:
        return {};
    }
  }

  get hasIcon(): boolean {
    return 'icon' in this.textAndIcon;
  }
}
