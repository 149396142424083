// Want to change or update something?
// Stashing changes synchronously commit mutation
import { ProblemDefinition } from '@/domain/Problem';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import { MutationTree } from 'vuex';
import { ContentState } from './types';
import Vue from 'vue';
import { ContentBuildStatus } from '@/api/core/content.api';
import { ITutorStrategy } from '@/domain/Tutoring';
import { RESET_STATE_MUTATION } from '../types';
import { getDefaultState } from '.';

export const mutations: MutationTree<ContentState> = {
  setProblemSet(state, ps: ProblemSetDefinition): void {
    // Save Problem Set.
    Vue.set(state.problemSetMap, ps.xref, {
      ...ps,
    });
  },
  deleteProblemSet(state, xref: string): void {
    // Delete Problem Set.
    Vue.delete(state.problemSetMap, xref);
  },
  setProblem(state, pr: ProblemDefinition): void {
    // Save Problem.
    Vue.set(state.problemMap, pr.xref, {
      ...pr,
    });
  },
  deleteProblem(state, xref: string): void {
    // Delete Problem.
    Vue.delete(state.problemMap, xref);
  },
  setTutorStrategy(state, ts: ITutorStrategy): void {
    // Save Tutoring.
    Vue.set(state.tutorStrategyMap, ts.xref, {
      ...ts,
    });
  },
  deleteTutorStrategy(state, xref: string): void {
    // Delete Tutoring.
    Vue.delete(state.tutorStrategyMap, xref);
  },
  setRedos(state, redos: Record<string, string[]>): void {
    // Save redos.
    state.redoMap = { ...state.redoMap, ...redos };
  },
  setValidationError(state, status: ContentBuildStatus): void {
    const ceri = status.ceri;
    const failMessages = status.failMessages;
    if (ceri && failMessages) {
      Vue.set(state.validationErrors, ceri, failMessages);
    }
  },
  deleteValidationError(state, xref: string): void {
    Vue.delete(state.validationErrors, xref);
  },
  [RESET_STATE_MUTATION](state): void {
    Object.assign(state, getDefaultState());
  },
};
