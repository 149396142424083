import { GetterTree } from 'vuex';
import { SkillDefinition } from '@/domain/Skill';
import { SkillState } from './types';
import { RootState } from '../types';

export const getters: GetterTree<SkillState, RootState> = {
  getHasDownloaded(state: SkillState): boolean {
    return state.hasDownloaded;
  },
  getIsDownloading(state: SkillState): boolean {
    return state.isDownloading;
  },
  getSelectedSkill(state: SkillState): SkillDefinition | null {
    if (!state.selectedSkillXref) {
      return null;
    }
    const selectedSkill = state.skills.find(
      (skill) => skill.xref === state.selectedSkillXref
    );
    return selectedSkill ? selectedSkill : null;
  },
  getSelectedXref(state: SkillState): string | null {
    if (!state.selectedSkillXref) {
      return null;
    }
    const selectedSkill = state.skills.find(
      (skill) => skill.xref === state.selectedSkillXref
    );
    return selectedSkill ? selectedSkill.xref : null;
  },
  getSkillsMap(state: SkillState): Map<string, SkillDefinition> {
    const res = new Map<string, SkillDefinition>();

    if (state.skills) {
      state.skills.forEach((skill) => res.set(skill.xref, skill));
    }
    return res;
  },
};
