// Computed properties that are shared
import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { AttributionState } from './types';
import { AttributionDefinition } from '@/domain/Attribution';

export const getters: GetterTree<AttributionState, RootState> = {
  getXrefToAttributionMap(
    state: AttributionState
  ): Map<string, AttributionDefinition> {
    const xrefToAttributionMap = new Map();

    for (const attribution of state.downloadedDefinitions) {
      xrefToAttributionMap.set(attribution.xref, attribution);
    }

    return xrefToAttributionMap;
  },
};
