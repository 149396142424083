import { AttributionDefinition } from '@/domain/Attribution';
import { coreAxios } from '@/plugins/axios';

const END_POINT = '/attributions';

const getAttributionDefinitions = (): Promise<AttributionDefinition[]> => {
  return coreAxios.get(`${END_POINT}`).then((result) => {
    return result.data;
  });
};

export { getAttributionDefinitions };
