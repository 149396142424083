
import { Component, Vue, Watch } from 'vue-property-decorator';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';

@Component({})
export default class StandardsScoringDialog extends Vue {
  showDialog = false;

  /**
   * Returns max width for dialog based on current breakpoint
   */
  get maxWidth(): number {
    return this.$vuetify.breakpoint.lgAndUp ? 800 : 600;
  }

  //////////////
  // Mixpanel //
  //////////////

  @Watch('showDialog')
  trackShowDialogOpen(newVal: boolean, oldVal: boolean): void {
    if (newVal !== oldVal) {
      trackMixpanel(EventType.standardsReportScoringDialog, {
        assignmentXref: this.$router.currentRoute.params.xref,
        showDialog: newVal,
      });
    }
  }
}
