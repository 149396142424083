
import { ContentType } from '@/domain/Content';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import { getContentType } from '@/utils/builder.util';
import { getProblemSetTypeDisplayName } from '@/utils/problemSet.util';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

interface ProblemSetStats {
  childProblemSetTypes: string[];
  numChildProblems: number;
}

@Component
export default class AddProblemSetDialog extends Vue {
  @Prop() value: boolean;

  psXref = '';
  loading = false;
  notFound = false;

  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(val: boolean) {
    this.$emit('input', val);
  }

  get problemSetMap(): Record<string, ProblemSetDefinition> {
    return this.$store.state.content.problemSetMap;
  }

  get problemSet(): ProblemSetDefinition | undefined {
    return this.problemSetMap[this.psXref];
  }

  get problemSetType(): string {
    return this.problemSet
      ? getProblemSetTypeDisplayName(this.problemSet.problemSetType)
      : '';
  }

  get problemSetStats(): ProblemSetStats {
    return this.computeStats(this.psXref);
  }

  get errorMessage(): string | null {
    return this.notFound ? 'Problem Set ID not found' : null;
  }

  getProblemSet(): void {
    // Search (download) Problem Set by ID if not already.
    if (!this.problemSet) {
      this.loading = true;
      this.notFound = false;
      // FIXME: Figure out whether we want to save the entire search tree in store.
      this.$store
        .dispatch('content/getProblemSetTree', {
          xref: this.psXref,
        })
        .then(() => {
          // Problem Set not found.
          if (!this.problemSetMap[this.psXref]) {
            this.notFound = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  computeStats(psXref: string): ProblemSetStats {
    const problemSet = this.problemSetMap[psXref];
    const stats: ProblemSetStats = {
      childProblemSetTypes: [],
      numChildProblems: 0,
    };
    if (problemSet) {
      for (const child of problemSet.children) {
        if (getContentType(child) == ContentType.PROBLEM) {
          stats.numChildProblems++;
        } else {
          const childProblemSet = this.problemSetMap[child];
          if (childProblemSet) {
            const childStats = this.computeStats(child);
            // FIXME: Do we need to handle duplicates?
            stats.childProblemSetTypes.push(
              // Include current child Problem Set.
              getProblemSetTypeDisplayName(childProblemSet.problemSetType),
              // AND its children.
              ...childStats.childProblemSetTypes
            );
            stats.numChildProblems += childStats.numChildProblems;
          }
        }
      }
    }
    return stats;
  }

  addProblemSet(): void {
    this.$emit('added', this.problemSet);
    this.showDialog = false;
  }

  @Watch('psXref')
  onXref(): void {
    // Clear prior search results.
    this.notFound = false;
  }

  @Watch('showDialog')
  onDialog(): void {
    if (!this.loading) {
      // Clear prior search CERI.
      this.psXref = '';
    }
  }
}
