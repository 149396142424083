/**
 * ECMA2016 / ES6
 * Source: https://gist.github.com/danieliser/b4b24c9f772066bcf0a6
 */
export function convertHexToRGBA(
  hexCode: string,
  opacity = 1,
  maxValue?: number
): string {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  /* Get percentage using a Logarithmic scale */
  if (maxValue && maxValue > 100) {
    opacity = (Math.log(opacity) / Math.log(maxValue)) * 100;
  }

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  return `rgba(${r},${g},${b},${opacity})`;
}
