import {
  ActionType,
  IAction,
  StudentResponseAction,
  StudentSubmissionAction,
} from '@/domain/Action';

export function getActionResponses(
  actions: IAction[]
): { response: Record<number, string[]>; correct?: boolean }[] {
  const responses: {
    response: Record<number, string[]>;
    correct?: boolean;
  }[] = [];
  for (const action of actions) {
    if (action.actionType == ActionType.STUDENT_RESPONSE_ACTION) {
      const partData = (action as StudentResponseAction).partData;
      const response: Record<number, string[]> = {};
      let correct = true;
      for (const marker in partData) {
        response[marker] = partData[marker].response;
        correct = correct && partData[marker].correct;
      }
      responses.push({ response, correct });
    } else if (action.actionType == ActionType.STUDENT_SUBMISSION_ACTION) {
      const response = (action as StudentSubmissionAction).response;
      responses.push({ response: { 1: [response] } });
    }
  }
  return responses;
}
