
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ProblemTypeSDK3 } from '@/domain/Problem';
import { ProblemTypeItem, getProblemTypeItems } from '@/utils/problem.util';
@Component({})
export default class ProblemTypeFilter extends Vue {
  problemTypes = Object.values(ProblemTypeSDK3).filter((type) =>
    [
      ProblemTypeSDK3.OPEN_RESPONSE,
      ProblemTypeSDK3.CHOOSE_ONE,
      ProblemTypeSDK3.CHOOSE_N,
      ProblemTypeSDK3.FILL_IN,
      ProblemTypeSDK3.SORT,
      ProblemTypeSDK3.DRAG_DROP,
    ].includes(type)
  );

  selectedProblemType: ProblemTypeSDK3[] = [...this.problemTypes];

  get problemTypeFilters(): ProblemTypeItem[] {
    return getProblemTypeItems(this.problemTypes);
  }

  @Watch('selectedProblemType') problemTypeChanged(): void {
    this.$emit('selectedProblemTypeChanged', this.selectedProblemType);
  }
}
