
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import { getProblemSetTypeDisplayName } from '@/utils/problemSet.util';
import { cloneDeep, isEqual } from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

enum Category {
  GENERAL,
  TYPES,
  CURRICULUM,
  GRADE_LEVEL_STANDARDS,
}

@Component({})
export default class UpdateProblemSetDialog extends Vue {
  @Prop({ default: false }) value: boolean;
  @Prop({ required: true }) problemSet: ProblemSetDefinition;

  loading = false;
  localProblemSet: Partial<ProblemSetDefinition> = {};
  headerText = '';

  Category = Category;

  category = Category.GENERAL;
  categoryTabs = [
    {
      label: 'General',
      icon: 'mdi-folder-information',
      type: Category.GENERAL,
    },
    {
      label: 'Types',
      icon: 'mdi-code-braces',
      type: Category.TYPES,
    },
    {
      label: 'Curriculum',
      icon: 'mdi-bookshelf',
      type: Category.CURRICULUM,
    },
    {
      label: 'Grade Level & Standards',
      icon: 'mdi-school',
      type: Category.GRADE_LEVEL_STANDARDS,
    },
  ];

  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(val: boolean) {
    this.$emit('input', val);
  }

  get headerTextField(): string {
    return this.localProblemSet.properties?.HEADER || '';
  }

  set headerTextField(value: string) {
    const header = value.length ? value : null;
    if (!this.localProblemSet.properties) {
      // .properties can be undefined so we have to create it if it doesn't exist to avoid error
      Vue.set(this.localProblemSet, 'properties', { HEADER: header });
    } else {
      Vue.set(this.localProblemSet.properties, 'HEADER', header);
    }
  }

  get modifiedFields(): Partial<ProblemSetDefinition> {
    const local = this.localProblemSet;
    const prop = this.problemSet;
    const modified: Partial<ProblemSetDefinition> = {};

    for (const [key, value] of Object.entries(local as ProblemSetDefinition)) {
      const k = key as keyof ProblemSetDefinition;
      if (!isEqual(prop[k], value)) {
        modified[k] = value;
      }
    }
    return modified;
  }

  get hasModifiedFields(): boolean {
    return Object.keys(this.modifiedFields).length > 0;
  }

  get psTypeDisplayName(): string {
    const psType = this.localProblemSet.problemSetType;
    return psType ? getProblemSetTypeDisplayName(psType) : '';
  }

  updateProblemSet(): void {
    this.loading = true;
    this.$store
      .dispatch('content/saveProblemSet', {
        xref: this.problemSet.xref,
        modifiedFields: this.modifiedFields,
      })
      .then(({ ceri: xref, failMessages: error }) => {
        if (error) {
          this.$notify(`Failed to update problem set: ${error}`);
        } else if (xref) {
          this.$notify(`Saved changes to Problem Set ${xref}.`);
          this.showDialog = false;
        }
      })
      .catch(() => {
        this.$notify('Something went wrong. Failed to update Problem Set.');
      })
      .finally(() => {
        this.loading = false;
      });
  }

  @Watch('showDialog')
  resetState(): void {
    this.category = Category.GENERAL;
    this.localProblemSet = cloneDeep(this.problemSet);
  }
}
