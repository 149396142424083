import { InsightsHubLevel } from './Curriculum';
import { GradeLevel } from './Grade';

export interface AttributeDefinition {
  name: string;
  description: string;
  dataType: string;
  targetTypes: AttributeTargetType[];
  valueType: ValueType;
  isActive: boolean;
}

export enum AttributeTargetType {
  GROUP = 'GROUP',
  USER = 'USER',
  ASSIGNMENT = 'ASSIGNMENT',
  PARTNER = 'PARTNER',
  LEGACY_PROBLEM = 'LEGACY_PROBLEM',
  LEGACY_PROBLEM_SET = 'LEGACY_PROBLEM_SET',
  SDK3_PROBLEM = 'SDK3_PROBLEM',
  SDK3_PROBLEM_SET = 'SDK3_PROBLEM_SET',
  LEGACY_FOLDER = 'LEGACY_FOLDER',
}

export enum ValueType {
  UNBOUNDED_SINGLE_VALUED = 'UNBOUNDED_SINGLE_VALUED',
  DISCRETE_SINGLE_VALUED = 'DISCRETE_SINGLE_VALUED',
  DISCRETE_MULTI_VALUED = 'DISCRETE_MULTI_VALUED',
  UNBOUNDED_MULTI_VALUED = 'UNBOUNDED_MULTI_VALUED',
  INCREMENTAL_SINGLE_VALUED = 'INCREMENTAL_SINGLE_VALUED',
}

export enum AttributeNames {
  COURSE_END_TIME = 'courseEndTime',
  CURRICULUM = 'curriculum',
  GRADE_LEVEL = 'gradeLevel',
  NCES_SCHOOL = 'ncesSchool',
  QUICK_COMMENTS_TEACHER = 'quickCommentsTeacher',
  ATTRIBUTION = 'attribution',
  INSIGHTS_HUB_LEVEL = 'insightsHubLevel',
  SKILL = 'skill',
  IS_TEXTBOOK = 'isTextbook',
  IS_ASSESSMENT = 'isAssessment',
  IS_RESEARCH = 'isResearch',
  IS_CERTIFIED = 'isCertified',
}

export interface AttributesMap {
  [AttributeNames.CURRICULUM]?: string[];
  [AttributeNames.ATTRIBUTION]?: string[];
  [AttributeNames.SKILL]?: string[];
  [AttributeNames.IS_TEXTBOOK]?: boolean;
  [AttributeNames.IS_RESEARCH]?: boolean;
  [AttributeNames.IS_CERTIFIED]?: boolean;
  [AttributeNames.IS_ASSESSMENT]?: boolean;
  [AttributeNames.GRADE_LEVEL]?: GradeLevel[];
  [AttributeNames.INSIGHTS_HUB_LEVEL]?: InsightsHubLevel;
  [AttributeNames.NCES_SCHOOL]?: string[];
  [AttributeNames.QUICK_COMMENTS_TEACHER]?: boolean;
}

export type AttributeValueType =
  | number
  | string
  | boolean
  | Record<string, unknown>;

export interface IHasAttributes {
  attributes?: Partial<AttributesMap>;
}
