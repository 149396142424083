
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ProblemSetFileView from './ProblemSetFileView.vue';
import FolderFileView from './FolderFileView.vue';
import {
  removeMembersFromFolder,
  updateFolderMemberPosition,
} from '@/api/core/folders.api';
import {
  FolderDefinition,
  FolderMember,
  FolderMemberType,
  IFolderMember,
} from '@/domain/Folder';
import { ContentMemberType, ProblemSetDefinition } from '@/domain/ProblemSet';
import { cloneDeep, orderBy } from 'lodash';
import { getPathParam } from '@/utils/navigation.util';
import draggable from 'vuedraggable';
import ScrollObserver from '../base/ScrollObserver.vue';
import { AclPermissionType } from '@/domain/Acls';

export enum Mode {
  BROWSE = 'BROWSE',
  EDIT = 'EDIT',
}

export type SortBy = {
  attribute: keyof IFolderMember;
  direction: 'asc' | 'desc';
};

@Component({
  components: {
    draggable,
    FolderFileView: () => import('./FolderFileView.vue'),
    ProblemSetFileView,
    ScrollObserver,
  },
})
export default class FolderExplorer extends Vue {
  @Prop() value: boolean;
  @Prop({ required: true }) folder: FolderDefinition;
  @Prop({ default: () => [] }) path: FolderDefinition[];
  @Prop({ default: 1 }) depth: number;
  @Prop({ default: () => Mode.BROWSE }) mode: Mode;
  @Prop({ required: false }) sort: SortBy | undefined | null;
  @Prop({ default: false }) showAttribution: boolean;
  @Prop({
    default: () => [
      FolderMemberType.FOLDER,
      FolderMemberType.PUB_PS,
      FolderMemberType.WIP_PS,
    ],
  })
  fmTypes: FolderMemberType[];
  @Prop({ default: () => [] }) cmTypes: ContentMemberType[];
  @Prop({ default: 20 }) limit: number;

  Mode = Mode;

  isDownloading = false;

  FolderMemberType = FolderMemberType;

  downloadingMoreMembers = false;

  nextPageToken: number | null = 0;

  get draggable(): boolean {
    return (
      this.mode == Mode.EDIT &&
      !this.sort &&
      this.folder.permissions.includes(AclPermissionType.UPDATE)
    );
  }

  get pathList(): FolderDefinition[] {
    return this.path.length ? this.path : [this.folder];
  }

  get folderPath(): string {
    return getPathParam(this.pathList);
  }

  get indent(): number {
    return this.depth * 32;
  }

  get folderMap(): Record<string, FolderDefinition> {
    return this.$store.state.folder.folderMap;
  }

  get problemSetMap(): Record<string, ProblemSetDefinition> {
    return this.$store.state.content.problemSetMap;
  }

  get children(): FolderMember[] {
    const children = this.folder.children;
    return children.map((child) => {
      // FIXME: Do not support Assignments.
      if (child.startsWith('PS') || child.startsWith('WPS')) {
        return this.problemSetMap[child];
      } else {
        return this.folderMap[child];
      }
    });
  }

  get sorted(): FolderMember[] {
    if (this.sort) {
      const sortAttr = this.sort.attribute;

      return orderBy(
        this.children,
        (child) => child[sortAttr],
        this.sort.direction
      );
    }
    return this.children;
  }

  openFolder(): void {
    if (this.value) {
      // Download members in Folder if not already.
      if (this.folder.children.length === 0) {
        this.isDownloading = true;
        this.$store
          .dispatch('folder/getFolderMembers', {
            xref: this.folder.xref,
            limit: this.limit,
          })
          .then((nextPageToken) => {
            this.nextPageToken = nextPageToken;
          })
          .finally(() => {
            this.isDownloading = false;
          });
      }
    }
  }

  fetchNextPage(): void {
    // NULL = end of the list, no more to fetch
    if (
      this.isDownloading ||
      this.downloadingMoreMembers ||
      !this.nextPageToken
    ) {
      return;
    }
    this.downloadingMoreMembers = true;
    this.$store
      .dispatch('folder/getFolderMembers', {
        xref: this.folder.xref,
        limit: this.limit,
        nextPageToken: this.nextPageToken,
      })
      .then((nextPageToken) => {
        this.nextPageToken = nextPageToken;
      })
      .finally(() => {
        this.downloadingMoreMembers = false;
      });
  }

  searchFolder(): void {
    const searchPath = (this.$route.query.p ?? '') as string;
    // Search Target is a member in this Folder.
    if (searchPath.startsWith(this.folderPath)) {
      // Open Folder if not already.
      if (this.value) {
        this.openFolder();
      } else {
        this.$emit('input', true);
      }
      if (searchPath === this.folderPath) {
        // Current Folder is search Target.
        // Scroll to 'me'.
        this.$nextTick(() => {
          this.$vuetify.goTo(`#${CSS.escape(searchPath)}`, {
            duration: 300,
            offset: 0,
            easing: 'easeInOutCubic',
          });
        });
      }
    }
  }

  created(): void {
    this.searchFolder();
    this.openFolder();
  }

  onDragStart(): void {
    // Close any opened members to show only sibling files.
    const expandable = (this.$refs.expandable ?? []) as (
      | FolderFileView
      | ProblemSetFileView
    )[];
    for (const component of expandable) {
      component.open = false;
    }
  }

  onDragEnd(moved: { oldIndex: number; newIndex: number }): void {
    if (moved.oldIndex != moved.newIndex) {
      const member = this.children[moved.oldIndex];
      updateFolderMemberPosition(
        this.folder.xref,
        member.xref,
        // Positions start at 1.
        moved.newIndex + 1
      )
        .then(() => {
          // Reposition member in start index to end index.
          const temp = cloneDeep(this.folder.children);
          const members = temp.splice(moved.oldIndex, 1);
          temp.splice(moved.newIndex, 0, members[0]);
          // Update children in new order.
          this.$store.commit('folder/setFolder', {
            ...this.folder,
            // Sets the order in which the member files are looked up in store Map.
            children: temp,
          });
          this.$notify(`Saved changes to ${this.folder.name}.`);
        })
        .catch(() => {
          this.$notify(
            `Failed to move member ${member.name} in ${this.folder.name}.`
          );
        });
    }
  }

  removeMembers(members: string[]): void {
    removeMembersFromFolder(this.folder.xref, members)
      .then(() => {
        // Update children list.
        this.$store.commit('folder/setFolder', {
          ...this.folder,
          children: this.folder.children.filter(
            (child) => !members.includes(child)
          ),
        });
      })
      .catch((error) => {
        this.$notify(`Failed to remove Folder members: ${members.join(', ')}`);
      });
  }

  @Watch('value')
  onOpen(): void {
    this.openFolder();
  }

  @Watch('$route.query.p')
  onSearch(): void {
    const manualNavigation = this.$store.state.folder.manualNavigation;
    if (!manualNavigation) {
      this.searchFolder();
    }
  }
}
