
import {
  MigrationStatusRaw,
  V1Credentials,
  beginMigration,
} from '@/api/core/migration.api';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { MigrationStatus } from './NavBar.vue';
import { ComposedError } from '@/plugins/axios';
import { CoreApiError } from '@/api/base/error';

@Component
export default class MigrationDialog extends Vue {
  @Prop({ required: true }) value: boolean;
  @Prop({ required: true }) migrationStatus: MigrationStatus;
  @Prop({ required: false }) data: MigrationStatusRaw | null;

  dialogStepper = 1;

  username = '';
  password = '';
  showPassword = false;

  starting = false;
  error = '';

  rules = { required: (value: boolean) => !!value || 'Required.' };

  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(val: boolean) {
    this.$emit('input', val);
  }

  get dialogStepperValue(): number {
    switch (this.migrationStatus) {
      case MigrationStatus.COMPLETE:
        return 3;
      case MigrationStatus.IN_PROGRESS:
        return 2;
      default:
        return this.dialogStepper;
    }
  }

  startMigration(creds: V1Credentials): void {
    this.starting = true;
    this.error = '';
    beginMigration(creds)
      .then((uuid) => {
        this.dialogStepper = 2;
        this.$emit('updateMigrationStatus', MigrationStatus.IN_PROGRESS);
        this.$emit('updateMigrationId', uuid);
      })
      .catch((e: ComposedError) => {
        const res = e.error.response?.data as CoreApiError;
        this.error = res.message ?? '';
      })
      .finally(() => {
        this.starting = false;
      });
  }
}
