
import {
  AnswerSet,
  AnswerType,
  ProblemDefinition,
  ProblemTypeSDK3,
} from '@/domain/Problem';
import { Component, Prop, Vue } from 'vue-property-decorator';
import BuilderEditor from './BuilderEditor.vue';
import {
  ResponseBox,
  ResponseBoxType,
} from '@/utils/tinyMCE/problemBuildertinyMCEConfig';
import { cloneDeep, debounce, isEqual, orderBy } from 'lodash';
import { ProblemTypeItem, getProblemTypeItems } from '@/utils/problem.util';
import SortAnswers from './Answers/SortAnswers.vue';
import FillInAnswers from './Answers/FillInAnswers.vue';
import ChooseAnswers from './Answers/ChooseAnswers.vue';
import dayjs from 'dayjs';
import PossibleResponses from './Answers/PossibleResponses.vue';
import DragDropAnswers from './Answers/DragDropAnswers.vue';
import TimeFromNow from './ContentView/TimeFromNow.vue';
import ValidateContentDialog from './ValidateContentDialog.vue';
import { ProblemSetDefinition, ProblemSetType } from '@/domain/ProblemSet';
import ProblemSupports from './Supports/ProblemSupports.vue';
import { ITutorStrategy } from '@/domain/Tutoring';
import {
  ContentType,
  PersistableStateType,
  UserToContentRelationshipType,
} from '@/domain/Content';
import EditProblemDialog from './EditProblemDialog.vue';
import PublishContentDialog from './PublishContentDialog.vue';
import { TutoringFilterParams } from '@/api/core/content.api';
import { SkillDefinition } from '@/domain/Skill';
import EditProblemStandards from './EditProblemStandards.vue';
import { AclPermissionType } from '@/domain/Acls';

export function getNewProblem(): Partial<ProblemDefinition> {
  return {
    contentType: ContentType.PROBLEM,
    problemTypeSDK3: ProblemTypeSDK3.FILL_IN,
    question: '',
    answersSDK3: null,
    updatedAt: dayjs().valueOf(),
  };
}

// TODO: Figure out if we need any UI or component to show these. If we do, these will need to be moved there?
export function getOpenResponseAnswers(): AnswerSet {
  return {
    memberType: 'ANSWER_SET',
    members: [
      {
        memberType: 'ANSWER_PART',
        answerType: AnswerType.OPEN_RESPONSE,
        htmlMarker: 1,
      },
    ],
  };
}

@Component({
  components: {
    BuilderEditor,
    SortAnswers,
    FillInAnswers,
    ChooseAnswers,
    TimeFromNow,
    PossibleResponses,
    DragDropAnswers,
    ValidateContentDialog,
    ProblemSupports,
    EditProblemDialog,
    EditProblemStandards,
    PublishContentDialog,
  },
})
export default class ProblemBuilder extends Vue {
  @Prop({ required: true }) problem: ProblemDefinition;
  @Prop({ required: false, default: null })
  context: ProblemSetDefinition | null;
  @Prop({ required: false, default: 0 }) margin: number;
  @Prop({ required: false, default: true }) showAnswers: boolean;
  @Prop({ required: false, default: false }) showEditStandards: boolean;

  AclPermissionType = AclPermissionType;

  validateDialog = false;
  editProblemDialog = false;
  publishDialog = false;

  saving = false;
  fab = false;

  loadingSupports = false;

  dropCount = 5;

  ProblemTypeSDK3 = ProblemTypeSDK3;
  problemTypes = [
    ProblemTypeSDK3.CHOOSE_ONE,
    ProblemTypeSDK3.CHOOSE_N,
    ProblemTypeSDK3.DRAG_DROP,
    ProblemTypeSDK3.FILL_IN,
    ProblemTypeSDK3.SORT,
    ProblemTypeSDK3.OPEN_RESPONSE,
  ];
  ProblemSetType = ProblemSetType;
  toggleSupports = false;
  isMultipleCorrect = false;
  responseBoxes: ResponseBox[] = [];
  localProblem: Partial<ProblemDefinition> = {};

  get readonly(): boolean {
    return (
      !this.localProblem.permissions?.includes(AclPermissionType.UPDATE) ?? true
    );
  }

  get editStandardsDialog(): boolean {
    return this.showEditStandards;
  }

  set editStandardsDialog(value: boolean) {
    this.$emit('open-edit-standards', value);
  }

  get problemSkills(): string[] {
    if (this.problem.attributes) {
      return this.problem.attributes.skill ?? [];
    }
    return [];
  }

  get problemSkillObjects(): Map<string, SkillDefinition> {
    return this.$store.getters['skillList/getSkillsMap'];
  }

  get skillNameSkillCodeFromSkillObject(): SkillDefinition[] {
    const problemSkills = this.problemSkills;
    const skillObjects = this.problemSkillObjects;
    const result: SkillDefinition[] = [];

    for (const skill of problemSkills) {
      const skillObject = skillObjects.get(skill);
      if (skillObject) result.push(skillObject);
    }
    return result;
  }

  get validationErrors(): string[] {
    return this.$store.state.content.validationErrors[this.problem.xref] ?? [];
  }

  get problemTypesList(): ProblemTypeItem[] {
    return getProblemTypeItems(this.problemTypes);
  }

  get responseBoxTypes(): ResponseBoxType[] {
    const types = [];
    if (this.localProblem.problemTypeSDK3 === ProblemTypeSDK3.FILL_IN) {
      types.push(ResponseBoxType.TEXT, ResponseBoxType.DROPDOWN);
    } else if (
      this.localProblem.problemTypeSDK3 === ProblemTypeSDK3.DRAG_DROP
    ) {
      types.push(ResponseBoxType.DROP_TARGET);
    }
    return types;
  }

  get modifiedFields(): Partial<ProblemDefinition> {
    if (this.problem) {
      // Determine which fields are modified.
      const local = this.localProblem;
      const prop = this.problem;
      const modified: Partial<ProblemDefinition> = {};
      for (const [key, value] of Object.entries(local as ProblemDefinition)) {
        const k = key as keyof ProblemDefinition;
        if (!isEqual(prop[k], value)) {
          modified[k] = value;
        }
      }
      return modified;
    } else {
      // No Problem given to initialize. All changes are new.
      return { ...this.localProblem };
    }
  }

  get hasModifiedFields(): boolean {
    return Object.keys(this.modifiedFields).length > 0;
  }

  get tutorStrategies(): ITutorStrategy[] {
    const supports = [];
    const tutorStrategiesMap =
      this.$store.getters['content/targetToTutorStrategiesMap'];
    let tses = tutorStrategiesMap[this.problem.xref];
    if (tses) {
      supports.push(...tses);
    }
    if (
      this.problem.permissions.includes(AclPermissionType.UPDATE) &&
      this.problem.mappedCeri
    ) {
      tses = tutorStrategiesMap[this.problem.mappedCeri];
      if (tses) {
        supports.push(...tses);
      }
    }
    return orderBy(supports, (ts) => ts.createdAt, 'asc');
  }

  debounceUpdate = debounce(this.updateProblem, 150);

  updateProblem(): void {
    if (this.problem && this.hasModifiedFields) {
      this.saving = true;
      this.$store
        .dispatch('content/saveProblem', {
          xref: this.problem.xref,
          modifiedFields: this.modifiedFields,
        })
        .then(({ ceri: xref, failMessages: error }) => {
          if (error) {
            this.$notify(`Failed to update Problem: ${error}`);
          } else if (xref) {
            const updatedAt = dayjs().valueOf();
            Vue.set(this.localProblem, 'updatedAt', updatedAt);
            this.$notify(`Saved changes to Problem ${xref}.`);
          }
        })
        .catch(() => {
          this.$notify('Something went wrong. Failed to update Problem.');
        })
        .finally(() => {
          this.saving = false;
        });
    }
  }
  removeStandard(index: number) {
    const updatedSkill = [...this.problemSkills];
    updatedSkill.splice(index, 1);
    this.updateProblemSkills(updatedSkill);
  }

  handleModifiedFields(modifiedFields: Partial<ProblemDefinition>) {
    this.localProblem = { ...this.localProblem, ...modifiedFields };
    this.updateProblem();
  }

  updateProblemSkills(skill: string[]) {
    this.handleModifiedFields({
      attributes: {
        ...this.localProblem.attributes,
        skill,
      },
    });
  }

  created(): void {
    this.initialize();
  }

  initialize(): void {
    // Clear (or reset) any cached data prior to problem change.
    this.localProblem = cloneDeep(this.problem);
    this.isMultipleCorrect =
      this.localProblem.answersSDK3?.members?.some(
        (member) =>
          member.memberType == 'ANSWER_PART' &&
          member.properties?.DROP_COUNT != 1
      ) ?? false;

    if (this.isMultipleCorrect && this.localProblem.answersSDK3?.members) {
      if (
        this.localProblem.answersSDK3?.members[0].properties &&
        this.localProblem.answersSDK3?.members[0].memberType == 'ANSWER_PART'
      ) {
        this.dropCount =
          this.localProblem.answersSDK3?.members[0].properties.DROP_COUNT || 5;
      }
    }

    if (this.tutorStrategies.length === 0) {
      this.loadingSupports = true;
      const filterParams: TutoringFilterParams = {
        targets: [this.problem.xref],
        psTypes: [
          PersistableStateType.PUBLISHED,
          PersistableStateType.WORK_IN_PROGRESS,
        ],
      };
      if (
        this.problem.permissions.includes(AclPermissionType.UPDATE) &&
        this.problem.mappedCeri
      ) {
        filterParams.targets?.push(this.problem.mappedCeri);
      }
      const promises = [];
      if (this.isContentAdminUser || this.isTrustedBuilderUser) {
        promises.push(
          this.$store.dispatch('content/getTutorStrategies', {
            filterParams: { ...filterParams, isCertified: true },
          })
        );
      }
      promises.push(
        this.$store.dispatch('content/getTutorStrategies', {
          filterParams: {
            ...filterParams,
            // Supports owned by Builder.
            user: 'me',
            // Should be default but...
            filterBy: [UserToContentRelationshipType.OWNER],
          },
        })
      );
      Promise.all(promises)
        .then((tses) => {
          this.toggleSupports = tses.some((ts) => ts.length);
        })
        .finally(() => {
          this.loadingSupports = false;
        });
    } else {
      this.toggleSupports = true;
    }
  }

  updateProblemType(): void {
    if (this.localProblem.problemTypeSDK3 == ProblemTypeSDK3.OPEN_RESPONSE) {
      // No UI for this. Will need to be added manually by us here.
      this.localProblem.answersSDK3 = getOpenResponseAnswers();
    } else {
      // Reset Answers.
      this.localProblem.answersSDK3 = null;
    }
    this.debounceUpdate();
  }

  updateAnswerDropCount(): void {
    if (this.localProblem.answersSDK3) {
      const answers = cloneDeep(this.localProblem.answersSDK3);
      answers.members?.forEach((member) => {
        if (member.memberType == 'ANSWER_PART' && member.properties) {
          member.properties.DROP_COUNT = this.dropCount;
        }
      });
      this.answersChanged(answers);
    }
  }

  resetDropCount() {
    this.dropCount = this.isMultipleCorrect ? 5 : 1;
  }

  updateResponseBoxes(responseBoxes: ResponseBox[]) {
    this.responseBoxes = responseBoxes;
  }

  answersChanged(answersSDK3: AnswerSet): void {
    const current = this.localProblem.answersSDK3;
    if (!isEqual(current, answersSDK3)) {
      Vue.set(this.localProblem, 'answersSDK3', cloneDeep(answersSDK3));
      this.debounceUpdate();
    }
  }

  notifyUnimplemented(): void {
    this.$notify('Coming soon! Needs to be implemented.');
  }

  openPublishDialog(): void {
    this.publishDialog = true;
  }

  notifyReadonly(): void {
    this.$notify(
      "To edit content you don't own, use vertical dot menu to replace it with a copy."
    );
  }
}
