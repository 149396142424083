
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ProblemSetDefinition, ProblemSetType } from '@/domain/ProblemSet';
import axios, { CancelTokenSource } from 'axios';
import ScrollObserver from '@/components/base/ScrollObserver.vue';
import {
  ContentSaveOperationType,
  getProblemSetChildrenXrefs,
  getProblemSetDefinition,
} from '@/api/core/content.api';
import {
  ProblemSetNode,
  getProblemCountFromStructure,
} from '@/utils/problemSet.util';
import { isEqual } from 'lodash';
import { addMembersToFolder, searchForFolders } from '@/api/core/folders.api';
import { UserFolderType } from '@/domain/Folder';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';
import { getUserFolder } from '@/api/core/user.api';

@Component({
  components: {
    ScrollObserver,
  },
})
export default class SaveToMyProblemSetsDialog extends Vue {
  @Prop() value: boolean;
  @Prop({ default: () => [] }) selectedTree: ProblemSetNode[];

  searchText: string | null = null; //The search text

  savedProblemSetCeri: string | null = null;

  saved = false;
  submitting = false; // Actively submitting request to save
  loading = false; // Loading my problem sets
  loadingMore = false; // Loading more problem sets

  limit = 5; // Paging result limiter
  source: CancelTokenSource | null = null;

  searchTimeout = 0;
  timeout = 500;

  duplicateMembers: string[] = [];

  /**
   * Chosen problem set
   * @type: ProblemSet | string | Null
   *  Null means nothing chosen.
   *  Empty string means the same
   *  A string means they chose the option that just adds a new PS.
   *    Use this string to check if any of "my problem sets" contains a name with that string.
   *    If not create a new one, if so then use that one
   */
  problemSet: ProblemSetDefinition | string | null = null;

  get nextPageToken(): string {
    return this.$store.getters['myps/getNextPageToken'];
  }

  // FIXME: Figure out if we need to exclude specific Problem Set types? Multi-part Problem Sets, for example.
  get problemSets(): ProblemSetDefinition[] {
    return this.$store.getters['myps/getProblemSets'];
  }

  get isLoadingProblemSets(): boolean {
    return this.loading || this.loadingMore;
  }

  get compValue(): boolean {
    return this.value;
  }

  set compValue(newVal: boolean) {
    this.$emit('input', newVal);
    this.saved = false;
    this.savedProblemSetCeri = null;
    // When canceling/closing dialog
    if (newVal === false) {
      this.problemSet = null;
      this.duplicateMembers = [];
    }
  }

  onProblemSetChange(): void {
    if (typeof this.problemSet === 'string') {
      // We are given a string. lets see if it's the same as a name of one of our problem sets
      const found = this.problemSets.find((ps) => {
        return ps.name === this.problemSet;
      });
      if (found) {
        this.problemSet = found;
      }
    }
  }

  get addToPSButtonText(): string {
    let buttonText = 'SAVE TO PROBLEM SET';
    if (typeof this.problemSet === 'string') {
      buttonText = `CREATE & ${buttonText}`;
    }

    return buttonText;
  }

  get duplicateMemberText(): string {
    return this.duplicateMembers.join(', ');
  }

  get hasDuplicates(): boolean {
    return this.duplicateMembers.length !== 0;
  }

  get numProblemsToAdd(): number {
    // FIXME: Count of UNIQUE Problems? For example, a Problem found in multiple child Problem Sets?
    return getProblemCountFromStructure(this.selectedTree);
  }

  get problemSetMap(): Record<string, ProblemSetDefinition> {
    return this.$store.state.content.problemSetMap;
  }

  // FIXME: Figure out if this should be a util method?
  createPublishedProblemSet(
    problemSetType: ProblemSetType,
    members: string[],
    name?: string
  ): Promise<string> {
    return this.$store
      .dispatch('content/saveProblemSet', {
        modifiedFields: {
          problemSetType,
          name,
        },
      })
      .then((status) => {
        const { ceri: xref, failMessages: error } = status;
        if (error) {
          throw new Error('Failed to create Problem Set: ' + error);
        } else {
          // Add new members to WIP Problem Set and publish WIP Problem Set.
          return this.$store
            .dispatch('content/addProblemSetMembers', {
              xref,
              members,
              opType: ContentSaveOperationType.PUBLISH,
            })
            .then((status) => {
              const { ceri: xref, failMessages: error } = status;
              if (error || !xref) {
                throw new Error(
                  `Failed to add members to and publish Problem Set ${xref}: ` +
                    error
                );
              }
              // Return new Published CERI.
              return xref;
            });
        }
      });
  }

  updatePublishedProblemSet(xref: string, members: string[]): Promise<string> {
    // Replace members in Published Problem Set and re-publish Problem Set.
    return this.$store
      .dispatch('content/replaceProblemSetMembers', {
        xref,
        members,
        opType: ContentSaveOperationType.PUBLISH,
      })
      .then((status) => {
        const { ceri: xref, failMessages: error } = status;
        if (error || !xref) {
          throw new Error(
            `Failed to update members of publish Problem Set ${xref}: ` + error
          );
        }
        // Return new Published CERI.
        return xref;
      });
  }

  addToMyProblemSets(xref: string): Promise<void> {
    const mpsf = this.$store.state.auth.MY_PROBLEM_SETS;
    return addMembersToFolder(mpsf, [xref]);
  }

  async getMembersToAdd(selected: ProblemSetNode[]): Promise<string[]> {
    const members: string[] = [];
    for (const node of selected) {
      // Do NOT need to care about WIP Problems and Problem Sets?
      if (node.xref.startsWith('PR')) {
        members.push(node.xref);
      } else if (node.xref.startsWith('PS')) {
        // Use the content store. Problem Set (tree) MUST have been downloaded.
        const problemSet = this.problemSetMap[node.xref];
        if (problemSet) {
          if (
            node.children?.length == 1 &&
            problemSet.problemSetType == ProblemSetType.MULTI_PART_PROBLEM_SET
          ) {
            // FIXME: Figure out if we do the same for other Problem Set types?
            // FIXME: What if this is a Problem Set?
            // ONLY one Problem.
            members.push(node.children[0].xref);
          } else {
            const selected = node.children?.map((node) => node.xref);
            // MUST NOT be empty for Published Problem Set
            const current = problemSet.children;
            if (!isEqual(current, selected)) {
              const newMembers = await this.getMembersToAdd(
                node.children ?? []
              );
              // Create a new WIP Problem Set and published it with selected members.
              const newXref = await this.createPublishedProblemSet(
                problemSet.problemSetType,
                newMembers
                // problemSet.name
              );
              members.push(newXref);
            } else {
              // Nothing changed. Entire Problem Set (tree) is selected.
              members.push(node.xref);
            }
          }
        } else {
          throw new Error(
            `Use the content store. Problem Set ${node.xref} MUST have been downloaded.`
          );
        }
      }
    }

    if (members.length) {
      return members;
    } else {
      // Containing WIP content?
      throw new Error(
        'MUST provide Published members. CANNOT create an empty Problem Set.'
      );
    }
  }

  async addToProblemSet(): Promise<void> {
    if (!this.problemSet) {
      return;
    }
    this.submitting = true;
    const membersToAdd = await this.getMembersToAdd(this.selectedTree);

    if (typeof this.problemSet === 'string') {
      // Create a new Published Problem Set.
      this.createPublishedProblemSet(
        ProblemSetType.LINEAR_COMPLETE_ALL,
        membersToAdd,
        this.problemSet
      )
        .then((xref) => {
          const promises = [];
          // Add to My Problem Sets User Folder.
          promises.push(this.addToMyProblemSets(xref));
          promises.push(
            getProblemSetDefinition(xref).then((problemSet) => {
              this.$store.commit('myps/addProblemSet', problemSet);
            })
          );
          return Promise.all(promises).then(() => {
            this.savedProblemSetCeri = xref;
            this.saved = true;

            // Mixpanel tracking for problem set saved
            trackMixpanel(EventType.trackProblemSetSavedNew, {
              psName: this.problemSet,
              // savedPSId: this.savedPSId,
              savedPSCode: this.savedProblemSetCeri,
              numProblemsAdded: this.numProblemsToAdd,
              psCode: membersToAdd,
            });
          });
        })
        .finally(() => {
          this.submitting = false;
        });
    } else {
      // Added to existing Problem Set.
      // FIXME: Direct children only? Problem found in multiple child Problem Set(s).
      // Do we need to cycle detect?
      const children: string[] = await getProblemSetChildrenXrefs(
        this.problemSet.xref
      );
      for (const member of membersToAdd) {
        if (children.includes(member)) {
          if (!this.duplicateMembers.includes(member)) {
            this.duplicateMembers.push(member);
          }
        } else {
          // Add to the end.
          children.push(member);
        }
      }
      const problemSetCeri = this.problemSet.xref;
      this.updatePublishedProblemSet(problemSetCeri, children)
        .then((xref) => {
          this.saved = true;
          this.savedProblemSetCeri = xref;
          // Mixpanel tracker to save problemSet
          trackMixpanel(EventType.trackProblemSetSavedCurrent, {
            psName: this.problemSet.name,
            // savedPSId: this.savedPSId,
            savedPSCode: this.savedProblemSetCeri,
            duplicateFound: this.duplicateMembers.length > 0,
            numProblemsAdded: this.numProblemsToAdd,
            psCode: membersToAdd,
          });
          this.submitting = false;
        })
        .catch(() => {
          this.submitting = false;
        });
    }
  }

  goToProblemSet(): void {
    // Must have specific problem set to go to (just edited/created)
    if (!this.savedProblemSetCeri) {
      // TODO Handle error
      return;
    }
    this.$router.push({
      name: 'editMyPS',
      params: {
        xref: this.savedProblemSetCeri,
      },
    });
  }

  downloadProblemSets(): Promise<void> {
    return this.$store.dispatch('myps/requestMyProblemSets', {
      limit: this.limit,
      cancelToken: this.source?.token,
    });
  }

  downloadMoreProblemSets(): void {
    this.loadingMore = true;
    this.downloadProblemSets().then(() => {
      this.loadingMore = false;
    });
  }

  searchForProblemSets(name: string): void {
    this.loadingMore = true;
    this.$store
      .dispatch('myps/requestMyProblemSets', {
        name,
        limit: this.limit,
        cancelToken: this.source?.token,
      })
      .finally(() => {
        this.loadingMore = false;
      });
  }

  mounted(): void {
    this.loading = true;
    this.downloadProblemSets().then(() => {
      this.loading = false;
    });
  }

  @Watch('searchText')
  onSearchTextChanged(name: string): void {
    if (this.source) {
      // Cancel prior requests with this cancel token
      this.source.cancel();
    }
    this.source = axios.CancelToken.source();
    if (!name || name.trim() === '') {
      return;
    }
    if (this.searchTimeout > 0) {
      window.clearTimeout(this.searchTimeout);
      this.searchTimeout = 0;
    }
    if (name) {
      this.searchTimeout = window.setTimeout(() => {
        this.searchForProblemSets(name);
      }, this.timeout);
    }
  }

  // Mixpanel tracking for problem set dialog
  @Watch('compValue')
  onCompValueChanged(newVal: boolean, oldVal: boolean): void {
    const problemCodes = this.selectedTree.map((node) => node.xref);
    if (newVal === true && oldVal === false) {
      // Track event with Mixpanel when the dialog is shown
      trackMixpanel(EventType.trackSaveToMyProblemSetsDialogShown, {
        numProblemsAdded: this.numProblemsToAdd,
        problemCodes: problemCodes,
      });
    } else if (newVal === false && oldVal === true) {
      // Track event with Mixpanel when the dialog is hidden
      trackMixpanel(EventType.trackSaveToMyProblemSetsDialogClosed, {
        numProblemsAdded: this.numProblemsToAdd,
        problemCodes: problemCodes,
      });
    }
  }
}
