import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { MyPsState } from './types';
import { RootState } from '../types';

export const state: MyPsState = {
  problemSets: [],
  originalDownloadDone: false,
  downloading: false,
};

const namespaced = true;

export const myps: Module<MyPsState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
