
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProblemDefinition } from '@/domain/Problem';
import Editor from '@tinymce/tinymce-vue';
import SupportEditor from './SupportEditor.vue';
import { cloneDeep } from 'lodash';
import { TutorStrategyType, ITutorStrategy } from '@/domain/Tutoring';

@Component({
  components: {
    Editor,
    SupportEditor,
  },
})
export default class HintsOrExplanationView extends Vue {
  @Prop() problem: ProblemDefinition;
  @Prop() supportContent: ITutorStrategy | null;
  @Prop() supportType: string;
  currentIndex = -1;
  showEditor = false;
  addNewSupport = false;
  dragStartIndex = -1;
  dragEndIndex = -1;

  TutorStrategyType = TutorStrategyType;

  get supportTypeMapped() {
    return this.supportType === 'Hint' ? 'HINT_SET' : 'EXPLANATION';
  }

  created() {
    if (this.supportContent === null) {
      this.addNewSupport = true;
    }
  }

  addNew() {
    this.showEditor = true;
    this.addNewSupport = true;
    this.currentIndex = -1;
  }

  publishSupport(newSupport: string) {
    let updatedSupport: ITutorStrategy | null = cloneDeep(this.supportContent);

    if (this.currentIndex === -1 && !updatedSupport) {
      this.$emit('createStudentSupport', newSupport, this.supportTypeMapped);
      return;
    }

    if (updatedSupport) {
      if (
        this.supportType === 'Hint' &&
        updatedSupport.tutorStrategyType === TutorStrategyType.HINT_SET
      ) {
        if (
          updatedSupport.hints &&
          this.currentIndex >= 0 &&
          this.currentIndex < updatedSupport.hints.length
        ) {
          this.$set(
            updatedSupport.hints[this.currentIndex],
            'hint',
            newSupport
          );
        } else {
          updatedSupport.hints.push({ hint: newSupport, hasVideo: false });
        }
      } else if (
        this.supportType === 'Explanation' &&
        updatedSupport.tutorStrategyType === TutorStrategyType.EXPLANATION
      ) {
        updatedSupport.explanation = newSupport;
      }

      this.$emit(
        'updateStudentSupport',
        updatedSupport,
        this.supportTypeMapped
      );

      this.addNewSupport = false;
      this.showEditor = false;
    }
  }

  editSupport(index: number) {
    if (this.supportContent !== undefined) {
      this.showEditor = true;
      this.currentIndex = index;
      this.addNewSupport = false;
    }
  }

  deleteSupport(index?: number) {
    if (this.supportType === 'Hint') {
      this.$emit('deleteHint', index);
    } else if (this.supportType === 'Explanation') {
      this.$emit('deleteExplanation', index);
    }
  }

  showTitle(index: number) {
    return this.supportType === 'Hint' ? 'Hint ' + (index + 1) : 'Explanation';
  }

  noHintsOrExplanation() {
    this.addNewSupport = false;
    this.showEditor = false;
    this.$emit('noHintsOrExplanation');
  }

  cancelEditor() {
    this.showEditor = false;
  }

  dragStart(index: number) {
    this.dragStartIndex = index;
    this.dragClasses(this.dragStartIndex, 'dragStart', [
      'card',
      'draggingOver',
    ]);
  }

  dragEnter(index: number) {
    // clear old dragEndIndex
    if (this.dragEndIndex !== -1 && this.dragEndIndex !== this.dragStartIndex) {
      this.dragClasses(this.dragEndIndex, 'card', ['draggingOver']);
    }
    this.dragEndIndex = index;
    if (this.dragStartIndex !== this.dragEndIndex) {
      this.dragClasses(this.dragEndIndex, 'draggingOver', [
        'card',
        'draggingOver',
      ]);
    }
  }

  dragEnd() {
    if (!this.supportContent || !Array.isArray(this.supportContent.hints)) {
      return;
    }

    const updatedHints = [...this.supportContent.hints];
    // changing order of cards only if start card index is
    // different from index where we drop the card
    if (this.dragEndIndex !== this.dragStartIndex) {
      const temp = updatedHints[this.dragStartIndex];
      // checking if we are moving card up or down
      if (this.dragEndIndex < this.dragStartIndex) {
        // up
        for (let i = this.dragStartIndex; i > this.dragEndIndex; i -= 1) {
          updatedHints[i] = updatedHints[i - 1];
        }
        updatedHints[this.dragEndIndex] = temp;
      } else {
        // down
        for (let i = this.dragStartIndex + 1; i <= this.dragEndIndex; i += 1) {
          updatedHints[i - 1] = updatedHints[i];
        }
        updatedHints[this.dragEndIndex] = temp;
      }
      this.dragStartIndex = this.dragEndIndex = -1;
      this.$emit(
        'updateStudentSupport',
        { ...this.supportContent, hints: updatedHints },
        this.supportTypeMapped
      );
    }
    this.dragClasses(-1, '', ['dragStart', 'draggingOver', 'card']);
  }

  dragClasses(ind: number, addClass: string, removeClasses: Array<string>) {
    let cards = this.$refs['dragHints'] as Array<Vue>;
    cards.forEach((comp: Vue, key: number) => {
      let el = comp.$el;
      if (ind !== -1 && key === ind) {
        el.classList.add(addClass);
      } else {
        el.classList.remove(...removeClasses);
      }
    });
  }
}
