import { AclResourceType, AclSidType, SidPermission } from '@/domain/Acls';
import { GroupDefinition } from '@/domain/Group';
import { coreAxios } from '@/plugins/axios';

const END_POINT = '/acls';

export const getAccessGroup = (xref: string): Promise<GroupDefinition> => {
  return coreAxios.get(`${END_POINT}/access-groups/${xref}`).then((res) => {
    return res.data;
  });
};

export const getResourcePermissions = (
  resourceType: AclResourceType,
  xref: string,
  controller?: AbortController
): Promise<Record<AclSidType, SidPermission[]>> => {
  return coreAxios
    .get(`${END_POINT}/resources/permissions/${resourceType}/${xref}`, {
      signal: controller?.signal,
    })
    .then((res) => {
      return res.data;
    });
};
