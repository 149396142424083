export enum GroupType {
  PRINCIPALS = 'PRINCIPALS',
  AST_COURSE = 'AST_COURSE',
  ASSIGNEE = 'ASSIGNEE',
  MENTEE = 'MENTEE',
  ACCESS = 'ACCESS',
  ASSIGNMENTS_FOLDER = 'ASSIGNMENTS_FOLDER',
  CONTENT_FOLDER = 'CONTENT_FOLDER',
}

export interface GroupDefinition {
  groupXref: string;
  name: string;
  ownerXref: string;
  groupType: GroupType;
  isActive: boolean;
  createdAt: number;
}

// Internal processing only. Will not included in DTO if not set manually.
export interface IGroupHasMembers {
  children: string[];
}

export enum GroupChildDepthType {
  DIRECT_CHILD = 'DIRECT_CHILD',
  ANY_CHILD = 'ANY_CHILD',
}
