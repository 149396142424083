
import { Component, Vue, Prop } from 'vue-property-decorator';
import { AssignmentDefinition } from '@/domain/Assignment';
import { Class } from '@/domain/Class';
import PopupBlockedDialog from '@/components/base/PopupBlockedDialog.vue';
import { ComposedError } from '@/plugins/axios';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';
import { deleteAssignment } from '@/api/core/assignment.api';

@Component({
  components: {
    PopupBlockedDialog,
  },
})
export default class DeleteAssignmentDialog extends Vue {
  @Prop({ required: true }) assignment: AssignmentDefinition;
  @Prop({ required: true }) targetClass: Class;
  @Prop() value: boolean;

  loading = false;
  dialog = false;
  error: ComposedError | null = null;
  popupBlockedDialog = false;

  get showDialog(): boolean {
    return this.value ? this.value : this.dialog;
  }

  set showDialog(val: boolean) {
    this.dialog = val;

    this.$emit('input', val);
  }

  deleteAssignment(): void {
    if (this.assignment.lmsProviderType) {
      this.loading = true;
      deleteAssignment(this.assignment.xref, this.assignment.lmsProviderType)
        .then(() => {
          this.$emit('deleted', this.assignment.xref);
          this.$notify('Successfully deleted assignment.');
          this.showDialog = false;
          trackMixpanel(EventType.trackAssignmentDeleted, {
            assignmentXref: this.assignment.xref,
          });
        })
        .catch((error) => {
          if (error.statusCode === 417) {
            error.handleGlobally &&
              error
                .handleGlobally()
                .then(() => {
                  //if the popup was not blocked we can continue as we have been
                  this.$notify('Authorization updated. Please try again');
                })
                .catch(() => {
                  //our popup was blocked
                  this.error = error;
                  this.popupBlockedDialog = true;
                });
          } else {
            // Going to LP will not fix this issue.
            this.$notify('Failed to delete assignment.');
          }
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      // Missing LMS mapping?
      this.$notify('Unable to delete assignment without LMS provider type.');
    }
  }
}
