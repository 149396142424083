import { getAlertMessages } from '@/api/core/notifications.api';
import store from '@/store';
import { Route } from 'vue-router/types/router';

export default (to?: Route, from?: Route): Promise<void> => {
  // FIXME: Use PageView enum?
  // Ignore URL part updates by page.
  if (to?.name != from?.name) {
    if (store.getters['auth/isAuthenticated']) {
      return getAlertMessages().then((alertMessages) => {
        store.commit('notifications/setAlertMessages', alertMessages);
      });
    }
  }
  return Promise.resolve();
};
