import { ActionTree } from 'vuex';
import { ClassState } from './types';
import { RootState } from '../types';
import { getClasses } from '@/api/class.api';
import { Class, ClassWrapper, ImportFilter } from '@/domain/Class';
import { LmsProviderType } from '@/domain/LmsProviderType';

export const actions: ActionTree<ClassState, RootState> = {
  requestClasses(context, forceRedownload = false): Promise<Class[]> {
    if (
      forceRedownload ||
      (!context.state.hasDownloaded && !context.state.isDownloading)
    ) {
      // Let the component decide when to load or reload classes
      // Update loading state here to notify other instances or components if
      // we do perform such operation rather than having the calling component do
      // this kind of update?
      context.commit('setIsDownloading', true);
      return getClasses({ importFilter: ImportFilter.IMPORTED }).then(
        (classes: ClassWrapper) => {
          let imported: Class[] = classes.importedClasses ?? [];
          if (
            context.rootState.auth.user?.lmsProviderType ===
            LmsProviderType.LTI_ENABLED
          ) {
            // Filter to only launch context
            const contextXref = context.rootState.lti.launch?.contextXref;
            imported = imported.filter((ic: Class) => ic.id === contextXref);
          }
          context.commit('setClassList', imported);
          context.commit('setHasDownloaded', true);
          context.commit('setIsDownloading', false);
          return imported;
        }
      );
    }
    return Promise.resolve(context.state.classes);
  },
};
