// Computed properties that are shared
import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { MetaState } from './types';

export const getters: GetterTree<MetaState, RootState> = {
  getShouldRefreshOnRouterChange(state: MetaState): boolean {
    return state.shouldReloadOnRouteChange;
  },
};
