
import { Component, Prop, Vue } from 'vue-property-decorator';
import { InsightsHubLevel } from '@/domain/Curriculum';
import {
  EXPLORE_CONTENT,
  FolderDefinition,
  FolderMemberType,
  WORKSPACE,
} from '@/domain/Folder';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import { AclPermissionType } from '@/domain/Acls';

export interface Selection {
  wipeAllLevels: boolean;
  path: FolderDefinition[] | null;
}

@Component
export default class FolderSelector extends Vue {
  @Prop({ required: true }) value: Selection;
  @Prop({ required: true }) path: (FolderDefinition | ProblemSetDefinition)[];
  @Prop({ default: false }) disabled: boolean;

  openedPath: FolderDefinition[] = [];
  downloading: string | null = null;

  get folderMap(): Record<string, FolderDefinition> {
    return this.$store.state.folder.folderMap;
  }

  get rootFolder(): FolderDefinition | null {
    const root = this.path[0];
    return root.memberType == FolderMemberType.FOLDER
      ? (root as FolderDefinition)
      : null;
  }

  // FIXME: Figure out a way to customize this list of root Folders to start with.
  get rootFolders(): FolderDefinition[] {
    let folders = [];
    if (this.rootFolder) {
      folders.push(this.rootFolder);
    }
    if (this.isContentAdminUser || this.isTrustedBuilderUser) {
      // FIXME: Download IF not already? Currently, this will depend on IF the parent page
      // downloaded this previously, otherwise we do NOT show it here.
      const downloaded = this.folderMap[WORKSPACE];
      const target = folders.find((folder) => folder.xref == WORKSPACE);
      if (downloaded && !target) {
        folders = [downloaded, ...folders];
      }
    }
    if (this.isContentAdminUser) {
      // FIXME: Download IF not already? Currently, this will depend on IF the parent page
      // downloaded this previously, otherwise we do NOT show it here.
      const downloaded = this.folderMap[EXPLORE_CONTENT];
      const target = folders.find((folder) => folder.xref == EXPLORE_CONTENT);
      if (downloaded && !target) {
        folders = [downloaded, ...folders];
      }
    }
    return folders;
  }

  set wipeAllLevels(value: boolean) {
    this.$emit('input', {
      wipeAllLevels: value,
      // Clear any prior selection.
      // FIXME: This could be smarter rather than clearing selection whenever this is toggled. For example,
      // if preserve level is on, we only need to check whether the current destination selected is in the
      // "correct" level. If not, then we'll want to clear selection here.
      destination: null,
    });
  }

  get wipeAllLevels(): boolean {
    return this.value.wipeAllLevels;
  }

  set destination(value: string | null) {
    this.$emit('input', {
      ...this.value,
      path: value ? [...this.openedPath, this.folderMap[value]] : null,
    });
  }

  get destination(): string | null {
    let result = undefined;
    if (this.value.path) {
      result = this.value.path[this.value.path.length - 1].xref;
    }
    return result ?? null;
  }

  get parent(): FolderDefinition | ProblemSetDefinition {
    return this.path[this.path.length - 2];
  }

  get target(): FolderDefinition | ProblemSetDefinition {
    return this.path[this.path.length - 1];
  }

  get folderPath(): FolderDefinition[] {
    const index = this.path.findIndex(
      (part) =>
        part.memberType == FolderMemberType.PUB_PS ||
        part.memberType == FolderMemberType.WIP_PS
    );
    // Exclude content path.
    return this.path.slice(0, index) as FolderDefinition[];
  }

  get targetFolderIHLevel(): number {
    return this.getIHLevel(this.folderPath);
  }

  get openedFolder(): FolderDefinition | undefined {
    return this.openedPath[this.openedPath.length - 1];
  }

  get openedFolderIHLevel(): number {
    return this.getIHLevel(this.openedPath);
  }

  // We cannot return FolderFile[] here because the Object has a header field. In the select component,
  // Objects that have a header or divider property are considered special cases and generate a list
  // header or divider; these items are not selectable.
  get folders(): { text: string; value: string; disabled: boolean }[] {
    const items = [];
    const members = [];
    if (this.openedFolder) {
      // List out (Folder) members.
      for (const member of this.openedFolder.children) {
        const folder = this.folderMap[member];
        if (folder) {
          members.push(folder);
        }
      }
    } else {
      // List out Explore Content and Workspace.
      members.push(...this.rootFolders);
    }

    // Filter or disable members if needed.
    for (const member of members) {
      // Determine insights hub level.
      const level = member.attributes?.insightsHubLevel;
      // If not set, inherit parent's (which may have been inherited from their parent(s))
      const memberLevel = level ?? this.openedFolderIHLevel;
      items.push({
        text: member.name,
        value: member.xref,
        disabled:
          // Do NOT have WRITE permissions to destination.
          !member.permissions.includes(AclPermissionType.UPDATE) ||
          // Not allowed to copy/move a Folder to itself.
          member.xref == this.target.xref ||
          (this.wipeAllLevels
            ? // Wipe all levels is turned on so allow to move to any folder regardless of levels.
              false
            : // Only allow to move target folder to another folder ONE level up of its insights hub level.
              this.openedFolderIHLevel >= this.targetFolderIHLevel ||
              memberLevel !== this.targetFolderIHLevel - 1),
      });
    }

    return items;
  }

  goBack(): void {
    // Get parent path from path of current by removing target from current path and set parent path to be opened.
    this.openedPath.pop();
  }

  openChild(child: string): void {
    const childFolder = this.folderMap[child];
    if (childFolder) {
      // Download member files in child folder if not already.
      const members = childFolder.children ?? [];
      if (members.length === 0) {
        // Note downloading child so we can update loading state.
        this.downloading = childFolder.xref;
        this.$store
          .dispatch('folder/getFolderMembers', { xref: childFolder.xref })
          .then(() => {
            // Okay, we're good. Set child path to be opened.
            this.openedPath.push(this.folderMap[childFolder.xref]);
          })
          .finally(() => {
            // Done downloading. Clear loading state on child.
            this.downloading = null;
          });
      } else {
        // Members downloaded already so we should be good.
        this.openedPath.push(childFolder);
      }
    }
  }

  getIHLevel(folderPath: FolderDefinition[]): number {
    const copy = [...folderPath];
    // Remove Explore Content and Workspace from processing.
    copy.shift();
    if (copy.length > 0) {
      // Starts at 0 for curriculum folders.
      let insightsHubLevel: InsightsHubLevel | 0 = 0;
      for (const folder of folderPath) {
        // Any folder that do NOT have an insights hub level set is said to "inherit" what is set in its parent(s).
        const folderLevel = folder.attributes?.insightsHubLevel;
        if (folderLevel) {
          // Folder has insights hub level set; thus, overrides current.
          insightsHubLevel = folderLevel;
        } else if (insightsHubLevel < 3) {
          insightsHubLevel += 1;
        }
      }
      return insightsHubLevel;
    } else {
      // Account for Explore Content and Workspace. A level up of curriculum folders.
      return -1;
    }
  }
}
