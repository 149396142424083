
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ScrollObserver extends Vue {
  @Prop({ required: false, default: 80 }) rootMargin: number;

  observer: IntersectionObserver | null = null;
  mounted(): void {
    // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    this.observer = new IntersectionObserver(
      ([entry]) => {
        if (entry && entry.isIntersecting) {
          this.$emit('visible');
        }
      },
      { rootMargin: `${this.rootMargin}px` }
    );

    this.observer?.observe(this.$el);
  }

  destroyed(): void {
    this.observer?.disconnect();
  }
}
