import {
  AttributeDefinition,
  AttributeNames,
  AttributesMap,
} from '@/domain/Attributes';
import { InsightsHubLevel } from '@/domain/Curriculum';
import { coreAxios } from '@/plugins/axios';

const END_POINT = `/attributes`;

export interface AttributesMapDTO
  extends Omit<
    AttributesMap,
    | AttributeNames.IS_TEXTBOOK
    | AttributeNames.IS_RESEARCH
    | AttributeNames.IS_CERTIFIED
    | AttributeNames.IS_ASSESSMENT
    | AttributeNames.INSIGHTS_HUB_LEVEL
    | AttributeNames.QUICK_COMMENTS_TEACHER
  > {
  [AttributeNames.IS_TEXTBOOK]?: boolean[];
  [AttributeNames.IS_RESEARCH]?: boolean[];
  [AttributeNames.IS_CERTIFIED]?: boolean[];
  [AttributeNames.IS_ASSESSMENT]?: boolean[];
  [AttributeNames.INSIGHTS_HUB_LEVEL]?: InsightsHubLevel[];
  [AttributeNames.QUICK_COMMENTS_TEACHER]?: boolean[];
}

const getAttributeDefinition = (
  attributeName: string
): Promise<AttributeDefinition> => {
  return coreAxios.get(`${END_POINT}/${attributeName}`).then((res) => {
    return res.data;
  });
};

/////////////
// Helpers //
/////////////

function transformAttributes(map: AttributesMapDTO): AttributesMap {
  const attributesMap: AttributesMap = {};
  // Should contain values IF returned in map.
  for (const attribute in map) {
    switch (attribute) {
      case AttributeNames.IS_TEXTBOOK:
      case AttributeNames.IS_RESEARCH:
      case AttributeNames.IS_CERTIFIED:
      case AttributeNames.IS_ASSESSMENT:
      case AttributeNames.INSIGHTS_HUB_LEVEL:
      case AttributeNames.QUICK_COMMENTS_TEACHER:
        // eslint-disable-next-line
        // @ts-ignore
        attributesMap[attribute] = map[attribute][0];
        break;
      default:
        // eslint-disable-next-line
        // @ts-ignore
        attributesMap[attribute] = map[attribute];
        break;
    }
  }
  return attributesMap;
}

function inverseTransformAttributes(map: AttributesMap): AttributesMapDTO {
  const attributesMap: AttributesMapDTO = {};
  // Should contain values IF returned in map.
  for (const attribute in map) {
    switch (attribute) {
      case AttributeNames.IS_TEXTBOOK:
      case AttributeNames.IS_RESEARCH:
      case AttributeNames.IS_CERTIFIED:
      case AttributeNames.IS_ASSESSMENT:
      case AttributeNames.INSIGHTS_HUB_LEVEL:
      case AttributeNames.QUICK_COMMENTS_TEACHER:
        // eslint-disable-next-line
        // @ts-ignore
        attributesMap[attribute] = [map[attribute]];
        break;
      default:
        // eslint-disable-next-line
        // @ts-ignore
        attributesMap[attribute] = map[attribute];
        break;
    }
  }
  return attributesMap;
}

export {
  getAttributeDefinition,
  transformAttributes,
  inverseTransformAttributes,
};
