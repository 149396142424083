export interface StatsPerProblemSetType {
  problemSetType: string;
  assignmentXrefs: Array<string>;
  totalNumUniqueProblemsAssigned: number;
  totalNumProblemsAssigned: number;
  totalNumProblemsCompleted: number;
  sumOfStudentAverageScores: number;
  numStudentsScored: number;
}

export interface StandardHierarchyNodeCellStats {
  skillCode: string;
  numUniqueProblemsAssigned: number;
  totalProblemsAssigned: number;
  totalProblemsCompleted: number;
  totalScoreOnProblems: number;
  totalScoredProblems: number;
}

export interface TotalStandardStats {
  rowData: Array<StandardHierarchyNodeCellStats>;
  schools: Array<SchoolStandardStats>;
}

export interface SchoolStandardStats {
  schoolXref: string;
  rowData: Array<StandardHierarchyNodeCellStats>;
  teachers: Array<TeacherStandardStats>;
}

export interface TeacherStandardStats {
  teacherXref: string;
  rowData: Array<StandardHierarchyNodeCellStats>;
  classes: Array<ClassStandardStats>;
}

export interface ClassStandardStats {
  classXref: string;
  rowData: Array<StandardHierarchyNodeCellStats>;
  students: Array<StudentStandardStats>;
}

export interface StudentStandardStats {
  studentXref: string;
  rowData: Array<StandardHierarchyNodeCellStats>;
}

export interface AssessmentStats {
  id: number | null; // need to match this up with headers. ex: if 17, header is "17_Readiness A"
  name: string; // the unit/lesson name. ex "Ratios and Proportions". might not be needed
  totalStudentsNum: number; // 1 if this is the student level
  numOfStudentsAssigned: number; // Number of students out of total that got assigned this assessment
  totalProblems: number; // Count of all problems in the assessment
  totalGradedProblems: number; // Count of all problems excluding ungraded open response
  totalCompletedProblems: number; // Ask about this, if it’s the full assessment or partial completion of the assessment
  totalScoreOnProblems: number;
}

export interface TotalAssessmentStats {
  rowData: Array<AssessmentStats>;
  schools: Array<SchoolAssessmentStats>;
}

export interface SchoolAssessmentStats {
  schoolXref: string;
  rowData: Array<AssessmentStats>;
  teachers: Array<TeacherAssessmentStats>;
}

export interface TeacherAssessmentStats {
  teacherXref: string;
  rowData: Array<AssessmentStats>;
  classes: Array<ClassAssessmentStats>;
}

export interface ClassAssessmentStats {
  classXref: string;
  rowData: Array<AssessmentStats>;
  students: Array<StudentAssessmentStats>;
}

export interface StudentAssessmentStats {
  studentXref: string;
  rowData: Array<AssessmentStats>;
}

export interface TeacherCurriculumStats {
  teacherXref: string;
  // Keys are class xrefs
  classStats: ClassCurriculumStats[];

  // Computed grade-level data for Teacher by aggregating across course stats
  summaryProblemSetTypeStats: Map<string, StatsPerProblemSetType>;
  summaryAssessmentTypeStats: Map<string, StatsPerProblemSetType>;
}

export interface ClassCurriculumStats {
  classXref: string;
  // Keys are lesson folder numbers
  moduleStats: Map<number, ClassStatsPerModule>;

  // Computed grade-level data for Course by aggregating across module stats
  summaryProblemSetTypeStats: Map<string, StatsPerProblemSetType>;
  summaryAssessmentTypeStats: Map<string, StatsPerProblemSetType>;
}

export interface ClassStatsPerModule {
  folderId: number;
  // Keys are lesson folder numbers
  lessonStats: Map<number, ClassStatsPerLesson>;
  // Keys are assessment types
  assessmentTypeStats: Map<string, StatsPerProblemSetType>;

  // Computed module-level data by aggregating across lesson stats
  summaryProblemSetTypeStats: Map<string, StatsPerProblemSetType>;
}

export interface ClassStatsPerLesson {
  folderId: number;
  // Keys are problem set types
  problemSetTypeStats: Map<string, StatsPerProblemSetType>;
}

export interface StandardOverTimeDateData {
  date: number;
  districtNumerator: number;
  districtDenominator: number;
  comparisonNumerator: number;
  comparisonDenominator: number;
}

export enum OverTimeGraphType {
  ASSIGNED_OVER_TIME = 'ASSIGNED_OVER_TIME',
  COMPLETED_OVER_TIME = 'COMPLETED_OVER_TIME',
  SCORE_OVER_TIME = 'SCORE_OVER_TIME',
}

export enum StandardId {
  COMMON_CORE = 3,
  COMMON_CORE_PLUS = 1,
}
