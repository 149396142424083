import { getLaunch } from './../../api/core/lti.api';
import { RootState } from '@/store/types';
import { LTIState } from './types';
import { ActionTree } from 'vuex';
import { LTILaunch } from '@/domain/LTI';

export const actions: ActionTree<LTIState, RootState> = {
  getLaunch(context): Promise<LTILaunch | null> {
    return getLaunch()
      .then((launch) => {
        context.commit('setLaunch', launch);
        return launch;
      })
      .catch(() => {
        return null;
      });
  },
};
