import { GetterTree } from 'vuex';
import { SearchState } from './types';
import { RootState } from '../types';
import { ProblemSetDefinition } from '@/domain/ProblemSet';

export const getters: GetterTree<SearchState, RootState> = {
  getSkillBuilders(state: SearchState): ProblemSetDefinition[] {
    return state.skillBuilders;
  },
  getNumProblems(state: SearchState): number {
    return state.problemCount + state.problemSetCount;
  },
};
