
import { Component, Prop, Vue } from 'vue-property-decorator';
import LoginButton from '@/components/base/LoginButton.vue';

@Component({
  components: {
    LoginButton,
  },
})
export default class UnauthenticatedView extends Vue {
  @Prop({
    default: 'yellow',
  })
  buttonColor: string;
  @Prop({
    default: 'primary',
  })
  textColor: string;
}
