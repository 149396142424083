import { ActionTree } from 'vuex';
import { CurriculumState } from './types';
import { RootState } from '../types';
import { CurriculumDefinition } from '@/domain/Curriculum';
import { getCurriculumDefinitions } from '@/api/core/curricula.api';

export const actions: ActionTree<CurriculumState, RootState> = {
  requestCurricula(context): Promise<CurriculumDefinition[]> {
    if (
      !context.state.isDownloadingCurricula &&
      !context.state.hasDownloadedCurricula
    ) {
      context.commit('setIsDownloadingCurricula', true);
      context.commit('setHasDownloadedCurricula', false);

      return getCurriculumDefinitions()
        .then((definitions: CurriculumDefinition[]) => {
          context.commit('setHasDownloadedCurricula', true);
          context.commit('setDownloadedDefinitions', definitions);
          return Promise.resolve(definitions);
        })
        .catch((e) => {
          return Promise.reject(e);
        })
        .finally(() => {
          context.commit('setIsDownloadingCurricula', false);
        });
    }
    return Promise.resolve(context.state.downloadedDefinitions);
  },
};
