
import { Vue, Component, Prop } from 'vue-property-decorator';

export enum InfoMode {
  TEST,
  TIME,
  REDO,
  SCORE_DELIVERY,
}

@Component
export default class ModeInfoDialog extends Vue {
  @Prop({ required: true }) value: boolean;
  @Prop() mode: InfoMode;

  InfoMode = InfoMode;

  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(value: boolean) {
    this.$emit('input', value);
  }

  get dialogTitle(): string {
    return this.mode === InfoMode.REDO ? 'Redo Explanation' : 'Warning';
  }
}
