import { coreAxios } from '@/plugins/axios';
import { School } from '@/domain/School';

const END_POINT = '/schools';

const getSchool = (ncesId: string): Promise<School> => {
  return coreAxios.get(`${END_POINT}/${ncesId}`).then((res) => {
    return res.data;
  });
};

export interface SearchSchoolParams {
  ID?: string | string[];
  MZIP?: string | string[];
}

//TODO: we need a way to enumerate these fields in the future.
// I don't want to have to specify (and change) things like MZIP
// in all the locations it is used.
const searchSchools = (params: SearchSchoolParams): Promise<School[]> => {
  const searchStr = JSON.stringify(params);

  return coreAxios
    .get(`${END_POINT}`, {
      params: {
        search: searchStr,
      },
    })
    .then((res) => {
      return res.data;
    });
};

export { getSchool, searchSchools };
