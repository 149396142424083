
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProblemSetDefinition, ProblemSetType } from '@/domain/ProblemSet';
import CreateProblemSetDialog from './CreateProblemSetDialog.vue';
import FindProblemDialog from './FindProblemDialog.vue';
import AddProblemSetDialog from './AddProblemSetDialog.vue';
import ContentLabel from './ContentView/ContentLabel.vue';
import ValidateContentDialog from './ValidateContentDialog.vue';
import PublishContentDialog from './PublishContentDialog.vue';

export enum BuilderMode {
  READ = '0',
  EDIT = '1',
  PREVIEW = '2',
}

@Component({
  components: {
    CreateProblemSetDialog,
    FindProblemDialog,
    AddProblemSetDialog,
    ContentLabel,
    ValidateContentDialog,
    PublishContentDialog,
  },
})
export default class BuilderToolbar extends Vue {
  @Prop({ default: null }) value: BuilderMode | null;
  @Prop({ required: true }) rootPsXref: string;
  @Prop({ required: false, default: null }) prXref: string | null;
  @Prop({ required: true }) problemSetContext: ProblemSetDefinition;

  BuilderMode = BuilderMode;
  previousMode = BuilderMode.READ;
  internalMode = BuilderMode.READ;

  createProblemSetDialog = false;
  findProblemDialog = false;
  findProblemSetDialog = false;
  validateDialog = false;
  publishDialog = false;
  isSideNavVisible = true;

  get mode(): BuilderMode {
    if (this.internalMode == BuilderMode.PREVIEW) {
      return BuilderMode.PREVIEW;
    } else if (this.value == BuilderMode.PREVIEW) {
      return BuilderMode.READ;
    } else {
      return this.value ?? this.internalMode;
    }
  }

  set mode(value: BuilderMode) {
    if (value == BuilderMode.PREVIEW) {
      // Record this to return from student preview.
      this.previousMode = this.mode;
    } else {
      this.$emit('input', value);
    }
    this.internalMode = value;
  }

  get studentPreview(): boolean {
    return this.internalMode == BuilderMode.PREVIEW;
  }

  set studentPreview(value: boolean) {
    if (!value) {
      this.internalMode = this.previousMode;
    }
  }

  get modes() {
    return [
      {
        title: 'Problem',
        action: () => this.$emit('create-problem'),
      },
      {
        title: 'Problem from ID',
        action: () => this.openFindProblemDialog(),
      },
      {
        title: 'Multi-part Problem',
        action: () => this.createMultiPartProblem(),
      },
      {
        title: 'Problem Set',
        action: () => this.openCreateProblemSetDialog(),
      },
      {
        title: 'Problem Set from ID',
        action: () => this.openFindProblemSetDialog(),
      },
    ];
  }

  openFindProblemDialog(): void {
    this.findProblemDialog = true;
  }

  openCreateProblemSetDialog(): void {
    this.createProblemSetDialog = true;
  }

  openFindProblemSetDialog(): void {
    this.findProblemSetDialog = true;
  }

  get baseLink(): string {
    return `${process.env.VUE_APP_STUDENT_WORKBENCH_URL}/preview`;
  }

  get problemPreviewLink(): string {
    if (this.isMultiPart) {
      return `${this.baseLink}/problemSet/${this.problemSetContext.xref}`;
    }
    return `${this.baseLink}/problem/${this.prXref}`;
  }

  get problemSetPreviewLink(): string {
    return `${this.baseLink}/problemSet/${this.rootPsXref}`;
  }

  get isMultiPart(): boolean {
    return (
      this.problemSetContext.problemSetType ===
      ProblemSetType.MULTI_PART_PROBLEM_SET
    );
  }

  createMultiPartProblem(): void {
    this.$store
      .dispatch('content/saveProblemSet', {
        modifiedFields: {
          problemSetType: ProblemSetType.MULTI_PART_PROBLEM_SET,
        },
      })
      .then(({ ceri: xref, failMessages: error }) => {
        if (error) {
          this.$notify(`Failed to create Problem Set: ${error}`);
        } else if (xref) {
          this.$notify(`Created Problem Set ${xref}`);
          this.getProblemSet(xref);
        }
      })
      .catch(() => {
        this.$notify('Something went wrong. Failed to create Problem Set.');
      });
  }

  getProblemSet(xref: string): void {
    this.$store
      .dispatch('content/getProblemSet', { xref })
      .then((problemSet) => {
        this.$emit('new', problemSet);
        this.$notify(`Found Problem Set ${xref}`);
      })
      .catch(() => {
        this.$notify(`Failed to find Problem Set ${xref}.`);
      });
  }

  notifyUnimplemented(): void {
    this.$notify('Coming soon! Needs to be implemented.');
  }

  showSideNav(): void {
    this.isSideNavVisible = !this.isSideNavVisible;
    this.$emit('show-side-nav', this.isSideNavVisible);
  }
}
