
import { Component, Vue } from 'vue-property-decorator';
import { updateMyUserAttribute } from '@/api/core/user.api';
import { AttributeNames } from '@/domain/Attributes';
import userflow from 'userflow.js';
import { getGradeLevelList } from '@/domain/Grade';
import { getCurriculumDefinitions } from '@/api/core/curricula.api';
import { CurriculumDefinition } from '@/domain/Curriculum';

@Component
export default class SelectGC extends Vue {
  selectedGrades: string[] = [];
  selectedCurricula: string[] = [];
  //TODO: need to change this to something else that's a real curriculum object when the actual object is fixed everywhere else
  curricula: CurriculumDefinition[] = [];

  submitting = false;

  get grades(): { display: string; value: string }[] {
    return getGradeLevelList();
  }

  skip(): void {
    userflow.track(
      'grade_and_curriculum_skipped',
      // No attributes
      {},
      // Options
      {
        userOnly: true,
      }
    );
    this.goToRedirect();
  }

  submit(): void {
    this.submitting = true;
    const pGrade = updateMyUserAttribute(
      AttributeNames.GRADE_LEVEL,
      this.selectedGrades
    );
    const pCurriculum = updateMyUserAttribute(
      AttributeNames.CURRICULUM,
      this.selectedCurricula
    );
    Promise.all([pGrade, pCurriculum])
      .then(() => {
        this.goToRedirect();
      })
      .catch(() => {
        //Something bad happened.
        this.$notify(
          'An error occurred while saving grade/curriculum info. Please try again or move on.'
        );
      })
      .finally(() => {
        this.submitting = false;
      });
  }

  goToRedirect(): void {
    this.$router.push(this.$route.query.goTo as string);
  }

  created(): void {
    getCurriculumDefinitions().then((response) => {
      this.curricula = response;
    });
  }
}
