import type { AxiosResponse } from 'axios';
import { coreAxios } from '@/plugins/axios';
import { ProblemLog, StudentData } from '@/domain/ReportData/AssignmentData';
import {
  AssignmentDefinition,
  AssignmentSortByField,
  AssignmentScope,
  AssigneeType,
  AssignmentProperty,
  AssignmentStats,
} from '@/domain/Assignment';
import { LmsProviderType } from '@/domain/LmsProviderType';
import { CancelToken } from 'axios';
import { User } from '@/domain/User';
import { UserProfileDTO, transformUserProfileToUser } from '@/utils/user.util';
import { IsActiveFilter } from '@/domain/State';
import { FolderMemberType } from '@/domain/Folder';
import type { DefinitionInclude, ObjectList } from './base.api';
import { ModifiedAssignData } from '@/domain/AssignData';
import { AclPermissionType, AclResourceType } from '@/domain/Acls';

const END_POINT = '/assignments';

//////////
// DTOs //
//////////

export interface AssignmentDefinitionDTO {
  scope: AssignmentScope;
  assigneeType: AssigneeType;
  xref: string;
  assigneeXref: string;
  ownerXref: string;
  groupContextXref: string;
  problemSetCeri: string;
  lmsProviderType: string;
  name: string;
  assignDate: number;
  releaseDate: number;
  dueDate: number;
  active: boolean;
  properties?: Array<AssignmentProperty>;
  skills: Array<string>;
  permissions?: AclPermissionType[];
}

/////////////////
// CAS Web API //
/////////////////

export interface AssignmentFilterAndSortParams {
  userXref?: string;
  courseXrefs?: Array<string>;
  isActive?: IsActiveFilter;
  limit?: number;
  nextPageToken?: string;
  sortBy?: AssignmentSortByField;
  include?: Array<DefinitionInclude>;
  dueDate?: string;
  releaseDate?: string;
  assignDate?: string;
  skills?: string[];
}

const getAssignmentDefinitions = (
  params: AssignmentFilterAndSortParams,
  controller?: AbortController
): Promise<ObjectList<AssignmentDefinition>> => {
  return coreAxios
    .get(`${END_POINT}`, {
      params,
      signal: controller?.signal,
    })
    .then((result: AxiosResponse<ObjectList<AssignmentDefinitionDTO>>) => {
      return {
        data: result.data.data.map(transformAssignment),
        nextPageToken: result.data.nextPageToken,
        count: result.data.count,
      };
    });
};

export interface AssignmentDefinitionParams {
  details?: boolean;
  isActive?: IsActiveFilter;
}

// Active filters:    'ENABLED', 'DISABLED', or 'IGNORE'
// Details option:    true returns assignment's properties, defaults to false
const getAssignmentDefinition = (
  assignmentXref: string,
  params?: AssignmentDefinitionParams,
  cancelToken?: CancelToken
): Promise<AssignmentDefinition> => {
  return coreAxios
    .get(`${END_POINT}/${assignmentXref}`, {
      params: params,
      cancelToken,
    })
    .then((result: AxiosResponse<AssignmentDefinitionDTO>) => {
      return transformAssignment(result.data);
    });
};

// Data Types:             'ASSIGNMENT_LOGS', 'PROBLEM_LOGS', 'ACTION_LOGS', 'STATISTICS'
// Action filters:    'ASSIGNMENT_ACTIONS', 'PROBLEM_SET_ACTIONS', 'PROBLEM_ACTIONS', 'RESPONSE_ACTIONS'
//                    'TUTORING_REQUEST_ACTIONS', 'TIMER_ACTIONS', 'TUTORING_SET_ACTIONS'
// Statistic filters: 'ALL_STUDENTS', 'SPECIFIED_STUDENTS'
// State filters:     'ENABLED', 'DISABLED', 'IGNORE'
type ActionFilter =
  | 'ASSIGNMENT_ACTIONS'
  | 'TIMER_ACTIONS'
  | 'PROBLEM_ACTIONS'
  | 'RESPONSE_ACTIONS'
  | 'TUTORING_REQUEST_ACTIONS';

type DataType =
  | 'ASSIGNMENT_LOGS'
  | 'PROBLEM_LOGS'
  | 'ACTION_LOGS'
  | 'STATISTICS';

type StatisticsFilter = 'ALL_STUDENTS' | 'SPECIFIED_STUDENTS';

interface AssignmentReportDataParams {
  dTypes?: Array<DataType>;
  aFilters?: Array<ActionFilter>;
  usersFilter?: Array<string>;
  statisticsFor?: StatisticsFilter;
  isActiveFilter?: IsActiveFilter;
}

const getAssignmentReportData = (
  assignmentXref: string,
  // FIXME: Are these filters what we want here?
  params: AssignmentReportDataParams = {}
): Promise<StudentData> => {
  const queryParams = setDefaultAssignmentReportDataParams(params);

  return coreAxios
    .get(`${END_POINT}/${assignmentXref}/sdata`, {
      params: {
        ...queryParams,
      },
    })
    .then((result: AxiosResponse<StudentData>) => {
      return result.data;
    });
};

const deleteAssignmentProgress = (
  assignmentXref: string,
  studentXref: string
): Promise<void> => {
  return coreAxios.delete(
    `${END_POINT}/${assignmentXref}/sdata/${studentXref}`
  );
};

const getAssignmentAssignees = (
  assignmentXref: string
): Promise<Array<User>> => {
  return coreAxios
    .get(`${END_POINT}/${assignmentXref}/assignees`)
    .then((res: AxiosResponse<UserProfileDTO[]>) => {
      return res.data.map(transformUserProfileToUser);
    });
};

// FIXME: Figure out how we can prevent Teacher from overwriting entire partLogData map
// including but not limited to prior responses. Support partial partLogData?
const updateStudentProblemLog = (
  assignmentXref: string,
  studentXref: string,
  problemLogId: number,
  payload: Pick<ProblemLog, 'continuousScore' | 'partLogData'>
): Promise<void> => {
  return coreAxios.patch(
    `${END_POINT}/${assignmentXref}/sdata/${studentXref}/pLogs/${problemLogId}`,
    payload
  );
};

const getAssignmentStats = (xref: string): Promise<AssignmentStats> => {
  return coreAxios.get(`${END_POINT}/${xref}/astats`).then((result) => {
    return result.data;
  });
};

const deleteAssignment = (
  xref: string,
  lmsProviderType?: LmsProviderType
): Promise<void> => {
  return coreAxios.delete(`${END_POINT}/${xref}`, {
    params: { lmsPtype: lmsProviderType?.id },
  });
};

const updateAssignment = (
  xref: string,
  modifiedFields: ModifiedAssignData,
  lmsProviderType?: LmsProviderType
): Promise<void> => {
  return coreAxios.patch(`${END_POINT}/${xref}`, modifiedFields, {
    params: { lmsPtype: lmsProviderType?.id },
  });
};

const uploadAssignmentScores = (
  xref: string,
  lmsProviderType?: LmsProviderType
): Promise<void> => {
  return coreAxios.post(`${END_POINT}/${xref}/scores/upload`, undefined, {
    params: { lmsPtype: lmsProviderType?.id },
  });
};

/////////////
// Helpers //
/////////////
function transformAssignment(
  assignment: AssignmentDefinitionDTO
): AssignmentDefinition {
  return {
    ...assignment,
    dueDate: assignment.dueDate || null,
    lmsProviderType: LmsProviderType.findByName(
      assignment.lmsProviderType
    ) as LmsProviderType,
    memberType: FolderMemberType.ASSIGNMENT,
    resourceType: AclResourceType.ASSIGNMENT,
    permissions: assignment.permissions ?? [],
    // Internal processing only. Will not included in DTO if not set manually.
    createdAt: assignment.assignDate,
    updatedAt: assignment.assignDate,
  };
}

function setDefaultAssignmentReportDataParams(
  params: AssignmentReportDataParams
): AssignmentReportDataParams {
  if (!params.dTypes) {
    params.dTypes = [
      'ASSIGNMENT_LOGS',
      'ACTION_LOGS',
      'PROBLEM_LOGS',
      'STATISTICS',
    ];
  }

  if (!params.aFilters) {
    params.aFilters = [
      'ASSIGNMENT_ACTIONS',
      'TIMER_ACTIONS',
      'PROBLEM_ACTIONS',
      'RESPONSE_ACTIONS',
      'TUTORING_REQUEST_ACTIONS',
    ];
  }

  if (!params.statisticsFor) {
    params.statisticsFor = 'ALL_STUDENTS';
  }

  return params;
}

export {
  getAssignmentDefinitions,
  getAssignmentDefinition,
  getAssignmentReportData,
  deleteAssignmentProgress,
  updateStudentProblemLog,
  getAssignmentAssignees,
  getAssignmentStats,
  deleteAssignment,
  updateAssignment,
  uploadAssignmentScores,
  transformAssignment,
};
