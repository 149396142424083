import { render, staticRenderFns } from "./FolderFileView.vue?vue&type=template&id=4071e074&scoped=true&"
import script from "./FolderFileView.vue?vue&type=script&lang=ts&"
export * from "./FolderFileView.vue?vue&type=script&lang=ts&"
import style0 from "./FolderFileView.vue?vue&type=style&index=0&id=4071e074&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4071e074",
  null
  
)

export default component.exports