
import { ModuleDefinition } from '@/domain/Curriculum';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataOptions } from 'vuetify';
import TreeGradientTable, {
  GradientHeaders,
  GradientRows,
  Row,
  RowType,
} from '../base/TreeGradientTable.vue';
import SelectAssignmentIdDialog from './SelectAssignmentIdDialog.vue';
import SelectProblemSetIdDialog from './SelectProblemSetIdDialog.vue';

export interface LessonProblemSet {
  lessonId: number;
  psXref: string;
}

@Component({
  components: {
    SelectAssignmentIdDialog,
    SelectProblemSetIdDialog,
    TreeGradientTable,
  },
})
export default class LessonDetailsTable extends Vue {
  @Prop({ default: null }) module: ModuleDefinition | null;
  @Prop({ required: true }) color: string;
  @Prop({ required: true }) headerTitle: string;
  @Prop({ required: true }) rowHeaders: GradientHeaders;
  @Prop({ required: true }) rows: GradientRows;
  @Prop({ default: new Set<string>() }) collapsedPaths: Set<string>;
  @Prop({ required: false }) options: DataOptions;
  @Prop({ default: false }) anonymized: boolean;
  @Prop({ default: false }) loading: boolean;

  RowType = RowType;

  // Problem Set Dialog
  problemSetDialog = false;
  lessonFolderId: number | null = null;
  psCeris: string[] = [];

  // Assignment Dialog
  assignmentDialog = false;
  assignmentXrefs: string[] = [];

  openProblemSetDialog(header: string) {
    const tokens = this.decodeHeader(header);

    this.lessonFolderId = Number(tokens[0]);
    const psType = tokens[1];

    if (psType) {
      const lesson = this.module?.lessons.get(this.lessonFolderId);

      if (lesson) {
        // A list of Problem Set selections
        this.psCeris = lesson.problemSetTypeStats.get(psType)?.ceris ?? [];

        // Open Problem Set Dialog for selection
        this.problemSetDialog = true;
      }
    }
  }

  openAssignmentDialog(header: string, row: Row) {
    // Header values are strings shaped <folderId>_<optional PS type>_<mode>
    const tokens = header.split('_');

    // Remove mode (always the last element)
    tokens.pop();

    // Determine the set of Lesson Assignments
    let assignmentSet = tokens[0];

    if (tokens[1]) {
      // Subset of Problem Set Type Assignments
      assignmentSet += `_${tokens[1]}`;
    }

    // A list of assignment selections
    this.assignmentXrefs =
      (row[`${assignmentSet}_assignmentXrefs`] as string[]) || [];

    // Open Assignment Dialog for selection
    this.assignmentDialog = true;
  }

  decodeHeader(header: string): string[] {
    // Header values are strings shaped <folderId>_<optional PS type>_<mode>
    const tokens = header.split('_');

    // Remove mode (always the last element)
    tokens.pop();

    return tokens;
  }

  goToLessonProblemSet(psXref: string): void {
    this.$emit('clickLessonProblemSet', psXref);
  }
}
