import { render, staticRenderFns } from "./DeleteAssignmentDialog.vue?vue&type=template&id=057f2a25&scoped=true&"
import script from "./DeleteAssignmentDialog.vue?vue&type=script&lang=ts&"
export * from "./DeleteAssignmentDialog.vue?vue&type=script&lang=ts&"
import style0 from "./DeleteAssignmentDialog.vue?vue&type=style&index=0&id=057f2a25&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "057f2a25",
  null
  
)

export default component.exports