
import { Component, Vue, Watch } from 'vue-property-decorator';
import { getProblemSetDefinition } from '@/api/core/content.api';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';

@Component
export default class SearchPsByPsaDialog extends Vue {
  showDialog = false;
  psa = '';
  noResults = false;
  loading = false;

  /**
   * When psa changes, clear noResults display
   */
  @Watch('psa')
  onPsaChange(): void {
    this.noResults = false;
  }

  @Watch('showDialog')
  onShowDialogChange(): void {
    // Cleanup on close
    if (!this.showDialog) {
      this.resetDefaults();
    }
  }

  get hasContent(): boolean {
    return this.psa != '';
  }

  cancel(): void {
    this.showDialog = false;

    this.resetDefaults();
  }

  resetDefaults(): void {
    this.psa = '';
    this.noResults = false;
    this.loading = false;
  }

  submitSearch(): void {
    this.loading = true;

    getProblemSetDefinition(this.psa)
      .then((res) => {
        this.loading = false;

        this.$router.push({
          name: 'LessonPageProblemSet',
          params: {
            problemSetXref: res.xref,
          },
        });

        trackMixpanel(EventType.searchProblemSetSuccess, {
          psa: this.psa,
          // psId: res,
        });
      })
      .catch(() => {
        this.loading = false;
        this.noResults = true;

        // Track failed search (no results) with Mixpanel
        trackMixpanel(EventType.searchProblemSetError, {
          psa: this.psa,
        });
      });
  }
}
