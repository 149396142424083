
import { AclSidType, AclPermissionType } from '@/domain/Acls';
import { User } from '@/domain/User';
import { GroupDefinition } from '@/domain/Group';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { getAccessGroup, getResourcePermissions } from '@/api/core/acls.api';
import { getUser } from '@/api/core/user.api';
import { FolderDefinition } from '@/domain/Folder';
import { SidPermission } from '@/domain/Acls';

@Component
export default class PermissionsDialog extends Vue {
  @Prop({ required: true }) path: FolderDefinition[];

  AclSidType = AclSidType;
  AclPermissionType = AclPermissionType;
  permissionsMap = this.getNewPermissionsMap();

  dialog = false;
  downloading = false;

  controller: AbortController | undefined = undefined;

  // FIXME: Support other resources. Assignments, Problem Sets, ...
  get file(): FolderDefinition | undefined {
    return this.path[this.path.length - 1];
  }

  get filePath(): string {
    const names = this.path.map((element) => element.name);
    return names.join(' / ');
  }

  getNewPermissionsMap(): Record<AclSidType, SidPermission[]> {
    return {
      [AclSidType.USER]: [],
      [AclSidType.GROUP]: [],
      [AclSidType.ROLE]: [],
    };
  }

  fillInPermissionsMap(): Promise<void> {
    const promises = [];
    for (const sidType in this.permissionsMap) {
      const permissions: SidPermission[] =
        this.permissionsMap[sidType as AclSidType];
      // FIXME: Could we make this more efficient by doing lookups in bulk?
      switch (sidType) {
        case AclSidType.USER:
          // TODO: Figure out how we should handle getting other user profiles.
          promises.push(
            ...permissions.map((permission) =>
              getUser(permission.sidXref, false, false).then((user: User) => {
                permission.sidXref = user.displayName;
              })
            )
          );
          break;
        case AclSidType.GROUP:
          promises.push(
            ...permissions.map((permission) =>
              getAccessGroup(permission.sidXref).then(
                (group: GroupDefinition) => {
                  permission.sidXref = group.name;
                }
              )
            )
          );
          break;

        case AclSidType.ROLE:
        default:
          break;
      }
    }
    return Promise.allSettled(promises).then(() => {
      return;
    });
  }

  @Watch('dialog')
  onDialog(): void {
    // Cancel previous request.
    if (this.controller) {
      this.controller.abort();
    }
    if (this.dialog && this.file) {
      this.controller = new AbortController();
      // Clear out prior results
      this.permissionsMap = this.getNewPermissionsMap();
      this.downloading = true;
      getResourcePermissions(
        this.file.resourceType,
        this.file.xref,
        this.controller
      )
        .then((permissionsMap: Record<AclSidType, SidPermission[]>) => {
          this.permissionsMap = permissionsMap;
          return this.fillInPermissionsMap();
        })
        .finally(() => {
          this.downloading = false;
        });
    }
  }
}
