
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class NoHintsOrExplanation extends Vue {
  firstHint() {
    this.$emit('addFirst', 'Hint');
  }

  firstExplanation() {
    this.$emit('addFirst', 'Explanation');
  }
}
