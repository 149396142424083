
import { ProblemDefinition } from '@/domain/Problem';
import {
  ContentMemberType,
  ProblemSetDefinition,
  ProblemSetType,
} from '@/domain/ProblemSet';
import { isEqual } from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ContentLabel from './ContentView/ContentLabel.vue';
import { getPartLetter } from '@/utils/problem.util';
import draggable from 'vuedraggable';
import { ContentType } from '@/domain/Content';
import { getContentType, isPublished } from '@/utils/builder.util';
import { AclPermissionType } from '@/domain/Acls';

export interface Node {
  xref: string;
  type?: ContentMemberType;
  path?: string;
  label?: string;
  description?: string;
}

@Component({
  components: {
    ContentLabel,
    draggable,
    ProblemSetTreeLevel: () => import('./ProblemSetTreeLevel.vue'),
  },
})
export default class ProblemSetTreeLevel extends Vue {
  @Prop({ required: false, default: true }) value: boolean;
  @Prop({ required: true }) context: Node;
  @Prop({ required: false, default: false }) showCeri: boolean;

  ContentType = ContentType;

  // Control opening of child nodes (subtrees). Should be safe to make ceris keys here, since
  // there should be no duplicates in the same level?
  problemSetNodes: Record<string, boolean> = {};

  get readonly(): boolean {
    return (
      !this.problemSet?.permissions.includes(AclPermissionType.UPDATE) ?? true
    );
  }

  get opened(): boolean {
    return this.value;
  }

  set opened(value: boolean) {
    this.$emit('input', value);
  }

  get selected(): string | null {
    return (this.$route.query.contentPath as string) ?? null;
  }

  set selected(value: string) {
    let pathParam = undefined;
    if (!isEqual(value, this.selected)) {
      pathParam = value;
    }
    // Update the URL.
    this.$router.replace({
      query: {
        ...this.$route.query,
        contentPath: pathParam,
      },
    });
  }

  get contextPath(): string {
    return this.context.path ? this.context.path : this.context.xref;
  }

  get depth(): number {
    return this.contextPath.split(',').length;
  }

  get problemSetMap(): Record<string, ProblemSetDefinition> {
    return this.$store.state.content.problemSetMap;
  }

  get problemMap(): Record<string, ProblemDefinition> {
    return this.$store.state.content.problemMap;
  }

  get problemSet(): ProblemSetDefinition | undefined {
    return this.problemSetMap[this.context.xref];
  }

  get children(): (ProblemSetDefinition | ProblemDefinition)[] {
    const definitions = [];
    const ceris = this.problemSet?.children ?? [];
    for (const ceri of ceris) {
      let contentMap = null;
      const type = getContentType(ceri);
      switch (type) {
        case ContentType.PROBLEM:
          contentMap = this.problemMap;
          break;
        case ContentType.PROBLEM_SET:
          contentMap = this.problemSetMap;
          break;
      }
      if (contentMap) {
        let content = contentMap[ceri];
        if (
          isPublished(content.xref) &&
          content.permissions.includes(AclPermissionType.UPDATE) &&
          content.mappedCeri
        ) {
          content = contentMap[content.mappedCeri];
        }
        if (content) {
          definitions.push(content);
        }
      }
    }
    return definitions;
  }

  get members(): Node[] {
    const nodes: Node[] = [];
    for (let i = 0; i < this.children.length; i++) {
      const child = this.children[i];
      const position = i + 1;
      const childLabel =
        this.problemSet?.problemSetType == ProblemSetType.MULTI_PART_PROBLEM_SET
          ? getPartLetter(position)
          : `Problem ${position}`;
      const childPath = `${this.contextPath},${child.xref}`;
      const node: Node = {
        xref: child.xref,
        path: childPath,
        label: this.context.label
          ? `${this.context.label} ${childLabel}`
          : childLabel,
      };
      node.type = child.contentType;
      if (child.contentType == ContentType.PROBLEM) {
        node.description = this.getProblemQuestion(child.question);
      }
      nodes.push(node);
    }
    return nodes;
  }

  getProblemQuestion(question: string): string {
    let div: HTMLElement | null = document.getElementById(
      'question-conversion'
    );
    if (!div) {
      div = document.createElement('div');
      div.id = 'question-conversion';
    }
    div.innerHTML = question;
    return div.textContent || div.innerText || '';
  }

  toggleOpen(): void {
    if (this.selected?.startsWith(this.contextPath)) {
      this.opened = true;
    } else {
      // FIXME: Figure out if this is what we want to do here or we don't care?
      this.opened = false;
    }
  }

  created(): void {
    this.toggleOpen();
    this.checkInvalidPath();
  }

  // FIXME: Figure out if this component should emit an event and pass it up to the parent,
  // in the case where the move operation is also supported somewhere else, there may be
  // duplicated code. For now, let's handle the actual move operation here.
  onDragEnd(moved: { oldIndex: number; newIndex: number }): void {
    if (!isEqual(moved.oldIndex, moved.newIndex)) {
      const member = this.members[moved.oldIndex];
      this.$store
        .dispatch('content/moveProblemSetMember', {
          xref: this.context.xref,
          moved,
        })
        .then(({ ceri: xref, failMessages: error }) => {
          if (error) {
            this.$notify(
              `Failed to move member ${member.xref} in ${this.context.xref}: ${error}`
            );
          } else if (xref) {
            this.$notify(`Saved changes to Problem Set ${xref}.`);
          }
        })
        .catch(() => {
          this.$notify(
            `Failed to move member ${member.xref} in ${this.context.xref}.`
          );
        });
    }
  }

  checkInvalidPath(): void {
    let validSubpath = !this.selected?.startsWith(this.contextPath);
    if (!validSubpath) {
      for (const node of this.members) {
        if (this.selected?.startsWith(node.path)) {
          validSubpath = true;
          break;
        }
      }
    }
    if (!validSubpath && this.members[0]) {
      this.$emit(
        'invalid-path',
        this.members[0].path ? this.members[0].path : undefined
      );
    }
  }

  @Watch('selected')
  onSelectedPath(): void {
    this.toggleOpen();
    this.checkInvalidPath();
  }

  @Watch('members')
  onMembers(): void {
    this.checkInvalidPath();
  }
}
