import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { CurriculumState } from './types';
import { RootState } from '../types';

export const state: CurriculumState = {
  isDownloadingCurricula: false,
  hasDownloadedCurricula: false,
  downloadedDefinitions: [],
};

const namespaced = true;

export const curricula: Module<CurriculumState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
