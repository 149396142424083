import { UserAttributes, UserRole } from './../domain/User';
import { Server, Model } from 'miragejs';
import { SettingsDTO } from '@/api/core/user.api';
import seedData from './seeds';
import initializeEndpoints from './endpoints';
import { AttributeNames } from '@/domain/Attributes';
import { AuthenticatedUserDTO } from '@/api/init.api';
import dayjs from 'dayjs';
import { AclPermissionType } from '@/domain/Acls';

const MOCK_USER_MY_PSES = '0448b66c-9337-469f-9bbe-756f596ff0e7';
const MOCK_USER_MY_PSES_ASSIGNED = '74c2e813-ce6d-4fe5-9b40-8db01f91a73a';

const mockInvitationCode = 'mirage';

const mockUserAttributes: UserAttributes = {
  [AttributeNames.NCES_SCHOOL]: ['1'],
  [AttributeNames.GRADE_LEVEL]: ['GRADE_1'],
  [AttributeNames.CURRICULUM]: ['a9beff09-6e2a-8eef-d36a-4ebac161ca69'],
  [AttributeNames.QUICK_COMMENTS_TEACHER]: [true],
};

const mockUserSettings: Array<SettingsDTO> = [
  {
    name: 'anonymizeReportsByDefault',
    value: false,
    hasBeenSet: true,
  },
  {
    name: 'testModeCorrectness',
    value: false,
    hasBeenSet: true,
  },
  {
    name: 'testModeStudentReport',
    value: false,
    hasBeenSet: true,
  },
  {
    name: 'correctnessFeedback',
    value: false,
    hasBeenSet: true,
  },
  {
    name: 'starredTeacherEnabled',
    value: true,
    hasBeenSet: true,
  },
  {
    name: 'testMode',
    value: false,
    hasBeenSet: true,
  },
  {
    name: 'personalizedTutoringEnabled',
    value: false,
    hasBeenSet: true,
  },
  {
    name: 'accessToLDOEReport',
    value: true,
    hasBeenSet: true,
  },
  {
    name: 'accessToInsightsHub',
    value: true,
    hasBeenSet: true,
  },
  {
    name: 'quickCommentsTeacher',
    value: true,
    hasBeenSet: true,
  },
  {
    name: 'deliveryOfStudentScore',
    value: true,
    hasBeenSet: true,
  },
  {
    name: 'useRedo',
    value: false,
    hasBeenSet: true,
  },
  {
    name: 'redoEnabled',
    value: true,
    hasBeenSet: true,
  },
  {
    name: 'useTeachley',
    value: true,
    hasBeenSet: true,
  },
  {
    name: 'alphaTeacherBuilderAccess',
    value: true,
    hasBeenSet: true,
  },
];

const mockUser = {
  data: {
    xref: 'hLVmvz6zgr6nmyEMW9qt',
    userName: 'tBucks1337@testmail.com',
    firstName: 'Terrance',
    lastName: 'Buckington',
    displayName: 'Mr Buckington',
    userToken:
      process.env.VUE_APP_USER_TOKEN || '40b5b1d8-4f61-4e3e-bd31-16b3da8d937f',
    loginAliasId: process.env.VUE_APP_LAID || '48839',
    roles: [
      UserRole.STUDENT,
      UserRole.MENTOR,
      UserRole.TEACHER,
      UserRole.CONTENT_ADMIN,
      // UserRole.TRUSTED_BUILDER,
    ],
    timeZone: 'America/New_York',
    attributes: mockUserAttributes,
    lmsProviderType: 'GOOGLE_CLASSROOM',
    // lmsProviderType: 'LTI_ENABLED',
    createdAt: dayjs().valueOf(),
  } as AuthenticatedUserDTO,
};

/**
 * Mocks authentication status
 * first time requesting, it responds to indicate not authenticated
 * After that, says you're authenticated and sends hard-coded mocked user data
 */
const isLoggedIn = { status: false };
const cookieName = `ASSISTmentsService${
  process.env.VUE_APP_COOKIE_SUFFIX || ''
}`;

function checkLogin(): void {
  //https://stackoverflow.com/a/25490531
  const b = document.cookie.match(
    '(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)'
  );
  const cookie = b ? b.pop() : '';
  if (cookie) {
    isLoggedIn.status = true;
  }
}

function encodeId(prefix: string, id: number): string {
  let ret = '';
  const alphabet = 'ABCDEFGHJKMNPQRSTUVWXYZ23456789';
  const alphabetLen = alphabet.length;

  while (id > 0) {
    ret += alphabet.charAt(id % alphabetLen);
    id = Math.floor(id / alphabetLen);
  }

  return `${prefix.toUpperCase()}A${ret.split('').reverse().join('')}`;
}

export {
  MOCK_USER_MY_PSES,
  MOCK_USER_MY_PSES_ASSIGNED,
  mockUser,
  mockUserSettings,
  mockUserAttributes,
  mockInvitationCode,
  isLoggedIn,
  checkLogin,
  cookieName,
  encodeId,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function makeServer({
  environment = 'development',
} = {}): Server {
  const server = new Server({
    environment,
    urlPrefix: 'http://localhost:8081',
    models: {
      aclResource: Model,
      assignment: Model,
      assignmentDefinition: Model,
      assignmentStats: Model,
      assignmentReport: Model,
      attribute: Model,
      attribution: Model,
      class: Model,
      classDefinition: Model,
      curriculum: Model,
      curriculumGrade: Model,
      folder: Model,
      folderDefinition: Model,
      folderInfo: Model,
      group: Model,
      lmsClass: Model,
      migrationStat: Model,
      problem: Model,
      problemDefinition: Model,
      problemSet: Model,
      problemSetReport: Model,
      problemSetDefinition: Model,
      quickComment: Model,
      school: Model,
      settings: Model,
      settingsDefinitions: Model,
      skill: Model,
      skillStrand: Model,
      ssasSupports: Model, // we want to rename it once we can unify the hint api method for the reports
      standardCoherence: Model,
      standardOverTime: Model,
      standardCurrentTime: Model,
      student: Model,
      studentAndPeerDataPerSkill: Model,
      studentSupport: Model,
      teacherCurriculumStat: Model,
      user: Model,
      tutorStrategy: Model,
      urlConversion: Model,
      teachley: Model,
    },
  });

  seedData(server);
  initializeEndpoints(server);

  return server;
}
