var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_vm._l((_vm.answerSet.members),function(member,memberIndex){return _c('div',{key:`${member.memberType}_${memberIndex}`},[(member.memberType == 'ANSWER_PART')?[(_vm.answerType === _vm.AnswerType.CHOOSE_N)?_c('div',[_c('v-col',[_c('v-card',{attrs:{"elevation":"0"}},[(member.answerValues)?_vm._l((member.answerValues),function(answerValue,valueIndex){return _c('div',{key:valueIndex},[_c('v-row',{key:`${answerValue.value}_${valueIndex}`,staticClass:"mb-3",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-checkbox',{key:`${answerValue.value}_${valueIndex}`,staticClass:"my-3",attrs:{"data-cy":`checkbox-${valueIndex}`,"input-value":_vm.tutorStrategy.answerPartsToResponses[
                          member.htmlMarker
                        ].includes(answerValue.value),"disabled":_vm.disabled,"hide-details":""},on:{"click":function($event){return _vm.updateChooseN(member.htmlMarker, answerValue.value)}}})],1),_c('v-col',[_c('div',{staticClass:"d-flex align-stretch rounded bordered-answer"},[_c('div',{staticClass:"d-flex px-3 py-4 rounded neutral lighten-2 neutral--text text--darken-3 align-center"},[_vm._v(" "+_vm._s(_vm.getLabel( member.properties?.LABEL_START || '', valueIndex ))+" ")]),_c('span',{staticClass:"text-body-1 pl-4 pt-4 content-body d-flex align-center",domProps:{"innerHTML":_vm._s(answerValue.value)}})])])],1)],1)}):_vm._e()],2)],1)],1):_vm._e(),(_vm.answerType === _vm.AnswerType.SORT)?_c('div',[[_c('draggable',{attrs:{"draggable":".item","animation":"200","disabled":_vm.disabled},on:{"end":_vm.updateSort},model:{value:(_vm.tutorStrategy.answerPartsToResponses[member.htmlMarker]),callback:function ($$v) {_vm.$set(_vm.tutorStrategy.answerPartsToResponses, member.htmlMarker, $$v)},expression:"tutorStrategy.answerPartsToResponses[member.htmlMarker]"}},[_c('transition-group',{class:_vm.answerSet.properties?.STACKED || !_vm.answerSet.properties
                  ? ''
                  : 'd-flex align-center justify-center',attrs:{"enter-class":"drag-transition-enter"}},_vm._l((_vm.tutorStrategy
                  .answerPartsToResponses[member.htmlMarker]),function(answer,index){return _c('div',{key:`${answer}_${index}`,staticClass:"item"},[(
                    _vm.answerSet.properties?.STACKED || !_vm.answerSet.properties
                  )?_c('v-row',{staticClass:"align-stretch rounded bordered-answer ma-2",attrs:{"align":"center","data-cy":`sort-vertical-${index}`}},[_c('v-col',{staticClass:"label d-flex align-center",attrs:{"cols":"auto"}},[_c('v-icon',{staticClass:"draggable"},[_vm._v(" mdi-drag ")])],1),_c('v-col',{staticClass:"text-body-1 content-body d-flex align-center pa-0 pl-3"},[_c('span',{staticClass:"html-content",domProps:{"innerHTML":_vm._s(answer)}})])],1):_c('div',{staticClass:"d-flex align-center justify-center mx-2 px-4 py-2 draggable horizontal-drag-target rounded",attrs:{"data-cy":`sort-horizontal-${index}`}},[_c('span',{staticClass:"d-flex content-body",domProps:{"innerHTML":_vm._s(answer)}})])],1)}),0)],1)]],2):_vm._e()]:_vm._e()],2)}),(
      [
        _vm.AnswerType.EXACT_MATCH,
        _vm.AnswerType.ALGEBRA,
        _vm.AnswerType.EXACT_FRACTION,
        _vm.AnswerType.NUMERIC,
        _vm.AnswerType.NUMERIC_EXPRESSION,
      ].includes(_vm.answerType)
    )?_c('div',[_vm._v(" I'm a fill in ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }