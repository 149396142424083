
import { FolderDefinition } from '@/domain/Folder';
import { ContentMemberType, ProblemSetDefinition } from '@/domain/ProblemSet';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ProblemSetFileMenu from './ProblemSetFileMenu.vue';
import { Mode } from './FolderExplorer.vue';
import { SortBy } from './ProblemSetExplorer.vue';
import { getPathParam } from '@/utils/navigation.util';
import { ContentType } from '@/domain/Content';
import TimeFromNow from './ContentView/TimeFromNow.vue';
import { isPublished, isWip } from '@/utils/builder.util';
import { AclPermissionType } from '@/domain/Acls';

@Component({
  components: {
    ProblemSetExplorer: () => import('./ProblemSetExplorer.vue'),
    ProblemSetFileMenu,
    TimeFromNow,
  },
})
export default class ProblemSetFileView extends Vue {
  @Prop({ required: true }) problemSet: ProblemSetDefinition;
  @Prop({ default: () => [] }) path: (
    | FolderDefinition
    | ProblemSetDefinition
  )[];
  @Prop({ default: 0 }) depth: number;
  @Prop({ default: () => Mode.BROWSE }) mode: Mode;
  @Prop({ default: 0 }) indent: number;
  @Prop({ required: false }) sort: SortBy | undefined | null;
  @Prop({
    default: () => [ContentType.PROBLEM_SET, ContentType.PROBLEM],
  })
  cmTypes: ContentMemberType[];

  Mode = Mode;

  open = false;

  get isRoot(): boolean {
    return this.depth === 0;
  }

  get pathList(): (FolderDefinition | ProblemSetDefinition)[] {
    return [...this.path, this.problemSet];
  }

  get contentPath(): string {
    return getPathParam(this.pathList);
  }

  get problemSetName(): string | undefined {
    return this.problemSet.name;
  }

  get showWipIcon(): boolean {
    return (isWip(this.problemSet.xref) ||
      (this.problemSet.permissions.includes(AclPermissionType.UPDATE) &&
        this.problemSet.mappedCeri)) as boolean;
  }

  get label(): string {
    const header = this.problemSet.properties?.HEADER;
    const name = this.problemSetName;
    const cerisList = [this.problemSet.xref];
    if (
      this.problemSet.permissions.includes(AclPermissionType.UPDATE) &&
      this.problemSet.mappedCeri
    ) {
      cerisList.push(this.problemSet.mappedCeri);
    }
    const ceris = cerisList.join(', ');
    if (header && name) {
      return `${header} -- ${name} -- ${ceris}`;
    } else if (header) {
      return `${header} -- ${ceris}`;
    } else if (name) {
      return `${name} -- ${ceris}`;
    } else {
      return ceris;
    }
  }

  toggleOpen(): void {
    this.open = !this.open;
    if (this.mode == Mode.BROWSE) {
      this.$router.push({
        name: 'LessonPageProblemSet',
        params: {
          problemSetXref: this.problemSet.xref,
        },
      });
    }

    let path = undefined;
    if (this.open) {
      if (this.cmTypes.length) {
        path = this.contentPath;
      } else {
        this.$emit('open-problem-set', this.problemSet.xref);
      }
    } else {
      // Open parent
      path = getPathParam(this.path);
    }
    const current = this.$route.query.p;
    if (path && current != path) {
      // FIXME: Figure out if we need a manual migration in content store.
      this.$store.commit('folder/setManualNavigation', true);
      this.$router
        .replace({
          // name: this.$route.name,
          query: {
            ...this.$route.query,
            p: path,
          },
        })
        .then(() => {
          this.$store.commit('folder/setManualNavigation', false);
        });
    }
  }
}
