import { coreAxios } from '@/plugins/axios';

export interface URLConversionRequestParams {
  problemSetIds?: number[];
  folderIds?: number[];
}
export interface URLConversionResponseObject {
  resourceType: URLConversionResponseObjectType;
  originalId: number;
  newXref: string;
}
export enum URLConversionResponseObjectType {
  FOLDER = 'FOLDER',
  LEGACY_PLAIN_OLD_PROBLEM_SET = 'LEGACY_PLAIN_OLD_PROBLEM_SET',
}

const END_POINT = '/compatibility';
const CONVERSION_END_POINT = `${END_POINT}/legacyIdToSDK3`;

const getSDK3CeriForIds = (
  params: URLConversionRequestParams
): Promise<URLConversionResponseObject[]> => {
  return coreAxios.get(`${CONVERSION_END_POINT}`, { params }).then((res) => {
    return res.data;
  });
};

export { getSDK3CeriForIds };
