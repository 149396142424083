import { MutationTree } from 'vuex';
import { Class } from '@/domain/Class';
import { ClassState } from './types';

export const mutations: MutationTree<ClassState> = {
  setHasDownloaded(state: ClassState, flag: boolean) {
    state.hasDownloaded = flag;
  },
  setIsDownloading(state: ClassState, flag: boolean) {
    state.isDownloading = flag;
  },
  setClassList(state: ClassState, newList: Array<Class>) {
    state.classes = newList;
  },
};
