
import { Component, Prop, Vue } from 'vue-property-decorator';
import Editor from '@tinymce/tinymce-vue';
import baseConfig, {
  getResponseBoxes,
  insertResponse,
  ResponseBoxType,
} from '@/utils/tinyMCE/problemBuildertinyMCEConfig';
import { TinyMCEEditor } from '@/types/tiny';
import { uniqueId } from 'lodash';
import { UploadResult } from '@/utils/tinyMCE/tinymceInterfaces.util';
import { PropType } from 'vue';

//This should be used as the problem body editor. The parent component should hold the actual problem and it's contents and handle eveyrthing else
@Component({
  components: { Editor },
})
export default class BuilderEditor extends Vue {
  @Prop({ default: '' }) value: string;
  @Prop({ type: Boolean, default: false }) isAnswerSetup: boolean;
  @Prop({ type: Boolean, default: false }) isSupportsSetup: boolean;
  @Prop({ type: Boolean, default: false }) restrictImageSize: boolean;
  @Prop({ default: () => [] }) responseBoxTypes: ResponseBoxType[];
  @Prop({
    type: String as unknown as PropType<string | null>,
    default: null,
  })
  customEditorId: string | null;
  @Prop({ type: Boolean, default: false }) autoFocus: boolean;
  @Prop({ default: false }) disabled: boolean;

  get editorId(): string {
    return this.customEditorId ?? uniqueId('tinymce');
  }

  get editorText(): string {
    return this.value;
  }

  set editorText(value: string) {
    this.$emit('input', value);
    this.$emit('computed', getResponseBoxes(this.myEditor));
  }

  get toolbar(): string {
    // FIXME: Figure out if these should be in the default toolbar?
    let config = baseConfig.toolbar + ' | alignleft aligncenter alignright';
    if (this.responseBoxTypes.length) {
      config += ' | insertResponse';
    }
    return config;
  }

  get editorData(): object {
    const customConfig = {
      auto_focus: this.autoFocus ? this.editorId : null,
      toolbar: this.toolbar,
      assistments_image_data: this.restrictImageSize ? { width: '200' } : {},
      fixed_toolbar_container: `#${this.toolbarId}`,
    };
    const editorData = {
      ...baseConfig,
      ...customConfig,
      setup: this.setupFunction,
    };
    return editorData;
  }

  get toolbarId(): string {
    return `${this.editorId}-toolbar`;
  }

  get isAnswerOrSupportsSetup(): boolean {
    return this.isAnswerSetup || this.isSupportsSetup;
  }

  //Todo: See if (once we have composables) a composable can handle all of this stuff and the other
  // problemBuildertinyMCEConfig.ts stuff as a single file

  //This should be v-modeled in by the parent component.

  get menuItems() {
    const items = [];
    if (this.responseBoxTypes.includes(ResponseBoxType.TEXT)) {
      items.push({
        title: 'Text Input',
        type: ResponseBoxType.TEXT,
        image: 'TextInput.png',
      });
    }
    if (this.responseBoxTypes.includes(ResponseBoxType.DROPDOWN)) {
      items.push({
        title: 'Dropdown list',
        type: ResponseBoxType.DROPDOWN,
        image: 'Dropdown Icon.png',
      });
    }
    if (this.responseBoxTypes.includes(ResponseBoxType.DROP_TARGET)) {
      items.push({
        title: 'Drop Location',
        type: ResponseBoxType.DROP_TARGET,
        image: 'Drag&Drop.png',
      });
    }
    return items;
  }

  showMenu = false;
  slashMenu = false;
  menuX = 0;
  menuY = 0;

  buttonClicked() {
    this.openMenu(false);
  }

  openMenu(slash: boolean, offsetOverride?: { x: number; y: number }) {
    const offset = {
      x: 10,
      y: 20,
      ...offsetOverride,
    };

    const caretLocation = this.myEditor.selection.getBoundingClientRect();

    this.menuX = caretLocation.left + offset.x;
    this.menuY = caretLocation.top + offset.y;

    this.showMenu = true;
    if (slash) {
      this.slashMenu = true;
    }
  }

  insertResponse(e: Event, type: ResponseBoxType) {
    if (!e) {
      return;
    }

    this.showMenu = false;

    if (this.slashMenu) {
      //Remove the slash

      //Where is the cursor
      const rng = this.myEditor.selection.getRng();
      //Grab the text node
      const textNode = rng.commonAncestorContainer;

      if (textNode?.nodeName === '#text') {
        const nodeVal = textNode.nodeValue;

        //Find from the beginning of the node to the cursor.
        // Then find the last slash in that cursor. This should be our slash for this slashMenu
        const subString = nodeVal?.substring(0, rng.endOffset) ?? '';
        const slashLocation = subString.lastIndexOf('/');

        if (typeof slashLocation === 'number' && slashLocation >= 0) {
          //endoffset seems to be different than the index
          // const newRng = document.createRange();
          // newRng.selectNodeContents(textNode);
          const endChange = subString.length - slashLocation;
          rng.setStart(textNode, rng.endOffset - endChange);
          // newRng.setEnd(textNode, rng.endOffset);
          rng.deleteContents();

          this.myEditor.selection.setCursorLocation(textNode, rng.endOffset);
        }
      }
    }

    //Don't let the event propagate. This will prevent tinyMCE from adding a new line if someone hits Enter to add an item from the menu
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    insertResponse(this.myEditor, type);
  }

  get myEditor(): TinyMCEEditor {
    return window.tinyMCE.get(this.editorId);
  }

  setupFunction(editor: TinyMCEEditor) {
    baseConfig.setup(editor);

    this.emitOnBlur(editor);

    if (this.responseBoxTypes.length) {
      this.responseBoxSetup(editor);
    }

    if (this.isAnswerOrSupportsSetup) {
      this.answerSetup(editor);
    }
  }

  emitOnBlur(editor: TinyMCEEditor): void {
    editor.on('blur', () => {
      this.myEditor.plugins.assistcompress.compressAndUpload(
        (
          //TODO: Fix this. We shouldn't need our own "custom" UploadResult.
          // The compressAndUpload command should probably have some way of handling
          // typescript stuff or potentially changed to not be a plugin anymore?
          success: UploadResult[]
        ) => {
          let proceed = true;
          for (let i = 0; i < success.length; i++) {
            const obj = success[i];
            if (obj.status) {
              this.myEditor.$(obj.element).attr('data-assist-backup-src', null);
            } else {
              proceed = false;
              const imgElm = this.myEditor.$(obj.element);
              imgElm.attr('src', imgElm.attr('data-assist-backup-src'));
            }
          }
          this.editorText = this.myEditor.getContent();
          if (proceed) {
            this.editorText = this.myEditor.getContent();
            this.$emit('blur', this.editorText);
          } else {
            this.$notify('Failed to upload image(s).');
          }
        }
      );
    });
  }

  answerSetup(editor: TinyMCEEditor): void {
    editor.on(
      'focusout',
      () => (document.getElementById(this.editorId)!.style.minHeight = '0px')
    );
    editor.on(
      'focus',
      () => (document.getElementById(this.editorId)!.style.minHeight = '150px')
    );
  }

  responseBoxSetup(editor: TinyMCEEditor): void {
    editor.ui.registry.addButton('insertResponse', {
      icon: 'mdiMessageDraw',
      tooltip: 'Insert response box',
      onAction: this.buttonClicked,
    });

    editor.on('keypress', (e) => {
      if (e.key === '/' && e.isTrusted) {
        //start the slash menu
        this.openMenu(true);

        // e.preventDefault();
        // e.stopPropogation();
      }
    });

    editor.on('keydown', (e) => {
      if ((e.key === 'Backspace' || e.key === 'Delete') && e.isTrusted) {
        editor.save();
      }
    });
    editor.on('init', this.initialize);
  }

  initialize(): void {
    this.$emit('computed', getResponseBoxes(this.myEditor));
  }
}
