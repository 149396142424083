import { ActionTree } from 'vuex';
import { AuthState } from './types';
import { RootState } from '../types';
import { UserAttributes } from '@/domain/User';
import { getUserFolder } from '@/api/core/user.api';
import { UserFolderType } from '@/domain/Folder';

export const actions: ActionTree<AuthState, RootState> = {
  updateUserAttributes(context, attributes: Partial<UserAttributes>): void {
    context.commit('updateAttributes', attributes);
  },
  async getUserFolders(context): Promise<void> {
    const promises = [];
    promises.push(
      getUserFolder(UserFolderType.MY_PSES).then((mpsf) => {
        context.commit('folder/setFolder', mpsf, { root: true });
        context.commit('setMyProblemSetsFolder', mpsf.xref);
      })
    );
    promises.push(
      getUserFolder(UserFolderType.MY_PSES_ASSIGNED).then((mpsaf) => {
        context.commit('folder/setFolder', mpsaf, { root: true });
        context.commit('setMyProblemSetsAssignedFolder', mpsaf.xref);
      })
    );
    await Promise.allSettled(promises);
  },
};
