
import { Component, Vue, Watch } from 'vue-property-decorator';
import ScoreChip from '@/components/Report/ScoreChip.vue';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';
import { ActionType } from '@/domain/Action';

@Component({
  components: {
    ScoreChip,
  },
})
export default class ScorkingKeyDialog extends Vue {
  dialogIsOpen = false;
  symbolColumnWidth = 3;
  /**
   * Returns max width for dialog based on current breakpoint
   */
  get maxWidth(): number {
    return this.$vuetify.breakpoint.lgAndUp ? 800 : 600;
  }
  ActionType = ActionType;
  oneWrongAnswer = [this.ActionType.STUDENT_RESPONSE_ACTION];
  oneHint = [this.ActionType.HINT_REQUESTED_ACTION];
  wrongAnswerThenHint = [
    this.ActionType.HINT_REQUESTED_ACTION,
    this.ActionType.STUDENT_RESPONSE_ACTION,
  ];
  wrongAnswerThenHintThenWrongAnswer = [
    this.ActionType.STUDENT_RESPONSE_ACTION,
    this.ActionType.HINT_REQUESTED_ACTION,
    this.ActionType.STUDENT_RESPONSE_ACTION,
  ];
  sawAnswer = [this.ActionType.ANSWER_REQUESTED_ACTION];

  //////////////
  // Mixpanel //
  //////////////

  @Watch('dialogIsOpen')
  trackScoreDialogOpen(newVal: boolean, oldVal: boolean): void {
    if (newVal !== oldVal) {
      trackMixpanel(EventType.assignmentReportScoringDialog, {
        dialogIsOpen: newVal,
      });
    }
  }
}
