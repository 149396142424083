import { AuthState } from '../store/auth/types';
import { TutorState } from './tutor/types';
import { ClassState } from './class/types';
import { CurriculumState } from './curriculum/types';
import { MyPsState } from './myps/types';
import { NotificationState } from './notifications/types';
import { SearchState } from './search/types';
import { SkillState } from './skills/types';
import { URLState } from './urls/types';
import { MetaState } from './meta/types';
import { AttributionState } from './attribution/types';
import { InsightsHubState } from './insightshub/types';
import { LTIState } from './lti/types';
import { ContentState } from './content/types';
import { FolderState } from './folder/types';

export const RESET_STATE_MUTATION = 'resetState';

export interface RootState {
  auth: AuthState;
  tutor: TutorState;
  classList: ClassState;
  curricula: CurriculumState;
  myps: MyPsState;
  notifications: NotificationState;
  search: SearchState;
  skillList: SkillState;
  urls: URLState;
  meta: MetaState;
  attributions: AttributionState;
  lti: LTIState;
  insightsHub: InsightsHubState;
  content: ContentState;
  folder: FolderState;
}
