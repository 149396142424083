
import { ProblemSetDefinition, ProblemSetType } from '@/domain/ProblemSet';
import { getProblemSetTypeDisplayName } from '@/utils/problemSet.util';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ProblemSetTypeView extends Vue {
  @Prop({ required: true }) problemSet: ProblemSetDefinition;

  ProblemSetType = ProblemSetType;

  get problemSetType(): string {
    const name = getProblemSetTypeDisplayName(this.problemSet.problemSetType);
    return name.toLowerCase();
  }
}
