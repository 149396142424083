import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { RootState } from '../types';
import { ContentState } from './types';

export const getDefaultState = () => ({
  problemMap: {},
  problemSetMap: {},
  redoMap: {},
  tutorStrategyMap: {},
  validationErrors: {},
});

export const state: ContentState = getDefaultState();
const namespaced = true;

export const content: Module<ContentState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
