
import { Component, Vue, Prop } from 'vue-property-decorator';
import { PartialAssignmentStudentData } from '@/domain/ReportData/Tutor';
import { SkillDefinition } from '@/domain/Skill';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { DataTableHeader } from 'vuetify';
import { AssignmentDefinition } from '@/domain/Assignment';
import { StudentData, StudentStats } from '@/domain/ReportData/AssignmentData';
import { RETURN_TEXT, RETURN_URL } from '@/domain/PageParams';

dayjs.extend(duration);

interface TutorAssignmentTableRow {
  problemSetCeri: string;
  assignmentXref: string;
  assignmentName: string;
  averageScore: { [xref: string]: string | null };
  assignmentType: string;
  standardsCovered: Array<SkillDefinition>;
  assignmentDate: { [xref: string]: string | null };
  assignmentCompletion: { [xref: string]: boolean };
}

export interface CustomTableLabel {
  label: string;
  value: string;
  backgroundColor: string;
  textColor: string;
}

@Component
export default class AssignmentDataTable extends Vue {
  @Prop() customHeaderLabels!: Array<CustomTableLabel>;
  @Prop() problemSetData!: Map<string, Array<PartialAssignmentStudentData>>;
  @Prop({ default: false }) loading: boolean;
  @Prop({ required: true }) filteredTuteeXrefs: Array<string>;
  @Prop({ required: true }) assignmentDefinitions: AssignmentDefinition[];
  @Prop({ required: true }) assignmentReportData: Map<string, StudentData>;

  get tableData(): Array<TutorAssignmentTableRow> {
    const data: Array<TutorAssignmentTableRow> = []; // clear table rows
    this.assignmentDefinitions.forEach((assignmentDef) => {
      data.push(
        this.transformToTableRow(assignmentDef, this.assignmentReportData)
      );
    });
    return data;
  }

  /**
   * Computed props: skills from store
   */
  get skills(): Array<SkillDefinition> {
    return this.$store.state.skillList.skills;
  }

  customHeaderOptions: Partial<DataTableHeader> = {
    align: 'center',
  };

  sharedHeaders: Array<DataTableHeader> = [
    {
      text: '% Correct',
      value: 'averageScore',
      sort: this.sortByFirstSelectedUser,
    },
    {
      text: 'Date Assigned',
      value: 'assignmentDate',
      sort: this.sortByFirstSelectedUser,
    },
    {
      text: 'Complete',
      value: 'assignmentCompletion',
      sort: this.sortByFirstSelectedUser,
    },
  ];

  appendCustomHeaderOptions(header: DataTableHeader): DataTableHeader {
    return { ...this.customHeaderOptions, ...header };
  }

  get customHeaders(): Array<DataTableHeader> {
    return this.sharedHeaders.map(this.appendCustomHeaderOptions);
  }

  get tableHeaders(): Array<DataTableHeader> {
    return [
      {
        text: 'Assignment Name',
        value: 'assignmentName',
        sortable: true,
      },
      ...this.customHeaders,
      {
        text: 'Assignment Type',
        value: 'assignmentType',
        sortable: true,
      },
    ];
  }

  transformToTableRow(
    data: AssignmentDefinition,
    reportData: Map<string, StudentData>
  ): TutorAssignmentTableRow {
    const name = data.name;
    let assignmentType = '';

    if (name.includes('---')) {
      let extractedAssignmentType = name.split('---')[0];
      assignmentType = extractedAssignmentType.toLowerCase().includes('exit')
        ? 'Exit Ticket'
        : extractedAssignmentType.toLowerCase().includes('cool')
        ? 'Cool Down'
        : extractedAssignmentType;
    } else {
      assignmentType = 'Problem Set';
    }

    const problemSetCeri = data.problemSetCeri;
    const releaseDate = data.releaseDate;
    let standardsCovered: Array<SkillDefinition> = [];

    if (data.skills) {
      standardsCovered = this.skills.filter((skill: SkillDefinition) => {
        return data.skills.includes(skill.xref);
      });
    }

    const averageScore: { [xref: string]: string | null } = {};
    const assignmentDate: { [xref: string]: string | null } = {};
    const assignmentCompletion: { [xref: string]: boolean } = {};

    reportData
      .get(data.xref)
      ?.summaryStatsAll?.studentStats.forEach((studentData: StudentStats) => {
        averageScore[studentData.studentXref] = this.getPercentCorrect(
          studentData.score
        );
        assignmentDate[studentData.studentXref] =
          this.getAssignedDate(releaseDate);

        const hasEndTime = reportData
          .get(data.xref)
          ?.studentLogs.find(
            (log) => log.studentXref == studentData.studentXref
          )?.asEndTime
          ? true
          : false;

        assignmentCompletion[studentData.studentXref] = hasEndTime;
      });

    return {
      problemSetCeri: problemSetCeri,
      assignmentXref: data.xref,
      assignmentName: name,
      assignmentType: this.convertToTitleCase(assignmentType) || '',
      standardsCovered: standardsCovered,
      averageScore: averageScore,
      assignmentDate: assignmentDate,
      assignmentCompletion: assignmentCompletion,
    };
  }

  sortByFirstSelectedUser(
    a: { [xref: string]: string },
    b: { [xref: string]: string }
  ): number {
    const dataToCompareA =
      a[this.filteredTuteeXrefs[0]] != undefined
        ? parseFloat(a[this.filteredTuteeXrefs[0]]) / 100
        : '';
    const dataToCompareB =
      b[this.filteredTuteeXrefs[0]] != undefined
        ? parseFloat(b[this.filteredTuteeXrefs[0]]) / 100
        : '';
    const comparisonResult = dataToCompareA > dataToCompareB ? 1 : -1;
    return comparisonResult;
  }

  convertToTitleCase(text: string | undefined) {
    if (text) {
      let str = text.toLowerCase().split(' ');
      for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
      }
      return str.join(' ');
    }
  }

  getAssignedDate(unixms: number): string | null {
    return unixms != undefined ? dayjs(unixms).format('MM/DD') : null;
  }

  getPercentCorrect(score?: number): string | null {
    const intScore: string | null =
      score != undefined ? Math.round(score * 100) + '%' : null;

    return intScore;
  }

  created(): void {
    // Download skills if not already
    // Will be prevented to download again in store if already
    this.$store.dispatch('skillList/requestSkills');
  }

  goToAssignmentReportView(xref: string): void {
    this.$router.push({
      name: 'ReportLandingPage',
      params: {
        xref,
      },
      query: {
        [RETURN_TEXT]: 'Tutor Report',
        [RETURN_URL]: this.$router.currentRoute.fullPath,
      },
    });
  }
}
