import { MutationTree } from 'vuex';
import { User } from '@/domain/User';
import { TutorState } from './types';
import { TimeFrame } from '@/domain/Time';
import { AssignmentDefinition } from '@/domain/Assignment';
import { SkillDefinition } from '@/domain/Skill';
import { StudentData } from '@/domain/ReportData/AssignmentData';

export const mutations: MutationTree<TutorState> = {
  setHasDownloadedTutees(state: TutorState, flag: boolean) {
    state.hasDownloadedTutees = flag;
  },
  setIsDownloadingTutees(state: TutorState, flag: boolean) {
    state.isDownloadingTutees = flag;
  },
  setIsDownloadingStudentAssignments(state: TutorState, flag: boolean) {
    state.isDownloadingStudentAssignments = flag;
  },
  setHasDownloadedStudentAssignments(state: TutorState, flag: boolean) {
    state.hasDownloadedStudentAssignments = flag;
  },
  setIsDownloadingAssignmentReports(state: TutorState, flag: boolean) {
    state.isDownloadingAssignmentReports = flag;
  },
  setHasDownloadedAssignmentReports(state: TutorState, flag: boolean) {
    state.hasDownloadedAssignmentReports = flag;
  },
  setNoAssignmentDataFound(state: TutorState, flag: boolean) {
    state.noAssignmentDataFound = flag;
  },
  setTutees(state: TutorState, tutees: Array<User>) {
    state.tutees = tutees;
  },
  setSelectedTuteeXrefs(state: TutorState, tuteeXrefs: Array<string>) {
    state.selectedTuteeXrefs = [...tuteeXrefs];
  },
  setSelectedTimeFrame(state: TutorState, timeFrame: TimeFrame | null) {
    state.selectedTimeFrame = timeFrame;
  },
  setSelectedAssignments(
    state: TutorState,
    selections: Array<AssignmentDefinition>
  ) {
    state.selectedAssignments = selections;
  },
  setSelectedStandards(state: TutorState, selections: Array<SkillDefinition>) {
    state.selectedStandards = selections;
  },
  setStudentToAssignmentDefinitionsMap(
    state: TutorState,
    params: { studentXref: string; assignmentList: AssignmentDefinition[] }
  ) {
    state.studentToAssignmentDefinitionsMap.set(
      params.studentXref,
      params.assignmentList
    );
  },
  setAssignmentReportData(
    state: TutorState,
    assignmentReportData: Map<string, StudentData>
  ) {
    state.assignmentReportData = assignmentReportData;
  },
  setAssignmentDefinitions(
    state: TutorState,
    assignmentDefinitions: AssignmentDefinition[]
  ) {
    state.assignmentDefinitions = assignmentDefinitions;
  },
};
