import { Module } from 'vuex';
import { mutations } from './mutations';
import { getters } from './getters';
import { MetaState } from './types';
import { RootState } from '../types';

export const state: MetaState = {
  shouldReloadOnRouteChange: false,
};

const namespaced = true;

export const meta: Module<MetaState, RootState> = {
  namespaced,
  state,
  mutations,
  getters,
};
