
import {
  AnswerPart,
  AnswerSet,
  AnswerType,
  AnswerValue,
} from '@/domain/Problem';
import BuilderEditor from '@/components/Builder/BuilderEditor.vue';
import { cloneDeep } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import draggable from 'vuedraggable';

@Component({
  components: { BuilderEditor, draggable },
})
export default class SortAnswers extends Vue {
  @Prop() answerSet: AnswerSet | null;
  @Prop({ default: false }) disabled: boolean;

  default: AnswerSet = {
    memberType: 'ANSWER_SET',
    members: [
      {
        memberType: 'ANSWER_PART',
        htmlMarker: 1,
        answerType: AnswerType.SORT,
        // What SHOULD be manipulated in this component.
        answerValues: [],
      },
    ],
  };

  localAnswerSet = this.default;

  dragIndex: number | null = null;

  get answerPart(): AnswerPart {
    return (
      (this.localAnswerSet.members?.at(0) as AnswerPart) ??
      (this.default.members?.at(0) as AnswerPart)
    );
  }

  set answerPart(value: AnswerPart) {
    this.localAnswerSet = { ...this.localAnswerSet, members: [value] };
    this.$emit('answersChanged', this.localAnswerSet);
  }

  get answerValues(): AnswerValue[] {
    return this.answerPart.answerValues ?? [];
  }

  set answerValues(values: AnswerValue[]) {
    this.answerPart = {
      ...this.answerPart,
      answerValues: values,
    };
  }

  get stacked(): boolean {
    return this.localAnswerSet.properties?.STACKED ?? true;
  }

  set stacked(value: boolean) {
    const properties = { ...this.localAnswerSet.properties };
    properties.STACKED = value;
    this.localAnswerSet = { ...this.localAnswerSet, properties };
    this.$emit('answersChanged', this.localAnswerSet);
  }

  created(): void {
    // Initialize
    if (this.answerSet) {
      this.localAnswerSet = cloneDeep(this.answerSet);
    } else {
      this.localAnswerSet = this.default;
    }
  }

  addAnswer(): void {
    this.answerValues.push({ isCorrect: true, value: '' });
    this.answerValues = [...this.answerValues];
  }

  removeAnswer(index: number): void {
    this.answerValues.splice(index, 1);
    this.answerValues = [...this.answerValues];
  }

  setDragIndex(moved: { oldIndex: number }): void {
    this.dragIndex = moved.oldIndex;
  }
}
