
import { Component, Vue } from 'vue-property-decorator';
import FindProblemsTopBar from '@/components/FindProblems/FindProblemsPage/FindProblemsTopBar.vue';
import {
  FolderDefinition,
  EXPLORE_CONTENT,
  FolderMemberType,
} from '@/domain/Folder';
import FolderExplorer, { Mode } from '@/components/Builder/FolderExplorer.vue';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';

@Component({
  components: {
    FolderExplorer,
    FindProblemsTopBar,
  },
})
export default class FindProblemsPage extends Vue {
  open = false;
  Mode = Mode;
  FolderMemberType = FolderMemberType;
  //If we can figure out how to get reactive objects that we don't need to mark as Object | null
  // Then we can get away from this "as unknown as Object" garbage.
  // rootFolder: FolderInfo = null as unknown as FolderInfo;
  initialized = false;
  xref = EXPLORE_CONTENT;

  get folderMap(): Record<string, FolderDefinition> {
    return this.$store.state.folder.folderMap;
  }

  get rootFolder(): FolderDefinition {
    return this.folderMap[this.xref];
  }

  mounted(): void {
    const currentUser = this.$store.getters['auth/getCurrentUser'];
    if (currentUser && currentUser.xref) {
      this.trackFindNAssignLoaded();
    }
  }

  trackFindNAssignLoaded(): void {
    trackMixpanel(EventType.navFindNAssignLoaded);
  }

  created(): void {
    const p = this.$route.query.p;
    if (p) {
      const parts = String(p).split(',');
      this.xref = parts[0];
    }
    this.initialized = false;
    this.$store
      .dispatch('folder/getFolders', [this.xref])
      .then(() => {
        this.open = true;
      })
      .finally(() => {
        this.initialized = true;
      });
  }

  childFolderOpened(xref: string): void {
    (this.$refs.searchComp as FindProblemsTopBar).dense = true;
    this.trackFindNAssignFolderLoaded(xref);
  }

  trackFindNAssignFolderLoaded(xref: string): void {
    const eventData = {
      folderXref: xref,
    };
    trackMixpanel(EventType.findNAssignFolderLoaded, eventData);
  }
}
