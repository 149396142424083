import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { RootState } from '../types';
import { FolderState } from './types';

export const getDefaultState = () => ({
  folderMap: {},
  manualNavigation: false,
});

export const state: FolderState = getDefaultState();

const namespaced = true;

export const folder: Module<FolderState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
