<template>
  <div>
    <span class="text-subtitle-1 neutral--text text--darken-3">
      3: Google Admin
    </span>
    <p class="text-body-2 neutral--text text--darken-1">
      Contact your Google Classroom/G-Suite administrator and have them verify
      you. This has the added benefit that your administrator could verify all
      known teachers. They can do that by following
      <a
        class="text-decoration-none"
        href="https://support.google.com/edu/classroom/answer/6071551?hl=en"
        target="_blank"
      >
        these directions from Google support.
      </a>
    </p>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class GoogleClassroomInstructions extends Vue {}
</script>
