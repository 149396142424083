
import {
  FolderOperationParams,
  addMembersToFolder,
  moveFolder,
} from '@/api/core/folders.api';
import { AttributeNames } from '@/domain/Attributes';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import FolderSelector, { Selection } from './FolderSelector.vue';
import { FolderDefinition, FolderMemberType } from '@/domain/Folder';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import { cloneDeep } from 'lodash';
import { getPathParam, PageView } from '@/utils/navigation.util';
import { AclPermissionType } from '@/domain/Acls';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';

@Component({
  components: { FolderSelector },
})
export default class MoveToFolderDialog extends Vue {
  @Prop({ required: true }) path: (FolderDefinition | ProblemSetDefinition)[];

  AclPermissionType = AclPermissionType;

  dialog = false;
  loading = false;

  // FIXME: Figure out if/how we want to handle Insights Hub level.
  // selection: Selection = { wipeAllLevels: false, path: null };
  selection: Selection = { wipeAllLevels: true, path: null };

  // Default to true unless specifically overridden by user.
  inheritPermissions = true;
  PageView = PageView;

  get parent(): FolderDefinition | ProblemSetDefinition {
    return this.path[this.path.length - 2];
  }

  get target(): FolderDefinition | ProblemSetDefinition {
    return this.path[this.path.length - 1];
  }

  get movable(): boolean {
    if (this.parent.memberType == FolderMemberType.FOLDER) {
      return (this.parent as FolderDefinition).permissions.includes(
        AclPermissionType.UPDATE
      );
    } else {
      return true;
    }
  }

  get currentLocation(): string {
    let path = cloneDeep(this.path);
    // Exclude target.
    path.pop();
    const index = path.findIndex(
      (part) =>
        part.memberType == FolderMemberType.PUB_PS ||
        part.memberType == FolderMemberType.WIP_PS
    );
    if (index !== -1) {
      // Exclude content path.
      path = path.slice(0, index);
    }
    // Compute path parts. Delimit path elements by /.
    return path.map((element) => element.name).join(' / ');
  }

  get skipParams(): FolderOperationParams {
    const params: FolderOperationParams = { skip: { attributes: [] } };
    if (this.selection.wipeAllLevels) {
      params.skip?.attributes?.push(AttributeNames.INSIGHTS_HUB_LEVEL);
    }
    return params;
  }

  get destination(): FolderDefinition | null {
    return this.selection.path
      ? this.selection.path[this.selection.path.length - 1]
      : null;
  }

  get parentPage(): PageView {
    return this.$route?.meta?.page;
  }

  // Computed property to get the full destination path
  get destinationPath(): string {
    return this.selection.path
      ? this.selection.path.map((element) => element.name).join(' / ')
      : '';
  }

  save(): void {
    if (this.target && this.selection.path) {
      const path = cloneDeep(this.selection.path);
      const destination = path[path.length - 1];
      this.loading = true;
      let promise: Promise<void> | undefined = undefined;
      if (this.parent.memberType == FolderMemberType.FOLDER) {
        promise = moveFolder(
          this.parent.xref,
          this.target.xref,
          destination.xref,
          this.inheritPermissions,
          this.skipParams
        ).then(() => {
          const children = cloneDeep(this.parent.children);
          const index = this.parent.children.findIndex(
            (xref) => xref == this.target.xref
          );
          children.splice(index, 1);
          this.$store.commit('folder/setFolder', {
            ...this.parent,
            children,
          });
        });
      } else {
        promise = addMembersToFolder(destination.xref, [this.target.xref]);
      }

      promise
        .then(() => {
          if (destination.children.length) {
            this.$store.commit('folder/setFolder', {
              ...destination,
              children: [this.target.xref, ...destination.children],
            });
          }
          // Update the URL.
          this.$router.replace({
            query: {
              ...this.$route.query,
              p: getPathParam([...path, this.target]),
            },
          });
          this.dialog = false;

          // Track the move event with Mixpanel
          trackMixpanel(EventType.trackPSFolderProblemSetMoved, {
            originalFolder: this.currentLocation,
            originalFolderXref: this.parent.xref,
            newFolder: this.destinationPath,
            newFolderXref: destination.xref,
            targetFolderName:
              this.target.memberType === FolderMemberType.FOLDER
                ? this.target.name
                : null,
            targetFolderXref:
              this.target.memberType === FolderMemberType.FOLDER
                ? this.target.xref
                : null,
            targetProblemSetName:
              this.target.memberType !== FolderMemberType.FOLDER
                ? this.target.name
                : null,
            targetProblemSetXref:
              this.target.memberType !== FolderMemberType.FOLDER
                ? this.target.xref
                : null,
          });
        })
        .catch(() => {
          this.$notify(
            `Failed to move ${this.target.name} to ${destination.name}.`
          );
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  initialize(): void {
    // Clear prior selections.
    // FIXME: Figure out if/how we want to handle Insights Hub level.
    // this.selection = { wipeAllLevels: false, path: null };
    this.selection = { wipeAllLevels: true, path: null };
    // Reset this to default.
    this.inheritPermissions = true;
  }

  @Watch('dialog')
  onDialog(): void {
    if (this.dialog) {
      this.initialize();
    }
  }

  @Watch('selection.destination')
  onDestination(): void {
    this.inheritPermissions = true;
  }
}
