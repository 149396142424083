
import { Vue, Component } from 'vue-property-decorator';

export enum ActivityMode {
  ASSIGNED = 'Assigned',
  COMPLETED = 'Completed',
  SCORE = 'Score',
}

@Component
export default class ModeTabs extends Vue {
  modeChoices: ActivityMode[] = [
    ActivityMode.ASSIGNED,
    ActivityMode.COMPLETED,
    ActivityMode.SCORE,
  ];

  get modeTab(): number {
    return (
      this.modeChoices.indexOf(this.$store.state.insightsHub.selectedMode) || 0
    );
  }

  set modeTab(value: number) {
    this.selectedMode = this.modeChoices[value];
  }

  get selectedMode(): ActivityMode {
    const mode = this.$store.state.insightsHub.selectedMode;

    return mode === null ? ActivityMode.ASSIGNED : mode;
  }

  set selectedMode(value: ActivityMode) {
    this.$store.commit('insightsHub/setSelectedMode', value);
  }
}
