
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataOptions, DataTableHeader } from 'vuetify';
import { meanBy, orderBy, shuffle } from 'lodash';
import {
  StudentData,
  StudentLog,
  ProblemLog,
  StudentStats,
  CommonWrongAnswer,
  ProblemLogAndActions,
  ProblemStats,
} from '@/domain/ReportData/AssignmentData';
import { User } from '@/domain/User';
import { ProblemTypeSDK3 } from '@/domain/Problem';
import { appendGlobalHeaderOptions } from '@/utils/dataTables.utils';
import DeleteStudentProgressDialog from './DeleteStudentProgressDialog.vue';
import ScoringKeyDialog from './ScoringKeyDialog.vue';
import StudentActionsMenu from './StudentActionsMenu.vue';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { LmsProviderType } from '@/domain/LmsProviderType';
import sortBySortableName from '@/utils/sortBySortableName.util';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';
import { calculatePausedTime } from '@/utils/calculatePausedTime.util';
import { sortPercentage, sortScore } from '@/utils/table.utils';
import {
  ProblemForReport,
  ProblemStatsForReport,
  formatPercentage,
  getProblemAnswer,
  isInTestMode,
} from '@/utils/report.util';
import { AssignmentDefinition, AssignmentProperty } from '@/domain/Assignment';
import { getContentType } from '@/utils/builder.util';
import { ContentType } from '@/domain/Content';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import { ActionType, TimerFinishedAction } from '@/domain/Action';
import { CWAF } from '@/views/MyAssignments/ReportLandingPage.vue';
import { InstructionalRecommendation } from '@/domain/Tutoring';
import { uploadAssignmentScores } from '@/api/core/assignment.api';
import { downloadCsvData } from '@/utils/csv.util';
import AnswersViewSDK3 from '../Builder/ContentView/AnswersViewSDK3.vue';

dayjs.extend(duration);

enum StaticHeaders {
  ASSIGNEE = 'ASSIGNEE',
  COMPLETE = 'COMPLETE',
  REDO_PROBLEMS = 'REDO_PROBLEMS',
  AVERAGE_SCORE = 'AVERAGE_SCORE',
  TOTAL_HINTS = 'TOTAL_HINTS',
  TIME_PAUSED = 'TIME_PAUSED',
  TIME_SPENT = 'TIME_SPENT',
}

type TableRow = Record<
  string,
  | User
  | ProblemLogAndActions
  | CommonWrongAnswer[]
  | string
  | number
  | undefined
>;

interface ProblemHeaderForReport extends DataTableHeader {
  problem: ProblemForReport;
  click?: (header: ProblemHeaderForReport) => void;
  group?: string;
}

@Component({
  components: {
    AnswersViewSDK3,
    ScoringKeyDialog,
    StudentActionsMenu,
    DeleteStudentProgressDialog,
  },
})
export default class AssignmentReportTable extends Vue {
  @Prop({ required: true }) assignment: AssignmentDefinition;
  @Prop({ default: null }) reportData: StudentData | null;
  @Prop({ default: () => [] }) assignees: User[];
  @Prop({ default: () => [] }) problems: ProblemForReport[];
  @Prop({ default: () => ({}) }) problemStatsMap: Record<
    string,
    ProblemStatsForReport
  >;
  @Prop({ default: () => ({}) }) IRsMap: Record<
    string,
    InstructionalRecommendation[]
  >;
  @Prop({ default: () => ({}) }) CWAFsMap: Record<string, CWAF[]>;

  uploadDialog = false;
  uploadingScores = false;

  currentHoverRowIdentifier: string | null = null;

  hoverTimer = null;
  hoverDelay = 500; // delay in milliseconds

  hoverRowTimer = null;
  hoverRowDelay = 2000; // delay in milliseconds

  // Allows us access to the enum in the template.
  ProblemTypeSDK3 = ProblemTypeSDK3;
  StaticHeaders = StaticHeaders;
  formatPercentage = formatPercentage;

  deleteProgressDialog = false;
  selectedAssignee: User | null = null;

  ////////////////////
  // Hiding Columns //
  ////////////////////
  hideNames = false;
  hideScores = false;
  hideCompletes = false;
  hideRedoProblems = false;
  hideTimes = false;
  hideHintCounts = false;
  hideProblemAverage = false;

  /////////////////
  // Sticky Rows //
  /////////////////
  problemAvgAlwaysVisible = true;
  CWAAlwaysVisible = true;

  groups: string[] = [];

  get problemMap(): Record<string, ProblemForReport> {
    const xrefToProblemMap: Record<string, ProblemForReport> = {};
    for (const problem of this.problems) {
      xrefToProblemMap[problem.xref] = problem;
    }
    return xrefToProblemMap;
  }

  get lmsProviderType(): LmsProviderType | undefined {
    return this.assignment.lmsProviderType;
  }

  get isInTestMode(): boolean {
    return isInTestMode(this.assignment);
  }

  get hasRedo(): boolean {
    const props = this.assignment?.properties;
    if (props) {
      const useRedo = props.find((p) => p.propertyKey === 'useRedo');
      return useRedo?.propertyValue === 'true';
    }
    return false;
  }

  get timeLimit(): AssignmentProperty | undefined {
    return this.assignment?.properties?.find(
      (p) => p.propertyKey == 'timeLimit'
    );
  }

  get timeLimitInMinutes(): number | undefined {
    return this.timeLimit
      ? (this.timeLimit?.propertyValue as unknown as number) / 60
      : undefined;
  }

  get openResponseScoringBaseUrl(): string {
    return `${process.env.VUE_APP_TNG_URL}/openResponseGrade/${this.assignment.xref}`;
  }

  get problemSetMap(): Record<string, ProblemSetDefinition> {
    return this.$store.state.content.problemSetMap;
  }

  ///////////////////
  // Table Headers //
  ///////////////////

  get prependStaticHeaders(): DataTableHeader[] {
    const defaults: Partial<DataTableHeader> = {
      align: 'center',
      class: ['text-no-wrap', 'sticky-row', 'sticky-row-1', 'text-subtitle-2'],
      sortable: true,
    };
    const headers: DataTableHeader[] = [
      {
        ...defaults,
        text: 'Student Name/Problem',
        value: StaticHeaders.ASSIGNEE,
        align: 'start',
        cellClass: ['text-no-wrap'],
        sortable: !this.hideNames,
        sort: sortBySortableName,
      },
    ];
    if (!this.hideCompletes) {
      headers.push({
        ...defaults,
        text: '% Complete',
        value: StaticHeaders.COMPLETE,
      });
    }
    if (this.hasRedo && !this.hideRedoProblems) {
      headers.push({
        ...defaults,
        text: 'Redo Problems',
        value: StaticHeaders.REDO_PROBLEMS,
      });
    }
    if (!this.hideScores) {
      headers.push({
        ...defaults,
        text: 'Average Score',
        value: StaticHeaders.AVERAGE_SCORE,
        sort: sortPercentage,
      });
    }
    return headers;
  }

  get appendStaticHeaders(): DataTableHeader[] {
    const defaults: Partial<DataTableHeader> = {
      class: ['text-no-wrap', 'text-subtitle-2'],
      sortable: true,
    };
    const headers = [];
    if (!this.hideHintCounts) {
      headers.push({
        ...defaults,
        text: 'Total Hints',
        value: StaticHeaders.TOTAL_HINTS,
      });
    }
    if (!this.hideTimes && this.timeLimitInMinutes) {
      headers.push({
        ...defaults,
        text: 'Time Paused',
        value: StaticHeaders.TIME_PAUSED,
      });
    }
    if (!this.hideTimes) {
      headers.push({
        ...defaults,
        text: 'Time Spent',
        value: StaticHeaders.TIME_SPENT,
      });
    }
    return headers;
  }

  get problemHeaders(): ProblemHeaderForReport[] {
    const defaults: Partial<ProblemHeaderForReport> = {
      class: ['text-no-wrap'],
      cellClass: ['text-no-wrap'],
      sortable: true,
      sort: sortScore,
      click: (header) => this.openProblem(header.problem),
    };
    const headers = [];
    // Based on given order in list of problems (NOT problem logs).
    for (const problem of this.problems) {
      const hasRedo = problem.redoProblems?.length;
      const isRedo = problem.redoParent;
      let problemText = '';
      if (isRedo) {
        problemText = 'Redo';
      } else {
        problemText = 'P' + problem.position;
      }
      if (problem.partLetter) {
        problemText += ': ' + problem.partLetter;
      }
      if (hasRedo) {
        let group = undefined;
        if (this.hideRedoProblems) {
          group = undefined;
        } else if (problem.partLetter) {
          group = problem.parent;
        } else {
          group = problem.xref;
        }
        // Turns into three columns:
        // Final score
        headers.push({
          ...defaults,
          text: problemText,
          value: `${problem.xref}_final`,
          group,
          problem,
        });
        if (group && this.groups.includes(group)) {
          // Original
          headers.push({
            ...defaults,
            text: 'Original',
            value: problem.xref,
            class: ['text-no-wrap', 'redo-cell'],
            cellClass: ['redo-cell'],
            click: undefined,
            problem,
          });
          // Redo (AVG)
          if (hasRedo > 1) {
            headers.push({
              ...defaults,
              text: 'Redo (AVG)',
              value: `${problem.xref}_redo`,
              class: ['text-no-wrap', 'redo-cell'],
              cellClass: ['redo-cell'],
              click: undefined,
            });
          }
        }
      } else if (isRedo) {
        if (!this.hideRedoProblems && this.groups.includes(isRedo)) {
          // Redo column
          headers.push({
            ...defaults,
            text: problemText,
            value: problem.xref,
            class: ['text-no-wrap', 'redo-cell'],
            cellClass: ['redo-cell'],
            problem,
          });
        }
      } else {
        // Problem without any redos.
        headers.push({
          ...defaults,
          text: problemText,
          value: problem.xref,
          problem,
        });
      }
    }
    return headers as ProblemHeaderForReport[];
  }

  get headers(): DataTableHeader[] {
    return [
      ...this.prependStaticHeaders,
      ...this.problemHeaders,
      ...this.appendStaticHeaders,
    ].map(appendGlobalHeaderOptions);
  }

  ////////////////
  // Table Rows //
  ////////////////

  get assigneeStatsMap(): Record<string, StudentStats> {
    const statsMap: Record<string, StudentStats> = {};
    const studentStats = this.reportData?.summaryStatsAll?.studentStats ?? [];
    for (const stats of studentStats) {
      statsMap[stats.studentXref] = stats;
    }
    return statsMap;
  }

  // CANNOT be based on headers. We do NOT want this to change on the opening and closing of table headers.
  get redoAverage(): number | undefined {
    const averages = [];
    for (const problem of this.problems) {
      const isRedo = problem.redoParent;
      if (isRedo) {
        const problemStats = this.problemStatsMap[problem.xref];
        if (problemStats) {
          const avgScore = problemStats.avgScore;
          if (typeof avgScore === 'number') {
            averages.push(avgScore);
          }
        }
      }
    }
    if (averages.length) {
      return meanBy(averages);
    }
    return undefined;
  }

  get averageRow(): TableRow {
    const row: TableRow = {
      [StaticHeaders.ASSIGNEE]: 'Problem Average',
      // Not applicable. Empty cell.
      [StaticHeaders.COMPLETE]: '',
      [StaticHeaders.TOTAL_HINTS]: '',
      [StaticHeaders.TIME_PAUSED]: '',
      [StaticHeaders.TIME_SPENT]: '',
    };
    const classAverage = this.reportData?.summaryStatsAll?.avgScore;
    if (typeof classAverage === 'number') {
      row[StaticHeaders.AVERAGE_SCORE] = formatPercentage(classAverage);
    }
    for (const problemHeader of this.problemHeaders) {
      const xref = problemHeader.value;
      const problemStats = this.problemStatsMap[xref];
      let columnAverage = undefined;
      if (problemStats) {
        const problemAverage = problemStats.avgScore;
        if (typeof problemAverage === 'number') {
          columnAverage = problemAverage;
          row[xref] = formatPercentage(columnAverage);
        }
        // Custom column.
      } else {
        // Calculate the percentage.
        const columnScores = [];
        for (const itemRow of this.itemRows) {
          const logData = itemRow[xref] as ProblemLogAndActions;
          if (logData) {
            const prLog = logData.prLog;
            let score = undefined;
            if (typeof prLog.redoAverageScore === 'number') {
              score = Math.max(prLog.redoAverageScore, prLog.continuousScore);
            } else {
              score = prLog.continuousScore;
            }
            if (typeof score === 'number') {
              columnScores.push(score);
            }
          }
        }
        if (columnScores.length) {
          columnAverage = meanBy(columnScores);
          row[xref] = formatPercentage(columnAverage);
        }
      }
    }
    if (typeof this.redoAverage === 'number') {
      row[StaticHeaders.REDO_PROBLEMS] = formatPercentage(this.redoAverage);
    }
    return row;
  }

  get cwasRow(): TableRow {
    const row: TableRow = {
      [StaticHeaders.ASSIGNEE]: 'Common Wrong Answers',
    };
    for (const problemHeader of this.problemHeaders) {
      const xref = problemHeader.value;
      const problemStats = this.problemStatsMap[xref];
      if (problemStats && problemStats.cwas?.length) {
        row[xref] = problemStats.cwas;
      }
    }
    return row;
  }

  get itemRows(): TableRow[] {
    const rowsMap: Record<string, TableRow> = {};
    // Generate empty rows for an empty report.
    for (const assignee of this.assignees) {
      rowsMap[assignee.xref] = {
        [StaticHeaders.ASSIGNEE]: assignee,
      };
    }
    // Populate rows with data if any.
    if (this.reportData) {
      const sLogs = this.reportData.studentLogs ?? [];
      for (const sLog of sLogs) {
        const row = rowsMap[sLog.studentXref];
        if (row) {
          const redoLogsMap: Record<string, ProblemLog[]> = {};
          row[StaticHeaders.COMPLETE] = this.calculateCompletion(sLog);
          const stats = this.assigneeStatsMap[sLog.studentXref];
          if (stats) {
            const score = stats.score;
            if (typeof score === 'number') {
              row[StaticHeaders.AVERAGE_SCORE] = formatPercentage(score);
            }
            // Time spent.
            row[StaticHeaders.TIME_SPENT] = this.getTimeSpent(stats.timeSpent);
          }
          // Time paused.
          const timePaused = calculatePausedTime(sLog);
          row[StaticHeaders.TIME_PAUSED] = this.getTimeSpent(timePaused);
          const logData = sLog.problemLogAndActions ?? [];
          let totalHintCount = 0;
          let totalRedoCount = 0;
          for (const logs of logData) {
            const prLog = logs.prLog;
            row[prLog.prCeri] = logs;
            // Increment hint count.
            totalHintCount += prLog.hintCount;
            const pr = this.problemMap[prLog.prCeri];
            const hasRedo = pr?.redoProblems;
            if (hasRedo) {
              // Placeholder until overwritten.
              row[`${prLog.prCeri}_final`] = { ...logs };
            }
            const isRedo = prLog.redoParentXref;
            if (isRedo) {
              const redoLogs = redoLogsMap[isRedo] ?? [];
              redoLogs.push(prLog);
              redoLogsMap[isRedo] = redoLogs;
              // Increment redo count.
              totalRedoCount++;
            }
          }
          for (const parent in redoLogsMap) {
            const redoLogs = redoLogsMap[parent];
            let redoAvgLog;
            if (redoLogs.length > 1) {
              redoAvgLog = redoLogs.reduce(
                (acc, log) => {
                  const score = log.continuousScore ?? 0;
                  acc.continuousScore += score / redoLogs.length;
                  acc.sawAnswer = acc.sawAnswer || log.sawAnswer;
                  return acc;
                },
                { continuousScore: 0 } as ProblemLog
              );
            } else {
              redoAvgLog = redoLogs[0];
            }
            const type = getContentType(parent);
            let parentLogs = undefined;
            if (type == ContentType.PROBLEM) {
              parentLogs = row[parent] as ProblemLogAndActions;
            } else {
              const parentPs = this.problemSetMap[parent];
              // Multi-part Problems MUST ONLY contain Problems.
              const partLogs: ProblemLogAndActions[] = parentPs.children.map(
                (child) => row[child]
              ) as ProblemLogAndActions[];
              // Find FIRST Part that activated redo. That will be the ONLY average
              // to overwrite. Varies among Students...
              partLogs.sort((a, b) => {
                return a.prLog.startTime - b.prLog.startTime;
              });
              for (const partLog of partLogs) {
                const parentLog = partLog.prLog;
                if (redoAvgLog.continuousScore > parentLog.continuousScore) {
                  parentLogs = partLog;
                  break;
                }
              }
            }
            if (parentLogs) {
              const parentLog = parentLogs.prLog;
              // Record redo average that MAY possibly overwrite original score.
              row[`${parentLog.prCeri}_final`] = {
                prLog: {
                  ...parentLog,
                  redoAverageScore: redoAvgLog.continuousScore,
                  sawAnswer: parentLog.sawAnswer && redoAvgLog.sawAnswer,
                },
                actions: parentLogs.actions,
              };
              if (redoLogs.length > 1) {
                // Record redo average.
                row[`${parentLog.prCeri}_redo`] = { prLog: redoAvgLog };
              }
            }
          }
          // Total redo Problems.
          row[StaticHeaders.REDO_PROBLEMS] = totalRedoCount;
          // Total hints used.
          row[StaticHeaders.TOTAL_HINTS] = totalHintCount;
          // Calculate overdue.
          if (sLog.asEndTime && this.assignment?.dueDate) {
            const endTime = dayjs(sLog.asEndTime);
            const dueDate = dayjs(this.assignment.dueDate);
            if (endTime.isAfter(dueDate)) {
              row['overdue'] = dueDate.from(endTime, true);
            }
          }
        }
      }
    }
    return Object.values(rowsMap);
  }

  get anonymizedRows(): TableRow[] {
    if (this.hideNames) {
      // Shuffle rows.
      return shuffle(this.itemRows);
    } else {
      // Otherwise sort alphabetically by last, first.
      return orderBy(
        this.itemRows,
        [
          (row) => {
            const assignee = row[StaticHeaders.ASSIGNEE] as User;
            return assignee.lastName;
          },
          (row) => {
            const assignee = row[StaticHeaders.ASSIGNEE] as User;
            return assignee.firstName;
          },
        ],
        ['asc', 'asc']
      );
    }
  }

  isValidOpenResponseScore(logData: ProblemLogAndActions): boolean {
    const score = logData.prLog.continuousScore;
    // Score is not outside of range - e.g. 5
    // Between 0-4
    const withinRange: boolean = score >= 0 && score <= 1;
    // Score is a whole number - e.g. 1.25, 1.5
    // [0, 1, 2, 3, 4] contains score
    const isWholeNumber: boolean = (score * 4) % 1 == 0;
    return withinRange && isWholeNumber;
  }

  navigateToEssayScoringPage(logData: ProblemLogAndActions): void {
    const prLog = logData.prLog;
    this.$router
      .push({
        name: 'essayScoringPage',
        params: {
          problemXref: `${prLog.prCeri}`,
        },
        query: this.$route.query,
      })
      .then(() => {
        this.essayScoringClicked(prLog);
      });
  }

  /////////////
  // Methods //
  /////////////

  openProblem(problem: ProblemForReport, openIRs = false): void {
    this.$emit('clickedProblem', problem.xref, openIRs);
  }

  getTimeSpent(ms?: number): string {
    // Format 00:00:00:00
    return ms ? dayjs.duration(ms).format('DD:HH:mm:ss') : '-';
  }

  openDeleteProgressDialog(assignee: User): void {
    this.selectedAssignee = assignee;
    this.deleteProgressDialog = true;
  }

  deleteStudentProgress(): void {
    if (this.selectedAssignee) {
      this.$emit('deleteStudentProgress', this.selectedAssignee.xref);
    }
    // Close dialog.
    this.deleteProgressDialog = false;
  }

  navigateToStudentDetailsReport(assignee: User): void {
    this.$router.push({
      name: 'studentDetailsPage',
      params: {
        studentXref: assignee.xref,
      },
      query: this.$route.query,
    });
  }

  calculateCompletion(studentLog: StudentLog): number {
    // If timer was exhausted show work as incomplete.
    const action = studentLog.psActions?.find(
      (action) => action.actionType == ActionType.TIMER_FINISHED_ACTION
    ) as TimerFinishedAction;
    const timerExhausted = action?.timeLeft === 0;
    if (
      !studentLog.asStartTime ||
      !studentLog.problemLogAndActions ||
      !studentLog.problemLogAndActions.length
    ) {
      return 0;
    } else if (studentLog.asEndTime && !timerExhausted) {
      return 100;
    } else {
      let totalCompleteCount = 0;
      for (const problemLogAndAction of studentLog.problemLogAndActions) {
        //  FIXME: Check if the Problem is a valid member of the Problem Set assigned?
        if (problemLogAndAction.prLog.endTime) {
          totalCompleteCount++;
        }
      }
      return Math.round((100 * totalCompleteCount) / this.problems.length);
    }
  }

  toggleGroup(group: string, problemHeader: ProblemHeaderForReport): void {
    const found = this.groups.findIndex((xref) => xref == group);
    const isToggledOn = found === -1;
    const problemCode = problemHeader.value;
    // Track the toggle event
    const trackingData = {
      assignmentXref: this.assignment?.xref,
      problemCode: problemCode,
      group: group,
      redoToggle: isToggledOn ? 'true' : 'false',
    };
    trackMixpanel(EventType.assignmentReportToggleRedo, trackingData);

    if (!isToggledOn) {
      this.groups.splice(found, 1);
    } else {
      this.groups.push(group);
    }
  }

  uploadScoresToLms(): void {
    if (this.lmsProviderType) {
      this.uploadingScores = true;
      uploadAssignmentScores(this.assignment.xref, this.lmsProviderType)
        .then(() => {
          this.uploadDialog = false;
          this.trackReportUpload();
          this.$notify(
            `Uploaded scores to ${this.lmsProviderType?.displayName} successfully.`
          );
        })
        .catch((error) => {
          if (error.statusCode === 417) {
            // Needs login
            error.handleGlobally &&
              error
                .handleGlobally()
                .then(() => {
                  this.$notify('Authorization updated. Please try again.');
                })
                .catch(() => {
                  // Something wrong going to the LP
                  this.$notify(
                    'Failed authentication and authorization in login portal.'
                  );
                });
          } else {
            // Cannot be handled by authentication
            this.$notify(
              `Failed to upload scores to ${this.lmsProviderType?.displayName}.` +
                ' This will occur if no students have completed the assignment.' +
                ' Please make sure at least one student has scores to upload and try again.'
            );
          }
        })
        .finally(() => {
          this.uploadingScores = false;
        });
    }
  }

  generateCsvData(): Array<string[]> {
    const rows = [];

    const headerRow = ['', 'Average Score'];
    const correctAnswerRow = ['Correct Answers', ''];
    const classAverageRow = [
      'Class Average',
      this.reportData?.summaryStatsAll?.avgScore
        ? `${Math.round(this.reportData?.summaryStatsAll?.avgScore * 100)}%`
        : '',
    ];

    // Collect stats by ceri for easier lookups
    const prStatsByCeri: { [key: string]: ProblemStats } = {};
    this.reportData?.prAllStats?.forEach((stat) => {
      prStatsByCeri[stat.prCeri] = stat;
    });

    const problemOrder: string[] = [];

    // Add headers and average
    this.problemHeaders.forEach((problemHeader) => {
      const prCeri = problemHeader.problem.xref;
      const psCeri = problemHeader.problem.parent;
      const partLetter = problemHeader.problem.partLetter || '';

      problemOrder.push(prCeri.toUpperCase());

      const header = `${partLetter ? psCeri : prCeri} ${partLetter}`;

      headerRow.push(header);

      const prAverageSore = prStatsByCeri[prCeri].avgScore;

      classAverageRow.push(
        prAverageSore ? `${Math.round(prAverageSore * 100)}%` : ''
      );

      const correctAnswers: string[] = [];

      problemHeader.problem.answersSDK3?.members?.forEach((member) => {
        member.answerValues?.forEach((answer) => {
          if (answer.isCorrect) {
            correctAnswers.push(getProblemAnswer(answer.value));
          }
        });
      });

      correctAnswerRow.push(correctAnswers?.join(','));
    });

    // Start building csv, add the top rows
    rows.push([this.assignment.name]);
    rows.push(headerRow);
    rows.push(classAverageRow);
    rows.push(correctAnswerRow);
    rows.push(['Student Name']);

    // Create the student rows
    let studentRows: Array<string[]> = [];
    this.itemRows.forEach((row) => {
      const newRow: Array<string> = [];
      const assignee = row.ASSIGNEE as User;

      newRow.push(assignee.displayName);
      newRow.push(row.AVERAGE_SCORE ? row.AVERAGE_SCORE.toString() : '');

      problemOrder.forEach((xref) => {
        if (row[xref]) {
          const data = row[xref] as ProblemLogAndActions;

          newRow.push(
            data.prLog.continuousScore
              ? `${Math.round(data.prLog.continuousScore * 100)}%`
              : ''
          );
        } else {
          newRow.push('');
        }
      });

      studentRows.push(newRow);
    });

    // Sort the student rows by student name
    studentRows.sort((a, b) => {
      const nameA = (a[0].split(' ')[0] || '').toLowerCase();
      const nameB = (b[0].split(' ')[0] || '').toLowerCase();
      return nameA.localeCompare(nameB);
    });

    return rows.concat(studentRows);
  }

  downloadCsv(): void {
    const csvData = this.generateCsvData();

    this.trackCSVDownload();

    downloadCsvData(csvData, this.assignment.xref);
  }

  created(): void {
    // Default to setting.
    this.hideNames =
      this.$store.state.auth.user.settings.anonymizeReportsByDefault ?? false;
  }

  //////////////
  // Mixpanel //
  //////////////

  trackHideElements(elementHidden: string): void {
    trackMixpanel(EventType.hideInformationAssignmentReport, {
      assignmentXref: this.reportData?.contentInfo.xref,
      elementSelected: elementHidden,
      hideNames: this.hideNames,
      hideScores: this.hideScores,
      hideRedoProblems: this.hideRedoProblems,
      hideCompletes: this.hideCompletes,
      hideTimes: this.hideTimes,
      hideHintCounts: this.hideHintCounts,
      hideProblemAverage: this.hideProblemAverage,
    });
  }

  trackCSVDownload(): void {
    trackMixpanel(EventType.assignmentReportCSVDownload, {
      assignmentXref: this.reportData?.contentInfo.xref,
    });
  }

  trackReportUpload(): void {
    trackMixpanel(EventType.assignmentReportUpload, {
      assignmentXref: this.reportData?.contentInfo.xref,
    });
  }

  trackSortChange(options: DataOptions): void {
    const sortByCol = options.sortBy[0]; // assuming single-column sort
    const sortByDesc = options.sortDesc[0]; // true for descending, false for ascending

    // Condition a: if sortByCol is NOT undefined, return existing values
    if (sortByCol !== undefined) {
      trackMixpanel(EventType.assignmentReportSortOrder, {
        assignmentXref: this.reportData?.contentInfo.xref,
        sortedByCol: sortByCol,
        sortedByColOrder: sortByDesc ? 'descending' : 'ascending',
      });
    }
    // Condition b: if sortByCol is undefined and sortByDesc is undefined,
    // return sortByCol is default and sortByCol is reset
    else if (sortByCol === undefined && sortByDesc === undefined) {
      trackMixpanel(EventType.assignmentReportSortOrder, {
        assignmentXref: this.reportData?.contentInfo.xref,
        sortedByCol: 'default',
        sortedByColOrder: 'reset',
      });
    }
    // Condition c: if sortByCol is undefined and sortByDesc is NOT undefined,
    // do not trigger mixpanel event
    // This condition is handled implicitly by not having an else/if statement for this case
  }

  essayScoringClicked(pLog): void {
    trackMixpanel(EventType.essayScoringClicked, {
      assignmentXref: this.reportData?.contentInfo.xref,
      problemCode: `${pLog.prCeri}`,
    });
  }

  trackCWAHoverProblemEnter(cwa, problemCode) {
    if (this.hoverTimer) {
      clearTimeout(this.hoverTimer);
    }
    this.hoverTimer = setTimeout(() => {
      this.trackCWAProblemHover(cwa, problemCode);
    }, this.hoverDelay);
  }

  trackCWAHoverProblemLeave() {
    if (this.hoverTimer) {
      clearTimeout(this.hoverTimer);
    }
  }

  trackCWAProblemHover(cwa, problemCode) {
    const trackingData = {
      assignmentXref: this.reportData?.contentInfo.xref,
      problemCode: problemCode,
      commonWrongAnswer: cwa.answer,
    };
    trackMixpanel(EventType.assignmentReportCWAProblemHover, trackingData);
  }

  handleMouseOver(rowIdentifier) {
    if (this.currentHoverRowIdentifier !== rowIdentifier) {
      this.currentHoverRowIdentifier = rowIdentifier;
      if (this.hoverRowTimer) {
        clearTimeout(this.hoverRowTimer);
      }
      this.hoverRowTimer = setTimeout(() => {
        this.trackRowHover(rowIdentifier);
      }, this.hoverRowDelay);
    }
  }

  handleMouseLeave(rowIdentifier) {
    if (this.currentHoverRowIdentifier === rowIdentifier) {
      this.currentHoverRowIdentifier = null;
      if (this.hoverRowTimer) {
        clearTimeout(this.hoverRowTimer);
      }
    }
  }

  trackRowHover(rowIdentifier: string) {
    // Determine if the identifier is a studentXref or a static identifier
    const isStudentXref =
      rowIdentifier !== 'common-wrong-answers' &&
      rowIdentifier !== 'problem-average';
    const trackingData: Record<string, any> = {
      assignmentXref: this.reportData?.contentInfo.xref,
    };

    if (isStudentXref) {
      // For student rows
      trackingData.studentXref = rowIdentifier;
      trackMixpanel(EventType.assignmentReportStudentRowHover, trackingData);
    } else {
      // For static identifiers like 'common-wrong-answers'
      trackingData.rowIdentifier = rowIdentifier;
      trackMixpanel(EventType.assignmentReportStaticRowHover, trackingData);
    }
  }

  trackScoreChipOpened(
    newVal: boolean,
    oldVal: boolean,
    studentXref: string,
    score: number,
    attemptCount: number,
    problemCode: string,
    isRedo: boolean
  ) {
    if (newVal === true) {
      // Mixpanel track event for opening the ScoreChip
      trackMixpanel(EventType.assignmentReportScoreChipOpen, {
        assignmentXref: this.reportData?.contentInfo.xref,
        studentXref: studentXref,
        problemCode: problemCode,
        score: score,
        redoStatus: isRedo,
        attemptCount: attemptCount,
        scoreChipOpened: true,
      });
    } else if (newVal === false && oldVal === true) {
      // Mixpanel track event for closing the ScoreChip
      trackMixpanel(EventType.assignmentReportScoreChipClose, {
        assignmentXref: this.reportData?.contentInfo.xref,
        studentXref: studentXref,
        problemCode: problemCode,
        score: score,
        redoStatus: isRedo,
        attemptCount: attemptCount,
        scoreChipOpened: false,
      });
    }
  }
}
