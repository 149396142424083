
import { Component, Vue } from 'vue-property-decorator';
import { getUserSettings } from '@/api/core/user.api';
import SettingsTopBar from '@/components/Settings/SettingsTopBar.vue';
import SettingsSideBar from '@/components/Settings/SettingsSideBar.vue';

@Component({
  components: {
    SettingsTopBar,
    SettingsSideBar,
  },
})
export default class SettingsPage extends Vue {
  hasDownloadedSettings = false;

  // We have router guards on these pages, so the User must be authenticated
  // if they end up here.
  created(): void {
    const { settings } = this.$store.getters['auth/getCurrentUser'];

    if (!settings) {
      getUserSettings().then((userSettings) => {
        this.$store.commit('auth/setSettings', userSettings);
        this.hasDownloadedSettings = true;
      });
    } else {
      this.hasDownloadedSettings = true;
    }
  }
}
