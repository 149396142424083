
import { Component, Vue, Watch } from 'vue-property-decorator';
import { DataOptions, DataTableHeader } from 'vuetify';

// Components
import HeatMap from '@/components/InsightsHub/ActivityByCurriculumPage/HeatMap.vue';
import ModeTabs from '@/components/InsightsHub/base/ModeTabs.vue';
import StrandSelector from '@/components/InsightsHub/base/StrandSelector.vue';
import OverTimePopOut from '@/components/InsightsHub/base/OverTimePopOut.vue';

// Data Types
import { ActivityMode } from '@/components/InsightsHub/base/ModeTabs.vue';
import {
  CellType,
  Fraction,
  GradientHeaders,
  GradientRows,
  Row,
  RowType,
  StaticHeader,
} from '@/components/InsightsHub/base/TreeGradientTable.vue';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';
import { TimeSelector, TimeSelectorType } from '@/domain/Time';
import {
  StandardHierarchyNodeCellStats,
  TotalStandardStats,
} from '@/domain/ReportData/InsightsHub';
import { SkillDefinition, SkillStrand } from '@/domain/Skill';
import { User } from '@/domain/User';
import { CourseDefinition } from '@/domain/Class';
import { School } from '@/domain/School';
import { StandardId } from '@/domain/ReportData/InsightsHub';

// Functions
import {
  getStandardCurrentTime,
  getStandardCoherencyData,
} from '@/api/core/sdata.api';
import { orderBy, uniqBy, uniq } from 'lodash';
import { getCourseRoster } from '@/api/core/course.api';
import { downloadCsvData } from '@/utils/csv.util';

// Modules
import dayjs from 'dayjs';
import axios, { CancelTokenSource } from 'axios';
import HeatmapSkeletonLoader from '@/components/InsightsHub/base/HeatmapSkeletonLoader.vue';

enum HeatMapDisplayType {
  CURRENT_TIME = 'Current Time',
  COHERENCY = 'Coherency',
}

interface StandardsDropdownOption {
  text: string;
  value: SkillDefinition;
}

@Component({
  components: {
    HeatMap,
    ModeTabs,
    StrandSelector,
    HeatmapSkeletonLoader,
    OverTimePopOut,
  },
})
export default class ActivityByStandardPage extends Vue {
  csvData: Row[] = [];
  csvTableHeaders: DataTableHeader[] = [];
  gradeToStrand: Map<string, string> = new Map();
  isDownloadingData = false;
  heatMapType = HeatMapDisplayType.CURRENT_TIME;
  heatMapTypes = Object.values(HeatMapDisplayType);
  HeatMapDisplayType = HeatMapDisplayType;
  coherenceStandard: SkillDefinition | null = null;
  source: CancelTokenSource | null = null;
  columnItem: Row;
  columnItemHeader: Row;
  columnHeader: string;
  menu = false;
  positionX: number;
  positionY: number;
  previousSelectedElement = null;

  get skillStrands(): SkillStrand[] {
    this.skills.forEach((skill: SkillDefinition) => {
      if (skill.nodeCode.split('.')[0] == this.selectedGrade) {
        this.gradeToStrand.set(
          skill.nodeCode.split('.')[1],
          this.selectedGrade
        );
      }
    });

    const skillStrands = this.$store.state.insightsHub.skillStrands;
    return orderBy(
      skillStrands,
      (skillStrand) => this.gradeToStrand.get(skillStrand.code),
      'asc'
    );
  }

  // Make a map that is a strand code key with all the skills with that code as the value
  // Ex: {'NS': ['7.NS', '8.NS.A.1b']}, the array is of Skill objects though, not just strings
  get skillStrandToStandardsMap(): Map<string, SkillDefinition[]> {
    const strandMap = new Map<string, SkillDefinition[]>();

    this.skills.forEach((skill) => {
      const strandCode = skill.nodeCode.split('.')[1];
      if (!strandMap.has(strandCode)) {
        strandMap.set(strandCode, []);
      }

      const strand = strandMap.get(strandCode);

      if (strand) {
        strand.push(skill);
        // sort the strand array so that codes that start with K are first in the list
        strand.sort((a, b) => {
          if (a.nodeCode.startsWith('K') && b.nodeCode.startsWith('K')) {
            return a.nodeCode.localeCompare(b.nodeCode);
          } else if (a.nodeCode.startsWith('K')) {
            return -1;
          } else if (b.nodeCode.startsWith('K')) {
            return 1;
          } else {
            return a.nodeCode.localeCompare(b.nodeCode);
          }
        });

        strandMap.set(strandCode, strand);
      }
    });

    return strandMap;
  }

  get selectedStrand(): number {
    return this.$store.state.insightsHub.selectedStrandId;
  }

  get selectedStrandCode(): string {
    const strand = this.skillStrands.find(
      (skillStrand) =>
        skillStrand.dbid.toString() === this.selectedStrand.toString()
    );

    return strand?.code || '';
  }

  get selectedGrade(): string {
    const selectedGrade = this.$route.query.grade as string;
    return selectedGrade.split(' ')[1];
  }

  get selectedCoherenceStandard(): SkillDefinition | null {
    if (this.heatMapType === HeatMapDisplayType.COHERENCY) {
      return this.coherenceStandard;
    }
    return null;
  }

  set selectedCoherenceStandard(standard: SkillDefinition) {
    this.coherenceStandard = standard;
  }

  get standardPreAndPostRequisites() {
    const prerequisites: SkillDefinition[] = [];
    const postrequisites: SkillDefinition[] = [];
    const matchingSkills: SkillDefinition[] = [];

    // We want to search for data for the selected code, in common core, this isn't always just one skill,
    // We need to gather all the id's and the prerequisites id's in order to get accurate data
    const matchingCodes: string[] = [];
    let matchingCodePrereqs: string[] = [];

    this.skills.forEach((skill) => {
      if (skill.nodeCode === this.coherenceStandard?.nodeCode) {
        matchingCodes.push(skill.xref);
        matchingCodePrereqs.push(...skill.prerequisites);
      }
    });

    matchingCodePrereqs = uniq(matchingCodePrereqs);

    this.skills.forEach((skill) => {
      if (this.coherenceStandard) {
        // The skills with the same code need to be collected
        if (matchingCodes.includes(skill.xref)) {
          matchingSkills.push(skill);
        }

        // If the selected standard has the current skill in the loop as a prerequisite, add it to the pre requisites array
        if (matchingCodePrereqs.includes(skill.xref)) {
          prerequisites.push(skill);
        }

        // If the current skill in the loop has the selected standard as a prerequisite, add it to the post requisites array
        if (skill.prerequisites.some((xref) => matchingCodes.includes(xref))) {
          postrequisites.push(skill);
        }
      }
    });

    return [...prerequisites, ...matchingSkills, ...postrequisites];
  }

  get skills(): SkillDefinition[] {
    return this.$store.state.insightsHub.skills;
  }

  get currentTimeData(): TotalStandardStats {
    // If strand is selected get it from the store, otherwise return an empty object
    if (this.selectedStrand && !this.isDownloadingData) {
      return this.heatMapType === HeatMapDisplayType.COHERENCY
        ? this.$store.state.insightsHub.currentTimeCoherencyData.get(
            this.coherenceStandard?.xref
          )
        : this.$store.state.insightsHub.currentTimeData.get(
            this.selectedStrand
          );
    }
    return {
      rowData: [],
      schools: [],
    };
  }

  get collapsedPaths(): Set<string> {
    return this.$store.state.insightsHub.collapsedPaths;
  }

  set collapsedPaths(value: Set<string>) {
    this.$store.commit('insightsHub/setCollapsedPaths', value);
  }

  get options(): DataOptions {
    return this.$store.state.insightsHub.options;
  }

  set options(value: DataOptions) {
    this.$store.commit('insightsHub/setOptions', value);
  }

  get selectedMode(): ActivityMode {
    const mode = this.$store.state.insightsHub.selectedMode;
    return mode === null ? ActivityMode.ASSIGNED : mode;
  }

  get anonymized(): boolean {
    return this.$store.state.insightsHub.anonymized;
  }

  set anonymized(value: boolean) {
    this.$store.commit('insightsHub/setAnonymized', value);
  }

  get loading(): boolean {
    return this.isDownloadingData;
  }

  get gradeLevel(): string {
    const grade = this.$route.query.grade as string;
    return grade ? grade.toUpperCase().replace(' ', '_') : '';
  }

  get xrefToCourseMap(): Map<string, CourseDefinition> {
    return this.$store.getters['insightsHub/xrefToCourseMap'];
  }

  get xrefToSchoolMap(): Map<string, School> {
    return this.$store.getters['insightsHub/xrefToSchoolMap'];
  }

  get menteeNames(): User[] {
    return this.$store.state.insightsHub.menteeTeachers;
  }

  get studentDisplayNamesMap(): Map<string, Map<string, string>> {
    return this.$store.state.insightsHub.studentDisplayNamesMap;
  }

  get showOrHideNamesText() {
    return this.anonymized ? 'Show' : 'Hide';
  }

  get modeColor(): string {
    switch (this.selectedMode) {
      case ActivityMode.COMPLETED:
        // eslint-disable-next-line
        // @ts-ignore
        return this.$vuetify.theme.themes.light.correctEventually;
      case ActivityMode.SCORE:
        // eslint-disable-next-line
        // @ts-ignore
        return this.$vuetify.theme.themes.light.correct;
      case ActivityMode.ASSIGNED:
      default:
        // eslint-disable-next-line
        // @ts-ignore
        return this.$vuetify.theme.themes.light.primary.base;
    }
  }

  // Headers are objects with a text and a value key
  // The text key is the skillcode split at the 2nd period, then it uses one or the other index. so "7.NS.A.1" becomes either "7.NS" or "A.1"
  // The value is the full code plus the current mode. so "7.NS.A.1" becomes "7.NS.A.1_ASSIGNED" or "7.NS.A.1_COMPLETED"
  get computedHeaders(): GradientHeaders {
    const firstColHeader =
      this.selectedMode == ActivityMode.ASSIGNED ? 'Total' : 'AVG';

    const res: GradientHeaders = {
      averageHeader: {
        text: firstColHeader,
        value: '',
      },
      itemHeaders: [],
    };

    if (this.currentTimeData) {
      let modeSuffix;
      switch (this.selectedMode) {
        case ActivityMode.ASSIGNED:
          modeSuffix = 'ASSIGNED';
          break;
        case ActivityMode.COMPLETED:
          modeSuffix = 'COMPLETED';
          break;
        case ActivityMode.SCORE:
          modeSuffix = 'SCORE';
          break;
      }

      if (this.heatMapType == HeatMapDisplayType.COHERENCY) {
        const requisiteList = uniqBy(
          this.standardPreAndPostRequisites,
          'nodeCode'
        );

        for (const skill of requisiteList as SkillDefinition[]) {
          if (
            this.heatMapType === HeatMapDisplayType.COHERENCY &&
            !['total', 'avg'].includes(skill.nodeCode)
          ) {
            // Coherency just gets all the columns, no fancy stuff
            res.itemHeaders.push({
              text: skill.nodeCode,
              value: `${skill.nodeCode}_${modeSuffix}`,
            });
          }
        }
      } else if (this.currentTimeData.rowData) {
        let currentStrand = '';

        for (const cellData of this.currentTimeData.rowData) {
          // Split code at the second period, then use the first two parts together as an identifier of which strand it's on
          const splitCode = cellData.skillCode.split('.');
          const newStrand = `${splitCode[0]}.${splitCode[1]}`;
          if (newStrand !== currentStrand) {
            currentStrand = newStrand;
          }

          // If code starts with the currentStrand then add it to the itemHeaders without the currentStrand in the text unless it is the first one with that strand
          if (cellData.skillCode.startsWith(currentStrand)) {
            if (cellData.skillCode === currentStrand) {
              res.itemHeaders.push({
                text: cellData.skillCode,
                value: `${cellData.skillCode}_${modeSuffix}`,
              });
            } else {
              if (!this.collapsedPaths.has(currentStrand)) {
                res.itemHeaders.push({
                  text: cellData.skillCode.substring(currentStrand.length + 1),
                  value: `${cellData.skillCode}_${modeSuffix}`,
                });
              }
            }
          }
        }
      }

      // Update Average Header Value
      res.averageHeader.value = `${firstColHeader.toLowerCase()}_${modeSuffix}`;
    }

    return res;
  }

  get headerToolTips(): { [key: string]: string } {
    // Update to use the InsightsHub store when INH-90 is complete. Skilllist as it is now
    // is common core plus and has a lot of codes that wont match up with our common core headers
    const skillList = this.$store.state.insightsHub.skills;

    const tooltips: { [key: string]: string } = {};

    if (this.currentTimeData) {
      // loop through this.currentTimeData.rowData make an object with the skillCode as the key
      const rowHeaders: { [key: string]: boolean } =
        this.currentTimeData.rowData?.reduce(
          (acc, curr) => ({ ...acc, [curr.skillCode]: true }),
          {}
        );

      // loop through the skill list and if the code exists in the rowHeaders, add it to the tooltips object
      for (const skill of skillList) {
        if (rowHeaders && rowHeaders[skill.nodeCode]) {
          tooltips[skill.nodeCode] = skill.nodeName;
        }
      }
    }

    return tooltips;
  }

  get standardsDropdownOptions(): StandardsDropdownOption[] {
    const res: StandardsDropdownOption[] = [];

    const skills = uniqBy(
      this.skillStrandToStandardsMap.get(this.selectedStrandCode),
      'nodeCode'
    );

    if (skills) {
      skills.forEach((skill) => {
        res.push({ text: skill.nodeCode, value: skill });
      });
    }

    return res.sort((a, b) => {
      if (a.text.startsWith('K') && b.text.startsWith('K')) {
        return a.text.localeCompare(b.text);
      } else if (a.text.startsWith('K')) {
        return -1;
      } else if (b.text.startsWith('K')) {
        return 1;
      } else {
        return a.text.localeCompare(b.text);
      }
    });
  }

  get headerTitle(): string {
    return this.heatMapType === HeatMapDisplayType.COHERENCY
      ? 'Prerequisite Standards / Grade Level / Subsequent Standards '
      : 'Strand + Standard';
  }

  /////////////////
  // Report Data //
  /////////////////

  get computedRows(): GradientRows {
    const res = {
      averageRow: {
        [StaticHeader.NAME]:
          this.selectedMode == ActivityMode.ASSIGNED ? 'Total' : 'AVG',
        children: [],
      } as unknown as Row,
      itemRows: new Map<string, Row>(),
    };

    if (this.currentTimeData && this.currentTimeData.schools) {
      this.matchRowDataToHeaders(res.averageRow, this.currentTimeData.rowData);

      // School Rows
      for (const school of this.currentTimeData.schools) {
        // Add each school xref as a child of the average row
        res.averageRow.children?.push(school.schoolXref);

        const schoolId = `school_${school.schoolXref}`;
        const school_name =
          this.xrefToSchoolMap.get(school.schoolXref)?.name || 'Not Affiliated';

        let schoolRow = res.itemRows.get(schoolId) ?? {
          parents: null,
          xref: schoolId,
          type: RowType.SCHOOL,
          [StaticHeader.NAME]: this.anonymized ? 'SXXXXX' : school_name,
          children: [],
        };

        this.matchRowDataToHeaders(schoolRow, school.rowData);

        if (!this.collapsedPaths.has(schoolId)) {
          // Teacher Rows
          for (const teacher of school.teachers) {
            // Add each teacher xref as a child of the school row
            schoolRow.children?.push(teacher.teacherXref);

            const teacherName =
              this.menteeNames.find(
                (mentee) => mentee.xref === teacher.teacherXref
              )?.displayName || '';

            let teacherRow: Row = {
              parents: [school.schoolXref],
              xref: teacher.teacherXref,
              type: RowType.TEACHER,
              [StaticHeader.NAME]: this.anonymized ? 'TXXXXX' : teacherName,
              children: [],
              sortOverride: {
                [StaticHeader.NAME]: teacherName || '',
              },
            };

            this.matchRowDataToHeaders(teacherRow, teacher.rowData);

            if (
              !this.collapsedPaths.has(
                [schoolId, teacher.teacherXref].join('.')
              )
            ) {
              // Course Rows
              for (const course of teacher.classes) {
                // Add each course/class xref as a child of the teacher row
                teacherRow.children?.push(course.classXref);

                const courseName = this.xrefToCourseMap.get(
                  course.classXref
                )?.courseName;

                const courseRow: Row = {
                  parents: [teacherRow.xref],
                  xref: course.classXref,
                  type: RowType.COURSE,
                  children: [],
                  [StaticHeader.NAME]: this.anonymized ? 'CXXXXX' : courseName,
                };

                this.matchRowDataToHeaders(courseRow, course.rowData);

                // Student Rows
                if (
                  !this.collapsedPaths.has(
                    [schoolId, teacher.teacherXref, course.classXref].join('.')
                  )
                ) {
                  for (const student of course.students) {
                    // Add each student xref as a child of the course row
                    courseRow.children?.push(student.studentXref);

                    const studentName =
                      this.studentDisplayNamesMap
                        .get(course.classXref)
                        ?.get(student.studentXref) || '';

                    const studentRow: Row = {
                      parents: [courseRow.xref],
                      xref: student.studentXref,
                      type: RowType.STUDENT,
                      [StaticHeader.NAME]: this.anonymized
                        ? 'STXXXXX'
                        : studentName,
                    };

                    this.matchRowDataToHeaders(studentRow, student.rowData);

                    res.itemRows.set(student.studentXref, studentRow);
                  }
                }
                res.itemRows.set(course.classXref, courseRow);
              }
            }
            res.itemRows.set(teacherRow.xref, teacherRow);
          }
        }
        res.itemRows.set(schoolRow.xref, schoolRow);
      }
    }

    return res;
  }

  /////////////
  // Methods //
  /////////////

  // getClickedItem(item: any) {
  //   this.menu = false;
  //   this.columnItem = item.item;
  //   this.columnItemHeader = item.itemHeaders;
  //   this.columnHeader = item.headerValue;
  //   this.positionX = item.event.x;
  //   this.positionY = item.event.y;
  //   this.$nextTick(() => {
  //     if (!item.event.srcElement.outerHTML.includes('empty-cell')) {
  //       this.menu = true;
  //     }
  //   });
  //   //Add a highlighter for selected box in map
  //   const currentElement = item.event.target.innerHTML.includes('span')
  //     ? item.event.target
  //     : item.event.target.parentElement;
  //   currentElement.classList.add('selected-map-box');

  //   if (
  //     currentElement !== this.previousSelectedElement &&
  //     this.previousSelectedElement !== null
  //   ) {
  //     //Remove a highlighter for previously selected box in map if different box selected
  //     // eslint-disable-next-line
  //     // @ts-ignore
  //     this.previousSelectedElement.classList.remove('selected-map-box');
  //   }

  //   this.previousSelectedElement = currentElement;
  // }

  getStudentNamesForCourse(courseRow: Row): void {
    const filteredPaths = new Set(this.collapsedPaths);
    const coursePath = courseRow.path as string;

    // This function replaces the other toggle function for courses. So if the course path is not in the list we want to add it ie. collapse/Toggle off
    // Else fetch student names, update the store, then remove the course path from the list, which expands the row/Toggle on.
    if (!filteredPaths.has(coursePath)) {
      filteredPaths.add(coursePath);
      this.updateCollapsedPaths(filteredPaths);
    } else {
      // If the student names for the course have already been downloaded, no need to fetch, just update the store with the new list
      if (this.studentDisplayNamesMap.has(courseRow.xref)) {
        filteredPaths.delete(coursePath);
        this.updateCollapsedPaths(filteredPaths);
        return;
      }
      getCourseRoster(courseRow.xref).then((res) => {
        this.$store.commit('insightsHub/setStudentDisplayNamesMap', {
          courseXref: courseRow.xref,
          courseRoster: res,
        });
        // Display the student rows AFTER the names have been fetched
        filteredPaths.delete(coursePath);
        this.updateCollapsedPaths(filteredPaths);
      });
    }
  }

  updateOptions(options: DataOptions): void {
    this.options = options;
  }

  updateCollapsedPaths(collapsedPaths: Set<string>): void {
    this.$store.commit('insightsHub/setCollapsedPaths', collapsedPaths);
  }

  updateCsvData(currentData: {
    items: Row[];
    tableHeaders: DataTableHeader[];
  }): void {
    this.csvData = currentData.items;
    this.csvTableHeaders = currentData.tableHeaders;
  }

  currentItemsToCsvData(items: Row[]): string[][] {
    // Collect the headers in to one string
    const headerRowStrings = this.csvTableHeaders.map((header) => {
      return header.text;
    });

    // Loop through the data and make each row a string
    const csvStrings = items.map((item: Row) => {
      let row: string[] = [];

      this.csvTableHeaders.forEach((header: DataTableHeader) => {
        const headerKey = header.value;
        const itemValue = item[headerKey] as Fraction;

        if (itemValue) {
          if (itemValue.denominator) {
            row.push((itemValue.numerator / itemValue.denominator).toString());
          } else {
            row.push(itemValue.toString());
          }
        }
      });

      return row;
    });

    // Add header string to front of the data
    csvStrings.unshift(headerRowStrings);

    return csvStrings;
  }

  getStrandData(): void {
    if (this.source) this.source.cancel();
    this.isDownloadingData = true;

    this.source = axios.CancelToken.source();

    getStandardCurrentTime(
      this.selectedStrand,
      {
        primaryTeachers: true,
        mentor: this.$router.app.getCurrentUser.xref,
        gradeLevel: this.gradeLevel,
        timeSelector: new TimeSelector(
          TimeSelectorType.INCLUSIVE,
          this.$store.state.insightsHub.timeSelector.lowerLimit,
          this.$store.state.insightsHub.timeSelector.upperLimit
        ),
      },
      this.source?.token
    )
      .then((res) => {
        this.$store.commit('insightsHub/setCurrentTimeData', {
          strandId: this.selectedStrand,
          data: res,
        });
        this.isDownloadingData = false;
        this.collapseUnselectedGradeLevels();
        this.collapseCourses(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          return;
        }
        this.isDownloadingData = false;
      });
  }

  getCoherencyData() {
    if (this.source) this.source.cancel();
    this.isDownloadingData = true;

    this.source = axios.CancelToken.source();

    getStandardCoherencyData(
      StandardId.COMMON_CORE,
      {
        primaryTeachers: true,
        mentor: this.$router.app.getCurrentUser.xref,
        gradeLevel: this.gradeLevel,
        timeSelector: new TimeSelector(
          TimeSelectorType.INCLUSIVE,
          this.$store.state.insightsHub.timeSelector.lowerLimit,
          this.$store.state.insightsHub.timeSelector.upperLimit
        ),
        skillXrefs: this.standardPreAndPostRequisites.map(
          (skill) => skill.xref
        ),
      },
      this.source?.token
    )
      .then((res) => {
        this.$store.commit('insightsHub/setCurrentTimeCoherencyData', {
          skillId: this.coherenceStandard?.xref,
          data: res,
        });
        this.isDownloadingData = false;
        this.collapseUnselectedGradeLevels();
        this.collapseCourses(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          return;
        }
        this.isDownloadingData = false;
      });
  }

  downloadCsv(): void {
    const csv = this.currentItemsToCsvData(this.csvData);

    downloadCsvData(
      csv,
      `${this.selectedMode}_${dayjs().format('YYYY_MM_DD_hhmmss')}`
    );
  }

  collapseUnselectedGradeLevels(): void {
    let matchingHeader = '';
    let unselectedHeaders = new Set<string>();

    if (this.currentTimeData) {
      // Look for a header that matches the current grade level
      for (const header of this.currentTimeData.rowData) {
        const splitCode = header.skillCode.split('.');
        const baseCode = `${splitCode[0]}.${splitCode[1]}`;

        if (baseCode.startsWith(this.gradeLevel.split('_')[1])) {
          matchingHeader = baseCode;
        } else if (
          !unselectedHeaders.has(baseCode) &&
          !baseCode.includes('total')
        ) {
          unselectedHeaders.add(baseCode);
        }
      }

      // If there is a matching header, collapse all the others, otherwise don't collapse anything
      if (matchingHeader) {
        this.updateCollapsedPaths(
          new Set([...this.collapsedPaths, ...unselectedHeaders])
        );
      }
    }
  }

  collapseCourses(data: TotalStandardStats): void {
    const courseRows = new Set<string>();

    // Loop through the returned data and get all the paths for the courses and add to collapsedPaths
    data.schools.forEach((school) => {
      school.teachers.forEach((teacher) => {
        teacher.classes.forEach((course) => {
          const path = [
            `school_${school.schoolXref}`,
            teacher.teacherXref,
            course.classXref,
          ];
          courseRows.add(path.join('.'));
        });
      });
    });

    this.updateCollapsedPaths(new Set([...this.collapsedPaths, ...courseRows]));
  }

  matchRowDataToHeaders(
    rowObject: Row,
    rowData: StandardHierarchyNodeCellStats[]
  ) {
    // The rowObject is the object that needs to hold all the individual cell data as objects with a numerator, denominator, and type
    // The rowData is an array of data with a skillCode key and the numbers needed to fill the numerator and denominator
    // This function loops through the headers and searches the rowData for the entry with a matching skillcode, then adds it to the rowObject

    if (rowData) {
      // Find the entry with the 'total' skillCode and add that data it's own column. 'total' is actually not in the headers, so needs to be done separately
      const entry = rowData.find((data) => {
        return data.skillCode === 'total';
      });

      if (entry) {
        rowObject[`${this.computedHeaders.averageHeader.value}`] =
          this.getFractionFrom(entry);
      }

      for (const data of rowData) {
        if (data.skillCode === 'total') {
          continue;
        }
        let modeSuffix;
        switch (this.selectedMode) {
          case ActivityMode.ASSIGNED:
            modeSuffix = 'ASSIGNED';
            break;
          case ActivityMode.COMPLETED:
            modeSuffix = 'COMPLETED';
            break;
          case ActivityMode.SCORE:
            modeSuffix = 'SCORE';
            break;
        }

        const columnHeader = data.skillCode + '_' + modeSuffix;

        rowObject[columnHeader] = this.getFractionFrom(data);
      }
    }
  }

  getFractionFrom(data: StandardHierarchyNodeCellStats): Fraction {
    switch (this.selectedMode) {
      case ActivityMode.ASSIGNED:
        return {
          denominator: 1,
          numerator: data.numUniqueProblemsAssigned,
          type: CellType.RAW_VALUE,
        };
      case ActivityMode.COMPLETED:
        return {
          denominator: data.totalProblemsAssigned,
          numerator: data.totalProblemsCompleted,
          type: CellType.PERCENT,
        };
      case ActivityMode.SCORE:
        return {
          denominator: data.totalScoredProblems,
          numerator: data.totalScoreOnProblems,
          type: CellType.PERCENT,
        };
    }
  }

  openCoherenceSelect() {
    // Opens the dropdown on first load and then works like normal after.
    // TS doesn't like that the Element doesn't have the isFocused or isMenuActive properties
    // but they are added in the component and I'm not sure how to get around that.
    // So just ignoring the squiggly red line for now. Would love to find another solution.
    if (this.$refs.coherenceStandardSelect && !this.coherenceStandard) {
      // eslint-disable-next-line
      // @ts-ignore
      this.$refs.coherenceStandardSelect.isMenuActive = true;
    }
  }

  unsetCoherenceStandard() {
    this.coherenceStandard = null;
  }

  //////////////
  // Watchers //
  //////////////

  @Watch('selectedMode')
  newActivityModeClicked(): void {
    trackMixpanel(EventType.selectedInsightsActivityMode, {
      selectedMode: this.$store.state.insightsHub.selectedMode,
    });
  }

  @Watch('selectedStrand')
  skillStrandChanged(newStrandId: number): void {
    if (newStrandId) {
      this.menu = false;
      this.getStrandData();
    }
  }

  @Watch('coherenceStandard')
  coherenceStandardChanged(): void {
    if (this.coherenceStandard) this.getCoherencyData();
  }

  created(): void {
    this.$store.commit('insightsHub/setSelectedTab', null);
    this.$store.commit('insightsHub/setSelectedMode', null);
  }
}
