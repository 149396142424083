
import { ProblemDefinition } from '@/domain/Problem';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ProblemViewForBuilder from './ProblemViewForBuilder.vue';
import TimeFromNow from './TimeFromNow.vue';
import { ContentType } from '@/domain/Content';
import { getContentType, isPublished } from '@/utils/builder.util';
import { AclPermissionType } from '@/domain/Acls';

@Component({
  components: {
    ProblemViewForBuilder,
    ProblemSetViewForBuilder: () => import('./ProblemSetViewForBuilder.vue'),
    TimeFromNow,
  },
})
export default class ProblemSetViewForBuilder extends Vue {
  @Prop({ required: true }) problemSet: ProblemSetDefinition;
  @Prop({ required: false, default: null }) contextPath: string | null;
  @Prop({ required: false, default: true }) showAnswers: boolean;
  @Prop({ required: false, default: true }) showSupports: boolean;

  ContentType = ContentType;

  get targetPath(): string {
    return this.contextPath
      ? `${this.contextPath},${this.problemSet.xref}`
      : this.problemSet.xref;
  }

  get depth(): number {
    return this.targetPath.split(',').length;
  }

  get members(): (ProblemSetDefinition | ProblemDefinition)[] {
    const children = [];
    const ceris = this.problemSet?.children ?? [];
    for (const ceri of ceris) {
      let contentMap = null;
      const type = getContentType(ceri);
      switch (type) {
        case ContentType.PROBLEM:
          contentMap = this.problemMap;
          break;
        case ContentType.PROBLEM_SET:
          contentMap = this.problemSetMap;
          break;
      }
      if (contentMap) {
        let content = contentMap[ceri];
        if (
          isPublished(content.xref) &&
          content.permissions.includes(AclPermissionType.UPDATE) &&
          content.mappedCeri
        ) {
          content = contentMap[content.mappedCeri];
        }
        children.push(content);
      }
    }
    return children;
  }

  get problemSetMap(): Record<string, ProblemSetDefinition> {
    return this.$store.state.content.problemSetMap;
  }

  get problemMap(): Record<string, ProblemDefinition> {
    return this.$store.state.content.problemMap;
  }
}
