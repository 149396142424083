var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"surface-container neutral lighten-4 elevation-1",attrs:{"fluid":"","data-cy":"insights-hub-dashboard-top-bar"}},[_c('div',{staticClass:"text-overline text-decoration-none"},[_c('router-link',{staticClass:"text-overline text-decoration-none",attrs:{"to":{
        name: 'insightsHubLanding',
      },"data-cy":"insights-hub-home"}},[_vm._v(" "+_vm._s(_vm.curriculumName)+" ")]),(_vm.selectedModule)?_c('span',[_vm._v(" / ")]):_vm._e(),_c('span',{on:{"click":_vm.clearSelectedModule}},[_c('router-link',{staticClass:"text-overline text-decoration-none",attrs:{"to":{
          name: 'insightsHubLanding',
        }}},[_vm._v(" "+_vm._s(_vm.gradeName)+" ")])],1),(_vm.selectedModule)?[_c('span',[_vm._v(" / ")]),_c('span',[_vm._v(" "+_vm._s(_vm.moduleTitle)+" "+_vm._s(_vm.selectedModule.moduleNumber)+" - "+_vm._s(_vm.selectedModule.moduleName)+" ")])]:_vm._e()],2),_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',[_c('h3',{staticClass:"text-h3 mt-1"},[_vm._v(" "+_vm._s(_vm.selectedSchoolYear)+" School Year / "+_vm._s(_vm.headerTitle)+" ")])]),_c('div',{staticClass:"d-flex justify-end neutral lighten-4"},[(_vm.curriculumSelectionVisibility)?_c('span',[_c('v-select',{attrs:{"items":_vm.curriculaChoices,"label":"Select Curriculum","item-text":"name","item-value":"xref","rounded":"","hide-details":""},model:{value:(_vm.selectedCurriculumXref),callback:function ($$v) {_vm.selectedCurriculumXref=$$v},expression:"selectedCurriculumXref"}})],1):_vm._e(),(_vm.curriculumSelectionVisibility)?_c('span',[_c('v-select',{attrs:{"items":_vm.schoolYearChoices,"outlined":"","hide-details":""},model:{value:(_vm.timeSelector),callback:function ($$v) {_vm.timeSelector=$$v},expression:"timeSelector"}})],1):_vm._e()])]),_c('div',{staticClass:"mt-4"},[_c('v-tabs',{attrs:{"background-color":"transparent","color":"primary darken-3"}},[_c('v-tabs-slider',{attrs:{"color":"primary darken-2"}}),_c('v-tab',{attrs:{"to":{
          name: 'ActivityByStandardPage',
          query: { ..._vm.$route.query },
        },"data-cy":"activity-by-standard-tab","disabled":!_vm.gradeName.match(/^Grade [1-8]/)}},[_vm._v(" Activity by Standard ")]),_c('v-tab',{attrs:{"to":{
          name: 'ActivityByCurriculumPage',
          query: {
            ..._vm.$route.query,
          },
        },"data-cy":"activity-by-curriculum-tab"}},[_vm._v(" Activity by Curriculum ")]),_c('v-tab',{attrs:{"to":{
          name: 'achievementPage',
          query: { ..._vm.$route.query },
        },"data-cy":"achievement-tab"},on:{"click":_vm.clearSelectedModule}},[_vm._v(" Achievement ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }