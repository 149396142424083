
import { Component, Vue, Prop } from 'vue-property-decorator';
import { createTeacherRoleCode } from '@/api/core/roleCode.api';
import {
  inputValidationRules,
  validateEmail,
} from '@/utils/inputValidation.util';
import { LmsProviderType } from '@/domain/LmsProviderType';

@Component
export default class GenerateInviteCodeDialog extends Vue {
  @Prop() value: boolean;

  targetEmailAddressesLimit = 5;
  hasCodeBeenGenerated = false;
  generatedCode = '';

  targetEmailAddresses = [''];
  rules = inputValidationRules;

  get areEmailInputsValid(): boolean {
    return this.targetEmailAddresses.every(validateEmail);
  }

  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(val: boolean) {
    this.$emit('input', val);
  }

  get isCanvasUser(): boolean {
    return this.getCurrentUser.lmsProviderType === LmsProviderType.CANVAS;
  }

  validateEmailAddresses(): boolean {
    return this.targetEmailAddresses.every(
      (address) => this.rules.email(address) === true
    );
  }

  addAnotherEmailAddressInput(): void {
    if (this.targetEmailAddresses.length < this.targetEmailAddressesLimit) {
      this.targetEmailAddresses.push('');
    }
  }

  handleCodeGeneration(): void {
    if (!this.areEmailInputsValid) {
      this.$notify('Email addresses are not valid.');
      return;
    }

    createTeacherRoleCode(this.targetEmailAddresses)
      .then((code) => {
        this.hasCodeBeenGenerated = true;
        this.generatedCode = code;
        this.$notify('Invite codes generated');
      })
      .catch(() => {
        this.$notify('An error occured whilst generating invite codes');
        this.showDialog = false;
      });
  }

  copyCodeToClipboard(): void {
    const code: string = document.getElementById('code')?.innerText as string;

    navigator.clipboard.writeText(code).then(() => {
      this.$notify('Code copied to the clipboard!');
    });
  }

  closeDialog(): void {
    this.targetEmailAddresses = [''];
    this.showDialog = false;
    this.hasCodeBeenGenerated = false;
  }
}
