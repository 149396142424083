
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { FolderDefinition } from '@/domain/Folder';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import { getFolderPathsTo } from '@/api/core/folders.api';
import { RawLocation } from 'vue-router';
import { RETURN_TEXT, RETURN_URL } from '@/domain/PageParams';

@Component
export default class GoToParentProblemSetDialog extends Vue {
  @Prop() value: boolean;
  @Prop() ceri: string;
  @Prop() source: string;
  @Prop() returnText: string;

  downloading = false;
  downloaded = false;
  paths: (FolderDefinition | ProblemSetDefinition)[][] = [];

  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(val: boolean) {
    this.$emit('input', val);
  }

  findPaths() {
    if (this.downloading || this.downloaded) {
      return;
    }
    this.downloading = true;
    // FIXME: Figure out if target file would be included in requested path.
    getFolderPathsTo([this.source], this.ceri)
      .then((paths) => {
        this.paths = paths;
        this.downloaded = true;
        if (paths.length == 1) {
          this.$router.push(this.getLocation(paths[0]));
        }
      })
      .finally(() => {
        this.downloading = false;
      });
  }

  getLocation(path: (FolderDefinition | ProblemSetDefinition)[]): RawLocation {
    return {
      name: 'LessonPageProblemSet',
      params: {
        problemSetXref: path[path.length - 1].xref,
      },
      query: {
        [RETURN_TEXT]: this.returnText,
        [RETURN_URL]: this.$router.currentRoute.fullPath,
      },
    };
  }

  @Watch('showDialog') // if `value` is true, then the dialog's opening
  goToProblemSet(isDialogOpen: boolean): void {
    if (isDialogOpen) {
      // Check if there's more than one ID in the array
      this.findPaths();
    }
  }
}
