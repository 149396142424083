import { render, staticRenderFns } from "./DeleteStudentProgressDialog.vue?vue&type=template&id=cbe17470&scoped=true&"
import script from "./DeleteStudentProgressDialog.vue?vue&type=script&lang=ts&"
export * from "./DeleteStudentProgressDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbe17470",
  null
  
)

export default component.exports