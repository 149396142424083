
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ProblemDefinition } from '@/domain/Problem';
import { PrintDefinition } from '@/domain/PrintDefinition';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import { getProblemDefinitionsFromStructure } from '@/utils/problemSet.util';
import { isEmpty } from 'lodash';
import ProblemViewSDK3 from '../Builder/ContentView/ProblemViewSDK3.vue';

@Component({
  components: {
    ProblemViewSDK3,
  },
})
export default class PrintProblemSetsView extends Vue {
  @Prop({ required: true }) printData: PrintDefinition;

  get problemSetMap(): Record<string, ProblemSetDefinition> {
    return this.$store.state.content.problemSetMap;
  }

  get problemMap(): Record<string, ProblemDefinition> {
    return this.$store.state.content.problemMap;
  }

  get problems(): ProblemDefinition[] {
    if (
      !isEmpty(this.printData.problemSet) &&
      !isEmpty(this.problemSetMap) &&
      !isEmpty(this.problemMap)
    ) {
      return getProblemDefinitionsFromStructure(
        this.printData.problemSet,
        this.problemSetMap,
        this.problemMap
      );
    }
    return [];
  }

  @Watch('printData')
  onCeriUpdate() {
    // Need nextTick or it tries to print before the dom is updated with the new problems
    this.$nextTick(() => {
      const container = document.querySelector('#print-container');
      const imgMatches = container?.querySelectorAll('img') ?? [];
      let imagesLoaded = 0;
      let allLoaded = true;
      const imgMatchesCount = imgMatches.length;
      for (let i = 0; i < imgMatchesCount; i++) {
        const imgHtmlElem = imgMatches[i] as HTMLImageElement;
        if (!imgHtmlElem.complete) {
          allLoaded = false;
          this.$nextTick(() => {
            imgHtmlElem.onload = function () {
              imagesLoaded++;
              if (imgMatchesCount === imagesLoaded) {
                window.print();
              }
            };
            imgHtmlElem.onerror = function () {
              imagesLoaded++;
              if (imgMatchesCount === imagesLoaded) {
                window.print();
              }
            };
          });
        } else {
          imagesLoaded++;
        }
      }
      if (allLoaded) {
        this.$nextTick(() => {
          window.print();
        });
      }
    });
  }
}
