import { render, staticRenderFns } from "./LessonPageTopBar.vue?vue&type=template&id=f8dc5b54&scoped=true&"
import script from "./LessonPageTopBar.vue?vue&type=script&lang=ts&"
export * from "./LessonPageTopBar.vue?vue&type=script&lang=ts&"
import style0 from "./LessonPageTopBar.vue?vue&type=style&index=0&id=f8dc5b54&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8dc5b54",
  null
  
)

export default component.exports