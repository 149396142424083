/* eslint-disable @typescript-eslint/ban-types */
import { Server } from 'miragejs';
import { faker } from '@faker-js/faker';
import dayjs from 'dayjs';

faker.seed(0);

// If todays date is before july 1st generate data from last july to this june, otherwise this july to next june
const isBeforeJulFirst = dayjs().isBefore(dayjs(`${dayjs().year()}-07-01`));

const startYear = isBeforeJulFirst
  ? dayjs().subtract(1, 'year').year()
  : dayjs().year();

let date = dayjs(`${startYear}-07-01`).valueOf();
const endDate = dayjs(`${dayjs(date).add(1, 'year').year()}-06-30`).valueOf();

function createOverTimeEntry(
  server: Server,
  date: number,
  comparisonNumerator: number,
  comparisonDenominator: number,
  districtNumerator: number | null,
  districtDenominator: number | null
) {
  server.create('standardOverTime', {
    date,
    comparisonNumerator,
    comparisonDenominator,
    districtNumerator,
    districtDenominator,
  } as object);
}

function rand(start: number, end: number): number {
  return faker.number.int({ min: start, max: end });
}

export default function (server: Server): void {
  let comparisonNum = 0;
  let comparisonDen = 0;
  let districtNum = 0;
  let districtDen = 0;

  // Make a whole year of data starting from last year's june 1st
  while (date <= endDate) {
    // Get day of week so it adds 0 on weekends
    const isWeekend = [0, 6].includes(dayjs(date).day());

    const newComparisonNum = comparisonNum + (isWeekend ? 0 : rand(1, 10));
    const newComparisonDen = comparisonDen + (isWeekend ? 0 : 10);
    const newDistrictNum = districtNum + (isWeekend ? 0 : rand(1, 15));
    const newDistrictDen = districtDen + (isWeekend ? 0 : rand(10, 20));

    // Only need the comparison data for the graph when it's assigned over time
    createOverTimeEntry(server, date, newComparisonNum, 1, null, null);

    // Add a second set of data for the graph when it's completed or score over time
    // This is slightly offset time-wise so we can grab one or the other based on the time
    createOverTimeEntry(
      server,
      dayjs(date).add(10, 'seconds').valueOf(),
      newComparisonNum,
      newComparisonDen,
      newDistrictNum,
      newDistrictDen
    );

    comparisonNum = newComparisonNum;
    comparisonDen = newComparisonDen;
    districtNum = newDistrictNum;
    districtDen = newDistrictDen;

    date = dayjs(date).add(1, 'day').valueOf();
  }
}
