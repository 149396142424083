export enum DefinitionInclude {
  ATTRIBUTES = 'ATTRIBUTES',
  SETTINGS = 'SETTINGS',
}

export interface DefinitionParams {
  include?: DefinitionInclude[];
  standardId?: number;
}

export interface PagingParams {
  limit?: number;
  nextPageToken?: string | null;
}

// PageList?
export interface ObjectList<T> {
  data: T[];
  nextPageToken?: string;
  // FIXME: ONLY computed on the first page. ACLs is going to change this count potentially.
  count?: number;
}
