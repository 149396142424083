
import { ActionType } from '@/domain/Action';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ScoreChip extends Vue {
  @Prop({
    required: true,
  })
  score: number; // integer 0-100

  @Prop({
    default: () => [],
  })
  studentActions: ActionType[];

  @Prop({
    type: Boolean,
  })
  includesCorrectAnswer: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  addPaddingToCenter: boolean;

  @Prop({
    type: Boolean,
  })
  isStandardsReportChip: boolean;

  @Prop({
    default: false,
  })
  sawAnswer: boolean;

  @Prop({
    default: false,
  })
  isInTestMode: boolean;

  @Prop({
    default: false,
  })
  isOpenResponse: boolean;

  @Prop({
    type: Boolean,
  })
  round: boolean;

  @Prop() teachleyWorkSubmitted: boolean;

  ActionType = ActionType; // Make Enum values accessible in component logic
  get actionsToDisplay(): ActionType[] {
    const actions = this.studentActions.filter(
      (action) =>
        action == this.ActionType.STUDENT_RESPONSE_ACTION ||
        action == this.ActionType.HINT_REQUESTED_ACTION
    );

    // Removes the last element rather than displaying as having one extra
    // wrong answer which was actually their correct answer submission.
    if (actions.length <= 3 && this.includesCorrectAnswer) {
      return actions.slice(0, -1);
    }

    return actions.slice(0, 3);
  }

  get chipScore(): number {
    if (!this.score || (this.isInTestMode && this.score < 100)) {
      return 0;
    } else {
      return Math.round(this.score);
    }
  }

  /**
   * Set chip color based on score
   * @returns theme color name as string
   */
  get chipColor(): string {
    // Standards report color
    if (this.isStandardsReportChip) {
      return 'primary darken-2';
    }

    // Display blue chip when open response and has a score. TXP-336
    // isOpenResponse only comes from the StudentActionsMenu at the moment
    if (this.isOpenResponse) {
      return 'teacherScored';
    }

    // Perfect Score
    if (this.chipScore >= 100) {
      return 'correct';
    }
    // Correct Eventually (partial credit)
    if (
      !this.sawAnswer &&
      !this.isInTestMode &&
      !this.studentActions.includes(ActionType.IM_STUCK_REQUEST_ACTION)
    ) {
      return 'correctEventually';
    }
    // Incorrect (answer requested or test mode and not right on first try)
    return 'incorrect';
  }

  get liveTutoringActionExists(): boolean {
    return this.studentActions.some(
      (action: string) =>
        action == this.ActionType.LIVE_TUTORING_REQUESTED_ACTION
    );
  }

  mounted() {
    if (this.addPaddingToCenter) {
      this.$nextTick(() => {
        const iconWrapper = this.$refs.iconWrapper as HTMLElement | undefined;

        if (iconWrapper) {
          const iconWidth = iconWrapper.offsetWidth;

          const scoreChipWrapper = this.$refs.scoreChipWrapper as
            | HTMLElement
            | undefined;
          if (scoreChipWrapper) {
            scoreChipWrapper.style.paddingLeft = `${iconWidth}px`;
          }
        }
      });
    }
  }

  openTeachleyDialog() {
    this.$emit('open-teachley-dialog');
  }
}
