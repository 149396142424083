
import { Vue, Component, Prop } from 'vue-property-decorator';
import { SkillDefinition } from '@/domain/Skill';
import { sortBy } from 'lodash';

@Component
export default class StandardSelectionDialog extends Vue {
  @Prop({ required: true }) value: boolean;
  @Prop({ required: true }) assignmentSkills: Set<SkillDefinition>;
  @Prop({ required: true }) allSkills: Map<string, SkillDefinition>;

  // List of ids that corresponds to the checked boxes
  // selected = [];

  get selected(): Array<SkillDefinition> {
    return this.$store.getters['tutor/getSelectedStandards'] || [];
  }

  set selected(selected: Array<SkillDefinition>) {
    this.$store.commit(
      'tutor/setSelectedStandards',
      this.standardOptions.filter((selection) => {
        return selected.some(
          (skill: SkillDefinition) => skill.xref == selection.xref
        );
      })
    );
  }

  // Takes the ids from selected and makes an array with the other relevant data
  get selectedStandards(): Array<SkillDefinition> {
    return this.standardOptions.filter((selection) => {
      return this.selected.some((s) => s.xref == selection.xref);
    });
  }

  get standardOptions(): Array<SkillDefinition> {
    const options: Array<SkillDefinition> = [];
    const checked: Array<SkillDefinition> = [];

    this.assignmentSkills.forEach((skill) => {
      const skillInfo = this.allSkills.get(skill.xref);

      if (skillInfo) {
        // If the option is selected then put in array that will go on top of list
        this.selected.some((s) => s.xref == skill.xref)
          ? checked.push(skillInfo)
          : options.push(skillInfo);
      }
    });

    // Put the two arrays together. Checked ones on top.
    return sortBy(checked, 'displayName').concat(
      sortBy(options, 'displayName')
    );
  }

  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(val: boolean) {
    this.$emit('input', val);
  }

  setSelectedStandards() {
    this.$store.commit('tutor/setSelectedStandards', this.selectedStandards);
    this.showDialog = false;
  }

  updateStoredStandards(index: number): void {
    this.selectedStandards.splice(index, 1);

    this.$store.commit('tutor/setSelectedStandards', this.selectedStandards);
  }
}
