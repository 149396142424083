import { LmsProviderType } from './LmsProviderType';
import { AttributeNames, AttributesMap, IHasAttributes } from './Attributes';
import { Settings } from './Settings';

export interface UpdateUserObject {
  user?: Partial<User>;
  settings?: Partial<Settings>;
  attributes?: UserAttributes;
}

// FIXME: Refactor to support new UserAttributes when possible.
export interface User /* extends IHasAttributes */ {
  xref: string;
  firstName: string;
  lastName: string;
  userName: string;
  displayName: string;
  roles: UserRole[];
  timeZone: string;
  settings?: Settings;
  attributes?: UserAttributes;
  createdAt: number;
}

export interface AuthenticatedUser extends User {
  userToken: string;
  userflowSignature: string;
  loginAliasId: string;
  lmsProviderType: LmsProviderType | null;
}

// export type UserAttributes = Pick<
//   AttributesMap,
//   | AttributeNames.NCES_SCHOOL
//   | AttributeNames.CURRICULUM
//   | AttributeNames.GRADE_LEVEL
//   | AttributeNames.QUICK_COMMENTS_TEACHER
// >;

export type UserAttributes = {
  [AttributeNames.NCES_SCHOOL]?: string[];
  [AttributeNames.CURRICULUM]?: string[];
  [AttributeNames.GRADE_LEVEL]?: string[];
  [AttributeNames.QUICK_COMMENTS_TEACHER]?: boolean[];
};

export enum UserRole {
  SERVICE_ADMIN = 'SERVICE_ADMIN',
  CONTENT_ADMIN = 'CONTENT_ADMIN',
  TRUSTED_BUILDER = 'TRUSTED_BUILDER',
  TEACHER = 'TEACHER',
  MENTOR = 'MENTOR',
  STUDENT = 'STUDENT',
}
