import { FolderDefinition } from '@/domain/Folder';
import Vue from 'vue';
import { MutationTree } from 'vuex';
import { FolderState } from './types';
import { RESET_STATE_MUTATION } from '../types';
import { getDefaultState } from '.';

export const mutations: MutationTree<FolderState> = {
  [RESET_STATE_MUTATION](state): void {
    Object.assign(state, getDefaultState());
  },
  setFolder(state, folder: FolderDefinition): void {
    // Save Folder.
    Vue.set(state.folderMap, folder.xref, {
      ...folder,
    });
  },
  setManualNavigation(state, manualNavigation: boolean): void {
    state.manualNavigation = manualNavigation;
  },
  addMembersToFolder(state, { folderXref, memeberXrefs }): void {
    const folder = state.folderMap[folderXref];
    if (folder) {
      folder.children.unshift(...memeberXrefs);
      Vue.set(state.folderMap, folder.xref, {
        ...folder,
      });
    }
  },
};
