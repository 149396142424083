import { MutationTree } from 'vuex';
import { ProblemDefinition } from '@/domain/Problem';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import { SearchState } from './types';

export const mutations: MutationTree<SearchState> = {
  resetProblemSearch(state: SearchState): void {
    state.combinedSearchResults = [];
    state.numProblems = 0;
    state.problemCount = 0;
    state.problemSetCount = 0;
    state.nextPageToken = null;
    state.problemSetsNextPageToken = null;
    state.skillBuilderNextPageToken = null;
    state.isDownloadingProblems = false;
    state.shouldDownloadMoreProblems = false;
  },
  setCombinedSearchResults(
    state: SearchState,
    newList: (ProblemSetDefinition | ProblemDefinition)[]
  ): void {
    if (state.combinedSearchResults.length === 0) {
      state.combinedSearchResults = newList;
    } else {
      state.combinedSearchResults = [
        ...state.combinedSearchResults,
        ...newList,
      ];
    }
  },
  setProblemCount(state: SearchState, numProblems: number): void {
    state.problemCount = numProblems;
  },
  setProblemSetCount(state: SearchState, numProblems: number): void {
    state.problemSetCount = numProblems;
  },
  setSkillBuilders(state: SearchState, newList: ProblemSetDefinition[]): void {
    state.skillBuilders = newList;
  },
  setIsDownloadingProblems(state: SearchState, value: boolean): void {
    state.isDownloadingProblems = value;
  },
  setNextPageToken(state: SearchState, value: string | null): void {
    state.nextPageToken = value;

    state.shouldDownloadMoreProblems = value ? true : false;
  },
  setSkillBuilderNextPageToken(state: SearchState, value: string | null): void {
    state.skillBuilderNextPageToken = value;

    state.shouldDownloadMoreProblems = value ? true : false;
  },
  setProblemSetsNextPageToken(state: SearchState, value: string | null): void {
    state.problemSetsNextPageToken = value;

    state.shouldDownloadMoreProblems = value ? true : false;
  },
  setShouldDownloadMoreProblems(state: SearchState, value: boolean): void {
    state.shouldDownloadMoreProblems = value;
  },
};
