import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from '@/plugins/vuetify';
import './plugins/axios';
import makeServer from '@/mirage/server';
import '@/mixins/global-auth';
import NotificationPlugin from '@/plugins/notifications';

//Unregister all service workers (hopefully)
try {
  if (window.navigator && navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
  }
} catch (e) {
  //Do nothing
}

// Enable the DevTools if in development
if (process.env.NODE_ENV === 'development') {
  Vue.config.devtools = true;
}

Vue.config.productionTip = false;

if (
  process.env.NODE_ENV === 'development' &&
  process.env.VUE_APP_MIRAGE === 'true'
) {
  makeServer();
}

Vue.use(NotificationPlugin, store);

//https://www.intricatecloud.io/2020/03/how-to-handle-api-errors-in-your-web-app-using-axios/
// they have a section at the bottom about error handling and error reports. We need error reports

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
