
import { Component, Mixins, Watch } from 'vue-property-decorator';
// Components
import UnauthenticatedView from '@/components/base/UnauthenticatedView.vue';
import NotFoundView from '@/components/base/NotFoundView.vue';
import UnauthorizedView from '@/components/Tutor/base/UnauthorizedView.vue';
import NoStudentSelectedView from '@/components/Tutor/TutoringDashboard/NoStudentSelectedView.vue';
import SkillBarChartViewForReport from '@/components/Tutor/TutoringDashboard/SkillBarChartViewForReport.vue';
import DateRangeSelectionDialog from '@/components/Tutor/base/DateRangeSelectionDialog.vue';
import AssignmentSelectionDialog from '@/components/Tutor/base/AssignmentSelectionDialog.vue';
import StandardSelectionDialog from '@/components/Tutor/base/StandardSelectionDialog.vue';
import ViewByFilters from '@/components/Tutor/base/ViewByFilters.vue';
import AssignmentDataTable, {
  CustomTableLabel,
} from '@/components/Tutor/TutoringDashboard/AssignmentDataTable.vue';

// Types
import TutorMixins from '@/mixins/tutor-mixins';
import {
  AssignmentReportType,
  EventType,
  trackMixpanel,
} from '@/plugins/mixpanel';
import { CustomDataSetLabel } from '@/components/Report/BarChartView.vue';
import { StudentAndPeerDataPerSkill } from '@/domain/ReportData/Tutor';
import { TimeFrame } from '@/domain/Time';
import { User } from '@/domain/User';
import { SkillDefinition } from '@/domain/Skill';
import { AssignmentDefinition } from '@/domain/Assignment';
import { StudentData } from '@/domain/ReportData/AssignmentData';

import { getStudentAndPeerDataPerSkill } from '@/api/core/sdata.api';

interface CustomQueryParams {
  tutees: string[];
}

@Component({
  components: {
    UnauthenticatedView,
    NotFoundView,
    UnauthorizedView,
    NoStudentSelectedView,
    SkillBarChartViewForReport,
    AssignmentDataTable,
    DateRangeSelectionDialog,
    AssignmentSelectionDialog,
    StandardSelectionDialog,
    ViewByFilters,
  },
})
export default class TutorReportPage extends Mixins(TutorMixins) {
  unauthorized = false;
  skillData: Array<StudentAndPeerDataPerSkill> = [];
  tuteeXrefs: Array<string> = [];
  currentUserXref = this.$router.app.getCurrentUser.xref;
  currentTab = 0;

  /////////////////////////////
  // No data found scenarios //
  /////////////////////////////
  noSkillDataFound = false;

  get noDataFoundWhatsoever(): boolean {
    return this.noSkillDataFound && this.noAssignmentDataFound;
  }

  ////////////////////
  // Loading States //
  ////////////////////
  // Store Data Loading
  get isDownloadingSkills(): boolean {
    return this.$store.state.skillList.isDownloading;
  }
  get isDownloadingTutees(): boolean {
    return this.$store.state.tutor.isDownloadingTutees;
  }
  get isDownloadingStudentAssignments(): boolean {
    return this.$store.state.tutor.isDownloadingStudentAssignments;
  }
  get hasDownloadedStudentAssignments(): boolean {
    return this.$store.state.tutor.hasDownloadedStudentAssignments;
  }
  get isDownloadingAssignmentReports(): boolean {
    return this.$store.state.tutor.isDownloadingAssignmentReports;
  }
  get hasDownloadedAssignmentReports(): boolean {
    return this.$store.state.tutor.hasDownloadedAssignmentReports;
  }
  get noAssignmentDataFound(): boolean {
    return this.$store.state.tutor.noAssignmentDataFound;
  }

  // Page Data Loading
  isDownloadingSkillData = false;
  hasDownloadedSkillData = false;

  /**
   * @returns selected time frame from component
   */
  get weekSelected(): TimeFrame | null {
    return this.$store.getters['tutor/getSelectedTimeFrame'];
  }

  /**
   * @returns full list of tutees for current user (from store)
   */
  get tutees(): Array<User> {
    return this.$store.state.tutor.tutees;
  }

  get noTuteeSelected(): boolean {
    return this.filteredTuteeXrefs.length === 0;
  }

  /**
   * @returns filtered tuteeXrefs list
   */
  get filteredTuteeXrefs(): Array<string> {
    return this.tuteeXrefs.filter((xref) => xref);
  }

  ////////////////
  // Chart Data //
  ////////////////

  get skills(): Map<string, SkillDefinition> {
    return this.$store.getters['skillList/getSkillsMap'];
  }

  get assignmentSkills(): Set<SkillDefinition> {
    const foundSkills: Set<SkillDefinition> = new Set();

    if (this.assignmentDefinitions.length > 0) {
      this.assignmentDefinitions.forEach((definition) => {
        if (definition.skills.length > 0) {
          definition.skills.forEach((defSkillXref) => {
            const skillDef = this.skills.get(defSkillXref);
            if (skillDef) {
              foundSkills.add(skillDef);
            }
          });
        }
      });
    }

    return foundSkills;
  }

  ////////////////
  // Table Data //
  ////////////////
  getTuteeInitials(xref: string): string | null {
    const tutee = this.tutees.find((t) => t.xref === xref);
    if (!tutee) {
      return null;
    } else {
      return `${tutee.firstName.charAt(0)}${tutee.lastName.charAt(0)}`;
    }
  }

  get customTableLabels(): Array<CustomTableLabel> {
    return this.filteredTuteeXrefs.map((xref, i) => ({
      label: this.getTuteeInitials(xref) as string,
      value: xref,
      backgroundColor: this.labelColors[i],
      textColor: this.textColors[i],
    }));
  }

  get customChartLabels(): Array<CustomDataSetLabel> {
    return this.filteredTuteeXrefs.map((xref, i) => ({
      xref,
      label: this.tutees.find((t) => t.xref === xref)?.displayName as string,
      backgroundColor: this.labelColors[i],
    }));
  }

  get assignmentReportData(): Map<string, StudentData> {
    return this.$store.state.tutor.assignmentReportData;
  }
  get assignmentDefinitions(): AssignmentDefinition[] {
    return this.$store.state.tutor.assignmentDefinitions;
  }

  /////////////////
  // Dialog Data //
  /////////////////
  showDateRangeSelectDialog = false;
  showAssignmentSelectionDialog = false;
  showStandardSelectionDialog = false;

  get assignmentSelectionXrefs(): Array<string> {
    return this.$store.getters['tutor/getSelectedAssignments'].map(
      (selection: AssignmentDefinition) => {
        return selection.xref;
      }
    );
  }

  get assignmentSelectionSkillXrefs(): Array<string> {
    return this.$store.getters['tutor/getSelectedAssignments']
      .map((selection: AssignmentDefinition) => {
        return selection.skills;
      })
      .flat();
  }

  get standardSelectionSkillXrefs(): Array<string> {
    return this.$store.getters['tutor/getSelectedStandards'].map(
      (selection: SkillDefinition) => {
        return selection.xref;
      }
    );
  }

  get hasAssignmentFilter(): boolean {
    return this.assignmentSelectionXrefs.length > 0;
  }

  get hasStandardFilter(): boolean {
    return this.standardSelectionSkillXrefs.length > 0;
  }

  get hasFilterSelection(): boolean {
    return this.hasAssignmentFilter || this.hasStandardFilter;
  }

  get filteredAssignments(): AssignmentDefinition[] {
    const filtered = [];

    if (this.hasFilterSelection) {
      for (const definition of this.assignmentDefinitions) {
        // Filters should skip the entry when they don't match the filter, otherwise add it to the list
        if (
          this.hasAssignmentFilter &&
          !this.assignmentSelectionXrefs.includes(definition.xref)
        ) {
          continue;
        }

        if (
          this.hasStandardFilter &&
          !definition.skills?.some((assignmentSkillXref) => {
            return this.standardSelectionSkillXrefs.includes(
              assignmentSkillXref
            );
          })
        ) {
          continue;
        }

        filtered.push(definition);
      }
    }

    return this.hasFilterSelection ? filtered : this.assignmentDefinitions;
  }

  // Skill data aka the chart data can be filtered down to certain skills only
  // When an assignment is selected as a filter, it should reduce down to only skills in that assignment
  // If skills aka standards are selected, it should reduce down to only display those skills
  get filteredSkillData(): StudentAndPeerDataPerSkill[] {
    const filteredChartData: StudentAndPeerDataPerSkill[] = [];

    if (this.hasFilterSelection) {
      this.skillData.forEach((skill: StudentAndPeerDataPerSkill) => {
        if (
          this.hasAssignmentFilter &&
          !this.assignmentSelectionSkillXrefs.includes(skill.skillXref)
        ) {
          return;
        }

        if (
          this.hasStandardFilter &&
          !this.standardSelectionSkillXrefs.includes(skill.skillXref)
        ) {
          return;
        }

        filteredChartData.push(skill);
      });
    }

    if (this.hasFilterSelection && filteredChartData.length === 0) {
      this.noSkillDataFound = true;
    } else {
      this.noSkillDataFound = false;
    }

    return this.hasFilterSelection ? filteredChartData : this.skillData;
  }

  ////////////////////
  // Initialization //
  ////////////////////
  updateQueryParam(queryParams: Partial<CustomQueryParams>): void {
    // Update the URL
    this.$router
      .replace({
        name: 'tutorReport',
        query: {
          ...this.$route.query,
          ...queryParams,
        },
      })
      .catch((error) => {
        // Ignore the vuex error regarding navigating to the page you are already on.
        if (
          error.name !== 'NavigationDuplicated' &&
          !error.message.includes(
            'Avoided redundant navigation to current location'
          )
        ) {
          // But handle any other errors the usual way
          error.handleGlobally && error.handleGlobally();
        }
      });
  }

  //to be called when tutees have changed
  @Watch('tuteeXrefs')
  onTuteeChange(): void {
    this.updateQueryParam({
      tutees: this.filteredTuteeXrefs,
    });
    this.$store.commit('tutor/setSelectedTuteeXrefs', this.filteredTuteeXrefs);
    if (!this.noTuteeSelected) {
      this.downloadData();
      this.tutorReportClicked(
        this.tuteeXrefs[0],
        this.tuteeXrefs[1],
        this.tuteeXrefs[2],
        this.tuteeXrefs[3]
      );
    }
  }

  // Watchers
  @Watch('weekSelected')
  onWeekChange(): void {
    if (!this.noTuteeSelected) {
      this.downloadData();
    }
  }

  // Download helpers
  initializeSelectedTutees(): void {
    // Get tutee xrefs from the query params
    if (this.$route.query.tutees?.length > 0) {
      this.tuteeXrefs = (
        Array.isArray(this.$route.query.tutees)
          ? this.$route.query.tutees
          : [this.$route.query.tutees]
      ) as string[];
    }
    if (!this.noTuteeSelected) {
      this.downloadData();
    }
  }

  onViewByChanged(newViewBy: string) {
    if (newViewBy === 'Date') {
      this.showDateRangeSelectDialog = true;
    } else if (newViewBy === 'Assignment') {
      this.showAssignmentSelectionDialog = true;
    } else if (newViewBy === 'Standard') {
      this.showStandardSelectionDialog = true;
    }
  }

  download(promises: Array<Promise<void>>): Promise<void> {
    return Promise.all(promises)
      .then(() => {
        return;
      })
      .catch((error) => {
        // Any errors
        if (error.response.status === 403) {
          // FIXME: Clear data?
          this.unauthorized = true;
        }
        return Promise.reject(error);
      });
  }

  async downloadStudentData() {
    if (
      this.weekSelected &&
      !this.isDownloadingStudentAssignments &&
      !this.isDownloadingAssignmentReports
    ) {
      this.$store.dispatch('tutor/requestAssignmentDefinitions', {
        tuteeXrefs: this.tuteeXrefs,
        currentUserXref: this.currentUserXref,
        weekSelected: this.weekSelected,
      });
    }
  }

  downloadSkillData(): Promise<void> {
    if (this.weekSelected && !this.isDownloadingSkillData) {
      this.isDownloadingSkillData = true;
      this.hasDownloadedSkillData = false;
      this.noSkillDataFound = false;

      const promise = getStudentAndPeerDataPerSkill(
        this.filteredTuteeXrefs,
        this.weekSelected.startDateTime,
        this.weekSelected.endDateTime
      )
        .then((skillData) => {
          this.skillData = skillData;
        })
        .catch((error) => {
          this.noSkillDataFound = true;
          return Promise.reject(error);
        })
        .finally(() => {
          this.hasDownloadedSkillData = true;
          this.isDownloadingSkillData = false;
        });

      return promise;
    }

    return Promise.reject();
  }

  async downloadData(): Promise<void> {
    if (this.filteredTuteeXrefs.length > 0 && this.weekSelected) {
      this.download([
        this.downloadSkillData(),
        this.downloadStudentData(),
      ]).catch(() => {
        this.isDownloadingSkillData = false;
        this.$store.commit('tutor/setIsDownloadingAssignmentReports', false);
      });
    }
  }

  created(): void {
    this.initializeSelectedTutees();
  }

  tutorReportClicked(
    s1Xref: string,
    s2Xref: string,
    s3Xref: string,
    s4xref: string
  ): void {
    trackMixpanel(EventType.reportViewed, {
      reportType: AssignmentReportType.tutorReport,
      student1Xref: s1Xref,
      student2Xref: s2Xref,
      student3Xref: s3Xref,
      student4Xref: s4xref,
    });
  }
}
