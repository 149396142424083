
import { ProblemSetDefinition, ProblemSetType } from '@/domain/ProblemSet';
import {
  ProblemSetTypeItem,
  getProblemSetTypeItems,
} from '@/utils/problemSet.util';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class CreateProblemSetDialog extends Vue {
  @Prop() value: boolean;

  problemSetTypes = Object.values(ProblemSetType);

  dialogStepper = 1;

  loading = false;

  psInitial: Pick<ProblemSetDefinition, 'name' | 'problemSetType'> &
    Partial<ProblemSetDefinition> = {
    name: '',
    problemSetType: ProblemSetType.LINEAR_COMPLETE_ALL,
  };

  psLocal = { ...this.psInitial };

  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(val: boolean) {
    this.$emit('input', val);
  }

  get problemSetTypesList(): ProblemSetTypeItem[] {
    return getProblemSetTypeItems(this.problemSetTypes);
  }

  get canSave(): boolean {
    return !(this.psLocal.name.length !== 0 && !!this.psLocal.problemSetType);
  }

  setupFinished(): void {
    this.loading = true;
    this.$store
      .dispatch('content/saveProblemSet', {
        modifiedFields: { ...this.psLocal },
      })
      .then(({ ceri: xref, failMessages: error }) => {
        if (error) {
          this.$notify(`Failed to create Problem Set: ${error}`);
        } else if (xref) {
          this.dialogStepper = 1;
          this.showDialog = false;
          this.$emit('created', xref);
        }
        this.loading = false;
      })
      .catch(() => {
        this.$notify('Something went wrong. Failed to create Problem Set.');
        this.loading = false;
      });
  }

  @Watch('showDialog')
  resetState(): void {
    this.dialogStepper = 1;
    this.loading = false;
    this.psLocal = { ...this.psInitial };
  }
}
