
import { QuickComment, QuickCommentFeedback } from '@/domain/QuickComment';
import { User } from '@/domain/User';
import { appendGlobalHeaderOptions } from '@/utils/dataTables.utils';
import { isEqual, orderBy } from 'lodash';
import { shuffle } from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DataOptions, DataTableHeader } from 'vuetify';
import EssayScoringDialog from './EssayScoringDialog.vue';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';
import QuickCommentsStatus from '../QuickCommentsStatus.vue';
import {
  ProblemLogForReport,
  QCStatus,
} from '@/views/MyAssignments/EssayScoringPage.vue';
import { PartLogData } from '@/domain/ReportData/AssignmentData';

enum StaticHeaders {
  ASSIGNEE = 'ASSIGNEE',
  RESPONSE = 'RESPONSE',
  SCORE = 'SCORE',
  TEACHER_COMMENT = 'TEACHER_COMMENT',
  CUSTOM_SORT = 'CUSTOM_SORT',
}

interface EssayScoringTableRow {
  prLogDbid: number;
  [StaticHeaders.ASSIGNEE]: User;
  [StaticHeaders.RESPONSE]: string;
  [StaticHeaders.SCORE]: number;
  [StaticHeaders.TEACHER_COMMENT]: string;
  [StaticHeaders.CUSTOM_SORT]: number;
  suggestedScore?: number;
  feedbacks?: QuickCommentFeedback[];
}

@Component({
  components: {
    EssayScoringDialog,
    QuickCommentsStatus,
  },
})
export default class EssayScoringTable extends Vue {
  @Prop({ required: true }) problemLogs: ProblemLogForReport[];
  @Prop({ required: true }) assignees: User[];
  @Prop({ default: () => [] }) quickComments: QuickComment[];
  @Prop({ default: () => QCStatus.NONE }) quickCommentsStatus: QCStatus;
  @Prop({ required: false, default: 0 }) updating: number;

  StaticHeaders = StaticHeaders;

  hideNames = false;
  showScoringDialog = false;

  availableScores: number[] = [0, 1, 2, 3, 4];

  get assigneeMap(): Record<string, User> {
    const assigneeMap: Record<string, User> = {};
    for (const assignee of this.assignees) {
      assigneeMap[assignee.xref] = assignee;
    }
    return assigneeMap;
  }

  get quickCommentsMap(): Record<number, QuickComment> {
    const res: Record<number, QuickComment> = {};
    for (const quickComment of this.quickComments) {
      res[quickComment.problemLogId] = quickComment;
    }
    return res;
  }

  get items(): EssayScoringTableRow[] {
    const rows: EssayScoringTableRow[] = [];
    for (const prLog of this.problemLogs) {
      const { assignee, ...rest } = prLog;
      let continuousScore = rest.continuousScore * 4;
      if (
        typeof rest.continuousScore === 'number' &&
        !this.availableScores.includes(continuousScore)
      ) {
        // Invalid score.
        continuousScore = -1;
      }
      let feedbacks: QuickCommentFeedback[] | undefined = undefined;
      let suggestedScore: number | undefined = undefined;
      // FIXME: Figure out if/when we want to support multiple Open Response Parts?
      // Will the response list always be of size 1?
      const answerText = rest.partLogData?.[1].response[0];
      if (answerText) {
        const quickComment = this.quickCommentsMap[rest.id];
        if (quickComment?.scoreIsAccurate) {
          suggestedScore = quickComment?.suggestedScore;
        }
        if (quickComment?.commentIsAccurate) {
          if (continuousScore != -1) {
            feedbacks = quickComment?.comments.filter(
              (qc) => qc.score == continuousScore
            );
          }
          if (!feedbacks?.length) {
            feedbacks = quickComment?.comments.filter(
              // (qc) => qc.score == quickComment?.suggestedScore
              (qc) => qc.score == suggestedScore
            );
          }
        }
      }
      const row = {
        prLogDbid: rest.id,
        [StaticHeaders.ASSIGNEE]: this.assigneeMap[assignee],
        [StaticHeaders.RESPONSE]: answerText,
        [StaticHeaders.SCORE]: continuousScore,
        // FIXME: Figure out if/when we want to support multiple Open Response Parts?
        [StaticHeaders.TEACHER_COMMENT]: rest.partLogData?.[1].teacherComment,
        [StaticHeaders.CUSTOM_SORT]: 0,
        suggestedScore,
        feedbacks,
      };
      rows.push(row);
    }

    return rows;
  }

  get itemsRows(): EssayScoringTableRow[] {
    if (this.hideNames) {
      // Shuffle rows.
      return shuffle(this.items);
    } else {
      // Otherwise sort alphabetically by last, first.
      return orderBy(
        this.items,
        [
          (row) => {
            const assignee = row[StaticHeaders.ASSIGNEE];
            return assignee.lastName;
          },
          (row) => {
            const assignee = row[StaticHeaders.ASSIGNEE];
            return assignee.firstName;
          },
        ],
        ['asc', 'asc']
      );
    }
  }

  get headers(): DataTableHeader[] {
    const defaults: Partial<DataTableHeader> = {
      align: 'center',
      class: ['text-no-wrap', 'sticky-row', 'sticky-row-1', 'text-subtitle-2'],
      sortable: true,
    };
    const staticHeaders: DataTableHeader[] = [
      {
        ...defaults,
        text: 'Student',
        value: StaticHeaders.ASSIGNEE,
        align: 'start',
      },
      {
        ...defaults,
        text: 'Response',
        value: StaticHeaders.RESPONSE,
        cellClass: ['markup-cell'],
        sortable: false,
      },
      {
        ...defaults,
        text: 'Score',
        value: StaticHeaders.SCORE,
      },
      {
        ...defaults,
        text: 'Teacher Comment',
        value: StaticHeaders.TEACHER_COMMENT,
        sortable: false,
      },
      {
        ...defaults,
        text: 'Custom Sort',
        value: StaticHeaders.CUSTOM_SORT,
        sort: (a: number, b: number): number => (a > b ? 1 : -1),
      },
    ];
    return staticHeaders.map(appendGlobalHeaderOptions);
  }

  toggleNames(): void {
    this.hideNames = !this.hideNames;
    this.logReportSettings(
      this.$route.params.xref,
      // this.getCurrentUser.xref,
      this.$route.params.problemXref,
      this.hideNames
    );
  }

  openResponseDialog(e: MouseEvent): void {
    if (e.target instanceof HTMLImageElement) {
      const src = e.target.currentSrc;
      this.$emit('open-response', `<img src=${src} />`);
    } else {
      let el = e.target as HTMLElement;
      do {
        el = el.parentElement as HTMLElement;
      } while (!el.classList.contains('answer-text'));
      const answerText = el.innerHTML;
      this.$emit('open-response', answerText);
    }
  }

  created(): void {
    // Default to setting.
    this.hideNames =
      this.$store.state.auth.user.settings.anonymizeReportsByDefault ?? false;
  }

  mounted(): void {
    window.com.wiris.js.JsPluginViewer.parseDocument();
    this.logReportSettings(
      this.$route.params.xref,
      // this.getCurrentUser.xref,
      this.$route.params.problemXref,
      this.hideNames
    );
  }

  updateScore(item: EssayScoringTableRow, score: number): void {
    const continuousScore = score / 4;
    const modifiedFields = { 1: { continuousScore } };
    this.$emit('update-part-log', {
      id: item.prLogDbid,
      modifiedFields,
    });
    trackMixpanel(EventType.qcScoreSelection, {
      problemLogId: item.prLogDbid,
      assignmentXref: this.$route.params.xref,
      studentXref: item[StaticHeaders.ASSIGNEE].xref,
      suggestedScore: item.suggestedScore,
      teacherScore: continuousScore,
    });
    // Mixpanel tracking for any update
    this.trackEssayScoringUpdate(item, modifiedFields);
  }

  updateComment(
    item: EssayScoringTableRow,
    comment: string,
    qcIndex?: number
  ): void {
    if (comment && !isEqual(item[StaticHeaders.TEACHER_COMMENT], comment)) {
      const modifiedFields = { 1: { teacherComment: comment } };
      this.$emit('update-part-log', {
        id: item.prLogDbid,
        modifiedFields,
      });
      if (typeof qcIndex === 'number') {
        trackMixpanel(EventType.qcCommentSelection, {
          problemLogId: item.prLogDbid,
          assignmentXref: this.$route.params.xref,
          studentXref: item[StaticHeaders.ASSIGNEE].xref,
          selectedCommentIndex: qcIndex,
          selectedComment: comment,
        });
      }
      // Mixpanel tracking for any update
      this.trackEssayScoringUpdate(item, modifiedFields, qcIndex);
    }
  }

  onOptionsUpdate(options: DataOptions): void {
    this.$nextTick(() => {
      window.com.wiris.js.JsPluginViewer.parseDocument();
    });
    this.trackSortChange(options);
  }

  //////////////////
  /// Mixpanel
  /////////////////

  logReportSettings(
    assignmentXref: string,
    problemId: string,
    anonymous: boolean
  ): void {
    trackMixpanel(EventType.essayScoringSetting, {
      assignmentXref: assignmentXref,
      problemId: problemId,
      anonymous: anonymous,
    });
  }

  @Watch('showScoringDialog')
  trackScoringDialogOpen(newVal: boolean, oldVal: boolean): void {
    if (newVal !== oldVal) {
      trackMixpanel(EventType.essayScoringDialogOpen, {
        assignmentXref: this.$route.params.xref,
        problemCode: this.$route.params.problemXref,
        showDialog: newVal,
      });
    }
  }

  @Watch('hideNames')
  trackHideNames(newVal: boolean, oldVal: boolean): void {
    if (newVal !== oldVal) {
      trackMixpanel(EventType.essayScoringHideNames, {
        assignmentXref: this.$route.params.xref,
        problemCode: this.$route.params.problemXref,
        hideNames: newVal,
      });
    }
  }

  trackEssayScoringUpdate(
    item: EssayScoringTableRow,
    modifiedFields: Record<
      number,
      Partial<Pick<PartLogData, 'continuousScore' | 'teacherComment'>>
    >,
    qcIndex?: number
  ) {
    const quickComment = this.quickCommentsMap[item.prLogDbid];

    let teacherScore = null;
    if (typeof modifiedFields[1].continuousScore === 'number') {
      teacherScore = modifiedFields[1].continuousScore * 4;
    } else if (typeof item[StaticHeaders.SCORE] === 'number') {
      teacherScore = item[StaticHeaders.SCORE] / 4;
    }

    const trackingData = {
      assignmentXref: this.$route.params.xref,
      studentXref: item[StaticHeaders.ASSIGNEE].xref,
      problemCode: this.$route.params.problemXref,
      problemLogId: item.prLogDbid,
      continuousScore: modifiedFields[1].continuousScore,
      suggestedScore: item.suggestedScore,
      selectedCommentIndex: qcIndex,
      quickComments:
        typeof qcIndex === 'number'
          ? item.feedbacks?.[qcIndex].text
          : undefined,
      teacherComment: modifiedFields[1].teacherComment,
      selectedComment:
        typeof qcIndex === 'number' ? item.feedbacks?.[qcIndex] : undefined,
      teacherScore,
      qcCommentShown: quickComment?.commentIsAccurate,
      qcGradeShown: quickComment?.scoreIsAccurate,
      updateScore: typeof modifiedFields[1].continuousScore === 'number',
      updateComment: typeof modifiedFields[1].teacherComment === 'string',
    };

    trackMixpanel(EventType.essayScoringUpdate, trackingData);
  }

  trackSortChange(options: DataOptions): void {
    const sortByCol = options.sortBy[0]; // assuming single-column sort
    const sortByDesc = options.sortDesc[0]; // true for descending, false for ascending

    // Condition a: if sortByCol is NOT undefined, return existing values
    if (sortByCol !== undefined) {
      trackMixpanel(EventType.essayScoringSortOrder, {
        assignmentXref: this.$route.params.xref,
        problemCode: this.$route.params.problemXref,
        sortedByCol: sortByCol,
        sortedByColOrder: sortByDesc ? 'descending' : 'ascending',
      });
    }

    // Condition b: if sortByCol is undefined and sortByDesc is undefined,
    // return sortByCol is default and sortByCol is reset
    else if (sortByCol === undefined && sortByDesc === undefined) {
      trackMixpanel(EventType.essayScoringSortOrder, {
        assignmentXref: this.$route.params.xref,
        problemCode: this.$route.params.problemXref,
        sortedByCol: 'default',
        sortedByColOrder: 'reset',
      });
    }
    // Condition c: if sortByCol is undefined and sortByDesc is NOT undefined,
    // do not trigger mixpanel event
    // This condition is handled implicitly by not having an else/if statement for this case
  }
}
