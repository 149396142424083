
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class CopyTextDialog extends Vue {
  @Prop({}) title: string;
  @Prop({}) description: string;
  @Prop({}) content: string;

  dialog = false;

  copyContent(): void {
    navigator.clipboard.writeText(this.content);
    this.$notify('Copied to clipboard!');
    this.dialog = false;
  }
}
