
import { getProblem } from '@/api/core/content.api';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class FindProblemDialog extends Vue {
  @Prop() value: boolean;

  prXref = '';
  loading = false;
  notFound = false;

  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(val: boolean) {
    this.$emit('input', val);
  }

  get errorMessage(): string | null {
    return this.notFound ? 'Problem ID not found' : null;
  }

  getProblem(): void {
    if (this.prXref) {
      this.loading = true;
      this.notFound = false;
      getProblem(this.prXref)
        .then((problem) => {
          this.$emit('found', problem);
          this.showDialog = false;
        })
        .catch(() => {
          // Problem not found.
          this.notFound = true;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  @Watch('prXref')
  onXref(): void {
    // Clear prior search results.
    this.notFound = false;
  }

  @Watch('showDialog')
  onDialog(): void {
    if (!this.loading) {
      // Clear prior search CERI.
      this.prXref = '';
    }
  }
}
