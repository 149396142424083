
import {
  copyFolder,
  CopyPermissionMode,
  EditableFolderFields,
  FolderOperationParams,
  getFolder,
  getFolderStats,
} from '@/api/core/folders.api';
import { AttributeNames } from '@/domain/Attributes';
import { FolderDefinition, FolderStats } from '@/domain/Folder';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import FolderSelector, { Selection } from './FolderSelector.vue';
import { cloneDeep } from 'lodash';
import { getPathParam } from '@/utils/navigation.util';
import { DefinitionInclude } from '@/api/core/base.api';
import { GroupChildDepthType } from '@/domain/Group';

@Component({
  components: { FolderSelector },
})
export default class CopyFolderDialog extends Vue {
  @Prop({ required: true }) path: FolderDefinition[];

  CopyPermissionMode = CopyPermissionMode;

  copyPermissionTypes: {
    mode: CopyPermissionMode;
    text: string;
    description: string;
  }[] = [
    {
      mode: CopyPermissionMode.CURRENT,
      text: 'COPY CURRENT PERMISSIONS',
      description:
        'This option will copy and adhere to the existing permissions on this folder regardless of the destination. ',
    },
    {
      mode: CopyPermissionMode.INHERIT,
      text: 'COPY DESTINATION PERMISSIONS',
      description:
        'This option will wipe the existing permissions on this folder in order to copy the new destination’s permissions.',
    },
    {
      mode: CopyPermissionMode.NONE,
      text: 'DO NOT COPY ANY PERMISSIONS',
      description:
        'This option wipes this folder from all permissions so only you have permission. ',
    },
  ];

  stats: FolderStats | null = null;

  name = '';
  header = '';

  dialog = false;
  copying = false;
  confirming = false;
  downloading = false;

  folderSettings = false;
  insightsHubSettings = false;
  attributions = false;

  copyPermissionMode: CopyPermissionMode | null = null;

  // FIXME: Figure out if/how we want to handle Insights Hub level.
  // selection: Selection = { wipeAllLevels: false, path: null };
  selection: Selection = { wipeAllLevels: true, path: null };

  confirmed = false;

  get folderMap(): Record<string, FolderDefinition> {
    return this.$store.state.folder.folderMap;
  }

  get targetFolder(): FolderDefinition {
    return this.path[this.path.length - 1];
  }

  get filePath(): string {
    const names = this.path.map((folder) => folder.name);
    // Exclude target.
    names.pop();
    return names.join(' / ');
  }

  get sourceOverride(): EditableFolderFields {
    const fields: EditableFolderFields = {};
    if (this.name) {
      fields.name = this.name;
    }
    if (this.header) {
      fields.properties = { HEADER: this.header };
    }
    return fields;
  }

  get keepParams(): FolderOperationParams {
    const params: FolderOperationParams = { keep: { attributes: [] } };
    if (this.insightsHubSettings) {
      params.keep?.attributes?.push(
        AttributeNames.CURRICULUM,
        AttributeNames.GRADE_LEVEL
      );
      if (!this.selection.wipeAllLevels) {
        params.keep?.attributes?.push(AttributeNames.INSIGHTS_HUB_LEVEL);
      }
    }
    if (this.attributions) {
      params.keep?.attributes?.push(AttributeNames.ATTRIBUTION);
    }
    return params;
  }

  save(): void {
    if (this.targetFolder && this.selection.path && this.copyPermissionMode) {
      const path = cloneDeep(this.selection.path);
      const destination = path[path.length - 1];
      this.copying = true;
      copyFolder(
        this.targetFolder.xref,
        destination.xref,
        this.copyPermissionMode,
        this.sourceOverride,
        this.folderSettings,
        this.keepParams
      )
        .then((xref: string) => {
          let promise = Promise.resolve();
          if (destination?.children.length) {
            // New member added to previously downloaded members.
            promise = getFolder(xref, [DefinitionInclude.ATTRIBUTES]).then(
              (folder: FolderDefinition) => {
                this.$store.commit('folder/setFolder', folder);
                this.$store.commit('folder/setFolder', {
                  ...destination,
                  children: [xref, ...destination.children],
                });
              }
            );
          }
          return promise.then(() => {
            // Update the URL.
            this.$router.replace({
              query: {
                ...this.$route.query,
                p: getPathParam([...path, { xref } as FolderDefinition]),
              },
            });
            this.dialog = false;
          });
        })
        .catch(() => {
          this.$notify('Failed to copy folder.');
        })
        .finally(() => {
          this.copying = false;
        });
    }
  }

  initialize(): void {
    // Reset fields to target folder.
    this.name = `Copy of ${this.targetFolder?.name ?? ''}`;
    this.header = this.targetFolder?.properties?.HEADER ?? '';
    // Clear prior selections.
    this.insightsHubSettings = false;
    this.attributions = false;
    this.copyPermissionMode = null;
    // FIXME: Figure out if/how we want to handle Insights Hub level.
    // this.selection = { wipeAllLevels: false, path: null };
    this.selection = { wipeAllLevels: true, path: null };
    // Reset this to default.
    this.folderSettings = true;
    // Reset state.
    this.confirming = false;
    this.confirmed = false;
  }

  @Watch('dialog')
  onDialog(): void {
    if (this.dialog) {
      this.initialize();
      if (!this.downloading && this.targetFolder) {
        this.downloading = true;
        getFolderStats(this.targetFolder.xref, GroupChildDepthType.ANY_CHILD)
          .then((stats: FolderStats) => {
            this.stats = stats;
          })
          .finally(() => {
            this.downloading = false;
          });
      }
    }
  }
}
