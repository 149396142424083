
import { Component, Vue, Watch } from 'vue-property-decorator';
import SearchResultsTopBar from '@/components/FindProblems/SearchResults/SearchResultsTopBar.vue';
import axios, { CancelTokenSource } from 'axios';
import { SelectedFilter } from './SearchResultsProblems.vue';
import {
  ProblemSetsFilterParams,
  ProblemsFilterParams,
} from '@/api/core/content.api';
import {
  DefinitionInclude,
  DefinitionParams,
  PagingParams,
} from '@/api/core/base.api';
import { ProblemSetType } from '@/domain/ProblemSet';
import { ProblemDefinition } from '@/domain/Problem';

@Component({
  components: {
    SearchResultsTopBar,
  },
})
export default class SearchResults extends Vue {
  downloading = false;

  // Filtering/paging
  limit = 20;
  source: CancelTokenSource | null = null;
  extraFields = [
    'ANSWERS',
    'PARENT_PROBLEM_SETS',
    'CURRICULA',
    'ATTRIBUTIONS',
    'SKILLS',
  ];

  selectedFilter: SelectedFilter = { curricula: [], problemType: [] };

  get nextPageToken(): string | null {
    return this.$store.state.search.nextPageToken;
  }

  get skillBuilderNextPageToken(): string | null {
    return this.$store.state.search.skillBuilderNextPageToken;
  }

  get filterParams(): ProblemsFilterParams {
    const params: ProblemsFilterParams = {
      skills: [this.selectedSkillXref],
      isTextbook: false,
      isResearch: false,
      isCertified: true,
      curricula: this.selectedFilter.curricula,
      problemSetTypes: [
        ProblemSetType.LINEAR_COMPLETE_ALL,
        ProblemSetType.RANDOM_COMPLETE_ALL,
        ProblemSetType.STUDENT_CHOICE,
        ProblemSetType.IF_THEN_ELSE,
        ProblemSetType.RANDOM_COMPLETE_ONE,
      ],
      types: this.selectedFilter.problemType,
    };
    return params;
  }

  get skillBuilderFilterParams(): ProblemSetsFilterParams {
    const params: ProblemSetsFilterParams = {
      skills: [this.selectedSkillXref],
      isResearch: false,
      isCertified: true,
      types: [
        ProblemSetType.SKILL_BUILDER_RANDOM,
        ProblemSetType.SKILL_BUILDER_LINEAR,
      ],
    };
    return params;
  }

  get pagingParams(): PagingParams {
    return { limit: this.limit, nextPageToken: this.nextPageToken };
  }

  get skillBuilderPagingParams(): PagingParams {
    return { limit: this.limit, nextPageToken: this.skillBuilderNextPageToken };
  }

  get definitionParams(): DefinitionParams {
    return { include: [DefinitionInclude.ATTRIBUTES] };
  }

  get selectedSkillXref(): string {
    const skillXref = this.$store.state.skillList.selectedSkillXref;
    return skillXref;
  }

  reset(): void {
    if (this.source) {
      // Cancel prior requests with this cancel token
      this.source.cancel();
    }

    // Clear out prior results
    this.$store.commit('search/resetProblemSearch');

    this.source = axios.CancelToken.source();
    this.downloading = true;
  }

  created(): void {
    this.downloadData();
  }

  @Watch('$route.params.skillXref')
  onSkillXrefChange(value: string, oldValue: string): void {
    if (value === oldValue) {
      return;
    }
    this.reset();
    this.downloadData();
  }

  requestProblemsBySkill() {
    let psXrefs: string[] = [];

    this.$store.commit('search/setIsDownloadingProblems', true);

    return this.$store
      .dispatch('search/searchProblemsBySkill', {
        filterParams: this.filterParams,
        pagingParams: this.pagingParams,
        definitionParams: this.definitionParams,
        cancelToken: this.source?.token,
      })
      .then((problemSetXrefs) => {
        psXrefs = problemSetXrefs;
        const promises: Promise<ProblemDefinition>[] = [];
        problemSetXrefs.forEach((xref: string) => {
          promises.push(
            this.$store.dispatch('content/getProblemSetTree', {
              xref: xref,
            })
          );
        });
        return Promise.all(promises);
      })
      .finally(() => {
        const problemSetMap = this.$store.state.content.problemSetMap;

        const problemSets = psXrefs.map((xref) => {
          return problemSetMap[xref];
        });

        this.$store.commit('search/setCombinedSearchResults', problemSets);
        this.$store.commit('search/setIsDownloadingProblems', false);
        this.downloading = false;
      });
  }

  requestSkillBuilders() {
    return this.$store.dispatch('search/requestSkillBuildersBySkill', {
      filterParams: this.skillBuilderFilterParams,
      pagingParams: this.skillBuilderPagingParams,
      definitionParams: this.definitionParams,
      cancelToken: this.source?.token,
    });
  }

  downloadData(): void {
    this.downloading = true;
    const skillXrefRouteParam = this.$route.params.skillXref;
    const currentPage = this.$router.currentRoute.name;

    // Redirect home if missing route param
    if (!skillXrefRouteParam) {
      this.$router.push({
        name: 'findProblems',
      });
      return;
    }

    this.$store.commit('skillList/setSelectedSkill', skillXrefRouteParam);

    // If list of skills is missing from store, request it
    if (this.$store.state.skillList.skills.length === 0) {
      this.$store.dispatch('skillList/requestSkills');
    }

    // Download all curricula if not already
    this.$store.dispatch('curricula/requestCurricula');

    const requestProblems = this.requestProblemsBySkill();

    const requestSkillBuilders = this.requestSkillBuilders();

    // Load page once the relevant request finishes
    const dataForThisPage =
      currentPage === 'searchProblems' ? requestProblems : requestSkillBuilders;

    dataForThisPage.finally(() => {
      this.downloading = false;
    });
  }

  downloadMoreProblems(): void {
    this.requestProblemsBySkill();
  }

  onSelectedFilterChanged(selectedFilter: SelectedFilter) {
    this.selectedFilter = selectedFilter;
    this.reset();
    this.downloadMoreProblems();
  }
}
