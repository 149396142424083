export interface TimeFrame {
  startDateTime: number;
  endDateTime: number;
}

export class TimeSelector {
  type: TimeSelectorType;
  // Time boundary or lower limit (if upper limit included)
  lowerLimit: number; // Numbers in milliseconds since epoch
  upperLimit?: number; // Numbers in milliseconds since epoch

  public constructor(
    type: TimeSelectorType,
    lowerLimit: number,
    upperLimit?: number
  ) {
    this.type = type;
    this.lowerLimit = lowerLimit;
    this.upperLimit = upperLimit;
  }

  public toArray(): Array<TimeSelectorType | number> {
    const dto = [this.type, this.lowerLimit];

    if (this.upperLimit) {
      dto.push(this.upperLimit);
    }

    return dto;
  }

  public static fromArray(
    list: Array<TimeSelectorType | number>
  ): TimeSelector | null {
    if (list.length >= 2) {
      const type = list[0];
      switch (type) {
        case TimeSelectorType.AFTER:
        case TimeSelectorType.AFTER_AND_INCLUDE:
        case TimeSelectorType.BEFORE:
        case TimeSelectorType.BEFORE_AND_INCLUDE:
        case TimeSelectorType.EXCLUSIVE:
        case TimeSelectorType.INCLUSIVE:
          break;
        default:
          return null;
      }
      const lowerLimit = Number(list[1]);
      let upperLimit = undefined;

      if (list[2]) {
        upperLimit = Number(list[2]);
      }

      return new TimeSelector(type, lowerLimit, upperLimit);
    }

    return null;
  }
}

export enum TimeSelectorType {
  AFTER = 'AFTER',
  AFTER_AND_INCLUDE = 'AFTER_AND_INCLUDE',
  BEFORE = 'BEFORE',
  BEFORE_AND_INCLUDE = 'BEFORE_AND_INCLUDE',
  EXCLUSIVE = 'EXCLUSIVE',
  INCLUSIVE = 'INCLUSIVE',
}
