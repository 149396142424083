
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Line as LineChart } from 'vue-chartjs';
import {
  Chart,
  ChartData,
  ChartOptions,
  TooltipCallbacks,
  TimeSeriesScale,
  PointElement,
  LineElement,
} from 'chart.js';
import 'chartjs-adapter-dayjs-3';

Chart.register(TimeSeriesScale, PointElement, LineElement);

@Component({ components: { LineChart } })
export default class LineChartView extends Vue {
  @Prop({ required: true }) dataSets: [];
  @Prop({ required: true }) xAxisLabels: string[];
  @Prop({ required: true }) minTime: string;
  @Prop({ required: true }) tooltipCallbacks: TooltipCallbacks<'line'>;

  get chartData(): ChartData {
    return {
      labels: this.xAxisLabels,
      datasets: this.dataSets,
    };
  }

  get options(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          text: 'Chart.js Time Scale',
          display: true,
        },
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: this.tooltipCallbacks,
          backgroundColor: '#FAFAFA',

          titleFont: { style: 'normal', size: 14 },
          titleColor: '#000',
          titleAlign: 'center',
          bodyFont: { style: 'normal', size: 14 },

          bodyColor: '#000',

          bodyAlign: 'center',

          footerFont: { style: 'normal', size: 12 },

          footerColor: 'rgba(0, 0, 0, 0.6)',
          footerAlign: 'center',

          displayColors: false,
          padding: { width: 12, height: 10 },
        },
      },
      elements: {
        point: {
          radius: 1,
        },
      },

      scales: {
        x: {
          type: 'time',
          position: 'bottom',
          time: {
            displayFormats: { month: 'MMM' },
            tooltipFormat: 'MMMM DD',
            unit: 'month',
          },
          min: this.minTime,
          ticks: {
            labelOffset: 20,
          },

          grid: {
            display: true,
            drawOnChartArea: false,

            tickLength: 5,
          },
        },

        y: {
          position: 'right',
          beginAtZero: true,
          ticks: {
            callback: function (value: string | number) {
              // If the max is less than 1 then we're working with percentages, convert to percentage
              return this.max < 1
                ? ((Number(value) / this.max) * 100).toFixed(0) + '%'
                : value.toString();
            },
          },
          grid: {
            display: true,
          },
        },
      },
    };
  }
}
