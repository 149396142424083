import { ActionTree } from 'vuex';
import { SkillState } from './types';
import { RootState } from '../types';
import { getSkills } from '@/api/core/skills.api';
import { SkillDefinition } from '@/domain/Skill';

export const actions: ActionTree<SkillState, RootState> = {
  /**
   * Download all the skills
   * if not already downloaded/downloading
   */
  requestSkills(context): Promise<Array<SkillDefinition>> {
    if (!context.state.hasDownloaded && !context.state.isDownloading) {
      // Let the component decide when to load or reload skills
      // Update loading state here to notify other instances or components if
      // we do perform such operation rather than having the calling component do
      // this kind of update?
      context.commit('setIsDownloading', true);

      return getSkills().then((skills: Array<SkillDefinition>) => {
        context.commit('setHasDownloaded', true);
        context.commit('setIsDownloading', false);
        context.commit('setSkillList', skills);

        return Promise.resolve(skills);
      });
    }

    return Promise.resolve(context.state.skills);
  },
};
