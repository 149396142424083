import { LTILaunch } from '@/domain/LTI';
import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { LTIState } from './types';

export const getters: GetterTree<LTIState, RootState> = {
  getLaunch(state: LTIState): LTILaunch | null {
    return state.launch;
  },
};
