
import { Component, Prop, Vue } from 'vue-property-decorator';
import { StudentResponseAction } from '@/domain/Action';
import { getActionResponses } from '@/utils/actions.util';
import { ProblemDefinition } from '@/domain/Problem';
import AnswersViewSDK3 from '../Builder/ContentView/AnswersViewSDK3.vue';

@Component({
  components: {
    AnswersViewSDK3,
  },
})
export default class TeachleyDialog extends Vue {
  @Prop() value: boolean;
  @Prop() studentLabel: string;
  @Prop({ required: true }) problem: ProblemDefinition;
  @Prop() responseActions: StudentResponseAction[];

  get maxWidth(): number {
    return this.$vuetify.breakpoint.lgAndUp ? 800 : 600;
  }

  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(val: boolean) {
    this.$emit('input', val);
  }

  get actionResponses(): {
    response: Record<number, string[]> | string;
    correct?: boolean;
  }[] {
    return getActionResponses(this.responseActions);
  }
}
