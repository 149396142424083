import Papa from 'papaparse';

export function downloadCsvData(
  csvData: string[][],
  csvFilename: string
): void {
  // Serialize data to CSV
  // Note just in case of future papaparse can do more than just an array of arrays.
  const csv = Papa.unparse(csvData, { quotes: true });

  // Create a hidden anchor
  const hiddenAnchor = document.createElement('a');
  // Set anchor attributes
  hiddenAnchor.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
  hiddenAnchor.target = '_blank';
  hiddenAnchor.download = `${csvFilename}.csv`;
  // Click on the anchor to initiate the download
  hiddenAnchor.click();
  // Delete the anchor
  hiddenAnchor.remove();
}
