
import { AlertMessage } from '@/domain/AlertMessage';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { hideAlertMessage } from '@/api/core/notifications.api';

@Component
export default class AlertMessagesView extends Vue {
  alertMessages: Array<AlertMessage> = [];

  index = 0;
  permanentlyHideAlertMessage = false;
  showDialog = false;

  get selectedAlertMessage(): AlertMessage {
    // Reset the permanentlyHideAlertMessage checkbox value for each alert
    this.permanentlyHideAlertMessage = false;

    return this.alertMessages[this.index];
  }

  get isLast(): boolean {
    return this.alertMessages.length - 1 === this.index;
  }

  @Watch('alertMessages')
  handleAlertMessagesChange(): void {
    if (this.alertMessages.length > 0) {
      // Reset the index and permanentlyHideAlertMessage fields
      this.index = 0;
      this.permanentlyHideAlertMessage = false;

      this.showDialog = true;
    } else {
      this.showDialog = false;
    }
  }

  created(): void {
    const setAlertMessages = () =>
      (this.alertMessages = this.$store.state.notifications.alertMessages);

    // Initialize component state
    setAlertMessages();
    this.$router.afterEach(setAlertMessages);
  }

  closeDialog(): void {
    hideAlertMessage(
      this.selectedAlertMessage.id,
      this.permanentlyHideAlertMessage
    );
    if (this.isLast) {
      this.showDialog = false;
    } else {
      this.index++;
    }
  }
}
