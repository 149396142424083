
import {
  getSDK3CeriForIds,
  URLConversionRequestParams,
  URLConversionResponseObject,
  URLConversionResponseObjectType,
} from '@/api/compatibility/compatibility.api';
import { RETURN_URL } from '@/domain/PageParams';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class LegacyLinkRedirectionPage extends Vue {
  hasCopiedLink = false;
  isLoading = false;

  link = `${process.env.VUE_APP_TNG_URL}`;

  get copyTooltipText(): string {
    return this.hasCopiedLink ? 'Link copied' : 'Copy link';
  }

  get type(): string | undefined {
    return this.$route.params.type;
  }

  get isLesson(): boolean {
    return this.type === 'lesson';
  }

  get primaryId(): number {
    return Number(this.$route.params.id);
  }

  get secondaryId(): number | null {
    const psid = this.$route.params.psid;
    return psid ? Number(this.$route.params.psid) : null;
  }

  get conversionObject(): URLConversionRequestParams {
    return this.isLesson
      ? {
          folderIds: [this.primaryId],
          problemSetIds: this.secondaryId ? [this.secondaryId] : undefined,
        }
      : { problemSetIds: [this.primaryId] };
  }

  copyLink(): void {
    navigator.clipboard.writeText(this.link as string);
    this.hasCopiedLink = true;
  }

  created(): void {
    const folderType = URLConversionResponseObjectType.FOLDER;
    const psType = URLConversionResponseObjectType.LEGACY_PLAIN_OLD_PROBLEM_SET;
    this.isLoading = true;
    getSDK3CeriForIds(this.conversionObject)
      .then((res) => {
        let path = '/';
        if (!this.type) {
          const problemSetXref = this.findXrefByType(
            psType,
            this.primaryId,
            res
          );
          if (problemSetXref) {
            path += `myps/${problemSetXref}`;
          }
        } else {
          path += 'find/';
          if (this.isLesson) {
            const folderXref = this.findXrefByType(
              folderType,
              this.primaryId,
              res
            );
            const problemSetXref = this.findXrefByType(
              psType,
              this.secondaryId as number,
              res
            );
            if (folderXref && problemSetXref) {
              path += `folders/${folderXref}/${problemSetXref}`;
            }
          } else {
            const problemSetXref = this.findXrefByType(
              psType,
              this.primaryId,
              res
            );
            if (problemSetXref) {
              path += `problemSets/${problemSetXref}`;
            }
          }
        }
        this.link += path;
        this.isLoading = false;
        this.$router.replace({ path });
      })
      .catch(() => {
        return this.redirect404();
      });
  }

  findXrefByType(
    type: URLConversionResponseObjectType,
    id: number,
    arr: URLConversionResponseObject[]
  ): any {
    return arr.find((obj) => obj.resourceType === type && obj.originalId === id)
      ?.newXref;
  }

  redirect404(): void {
    this.$router.replace({
      path: '/404',
      query: { [RETURN_URL]: this.$route.fullPath },
    });
  }
}
