import { render, staticRenderFns } from "./AssignBottomBar.vue?vue&type=template&id=6bf41b9a&scoped=true&"
import script from "./AssignBottomBar.vue?vue&type=script&lang=ts&"
export * from "./AssignBottomBar.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bf41b9a",
  null
  
)

export default component.exports