
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import BarChartView, {
  BarChartData,
  CustomDataSetLabel,
} from '@/components/Report/BarChartView.vue';
import HorizontalBarChartView from '@/components/Report/HorizontalBarChartView.vue';

@Component({
  components: {
    BarChartView,
    HorizontalBarChartView,
  },
})
export default class BarChartViewForReport extends Vue {
  @Prop({ required: true }) customChartLabels: Array<CustomDataSetLabel>;
  @Prop({ required: true }) customChartData: BarChartData;
  @Prop({ default: false }) isLoading: boolean;
  @Prop({ default: false }) noSkillDataFound: boolean;
  @Prop({ default: 'start' }) legendAlignment: 'center' | 'end' | 'start';
  @Prop({ default: null }) unitSymbol: '%' | null;
  @Prop({ default: null }) stepSize: number | null;
  @Prop({ default: 10 }) scrollingSize: number; // Window chunk size for scrolling
  @Prop({ required: false }) height: number;
  @Prop({ required: true }) assignmentReport: boolean;
  @Prop({ required: false }) stackedChart: boolean;
  @Prop({ default: false }) isInTestMode: boolean;
  @Prop({ default: false }) hasRedo: boolean;

  ///////////////
  // Scrolling //
  ///////////////
  windowIndex = 0;
  get windowChunks(): Array<BarChartData> {
    const windowChunks: Array<BarChartData> = [];
    const chartDataSize: number = this.customChartData.tickLabels.length;

    for (let i = 0; i < chartDataSize; i += this.scrollingSize) {
      // Get target chunk of labels
      const chunk: BarChartData = {
        tickLabels: this.customChartData.tickLabels.slice(
          i,
          i + this.scrollingSize
        ),
        datasets: {},
      };

      // Get target chunk of data in datasets
      for (const dataset in this.customChartData.datasets) {
        chunk.datasets[dataset] = this.customChartData.datasets[dataset].slice(
          i,
          i + this.scrollingSize
        );
      }

      windowChunks.push(chunk);
    }

    return windowChunks;
  }
  get targetWindowChunk(): BarChartData {
    if (this.windowChunks.length > 0) {
      return this.windowChunks[this.windowIndex];
    }

    return {
      tickLabels: [],
      datasets: {},
    };
  }
  get leftScrollEnabled(): boolean {
    return this.windowIndex > 0;
  }
  get rightScrollEnabled(): boolean {
    return this.windowIndex < this.windowChunks.length - 1;
  }
  get chartHeight(): string {
    // Number of standards on the side is the tick labels
    // Number of labels is the number of students, but there's an "overall" label, so -1
    // So (number of standards * number of students) is how many bars there are, then pad it all
    const height =
      this.targetWindowChunk.tickLabels.length *
      (this.customChartLabels.length - 1) *
      30;

    const padding = this.targetWindowChunk.tickLabels.length === 1 ? 120 : 300;

    return `${height + padding}px`;
  }
  scrollLeft(): void {
    if (this.leftScrollEnabled) {
      this.windowIndex -= 1;
    }
  }
  scrollRight(): void {
    if (this.rightScrollEnabled) {
      this.windowIndex += 1;
    }
  }

  //////////////
  // Watchers //
  //////////////
  @Watch('customChartData')
  onDataChange(): void {
    // Reset display window (size varies per download)
    this.windowIndex = 0;
  }

  @Watch('scrollingSize')
  onSizeChange(): void {
    // Reset display window (index may no longer apply to new size)
    this.windowIndex = 0;
  }
}
