import { Component, Vue } from 'vue-property-decorator';
import { AuthenticatedUser, UserRole } from '@/domain/User';
import { LmsProviderType } from '@/domain/LmsProviderType';

@Component
export default class AuthMixins extends Vue {
  get isAuthenticated(): boolean {
    return this.$store.getters['auth/isAuthenticated'];
  }
  get getCurrentUser(): AuthenticatedUser {
    return this.$store.getters['auth/getCurrentUser'];
  }
  get isLtiUser(): boolean {
    return this.getCurrentUser?.lmsProviderType === LmsProviderType.LTI_ENABLED;
  }
  get isTeacherUser(): boolean {
    const roles = this.getCurrentUser?.roles ?? [];
    return roles.includes(UserRole.TEACHER);
  }
  get isContentAdminUser(): boolean {
    const roles = this.getCurrentUser?.roles ?? [];
    return roles.includes(UserRole.CONTENT_ADMIN);
  }
  get isServiceAdminUser(): boolean {
    const roles = this.getCurrentUser?.roles ?? [];
    return roles.includes(UserRole.SERVICE_ADMIN);
  }
  get isTrustedBuilderUser(): boolean {
    const roles = this.getCurrentUser?.roles ?? [];
    return roles.includes(UserRole.TRUSTED_BUILDER);
  }
}

Vue.mixin(AuthMixins);
