import { GetterTree } from 'vuex';
import { MyPsState } from './types';
import { RootState } from '../types';
import { ProblemSetDefinition } from '@/domain/ProblemSet';

export const getters: GetterTree<MyPsState, RootState> = {
  getNextPageToken(state: MyPsState): string | undefined {
    return state.nextPageToken;
  },
  getProblemSets(state: MyPsState): ProblemSetDefinition[] {
    return state.problemSets;
  },
};
