
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

interface GradingRow {
  symbol: string;
  sawAnswer: string;
  meaning: string;
  color: string;
}

@Component
export default class SkillBuilderDialog extends Vue {
  @Prop() value: boolean;
  gradingRows: Array<GradingRow> = [
    {
      symbol: 'mdi-check-circle',
      sawAnswer: 'No',
      meaning: 'Fully correct',
      color: 'correct',
    },
    {
      symbol: 'mdi-check-circle-outline',
      sawAnswer: 'No',
      meaning:
        'A student has not seen the answer and got it right but not on the first try',
      color: 'correctEventually',
    },
    {
      symbol: 'mdi-close-circle',
      sawAnswer: 'Yes',
      meaning:
        'A student has seen the answer so they are automatically marked incorrect',
      color: 'incorrect',
    },
  ];

  ///////////////////
  // Table Headers //
  ///////////////////

  get staticHeaders(): Array<DataTableHeader> {
    const buildStaticHeader = (text: string): DataTableHeader => ({
      text,
      value: text.toLowerCase(),
      align: 'start',
      class: ['text-no-wrap', 'sticky-row', 'sticky-row-1', 'text-subtitle-2'],
      cellClass: ['text-no-wrap'],
    });

    return ['Symbol', 'Saw Answer', 'Correct'].map(buildStaticHeader);
  }

  /**
   * Returns max width for dialog based on current breakpoint
   */
  get maxWidth(): number {
    return this.$vuetify.breakpoint.lgAndUp ? 800 : 600;
  }

  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(val: boolean) {
    this.$emit('input', val);
  }
}
