
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ProblemSetDefinition, ProblemSetType } from '@/domain/ProblemSet';
import { ProblemDefinition } from '@/domain/Problem';
import RemoveFromProblemSetDialog, {
  RemoveOperation,
} from './RemoveFromProblemSetDialog.vue';
import { ContentType } from '@/domain/Content';
import { getContentType } from '@/utils/builder.util';
import { AclPermissionType } from '@/domain/Acls';

enum RemoveDialogMode {
  PARENT = 'PARENT',
  MEMBER = 'MEMBER',
}

@Component({
  components: { RemoveFromProblemSetDialog },
})
export default class ProblemSetBuilderMenu extends Vue {
  @Prop({ required: true }) contentPath: string;

  ProblemSetType = ProblemSetType;
  RemoveDialogMode = RemoveDialogMode;
  ContentType = ContentType;
  AclPermissionType = AclPermissionType;

  removeDialog = false;
  removeMode = RemoveDialogMode.MEMBER;

  get removeData(): RemoveOperation {
    if (this.removeMode == RemoveDialogMode.MEMBER) {
      const index = this.parentProblemSet?.children.findIndex(
        (child) => child == this.member?.xref
      );
      return {
        parent: this.parent ?? '',
        member: this.member?.xref ?? '',
        index: index ?? 0,
      };
    } else {
      const index = this.grandparentProblemSet?.children.findIndex(
        (child) => child == this.parent
      );
      return {
        parent: this.grandparent ?? '',
        member: this.parent ?? '',
        index: index ?? 0,
      };
    }
  }

  get pathParts(): string[] {
    return this.contentPath.split(',');
  }

  // Can be a Problem or Problem Set.
  get member(): ProblemSetDefinition | ProblemDefinition | undefined {
    const target = this.pathParts[this.pathParts.length - 1];
    return getContentType(target) == ContentType.PROBLEM
      ? this.problemMap[target]
      : this.problemSetMap[target];
  }

  get parent(): string | undefined {
    return this.pathParts[this.pathParts.length - 2];
  }

  get parentProblemSet(): ProblemSetDefinition | undefined {
    return this.parent ? this.problemSetMap[this.parent] : undefined;
  }

  get grandparent(): string | undefined {
    return this.pathParts[this.pathParts.length - 3];
  }

  get grandparentProblemSet(): ProblemSetDefinition | undefined {
    return this.grandparent ? this.problemSetMap[this.grandparent] : undefined;
  }

  get problemSetMap(): Record<string, ProblemSetDefinition> {
    return this.$store.state.content.problemSetMap;
  }

  get problemMap(): Record<string, ProblemDefinition> {
    return this.$store.state.content.problemMap;
  }

  get memberLabel(): string {
    if (
      this.parentProblemSet?.problemSetType ==
      ProblemSetType.MULTI_PART_PROBLEM_SET
    ) {
      return 'part ' + (this.member?.xref || '');
    } else if (this.member?.contentType == ContentType.PROBLEM) {
      return 'Problem';
    } else if (this.member?.contentType == ContentType.PROBLEM_SET) {
      return 'Problem Set';
    } else {
      return '';
    }
  }

  editStandards(): void {
    this.$emit('open-edit-standards');
  }

  notifyToBeImplementedSoon(): void {
    this.$notify('To be implemented soon! Stay tuned.');
  }

  openRemoveDialog(mode: RemoveDialogMode): void {
    this.removeMode = mode;
    this.removeDialog = true;
  }

  navigateToSibling(data: RemoveOperation): void {
    const parent = this.problemSetMap[data.parent];
    // Excluding removed member.
    const pathParts = this.pathParts.splice(0, data.index);
    let siblingIndex = data.index;
    // Sibling member replacing removed member (in its position).
    if (siblingIndex == parent.children.length) {
      siblingIndex -= 1;
    }
    const sibling = parent.children[siblingIndex];
    if (sibling) {
      pathParts.push(sibling);
    }
    if (pathParts.length > 0) {
      this.$emit('selected', pathParts.join(','));
    } else {
      this.$emit('selected', null);
    }
  }

  addNewPart() {
    this.$emit('add-new-part');
  }
}
