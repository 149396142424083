import { render, staticRenderFns } from "./ProblemSetFileMenu.vue?vue&type=template&id=be172136&scoped=true&"
import script from "./ProblemSetFileMenu.vue?vue&type=script&lang=ts&"
export * from "./ProblemSetFileMenu.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be172136",
  null
  
)

export default component.exports