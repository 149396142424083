
import { DefinitionInclude } from '@/api/core/base.api';
import {
  createFolder,
  EditableFolderFields,
  getFolder,
  getFolderStats,
  updateFolder,
} from '@/api/core/folders.api';
import { AclPermissionType } from '@/domain/Acls';
import { FolderDefinition, FolderStats } from '@/domain/Folder';
import { getPathParam, PageView } from '@/utils/navigation.util';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';
import { isEmpty, isEqual, isPlainObject } from 'lodash';
import { GroupChildDepthType } from '@/domain/Group';

export enum EditMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

@Component({
  components: {},
})
export default class EditFolderDialog extends Vue {
  @Prop({ required: true }) mode: EditMode;
  @Prop({ required: true }) path: FolderDefinition[];
  @Prop({ default: true }) inheritPermissions: boolean;

  EditMode = EditMode;
  PageView = PageView;
  AclPermissionType = AclPermissionType;

  dialog = false;
  loading = false;
  stats: FolderStats | null = null;
  fields: Pick<FolderDefinition, 'name' | 'properties'> = this.getFields();

  // Default to true unless specifically overridden by user.
  inheritPermissionsLocal = true;

  get header(): string | null {
    return this.fields.properties.HEADER;
  }

  set header(value: string) {
    // DO NOT save an empty string.
    this.fields.properties.HEADER = value ? value : null;
  }

  get parentPage(): PageView {
    return this.$route?.meta?.page;
  }

  get folderMap(): Record<string, FolderDefinition> {
    return this.$store.state.folder.folderMap;
  }

  get folder(): FolderDefinition {
    return this.path[this.path.length - 1];
  }

  get modifiedFields(): EditableFolderFields {
    let modified = {};

    switch (this.mode) {
      case EditMode.CREATE:
        modified = this.getModifiedFields(this.getFields(), this.fields);
        break;
      case EditMode.UPDATE:
        modified = this.getModifiedFields(
          this.getFields(this.folder),
          this.fields
        );
        break;
      default:
        throw new Error(`Unexpected mode type: ${this.mode}`);
    }

    return modified;
  }

  get validFields(): boolean {
    let valid = true;

    switch (this.mode) {
      case EditMode.CREATE:
        // Required fields.
        valid = valid && this.fields.name.length > 0;
        break;

      case EditMode.UPDATE:
        // Something was changed.
        valid = valid && !isEmpty(this.modifiedFields);
        if (this.fields.properties.OPEN_IN_LESSON_PAGE) {
          valid = valid && this.stats?.numFolders === 0;
        }
        break;

      default:
        throw new Error(`Unexpected mode type: ${this.mode}`);
    }

    return valid;
  }

  getFields(
    folder?: FolderDefinition
  ): Pick<FolderDefinition, 'name' | 'properties'> {
    return {
      // REQUIRED. The backend will error on an empty string.
      name: folder?.name ?? '',
      properties: {
        // May be NULL. The backend defaults and returns NULL if nothing is set.
        // The backend will save an empty string, but the frontend SHOULD NOT be
        // sending an empty string.
        HEADER: folder?.properties.HEADER ?? null,
        OPEN_IN_LESSON_PAGE: folder?.properties.OPEN_IN_LESSON_PAGE ?? false,
      },
    };
  }

  getModifiedFields<T extends object>(source: T, destination: T): Partial<T> {
    const modified: Partial<T> = {};
    for (const [key, value] of Object.entries(destination)) {
      const k = key as keyof T;
      if (!isEqual(source[k], value)) {
        if (isPlainObject(value)) {
          modified[k] = this.getModifiedFields(source[k], value) as T[keyof T];
        } else {
          modified[k] = value;
        }
      }
    }
    return modified;
  }

  initialize(): void {
    // Initialize fields to default.
    switch (this.mode) {
      case EditMode.CREATE:
        this.fields = this.getFields();
        break;
      case EditMode.UPDATE:
        this.fields = this.getFields(this.folder);
        break;
      default:
        throw new Error(`Unexpected mode type: ${this.mode}`);
    }
    this.inheritPermissionsLocal = true;
    this.stats = null;
  }

  save(): void {
    this.loading = true;
    let promise = null;
    switch (this.mode) {
      case EditMode.CREATE:
        promise = createFolder(
          this.folder.xref,
          this.modifiedFields,
          this.inheritPermissions && this.inheritPermissionsLocal
        )
          .then((xref: string) => {
            let promise = Promise.resolve();
            if (this.folder.children.length) {
              // New member added to previously downloaded members.
              promise = getFolder(xref, [DefinitionInclude.ATTRIBUTES]).then(
                (folder: FolderDefinition) => {
                  this.$store.commit('folder/setFolder', folder);
                  this.$store.commit('folder/setFolder', {
                    ...this.folder,
                    children: [xref, ...this.folder.children],
                  });
                }
              );
            }
            return promise.then(() => {
              // Update the URL.
              this.$router.replace({
                query: {
                  ...this.$route.query,
                  p: getPathParam([...this.path, { xref } as FolderDefinition]),
                },
              });

              // Track Mixpanel event for folder creation
              trackMixpanel(EventType.trackPSFolderCreated, {
                folderName: this.fields.name,
                folderPath: this.path.map((f) => f.name).join('/'),
                folderXref: xref,
              });
            });
          })
          .catch(() => {
            this.$notify('Failed to create folder.');
          });
        break;
      case EditMode.UPDATE:
        promise = updateFolder(this.folder.xref, this.modifiedFields)
          .then(() => {
            const updatedProperties = {
              ...this.folder.properties,
              ...this.modifiedFields.properties,
            };
            this.$store.commit('folder/setFolder', {
              ...this.folder,
              ...this.modifiedFields,
              properties: updatedProperties,
            });

            // Track Mixpanel event for folder update
            trackMixpanel(EventType.trackPSFolderUpdated, {
              folderName: this.fields.name,
              folderPath: this.path.map((f) => f.name).join('/'),
              folderXref: this.folder.xref,
            });
          })
          .catch(() => {
            this.$notify(`Failed to update ${this.folder.name}.`);
          });
        break;
      default:
        throw new Error(`Unexpected mode type: ${this.mode}`);
    }

    promise
      .then(() => {
        this.dialog = false;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  created(): void {
    this.initialize();
  }

  @Watch('dialog')
  onDialog(): void {
    if (this.dialog) {
      this.initialize();
    }
  }

  @Watch('fields.properties.OPEN_IN_LESSON_PAGE')
  onLessonFolder(): void {
    this.stats = null;
    if (
      this.mode == EditMode.UPDATE &&
      this.fields.properties.OPEN_IN_LESSON_PAGE
    ) {
      this.loading = true;
      getFolderStats(this.folder.xref, GroupChildDepthType.DIRECT_CHILD)
        .then((stats) => {
          this.stats = stats;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
