
import { AnswerPart, AnswerSet } from '@/domain/Problem';
import BuilderEditor from '@/components/Builder/BuilderEditor.vue';
import { cloneDeep, isEqual } from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import { nextTick } from 'vue';

// For removal and update in place.
interface AnswerValueRef {
  parent: AnswerSet | AnswerPart;
  // List Index to find Answer Value in the parent.
  index: number;
}

interface AnswerPoolValue {
  // For local internal processing...
  value: string;
  // Indicate whether this is a correct answer OR a distractor. If TRUE, answerValueRefs should
  // ONLY contain Answer Part Values. If FALSE, answerValueRefs should ONLY contain ONE Answer Set
  // Value.
  isCorrect: boolean;
  // Target Answer Value(s) to manipulate. For Answer Set, this will be ONLY contain
  // ONE Answer Value. For Answer Part, this may contain MORE THAN ONE references but
  // they SHOULD all be the same Answer Value (for example, in the case of duplicate
  // responses, multiple Answer Parts will have the same Answer Value).
  answerValueRefs: AnswerValueRef[];
}

@Component({
  components: { BuilderEditor, draggable },
})
export default class PossibleResponses extends Vue {
  @Prop() answerSet: AnswerSet | null;
  @Prop({ default: false }) disabled: boolean;

  localAnswerSet: AnswerSet | null = null;
  answerPoolValues: AnswerPoolValue[] = [];

  get shuffle(): boolean {
    return this.answerPoolProp.length == 0;
  }

  set shuffle(value: boolean) {
    if (!value) {
      // When in WIP, this may contain duplicates (empty string, for example).
      this.setAnswerPool(this.possibleResponses);
    } else {
      // Clear prior Answer Pool. Backend do NOT accept NULL values IF set property.
      this.setAnswerPool(undefined);
    }
    this.$emit('answersChanged', this.localAnswerSet);
  }

  get possibleResponses(): AnswerPoolValue[] {
    return this.answerPoolValues;
  }

  set possibleResponses(value: AnswerPoolValue[]) {
    // Make sure the Answer Pool prop is in sync with current order.
    this.setAnswerPool(value);
    this.answerPoolValues = value;
    this.$emit('answersChanged', this.localAnswerSet);
  }

  get answerPoolProp(): string[] {
    return this.localAnswerSet?.properties?.ANSWER_POOL ?? [];
  }

  set answerPoolProp(value: string[]) {
    this.setAnswerPoolProp(value);
  }

  setAnswerPool(values: AnswerPoolValue[] | undefined): void {
    this.setAnswerPoolProp(values?.map((poolValue) => poolValue.value));
  }

  setAnswerPoolProp(values: string[] | undefined): void {
    if (!this.localAnswerSet) {
      this.localAnswerSet = { memberType: 'ANSWER_SET' };
    }
    if (!this.localAnswerSet.properties) {
      this.localAnswerSet.properties = {};
    }
    if (values && values.length > 0) {
      this.localAnswerSet.properties.ANSWER_POOL = values;
    } else {
      delete this.localAnswerSet.properties.ANSWER_POOL;
    }
  }

  // Build Answer Pool Values, referencing the actual Answer Value(s) in nested Answer Set.
  getAnswerPoolValues(answerSet: AnswerSet): AnswerPoolValue[] {
    const pool = [];
    if (answerSet?.members) {
      const subpool = [];
      for (const member of answerSet.members) {
        switch (member.memberType) {
          case 'ANSWER_SET':
            subpool.push(...this.getAnswerPoolValues(member));
            break;
          case 'ANSWER_PART':
            subpool.push(...this.convertToAnswerPoolValues(member));
            break;
        }
      }
      // Remove duplicate correct answers. Leave incorrect ones (distractors) alone because
      // we do NOT want it appear as if we are removing new values (empty to start) IF they
      // happen to be added at the same time to be worked on.
      // What we want here: Answer Pool = Set (Answer Part Values) + List (Answer Set Values)
      const answerSetValues = [];
      const answerPartValues: Record<string, AnswerPoolValue> = {};
      for (const poolValue of subpool) {
        if (poolValue.isCorrect) {
          if (answerPartValues[poolValue.value]) {
            answerPartValues[poolValue.value].answerValueRefs.push(
              ...poolValue.answerValueRefs
            );
          } else {
            answerPartValues[poolValue.value] = poolValue;
          }
        } else {
          answerSetValues.push(poolValue);
        }
      }
      pool.push(...Object.values(answerPartValues));
      pool.push(...answerSetValues);
    }
    // Put Answer Set Values last in the list because we do NOT want it appears as if
    // new values here in this component (distractors) are added to the top of the list
    // in the UI.
    pool.push(...this.convertToAnswerPoolValues(answerSet));
    return pool;
  }

  convertToAnswerPoolValues(parent: AnswerSet | AnswerPart): AnswerPoolValue[] {
    const poolValues = [];
    if (parent.answerValues) {
      for (let index = 0; index < parent.answerValues.length; index++) {
        const answerValue = parent.answerValues[index];
        poolValues.push({
          value: answerValue.value,
          isCorrect: answerValue.isCorrect,
          answerValueRefs: [{ parent, index }],
        });
      }
    }
    return poolValues;
  }

  addAnswer(): void {
    const distractor = { isCorrect: false, value: '' };
    if (!this.localAnswerSet) {
      this.localAnswerSet = { memberType: 'ANSWER_SET' };
    }
    if (!this.localAnswerSet.answerValues) {
      this.localAnswerSet.answerValues = [];
    }
    this.localAnswerSet.answerValues.push(distractor);
    if (!this.shuffle) {
      // Make sure Answer Pool prop is in sync.
      this.answerPoolProp = [...this.answerPoolProp, distractor.value];
    }
    this.$emit('answersChanged', this.localAnswerSet);
  }

  removeAnswer(poolIndex: number): void {
    const poolValue = this.answerPoolValues[poolIndex];
    // Not ONLY do we need to update Answer Pool but also we will want to make sure Answer Value
    // (can be anywhere) in the Answer Set is in sync with the change.
    for (const answerValueRef of poolValue.answerValueRefs) {
      const parent = answerValueRef.parent;
      const index = answerValueRef.index;
      // Remove value from list.
      parent.answerValues?.splice(index, 1);
      if (parent.memberType == 'ANSWER_PART') {
        // Remove value from drop correct map if any.
        const dropCorrectMap = parent.properties?.DROP_CORRECT;
        if (dropCorrectMap) {
          delete dropCorrectMap[poolValue.value];
          const left = Object.keys(dropCorrectMap);
          if (
            left.length == 0 ||
            (left.length == 1 && dropCorrectMap[left[0]] == 1)
          ) {
            delete parent.properties?.DROP_CORRECT;
          }
        }
      }
    }
    if (!this.shuffle) {
      // Make sure Answer Pool prop is in sync.
      const answerPoolProp = [...this.answerPoolProp];
      answerPoolProp.splice(poolIndex, 1);
      this.answerPoolProp = answerPoolProp;
    }
    this.$emit('answersChanged', this.localAnswerSet);
  }

  syncAnswerPoolValue(poolIndex: number): void {
    const poolValue = this.answerPoolValues[poolIndex];
    // Sync Answer Values in nested Answer Set.
    for (const answerValueRef of poolValue.answerValueRefs) {
      const parent = answerValueRef.parent;
      const index = answerValueRef.index;
      if (parent.answerValues) {
        const current = parent.answerValues[index].value;
        if (parent.memberType == 'ANSWER_PART') {
          const dropCorrectMap = parent.properties?.DROP_CORRECT;
          if (dropCorrectMap && dropCorrectMap[current]) {
            dropCorrectMap[poolValue.value] = dropCorrectMap[current];
            delete dropCorrectMap[current];
          }
        }
        parent.answerValues[index].value = poolValue.value;
      }
    }
    if (!this.shuffle) {
      // Make sure Answer Pool prop is in sync.
      const answerPoolProp = [...this.answerPoolProp];
      answerPoolProp[poolIndex] = poolValue.value;
      this.answerPoolProp = answerPoolProp;
    }
    this.$emit('answersChanged', this.localAnswerSet);
  }

  initialize(): void {
    if (this.answerSet) {
      this.localAnswerSet = cloneDeep(this.answerSet);
      // NOTE: We may have duplicate values in the Answer Pool. But only duplicated in the sense
      // we have one instance PER isCorrect. IF update a distractor answer, we only expect to
      // update that one instance in the Answer Set. IF update a correct answer, we expect to
      // update in ALL Answer Parts that contain this Answer Value. In the case of duplicate
      // responses, N Answer Parts may contain the same Answer Value.
      const poolValues = this.getAnswerPoolValues(this.localAnswerSet);
      const answerPoolProp = this.localAnswerSet?.properties?.ANSWER_POOL ?? [];
      if (answerPoolProp.length) {
        const ordered = [];
        for (const propPoolValue of answerPoolProp) {
          const poolValueIndex = poolValues.findIndex(
            (poolValue) => poolValue.value == propPoolValue
          );
          if (poolValueIndex != -1) {
            ordered.push(poolValues[poolValueIndex]);
            // For WIP Problems, we may contain duplicate Answer Values. Remove the instance
            // used here so we do NOT reference it again (or find it in search again).
            poolValues.splice(poolValueIndex, 1);
          }
        }
        this.answerPoolValues = ordered;
      } else {
        this.answerPoolValues = poolValues;
      }
      nextTick(() => window.com.wiris.js.JsPluginViewer.parseDocument());
    } else {
      this.localAnswerSet = null;
      this.answerPoolValues = [];
    }
  }

  created(): void {
    this.initialize();
  }

  @Watch('answerSet')
  onUpdateAnswerSet(
    newValue: AnswerSet | null,
    oldValue: AnswerSet | null
  ): void {
    if (!isEqual(newValue, oldValue)) {
      this.initialize();
    }
  }
}
