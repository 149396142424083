
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class SettingsSideBarCard extends Vue {
  @Prop() title!: string;
  @Prop() description!: string;
  @Prop() icon!: string;
  @Prop() path!: string;

  routeToSettingsFractal(path: string): void {
    this.$router.push({
      path: `/settings/${path}`,
    });
  }

  get isActive(): boolean {
    return this.$route.path.includes(this.path);
  }
}
