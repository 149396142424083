
//Please refactor all of this code because a TON of it is copy-pasted from lessonPage.
import AssignBottomBar from '@/components/FindProblems/AssignBottomBar.vue';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  ProblemSetNode,
  getProblemCerisFromStructure,
} from '@/utils/problemSet.util';
import ProblemSetViewForFind from '@/components/base/ProblemSetViewForFind.vue';
import { addMembersToFolder } from '@/api/core/folders.api';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';

@Component({
  components: {
    AssignBottomBar,
    ProblemSetViewForFind,
  },
})
export default class EditMyProblemSetPage extends Vue {
  initializing = false;
  selectedTree: ProblemSetNode[] = [];

  removeDialog = false;

  get redoEnabled(): boolean {
    return this.$store.state.auth.user?.settings?.redoEnabled ?? false;
  }

  get redoMap(): Record<string, string[]> {
    return this.$store.state.content.redoMap;
  }

  get problemSetXref(): string {
    return this.$route.params.xref;
  }

  get problemSetMap(): Record<string, ProblemSetDefinition> {
    return this.$store.state.content.problemSetMap;
  }

  get problemSet(): ProblemSetDefinition | undefined {
    return this.problemSetMap[this.problemSetXref];
  }

  get problemsSelected(): string[] {
    return getProblemCerisFromStructure(this.selectedTree);
  }

  get removeProblemText(): string {
    const length = this.problemsSelected.length;
    if (length == 1) {
      return this.problemsSelected[0];
    } else {
      const addS = length > 1 ? 's' : '';
      return `${length} Problem${addS}`;
    }
  }

  removeTree(): void {
    // TODO: Handle nesting and recursive removal?
    this.$notify('TODO');
  }

  updateTree(tree: ProblemSetNode[]): void {
    this.selectedTree = tree;
  }

  removeProblemSet(): void {
    // TODO: Remove Problem Set from User Folder and go back to My Problem Sets Page?
    this.$notify('TODO');
  }

  copyProblemSet(copyCeri: string): void {
    const mpsaf = this.$store.state.auth.MY_PROBLEM_SETS;
    addMembersToFolder(mpsaf, [copyCeri])
      .then(() => {
        this.$router.push({
          name: 'editMyPS',
          params: {
            xref: copyCeri,
          },
        });
      })
      .catch(() => {
        this.$notify(`Failed to add ${mpsaf} to folder.`);
      });
  }

  initialize(): void {
    this.initializing = true;
    this.$store
      .dispatch('content/getProblemSetTree', {
        xref: this.problemSetXref,
      })
      .then((ps) => {
        if (this.redoEnabled) {
          this.$store.dispatch('content/getProblemSetRedos', {
            xref: ps.xref,
          });
        }
        // Call to track the event in Mixpanel
        trackMixpanel(EventType.editMyProblemSet, {
          psCode: this.problemSet.xref,
          psName: this.problemSet.name,
        });
      })
      .catch(() => {
        this.$router.push({ name: 'myProblemSets' });
      })
      .finally(() => (this.initializing = false));
  }

  reload(xref: string): void {
    if (xref != this.problemSetXref) {
      // Update the URL.
      this.$router.replace({
        name: 'editMyPS',
        params: {
          xref,
        },
        query: { ...this.$route.query },
      });
    }
  }

  created(): void {
    this.initialize();
  }

  @Watch('problemSetXref')
  onProblemSetChange(newCeri: string, oldCeri: string): void {
    if (oldCeri === newCeri) {
      return;
    }
    this.initializing = false;
    this.removeDialog = false;
    this.initialize();
  }
}
