
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import { Component, Prop, Vue } from 'vue-property-decorator';
import AssignDialog from './AssignDialog.vue';
import LoginButton from '@/components/base/LoginButton.vue';
import SaveToMyProblemSetsDialog from '@/components/MyProblemSets/SaveToMyProblemSetsDialog.vue';
import { MessageType } from '@/domain/LTI';
import {
  ProblemSetNode,
  getProblemCountFromStructure,
  getProblemSetStructure,
  isSkillBuilder,
} from '@/utils/problemSet.util';
import { PageView } from '@/utils/navigation.util';
import { isWip } from '@/utils/builder.util';

@Component({
  components: {
    AssignDialog,
    LoginButton,
    SaveToMyProblemSetsDialog,
  },
})
export default class AssignBottomBar extends Vue {
  @Prop() problemSet?: ProblemSetDefinition;
  @Prop({ default: () => [] }) selectedTree: ProblemSetNode[];
  @Prop() totalProblems?: number;

  saveToMyProblemSets = false;
  assignDialog = false;

  PageView = PageView;

  get isWip(): boolean {
    return this.problemSet ? isWip(this.problemSet.xref) : false;
  }

  get parentPage(): PageView {
    return this.$route?.meta?.page;
  }

  get isSkillBuilder(): boolean {
    return this.problemSet ? isSkillBuilder(this.problemSet) : false;
  }

  get problemSetMap(): Record<string, ProblemSetDefinition> {
    return this.$store.state.content.problemSetMap;
  }

  get problemSetTree(): ProblemSetNode[] {
    if (this.problemSet && this.problemSetMap) {
      return getProblemSetStructure(this.problemSet, this.problemSetMap);
    } else {
      return [];
    }
  }

  get numProblemsSelected(): number {
    return getProblemCountFromStructure(this.selectedTree);
  }

  get numProblemsTotal(): number {
    if (this.problemSet && !this.totalProblems) {
      return getProblemCountFromStructure(this.problemSetTree);
    } else {
      return this.totalProblems || 0;
    }
  }

  get enableAssign(): boolean {
    let enabled = false;
    if (this.problemSet && this.isSkillBuilder) {
      // IF Skill Builder, we are enabled.
      enabled = true;
    } else if (this.numProblemsSelected > 0) {
      //IF we have selected problems then enable also
      enabled = true;
    }
    return (
      enabled &&
      (this.isLtiUser
        ? // IF content selection
          this.$store.state.lti.launch?.messageType === MessageType.DEEP_LINK
        : true) &&
      !this.isWip
    );
  }

  get assignButtonText(): string {
    if (this.isSkillBuilder) {
      return 'ASSIGN SKILL BUILDER';
    } else {
      return `ASSIGN TO CLASS`;
    }
  }

  get defaultAssignmentName(): string {
    if (this.problemSet) {
      const header = this.problemSet?.properties?.HEADER;
      const assignmentNamePrefix = header ? `${header} --- ` : '';

      return `${assignmentNamePrefix}${this.problemSet.name}`;
    }
    return '';
  }
}
