
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ProblemDefinition } from '@/domain/Problem';
import SaveToMyProblemSetsDialog from '@/components/MyProblemSets/SaveToMyProblemSetsDialog.vue';
import ProblemViewMenu from '@/components/base/ProblemViewMenu.vue';
import { SkillDefinition } from '@/domain/Skill';
import { PageView } from '@/utils/navigation.util';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import ProblemViewSDK3 from '../Builder/ContentView/ProblemViewSDK3.vue';

@Component({
  components: {
    SaveToMyProblemSetsDialog,
    ProblemViewMenu,
    ProblemViewSDK3,
  },
})
export default class ProblemViewForFind extends Vue {
  @Prop() value: boolean;
  @Prop() problem: ProblemDefinition;
  @Prop({ default: () => [] }) path: ProblemSetDefinition[];
  @Prop() isFirst: boolean;
  @Prop() isLast: boolean;
  @Prop({ default: false }) haveRedo: boolean;
  @Prop({ default: false }) haveTeachley: boolean;
  @Prop({ default: true }) enabled: boolean;

  PageView = PageView;

  get parentPage(): PageView {
    return this.$route?.meta?.page;
  }

  get pathList(): (ProblemSetDefinition | ProblemDefinition)[] {
    return [...this.path, this.problem];
  }

  get selected(): boolean {
    return this.value;
  }

  set selected(val: boolean) {
    this.$emit('input', val);
  }

  get skills(): SkillDefinition[] {
    const skillMap = this.$store.getters['skillList/getSkillsMap'];
    const xrefs = this.problem.attributes?.skill ?? [];
    const res = [];
    for (const xref of xrefs) {
      const skill = skillMap.get(xref);
      if (skill) {
        res.push(skill);
      }
    }
    return res;
  }

  created(): void {
    this.$store.dispatch('skillList/requestSkills');
  }
}
