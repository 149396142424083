import { TinyMCEEditor } from '@/types/tiny';
import baseConfig from './baseTinyMCEEditorConfig';

export enum ResponseBoxType {
  DROP_TARGET = 'dropTarget',
  TEXT = 'text',
  DROPDOWN = 'dropdown',
}

export interface ResponseBox {
  type: ResponseBoxType;
  marker: number;
  element: Element;
}

const tagName = 'ast-r';
const tagBegin = `<${tagName}`;
const tagEnd = `</${tagName}>`;

const markerAttr = 'marker';
const typeAttr = 'type';
const markerRegex = /marker=['"](\d+)['"]/;
const typeRegex = /type=['"](\w+)['"]/;
const handledDataStr = 'data-ast';

//tinyMCE content related helpers
export function getResponseBoxes(editor: TinyMCEEditor): ResponseBox[] {
  const resp: ResponseBox[] = [];

  const elements: NodeList = editor.getBody().querySelectorAll(tagName) ?? [];

  for (let i = 0; i < elements.length; i++) {
    //typescript complains that closest isn't a function in Node, so we change it to element
    // This is because this is an element and elements extend Nodes
    const element = elements[i] as Element;

    const bogusParent = element.closest(
      '[data-mce-bogus], .mce-offscreen-selection'
    );

    if (bogusParent) {
      //Don't want a bogus one because of tinyMCE's weird selection system
      continue;
    }

    resp.push({
      type: (element.getAttribute(typeAttr) ?? '') as ResponseBoxType,
      marker: parseInt(element.getAttribute(markerAttr) ?? '0'),
      element,
    });
  }

  return resp;
}

export function insertResponse(editor: TinyMCEEditor, type: ResponseBoxType) {
  const boxes = getResponseBoxes(editor);
  const lastMarker =
    boxes.length > 0
      ? boxes.reduce((prev, curr) => (prev.marker > curr.marker ? prev : curr))
          .marker
      : 0;
  const marker = lastMarker + 1;

  //TODO: Make sure this isn't within another ast-r
  editor.insertContent(
    `<ast-r ${typeAttr}="${type}" ${markerAttr}="${marker}" ></ast-r>`
  );
}

//Transform functions (short to long and back)
//TODO: Figure out how to combine the two transform functions
function shortenedHtmlToDescriptive(content: string): string {
  let output = '';
  let start = content.indexOf(tagBegin);
  let end = 0;

  while (start !== -1) {
    //Add everything before our element
    output += content.substring(end, start);
    end = content.indexOf(tagEnd, start);

    if (end === -1) {
      end = content.length;
    } else {
      end += tagEnd.length;
    }

    //Alter and add
    // everything between start and end is our tag now.
    const entireTag = content.substring(start, end);

    if (entireTag.indexOf(handledDataStr) > 0) {
      //We've already handled this bit of our stuff. Let's leave it the way it is
      output += entireTag;
    } else {
      let match = entireTag.match(markerRegex);
      let marker = '';
      if (match) {
        marker = match[1];
      }

      match = entireTag.match(typeRegex);
      let type = '';
      if (match) {
        type = match[1];
      }

      //Only allow it through if we have both a marker and a type
      if (marker && type) {
        const aType: ResponseBoxType = type as ResponseBoxType;
        output += createMarker(aType, marker);
      }
    }
    //Find the next element
    start = content.indexOf(tagBegin, end);
  }

  output += content.substring(end, content.length);
  return output;
}

function descriptiveHtmlToShortened(content: string): string {
  let output = '';
  let start = content.indexOf(tagBegin);
  let end = 0;

  while (start !== -1) {
    //Add everything before our element
    output += content.substring(end, start);
    end = content.indexOf(tagEnd, start);

    if (end === -1) {
      end = content.length;
    } else {
      end += tagEnd.length;
    }

    //Alter and add
    // everything between start and end is our tag now.
    const entireTag = content.substring(start, end);

    let match = entireTag.match(markerRegex);
    let marker = '';
    if (match) {
      marker = match[1];
    }

    match = entireTag.match(typeRegex);
    let type = '';
    if (match) {
      type = match[1];
    }

    //TODO: find the item in our local map
    // Make sure that our type matches that type.
    if (type && marker) {
      output += `<ast-r type="${type}" marker="${marker}"></ast-r>`;
    }

    //Find the next element
    start = content.indexOf(tagBegin, end);
  }

  output += content.substring(end, content.length);
  return output;
}
//End transform functions

//Simple for now. Can make it more complex now.
function createMarker(type: ResponseBoxType, marker: number | string): string {
  let classes = 'primary theme--light v-chip v-size--default';

  if (type === ResponseBoxType.DROP_TARGET) {
    classes = 'astr-drop-target';
  }

  const innerHtml = getInnerHtml(type, marker);

  return `<ast-r class="${classes}" contenteditable="false" ${typeAttr}="${type}" ${markerAttr}="${marker}" ${handledDataStr}="true">${innerHtml}</ast-r>`;
}

function getInnerHtml(type: ResponseBoxType, marker: number | string) {
  let typeElements = '';

  if (type === ResponseBoxType.TEXT) {
    typeElements = `<span class="ast-r-type-text elevation-1">Text</span>`;
  }

  if (type === ResponseBoxType.DROPDOWN) {
    typeElements = `<span class="ast-r-type-dropdown v-icon primary--text white"><svg width="22" height="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#4a9fff" d="m7 10 5 5 5-5H7Z" /><span class="useless">&#8203;</span></svg></span>`;
  }

  if (type === ResponseBoxType.DROP_TARGET) {
    // typeElements = `&#8203;`;
    return '&#8203;';
  }

  return `<span class="v-chip__content content"><span class="ast-r-type">${typeElements}</span> Answer ${marker}</span>`;
}

function setupFunction(editor: TinyMCEEditor) {
  //Copied from material design icons
  const iconSvg =
    '<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18,14H10.5L12.5,12H18M6,14V11.5L12.88,4.64C13.07,4.45 13.39,4.45 13.59,4.64L15.35,6.41C15.55,6.61 15.55,6.92 15.35,7.12L8.47,14M20,2H4A2,2 0 0,0 2,4V22L6,18H20A2,2 0 0,0 22,16V4C22,2.89 21.1,2 20,2Z" /></svg>';
  //register icon
  editor.ui.registry.addIcon('mdiMessageDraw', iconSvg);

  //////PLUGIN STUFF ////////
  // hooking up events and ui into tinyMCE

  editor.on('GetContent', function (e) {
    e.content = descriptiveHtmlToShortened(e.content);
  });

  let beforeSetChanged = false;
  editor.on('BeforeSetContent', function (e) {
    const begin = e.content.length;
    e.content = shortenedHtmlToDescriptive(e.content);

    //Record if we actually changed length.
    beforeSetChanged = begin !== e.content.length;
  });

  editor.on('SetContent', function () {
    //If we did not change our stuff beforeSetContent then there's no reason to check the boxes?
    if (!beforeSetChanged) {
      return;
    }

    //After content has been set. Check all elements
    const boxes = getResponseBoxes(editor);

    let highestMarker = 0;

    const seen: { [key: number]: boolean } = {};
    const reinitialize: ResponseBox[] = [];
    for (const box of boxes) {
      if (seen[box.marker] || box.marker <= 0) {
        //re initialize this one
        reinitialize.push(box);
        continue;
      }

      highestMarker = Math.max(box.marker, highestMarker);
      seen[box.marker] = true;
    }

    for (const box of reinitialize) {
      highestMarker++;

      box.marker = highestMarker;
      box.element.setAttribute(markerAttr, `${box.marker}`);

      box.element.innerHTML = getInnerHtml(box.type, box.marker);
    }
  });
}

export default {
  ...baseConfig,
  custom_elements: '~ast-r',
  extended_valid_elements:
    // SVG is * because it filters the viewBox no matter what
    'ast-r[class|style|type|marker|contenteditable],svg[*],path[d|fill],math[*],maction[*],malignmark[*],maligngroup[*],menclose[*],merror[*],mfenced[*],mfrac[*],mglyph[*],mi[*],mlabeledtr[*],mlongdiv[*],mmultiscripts[*],mn[*],mo[*],mover[*],mpadded[*],mphantom[*],mprescripts[*],none[*],mroot[*],mrow[*],ms[*],mscarries[*],mscarry[*],msgroup[*],msline[*],mspace[*],msqrt[*],msrow[*],mstack[*],mstyle[*],msub[*],msubsup[*],msup[*],mtable[*],mtd[*],mtext[*],mtr[*],munder[*],munderover[*],semantics[*],annotation[*]',
  inline: true,
  toolbar_persist: true,

  setup: setupFunction,
  table_default_attributes: {
    border: '0',
  },
};
