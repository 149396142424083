
import { Component, Prop, Vue } from 'vue-property-decorator';
import FolderFileMenu from './FolderFileMenu.vue';
import { FolderDefinition, FolderMemberType } from '@/domain/Folder';
import { Mode, SortBy } from './FolderExplorer.vue';
import { AttributeNames } from '@/domain/Attributes';
import Attribution from '../base/Attribution.vue';
import { PageView, getPathParam } from '@/utils/navigation.util';
import { ContentMemberType } from '@/domain/ProblemSet';

@Component({
  components: {
    Attribution,
    FolderExplorer: () => import('./FolderExplorer.vue'),
    FolderFileMenu,
  },
})
export default class FolderFileView extends Vue {
  @Prop({ required: true }) folder: FolderDefinition;
  @Prop({ default: () => [] }) path: FolderDefinition[];
  @Prop({ default: 0 }) depth: number;
  @Prop({ default: () => Mode.BROWSE }) mode: Mode;
  @Prop({ required: false }) sort: SortBy | undefined | null;
  @Prop({ default: false }) showAttribution: boolean;
  @Prop({
    default: () => [
      FolderMemberType.FOLDER,
      FolderMemberType.PUB_PS,
      FolderMemberType.WIP_PS,
    ],
  })
  fmTypes: FolderMemberType[];
  @Prop({ default: () => [] }) cmTypes: ContentMemberType[];
  @Prop({ default: 20 }) limit: number;

  Mode = Mode;

  open = false;

  get pathList(): FolderDefinition[] {
    return [...this.path, this.folder];
  }

  get folderPath(): string {
    return getPathParam(this.pathList);
  }

  get isRoot(): boolean {
    return this.depth === 0;
  }

  get parentPage(): PageView {
    return this.$route?.meta?.page;
  }

  // FIXME: Figure out if there is a better way to do this than to introduce the page view concept here.
  get showFolderIcon(): boolean {
    // Root folders in MyPS get the folder icon
    const isMyPsRootFolder =
      this.isRoot && this.parentPage == PageView.MY_PROBLEM_SETS;

    // Sub folders on MyPS and Builder get the folder icon
    const isMyPsOrBuilderSubFolder =
      !this.isRoot &&
      [PageView.MAIN_HIERARCHY, PageView.MY_PROBLEM_SETS].includes(
        this.parentPage
      );

    return isMyPsRootFolder || isMyPsOrBuilderSubFolder;
  }

  get folderHeading(): string {
    const fontSize = this.depth + 1;
    if (fontSize > 5) {
      return 'text-h5';
    }

    return `text-h${fontSize}`;
  }

  get isLesson(): boolean {
    return this.folder.properties.OPEN_IN_LESSON_PAGE ?? false;
  }

  get attributions(): string[] {
    return this.folder.attributes?.[AttributeNames.ATTRIBUTION] ?? [];
  }

  get isTarget(): boolean {
    return this.$route.query.p === this.folderPath;
  }

  get backgroundColor(): string {
    if (this.isTarget) {
      return 'var(--v-primary-lighten3)';
    } else if (this.open) {
      return 'var(--v-primary-lighten4)';
    } else {
      return 'var(--v-neutral-lighten5)';
    }
  }

  toggleOpen(openOverride?: boolean): void {
    if (openOverride !== undefined) {
      this.open = openOverride;
    } else {
      this.open = !this.open;
    }

    if (this.isLesson && this.mode == Mode.BROWSE) {
      this.$router.push({
        name: 'LessonPageFolders',
        params: { folderXref: this.folder.xref },
      });
    }

    let path = undefined;
    this.$store.commit('folder/setManualNavigation', true);
    if (this.open) {
      path = this.folderPath;
      this.$emit('open-folder', this.folder.xref);
    } else {
      // Open parent
      path = getPathParam(this.path);
    }
    const current = this.$route.query.p;
    if (current != path) {
      this.$router
        .replace({
          // name: this.$route.name,
          query: {
            ...this.$route.query,
            p: path,
          },
        })
        .then(() => {
          this.$store.commit('folder/setManualNavigation', false);
        });
    }
  }
}
