
import { Component, Vue, Watch } from 'vue-property-decorator';
import { CancelTokenSource } from 'axios';
import { FolderDefinition } from '@/domain/Folder';
import { TimeSelector, TimeSelectorType } from '@/domain/Time';
import { CurriculumDefinition } from '@/domain/Curriculum';
import { getSchoolYears } from '@/utils/InsightsHub/schoolyear.util';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';
import dayjs from 'dayjs';
import { GradeLevel, getGradeLevelDisplayNames } from '@/domain/Grade';

@Component({})
export default class InsightsHubLandingPage extends Vue {
  downloading = false;
  source: CancelTokenSource | null = null;
  schoolYearChoices: { text: string; value: TimeSelector }[] = [];
  selectedCurriculumXref = '';
  defaultGrades = getGradeLevelDisplayNames([
    GradeLevel.GRADE_1,
    GradeLevel.GRADE_2,
    GradeLevel.GRADE_3,
    GradeLevel.GRADE_4,
    GradeLevel.GRADE_5,
    GradeLevel.GRADE_6,
    GradeLevel.GRADE_7,
    GradeLevel.GRADE_8,
  ]);

  get gradeFolders(): FolderDefinition[] {
    return this.$store.getters['insightsHub/gradeFoldersForSelectedCurriculum'];
  }

  get curriculaChoices(): CurriculumDefinition[] {
    return this.$store.getters['insightsHub/supportedCurricula'];
  }

  // Selected Curriculum
  timeSelector: TimeSelector | null = null;

  get selectedCurriculum(): CurriculumDefinition | undefined {
    return this.curriculaChoices.find((curricula) => {
      return curricula.xref === this.selectedCurriculumXref;
    });
  }

  set selectedCurriculum(selectedCurriculum) {
    this.$store.commit('insightsHub/setSelectedCurriculum', selectedCurriculum);
  }

  @Watch('selectedCurriculum')
  onCurriculumChange(newCurriculum: CurriculumDefinition): void {
    if (this.selectedCurriculum) {
      this.downloading = true;
      this.selectedCurriculum = newCurriculum;

      const rootFolderXref = this.selectedCurriculum.rootFolderXref;

      this.$store
        .dispatch('folder/getFolderMembers', { xref: rootFolderXref })
        .then(() => {
          this.downloading = false;
        });
    }
  }

  clearSelections(): void {
    this.$store.commit('insightsHub/setSelectedModuleId', null);
    this.$store.commit('insightsHub/setSelectedMode', null);
    this.$store.commit('insightsHub/setSelectedPsTypes', []);
    this.$store.commit('insightsHub/setSelectedAssessmentType', null);
    this.$store.commit('insightsHub/setAnonymized', false);
    this.$store.commit('insightsHub/setSelectedTab', null);
    this.$store.commit('insightsHub/setCollapsedPaths', new Set<string>());
    this.$store.commit('insightsHub/setOptions', {});
    this.$store.commit('insightsHub/resetCurrentTimeData');
  }

  created(): void {
    this.clearSelections();

    const schoolYears = getSchoolYears();

    for (const schoolYear of schoolYears) {
      this.schoolYearChoices.push({
        text: `${schoolYear.startDateTime.format(
          'YY'
        )}/${schoolYear.endDateTime.format('YY')}`,
        value: new TimeSelector(
          TimeSelectorType.INCLUSIVE,
          schoolYear.startDateTime.valueOf(),
          schoolYear.endDateTime.valueOf()
        ),
      });
    }

    this.timeSelector =
      this.$store.state.insightsHub.timeSelector ??
      this.schoolYearChoices[0].value;
  }

  gradeReportClicked(gradeSelected: string): void {
    const yearSelected = `${dayjs(this.timeSelector?.lowerLimit).format(
      'YY'
    )}/${dayjs(this.timeSelector?.upperLimit).format('YY')}`;

    const curriculumXref = this.selectedCurriculum?.xref;

    trackMixpanel(EventType.insightsGradeReportView, {
      curriculumSelected: curriculumXref ? curriculumXref : null,
      gradeSelected: gradeSelected,
      yearSelected: yearSelected,
    });
  }
}
