import { ActionTree } from 'vuex';
import { ContentState } from './types';
import { RootState } from '../types';
import {
  ContentBuildStatus,
  ContentSaveOperationType,
  addMembersToProblemSet,
  createProblem,
  createProblemSet,
  getProblem,
  getProblemSetChildren,
  getProblemSetDefinition,
  getRedosMap,
  removeMembersFromProblemSet,
  updateProblem,
  updateProblemSet,
  updateProblemSetMemberPosition,
  replaceMembersInProblemSet,
  getTutorStrategy,
  updateTutorStrategy,
  createTutorStrategyForProblem,
  searchForProblems,
  getProblemSetChildrenXrefs,
  searchForTutorStrategies,
  copyProblemSet,
} from '@/api/core/content.api';
import { ProblemSetDefinition, ProblemSetType } from '@/domain/ProblemSet';
import {
  getContentType,
  getTransitionStateType,
  isPublished,
} from '@/utils/builder.util';
import {
  ContentType,
  PersistableStateType,
  TransitionStateType,
} from '@/domain/Content';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { ITutorStrategy } from '@/domain/Tutoring';
import { ProblemDefinition } from '@/domain/Problem';
import dayjs from 'dayjs';
import { DefinitionInclude, ObjectList } from '@/api/core/base.api';
import { AclPermissionType } from '@/domain/Acls';

export const actions: ActionTree<ContentState, RootState> = {
  // FIXME: Force reload previously downloaded tree?
  async getProblemSetTree(context, { xref }): Promise<ProblemSetDefinition> {
    // FIXME: Figure out if we need to redownload children if downloaded previously?
    let children: (ProblemDefinition | ProblemSetDefinition)[] | undefined =
      undefined;
    try {
      children = await getProblemSetChildren(xref, [
        DefinitionInclude.ATTRIBUTES,
      ]);
    } catch (e) {
      // No children found?
      children = [];
    }
    const members = [];
    const childPromises: Promise<unknown>[] = [];
    for (const child of children) {
      members.push(child.xref);
      if (child.contentType === ContentType.PROBLEM) {
        context.commit('setProblem', child);
      } else if (child.contentType === ContentType.PROBLEM_SET) {
        // FIXME: Downloading the child PS twice (once here and once when getting its tree on the
        // recursive call).
        childPromises.push(
          context.dispatch('getProblemSetTree', {
            xref: child.xref,
          })
        );
      }
    }
    await Promise.all(childPromises);
    let ps = undefined;
    const sps = context.state.problemSetMap[xref];
    if (sps) {
      ps = sps;
    } else {
      ps = await getProblemSetDefinition(xref, [DefinitionInclude.ATTRIBUTES]);
    }
    ps = { ...ps, children: members };
    // Save Problem Set with updated/downloaded list of children.
    context.commit('setProblemSet', ps);
    return ps;
  },
  async getProblemSetRedos(context, { xref, tsParams }): Promise<void> {
    const ps = await context.dispatch('getProblemSet', {
      xref,
    });
    const redosMap = await getRedosMap(ps.children);
    const redoPrs = [];
    for (const child in redosMap) {
      const redos = redosMap[child];
      const ceris = [];
      for (const redo of redos) {
        ceris.push(redo.xref);
        if (redo.contentType == ContentType.PROBLEM) {
          context.commit('setProblem', redo);
          redoPrs.push(redo.xref);
        } else if (
          redo.contentType == ContentType.PROBLEM_SET &&
          redo.problemSetType == ProblemSetType.MULTI_PART_PROBLEM_SET
        ) {
          context.commit('setProblemSet', redo);
          // NOT making a recursive call and fetch redos of a redo.
          const redoPs = await context.dispatch('getProblemSetTree', {
            xref: redo.xref,
          });
          // Multi-part Problems MUST ONLY contain Problems.
          redoPrs.push(...redoPs.children);
        }
      }
      context.commit('setRedos', { [child]: ceris });
    }
    // FIXME: Figure out if/when we have the ability to search tutorings for multiple Problems.
    if (redoPrs.length && tsParams) {
      await context.dispatch('getTutorStrategies', {
        filterParams: { ...tsParams, targets: redoPrs },
      });
    }
  },
  // FIXME: Figure out if we need to update Tutoring references when transitioning Problem from Published to WIP?
  async getWipProblemSetTree(context, { xref }): Promise<ProblemSetDefinition> {
    let ps = await context.dispatch('getProblemSet', { xref });
    if (
      isPublished(ps.xref) &&
      ps.permissions.includes(AclPermissionType.UPDATE) &&
      ps.mappedCeri
    ) {
      // Download WIP version.
      ps = await context.dispatch('getProblemSetTree', {
        xref: ps.mappedCeri,
      });
    }
    const childPromises = [];
    const wipProblems = [];
    for (const child of ps.children) {
      const type = getContentType(child);
      switch (type) {
        case ContentType.PROBLEM:
          {
            const pr = context.state.problemMap[child];
            if (
              isPublished(pr.xref) &&
              pr.permissions.includes(AclPermissionType.UPDATE) &&
              pr.mappedCeri
            ) {
              // Download WIP Problems in bulk later.
              wipProblems.push(pr.mappedCeri);
            }
          }
          break;
        case ContentType.PROBLEM_SET:
          // MUST download WIP Problem Set to account for changes in memberships.
          childPromises.push(
            context.dispatch('getWipProblemSetTree', {
              xref: child,
            })
          );
          break;
      }
    }
    if (wipProblems.length) {
      const childPromise = searchForProblems(
        {
          ceris: wipProblems,
          psTypes: [PersistableStateType.WORK_IN_PROGRESS],
        },
        undefined,
        {
          include: [DefinitionInclude.ATTRIBUTES],
        }
      ).then((problemList) => {
        for (const pr of problemList.data) {
          context.commit('setProblem', pr);
        }
      });
      childPromises.push(childPromise);
    }
    await Promise.all(childPromises);
    return ps;
  },
  getTutorStrategy(context, { xref }): Promise<ITutorStrategy> {
    const tutoring = context.state.tutorStrategyMap[xref];
    if (tutoring) {
      return Promise.resolve({ ...tutoring });
    } else {
      return getTutorStrategy(xref).then((ts) => {
        context.commit('setTutorStrategy', ts);
        return ts;
      });
    }
  },
  getProblem(context, { xref }): Promise<ProblemDefinition> {
    const problem = context.state.problemMap[xref];
    if (problem) {
      return Promise.resolve({ ...problem });
    } else {
      return getProblem(xref, [DefinitionInclude.ATTRIBUTES]).then((pr) => {
        context.commit('setProblem', pr);
        return pr;
      });
    }
  },
  getTutorStrategies(context, { filterParams }): Promise<ITutorStrategy[]> {
    return searchForTutorStrategies(filterParams)
      .then((tsList: ObjectList<ITutorStrategy>) => {
        const tses = tsList.data;
        for (const ts of tses) {
          context.commit('setTutorStrategy', ts);
        }
        return tses;
      })
      .catch(() => {
        // No tutorings found?
        return [];
      });
  },
  // Problem Set in store should have its entire tree downloaded.
  getProblemSet(context, { xref }): Promise<ProblemSetDefinition> {
    const problemSet = context.state.problemSetMap[xref];
    if (problemSet) {
      // FIXME: Figure out if we need to ever redownload Problem Set tree here.
      return Promise.resolve({ ...problemSet });
    } else {
      return context
        .dispatch('getProblemSetTree', {
          xref,
        })
        .then((ps) => {
          return ps;
        });
    }
  },
  async getProblemSetTutorStrategies(
    context,
    { xref, filterParams }
  ): Promise<void> {
    const ps = await context.dispatch('getProblemSet', {
      xref,
    });
    const prs = [];
    for (const child of ps.children) {
      const type = getContentType(child);
      switch (type) {
        case ContentType.PROBLEM:
          {
            prs.push(child);
          }
          break;
        case ContentType.PROBLEM_SET:
          await context.dispatch('getProblemSetTutorStrategies', {
            xref: child,
            filterParams,
          });
          break;
      }
    }
    if (prs.length) {
      await context.dispatch('getTutorStrategies', {
        filterParams: { ...filterParams, targets: prs },
      });
    }
  },
  // FIXME: Figure out if we want a version for EACH content.
  // FIXME: Figure out if we need to update Tutoring references when transitioning Problem from Published to WIP?
  async save(
    context,
    { sourceCeri, destinationCeri, modifiedFields }
  ): Promise<void> {
    const destinationType = getContentType(destinationCeri);
    const sourceType = sourceCeri ? getContentType(sourceCeri) : undefined;
    if (sourceType && sourceType != destinationType) {
      throw new Error(
        `Invalid transition from ${sourceType} to ${destinationType}`
      );
    }
    // FIXME: Figure out if there is a better way to do this?
    let storeGetter = '';
    let storeSetter = '';
    let storeDelete = '';
    switch (destinationType) {
      case ContentType.PROBLEM:
        storeGetter = 'getProblem';
        storeSetter = 'setProblem';
        storeDelete = 'deleteProblem';
        break;
      case ContentType.PROBLEM_SET:
        storeGetter = 'getProblemSet';
        storeSetter = 'setProblemSet';
        storeDelete = 'deleteProblemSet';
        break;
      case ContentType.TUTOR_STRATEGY:
        storeGetter = 'getTutorStrategy';
        storeSetter = 'setTutorStrategy';
        storeDelete = 'deleteTutorStrategy';
        break;
      default:
        throw new Error(`Unrecognized content type: ${destinationType}`);
    }

    modifiedFields = modifiedFields ?? {};
    modifiedFields.updatedAt = dayjs().valueOf();

    const transitionState = getTransitionStateType(destinationCeri, sourceCeri);
    switch (transitionState) {
      case TransitionStateType.NEW_TO_WIP:
      case TransitionStateType.NEW_TO_PUB:
      case TransitionStateType.PUB_TO_PUB:
      case TransitionStateType.WIP_TO_WIP:
        {
          const destination = await context.dispatch(storeGetter, {
            xref: destinationCeri,
          });
          context.commit(storeSetter, {
            ...destination,
            ...modifiedFields,
          });
        }
        // Nothing to do.
        break;
      case TransitionStateType.PUB_TO_WIP:
        {
          const source = await context.dispatch(storeGetter, {
            xref: sourceCeri,
          });
          const destination = await context.dispatch(storeGetter, {
            xref: destinationCeri,
          });
          context.commit(storeSetter, {
            ...destination,
            ...modifiedFields,
            mappedCeri: sourceCeri,
          });
          context.commit(storeSetter, {
            ...source,
            mappedCeri: destinationCeri,
          });
        }
        break;
      case TransitionStateType.WIP_TO_PUB: {
        const source = await context.dispatch(storeGetter, {
          xref: sourceCeri,
        });
        context.commit(storeSetter, {
          ...source,
          ...modifiedFields,
          mappedCeri: undefined,
          xref: destinationCeri,
        });
        // WIP cleanup.
        if (
          [ContentType.PROBLEM, ContentType.PROBLEM_SET].includes(
            destinationType
          )
        ) {
          // Update WIP membership references if any.
          for (const psCeri in context.state.problemSetMap) {
            const problemSet = context.state.problemSetMap[psCeri];
            if (problemSet.children.includes(sourceCeri)) {
              // Save Problem Set.
              context.commit('setProblemSet', {
                ...problemSet,
                children: problemSet.children.map((child) =>
                  child == sourceCeri ? destinationCeri : child
                ),
              });
            }
          }
        }
        if (destinationType == ContentType.PROBLEM) {
          // Update WIP references if any.
          for (const tsCeri in context.state.tutorStrategyMap) {
            const tutoring = context.state.tutorStrategyMap[tsCeri];
            if (tutoring.tutoringTarget == sourceCeri) {
              // Save Tutor Strategy.
              context.commit('setTutorStrategy', {
                ...tutoring,
                tutoringTarget: destinationCeri,
              });
            }
          }
        }
        if (destinationType == ContentType.TUTOR_STRATEGY) {
          const source = await context.dispatch(storeGetter, {
            xref: sourceCeri,
          });
          // Previous Published TS is replaced with new Published TS.
          if (source.mappedCeri) {
            context.dispatch(storeDelete, { xref: source.mappedCeri });
          }
        }
        return context.dispatch(storeDelete, { xref: sourceCeri });
      }
    }
  },
  // FIXME: Figure out if it is easier if we redownload the updated definition on save every time
  // rather than doing all of this local processing to fix mappings and update fields.
  async saveTutorStrategy(
    context,
    { problemCeri, xref, modifiedFields, opType }
  ): Promise<ContentBuildStatus> {
    let tsStatus: ContentBuildStatus | null = null;
    if (xref) {
      if (isPublished(xref)) {
        const ts = await context.dispatch('getTutorStrategy', {
          xref,
        });
        if (
          ts.permissions.includes(AclPermissionType.UPDATE) &&
          ts.mappedCeri
        ) {
          xref = ts.mappedCeri;
        }
      }
      const { tutorStrategyType, ...rest } = modifiedFields;
      if (isPublished(xref) && isEmpty(rest) && opType) {
        // Nothing more we can do.
        return { ceri: xref };
      } else {
        // Update Tutoring.
        tsStatus = await updateTutorStrategy(xref, modifiedFields, opType);
      }
    } else {
      // Create NEW Tutoring.
      tsStatus = await createTutorStrategyForProblem(
        problemCeri,
        modifiedFields,
        opType
      );
    }
    if (tsStatus.failMessages) {
      // Account Tutoring failures on parent Problem.
      context.commit('setValidationError', {
        ceri: problemCeri,
        failMessages: tsStatus.failMessages,
      });
      tsStatus.failedCeris = [xref];
    } else if (tsStatus.ceri) {
      context.commit('deleteValidationError', tsStatus.ceri);
      await context.dispatch('save', {
        sourceCeri: xref,
        destinationCeri: tsStatus.ceri,
        modifiedFields,
      });
      tsStatus.passedCeris = { [xref]: tsStatus.ceri };
    }
    return tsStatus;
  },
  async duplicateProblemSet(context, xref): Promise<ContentBuildStatus> {
    const psStatus: ContentBuildStatus = await copyProblemSet(xref);
    if (psStatus.failMessages?.length) {
      context.commit('setValidationError', psStatus);
    } else {
      await context.dispatch('getProblemSet', {
        xref: psStatus.ceri,
      });
    }
    return psStatus;
  },
  // FIXME: Figure out if it is easier if we redownload the updated definition on save
  // every time rather than all of this local processing to fix mappings and update fields.
  async saveProblem(
    context,
    { xref, modifiedFields, opType }
  ): Promise<ContentBuildStatus> {
    let prStatus: ContentBuildStatus | null = null;
    if (xref) {
      if (isPublished(xref)) {
        const pr = await context.dispatch('getProblem', {
          xref,
        });
        if (
          pr.permissions.includes(AclPermissionType.UPDATE) &&
          pr.mappedCeri
        ) {
          xref = pr.mappedCeri;
        }
      }
      if (isPublished(xref) && isEmpty(modifiedFields) && opType) {
        // Nothing more we can do.
        // CANNOT return here. We need to handle WIP tutorings if any.
        prStatus = { ceri: xref };
      } else {
        // Update Problem.
        prStatus = await updateProblem(xref, modifiedFields, opType);
      }
    } else {
      // Create NEW Problem.
      prStatus = await createProblem(modifiedFields, opType);
    }
    if (prStatus.ceri) {
      // Validate or publish (only if no failures on parent Problem) Tutor Strategies on Problem IF ANY.
      if (
        opType == ContentSaveOperationType.VALIDATE ||
        (opType == ContentSaveOperationType.PUBLISH && !prStatus.failMessages)
      ) {
        const pr = await context.dispatch('getProblem', {
          xref: prStatus.ceri,
        });
        const targets = [pr.xref];
        if (
          pr.permissions.includes(AclPermissionType.UPDATE) &&
          pr.mappedCeri
        ) {
          targets.push(pr.mappedCeri);
        }
        if (xref && !targets.includes(xref)) {
          targets.push(xref);
        }
        const tutorStrategies = [];
        // FIXME: Re-download Problem tutorings to make sure we have all of them?
        for (const target of targets) {
          tutorStrategies.push(
            ...(context.getters.targetToTutorStrategiesMap[target] ?? [])
          );
        }
        const tsPromises: Promise<ContentBuildStatus>[] = [];
        for (const tutorStrategy of tutorStrategies) {
          if (
            isPublished(tutorStrategy.xref) &&
            tutorStrategy.permissions.includes(AclPermissionType.UPDATE) &&
            tutorStrategy.mappedCeri
          ) {
            // Ignore Published version to prevent duplicate requests as the call below will attempt to
            // publish ONLY the WIP version, which we should have included in this list already.
            continue;
          }
          tsPromises.push(
            context.dispatch('saveTutorStrategy', {
              xref: tutorStrategy.xref,
              modifiedFields: {
                // MUST include for backend to instantiate the class.
                tutorStrategyType: tutorStrategy.tutorStrategyType,
              },
              opType,
            })
          );
        }
        // Not calling allSettled(). The backend should 200 with validation or publish errors;
        // otherwise, we probably need to handle that error anyways, so report as failed request.
        const tsStatuses = await Promise.all(tsPromises);
        // Account Tutoring failures on parent Problem.
        for (const tsStatus of tsStatuses) {
          if (tsStatus.failMessages) {
            prStatus = {
              ...prStatus,
              failMessages: [
                ...(prStatus.failMessages ?? []),
                ...tsStatus.failMessages,
              ],
            };
          }
          if (tsStatus.failedCeris) {
            prStatus.failedCeris = [
              ...(prStatus.failedCeris ?? []),
              ...tsStatus.failedCeris,
            ];
          }
          if (tsStatus.passedCeris) {
            prStatus.passedCeris = {
              ...(prStatus.passedCeris ?? {}),
              ...tsStatus.passedCeris,
            };
          }
        }
      }
      if (!prStatus.failMessages) {
        await context.dispatch('save', {
          sourceCeri: xref,
          destinationCeri: prStatus.ceri,
          modifiedFields,
        });
        prStatus.passedCeris = {
          ...(prStatus.passedCeris ?? {}),
          [xref]: prStatus.ceri,
        };
      }
    }
    if (prStatus.failMessages) {
      context.commit('setValidationError', prStatus);
      prStatus.failedCeris = [...(prStatus.failedCeris ?? []), xref];
    } else {
      context.commit('deleteValidationError', prStatus.ceri);
    }
    return prStatus;
  },
  // FIXME: Figure out if it is easier if we redownload the updated definition on save
  // every time rather than all of this local processing to fix mappings and update fields.
  async saveProblemSet(
    context,
    { xref, modifiedFields, opType }
  ): Promise<ContentBuildStatus> {
    let psStatus: ContentBuildStatus | null = null;
    if (xref) {
      if (isPublished(xref)) {
        const ps = await context.dispatch('getProblemSet', {
          xref,
        });
        if (
          ps.permissions.includes(AclPermissionType.UPDATE) &&
          ps.mappedCeri
        ) {
          xref = ps.mappedCeri;
        }
      }
      const memberPromises = [];
      const childProblemSets = [];
      if (opType) {
        const ps = await context.dispatch('getProblemSet', { xref });
        for (const child of ps.children) {
          const contentType = getContentType(child);
          switch (contentType) {
            case ContentType.PROBLEM:
              memberPromises.push(
                context.dispatch('saveProblem', {
                  xref: child,
                  modifiedFields: {},
                  opType,
                })
              );
              break;
            case ContentType.PROBLEM_SET:
              childProblemSets.push(child);
              break;
          }
        }
      }
      // Not calling allSettled(). The backend should 200 with validation or publish errors;
      // otherwise, we probably need to handle that error anyways, so report as failed request.
      const memberStatuses = await Promise.all(memberPromises);
      if (childProblemSets.length) {
        for (const child of childProblemSets) {
          memberStatuses.push(
            await context.dispatch('saveProblemSet', {
              xref: child,
              modifiedFields: {},
              opType,
            })
          );
        }
      }
      // Account member failures on parent.
      psStatus = { ceri: xref };
      for (const memberStatus of memberStatuses) {
        if (memberStatus.failMessages) {
          psStatus.failMessages = [
            ...(psStatus.failMessages ?? []),
            ...memberStatus.failMessages,
          ];
        }
        if (memberStatus.failedCeris) {
          psStatus.failedCeris = [
            ...(psStatus.failedCeris ?? []),
            memberStatus.ceri,
          ];
        }
        if (memberStatus.passedCeris) {
          psStatus.passedCeris = {
            ...(psStatus.passedCeris ?? {}),
            ...memberStatus.passedCeris,
          };
        }
      }
      // Finally... Update Problem Set if needed.
      if (isPublished(xref) && isEmpty(modifiedFields) && opType) {
        return psStatus;
      } else if (!psStatus.failMessages) {
        psStatus = await updateProblemSet(xref, modifiedFields, opType);
      }
    } else {
      // Create NEW Problem Set. No members.
      psStatus = await createProblemSet(modifiedFields, opType);
    }
    if (psStatus.failMessages) {
      context.commit('setValidationError', psStatus);
      psStatus.failedCeris = [...(psStatus.failedCeris ?? []), xref];
    } else if (psStatus.ceri) {
      context.commit('deleteValidationError', psStatus.ceri);
      await context.dispatch('save', {
        sourceCeri: xref,
        destinationCeri: psStatus.ceri,
        modifiedFields,
      });
      psStatus.passedCeris = { ...psStatus.passedCeris, [xref]: psStatus.ceri };
    }
    return psStatus;
  },
  async addProblemSetMembers(
    context,
    { xref, members, opType }
  ): Promise<ContentBuildStatus> {
    const memberPromises = [];
    for (const member of members) {
      const type = getContentType(member);
      switch (type) {
        case ContentType.PROBLEM:
          memberPromises.push(
            context.dispatch('getProblem', {
              xref: member,
            })
          );
          break;
        case ContentType.PROBLEM_SET:
          memberPromises.push(
            context.dispatch('getProblemSet', {
              xref: member,
            })
          );
          break;
        default:
          throw new Error(`Unrecognized content type: ${type}`);
      }
    }
    await Promise.all(memberPromises);
    const status = await addMembersToProblemSet(xref, members);
    if (status.failMessages) {
      context.commit('setValidationError', status);
    } else if (status.ceri) {
      context.commit('deleteValidationError', status.ceri);
      const children = await getProblemSetChildrenXrefs(status.ceri);
      await context.dispatch('save', {
        sourceCeri: xref,
        destinationCeri: status.ceri,
        modifiedFields: { children },
      });
      if (opType) {
        return context.dispatch('saveProblemSet', {
          xref: status.ceri,
          modifiedFields: {},
          opType,
        });
      }
    }
    return status;
  },
  async removeProblemSetMembers(
    context,
    { xref, members, opType }
  ): Promise<ContentBuildStatus> {
    const status = await removeMembersFromProblemSet(xref, members);
    if (status.failMessages) {
      context.commit('setValidationError', status);
    } else if (status.ceri) {
      context.commit('deleteValidationError', status.ceri);
      let children: string[];
      try {
        children = await getProblemSetChildrenXrefs(status.ceri);
      } catch (e) {
        // No children found?
        children = [];
      }
      await context.dispatch('save', {
        sourceCeri: xref,
        destinationCeri: status.ceri,
        modifiedFields: { children },
      });
      if (opType) {
        return context.dispatch('saveProblemSet', {
          xref: status.ceri,
          modifiedFields: {},
          opType,
        });
      }
    }
    return status;
  },
  async moveProblemSetMember(
    context,
    { xref, moved, opType }
  ): Promise<ContentBuildStatus> {
    if (!isEqual(moved.oldIndex, moved.newIndex)) {
      const ps = await context.dispatch('getProblemSet', {
        xref,
      });
      const member = ps.children[moved.oldIndex];
      const status = await updateProblemSetMemberPosition(
        ps.xref,
        member,
        // Positions start at 1.
        moved.newIndex + 1
      );
      if (status.failMessages) {
        context.commit('setValidationError', status);
      } else if (status.ceri) {
        context.commit('deleteValidationError', status.ceri);
        // Reposition member in start index to end index.
        const temp = cloneDeep(ps.children);
        const members = temp.splice(moved.oldIndex, 1);
        temp.splice(moved.newIndex, 0, members[0]);
        await context.dispatch('save', {
          sourceCeri: xref,
          destinationCeri: status.ceri,
          modifiedFields: { children: temp },
        });
        if (opType) {
          return context.dispatch('saveProblemSet', {
            xref: status.ceri,
            modifiedFields: {},
            opType,
          });
        }
      }
      return status;
    }
    return Promise.resolve({ ceri: xref });
  },
  async replaceProblemSetMembers(
    context,
    { xref, members, opType }
  ): Promise<ContentBuildStatus> {
    const memberPromises = [];
    for (const member of members) {
      const type = getContentType(member);
      switch (type) {
        case ContentType.PROBLEM:
          memberPromises.push(
            context.dispatch('getProblem', {
              xref: member,
            })
          );
          break;
        case ContentType.PROBLEM_SET:
          memberPromises.push(
            context.dispatch('getProblemSet', {
              xref: member,
            })
          );
          break;
        default:
          throw new Error(`Unrecognized content type: ${type}`);
      }
    }
    await Promise.all(memberPromises);
    const status = await replaceMembersInProblemSet(xref, members);
    if (status.failMessages) {
      context.commit('setValidationError', status);
    } else if (status.ceri) {
      context.commit('deleteValidationError', status.ceri);
      const children = await getProblemSetChildrenXrefs(status.ceri);
      await context.dispatch('save', {
        sourceCeri: xref,
        destinationCeri: status.ceri,
        modifiedFields: { children },
      });
      if (opType) {
        return context.dispatch('saveProblemSet', {
          xref: status.ceri,
          modifiedFields: {},
          opType,
        });
      }
    }
    return status;
  },
  // TODO: Revert. In the case of revert, we want to delete WIP version AND replace it with the Published version.
  deleteProblemSet(context, { xref }): void {
    for (const psCeri in context.state.problemSetMap) {
      const modifiedFields: Partial<ProblemSetDefinition> = {};
      const problemSet = context.state.problemSetMap[psCeri];
      // Cleanup memberships if needed.
      if (problemSet.children.includes(xref)) {
        modifiedFields.children = problemSet.children.filter(
          (child) => child != xref
        );
      }
      // Cleanup mappings if needed.
      if (problemSet.mappedCeri == xref) {
        modifiedFields.mappedCeri = undefined;
      }
      if (!isEmpty(modifiedFields)) {
        // Save Problem Set.
        context.commit('setProblemSet', {
          ...problemSet,
          ...modifiedFields,
        });
      }
    }
    // Finally... delete Problem Set from map.
    context.commit('deleteProblemSet', xref);
    context.commit('deleteValidationError', xref);
  },
  // TODO: Revert. In the case of revert, we want to delete WIP version AND replace it with the Published version.
  deleteProblem(context, { xref }): void {
    // Cleanup memberships if needed.
    for (const psCeri in context.state.problemSetMap) {
      const problemSet = context.state.problemSetMap[psCeri];
      if (problemSet.children.includes(xref)) {
        // Save Problem Set.
        context.commit('setProblemSet', {
          ...problemSet,
          children: problemSet.children.filter((child) => child != xref),
        });
      }
    }
    // Cleanup mappings if needed.
    for (const prCeri in context.state.problemMap) {
      const problem = context.state.problemMap[prCeri];
      if (problem.mappedCeri == xref) {
        // Save Problem.
        context.commit('setProblem', {
          ...problem,
          mappedCeri: undefined,
        });
      }
    }
    // Delete associated Tutorings if needed.
    const tutorStrategies =
      context.getters.targetToTutorStrategiesMap[xref] ?? [];
    for (const tutorStrategy of tutorStrategies) {
      // Delete Tutoring.
      context.dispatch('deleteTutorStrategy', { xref: tutorStrategy.xref });
    }
    // Finally... delete Problem from map.
    context.commit('deleteProblem', xref);
    context.commit('deleteValidationError', xref);
  },
  // TODO: Revert. In the case of revert, we want to delete WIP version AND replace it with the Published version.
  deleteTutorStrategy(context, { xref }): void {
    // Cleanup mappings if needed.
    for (const tsCeri in context.state.tutorStrategyMap) {
      const tutoring = context.state.tutorStrategyMap[tsCeri];
      if (tutoring.mappedCeri == xref) {
        // Save Tutoring.
        context.commit('setTutorStrategy', {
          ...tutoring,
          mappedCeri: undefined,
        });
      }
    }
    // Finally... delete Tutoring from map.
    context.commit('deleteTutorStrategy', xref);
    context.commit('deleteValidationError', xref);
  },
  validate(context, { xref, modifiedFields }): Promise<ContentBuildStatus> {
    const type = getContentType(xref);
    switch (type) {
      case ContentType.PROBLEM:
        return context.dispatch('saveProblem', {
          xref,
          modifiedFields: {},
          opType: ContentSaveOperationType.VALIDATE,
        });
      case ContentType.PROBLEM_SET:
        return context.dispatch('saveProblemSet', {
          xref,
          modifiedFields: {},
          opType: ContentSaveOperationType.VALIDATE,
        });
      case ContentType.TUTOR_STRATEGY:
        return context.dispatch('saveTutorStrategy', {
          xref,
          modifiedFields,
          opType: ContentSaveOperationType.VALIDATE,
        });
      default:
        throw new Error(`Unrecognized content type: ${type}`);
    }
  },
  publish(context, { xref, modifiedFields }): Promise<ContentBuildStatus> {
    const type = getContentType(xref);
    switch (type) {
      case ContentType.PROBLEM:
        return context.dispatch('saveProblem', {
          xref,
          modifiedFields: {},
          opType: ContentSaveOperationType.PUBLISH,
        });
      case ContentType.PROBLEM_SET:
        return context.dispatch('saveProblemSet', {
          xref,
          modifiedFields: {},
          opType: ContentSaveOperationType.PUBLISH,
        });
      case ContentType.TUTOR_STRATEGY:
        return context.dispatch('saveTutorStrategy', {
          xref,
          modifiedFields,
          opType: ContentSaveOperationType.PUBLISH,
        });
      default:
        throw new Error(`Unrecognized content type: ${type}`);
    }
  },
};
