
import {
  updateMyUserAttribute,
  patchUserSetting,
  updateUser,
  deleteMyUserAttribute,
} from '@/api/core/user.api';
import { UpdateUserObject, UserAttributes } from '@/domain/User';
import { Component, Vue, Prop } from 'vue-property-decorator';
import UnsavedChangesDialog from '@/components/Settings/UnsavedChangesDialog.vue';
import { Settings } from '@/domain/Settings';
import { transformToArray } from '@/utils/arrays.util';
import { AttributeValueType } from '@/domain/Attributes';

@Component({
  components: {
    UnsavedChangesDialog,
  },
})
export default class ActionButtons extends Vue {
  @Prop() data: UpdateUserObject;
  @Prop() value: boolean;

  showUnsavedChangesDialog = false;
  savingChanges = false;

  get dataChanged(): boolean {
    return this.value;
  }

  set dataChanged(val: boolean) {
    this.$emit('input', val);
  }

  async saveChanges(): Promise<void> {
    try {
      if (this.dataChanged) {
        const promises = [];
        this.savingChanges = true;

        // Update the user object
        if (this.data.user) {
          // Update store if user has successfully updated
          promises.push(updateUser(this.data.user));
        }

        // Update the settings
        if (this.data.settings) {
          const settingsPairs: Array<{
            settingName: string;
            value: boolean | string | number;
          }> = Object.keys(this.data.settings as Settings).map((key) => ({
            settingName: key,
            value: (this.data.settings as Settings)[key],
          }));

          settingsPairs.forEach(({ settingName, value }) => {
            // Update store if setting was successfully updated
            promises.push(patchUserSetting(settingName, value));
          });
        }

        // Update attributes
        if (this.data.attributes) {
          const data = this.data.attributes as UserAttributes;
          Object.keys(data).forEach((key: string) => {
            // eslint-disable-next-line
            // @ts-ignore
            const value = data[key];
            // Empty/array check
            const fieldValue = transformToArray(value);
            // Not null or undefined and not an empty list of values
            if (value && fieldValue.length > 0) {
              promises.push(
                updateMyUserAttribute(key, fieldValue as AttributeValueType[])
              );
            } else {
              promises.push(deleteMyUserAttribute(key));
            }
          });
        }

        Promise.all(promises)
          .then(() => {
            this.$notify('Settings updated successfully.');
            this.$emit('save', true);
          })
          .catch(() => {
            this.$notify('Settings failed to update.');
          })
          .finally(() => {
            this.dataChanged = false;
            this.savingChanges = false;
          });
      }
    } catch (error) {
      this.$notify('An error has occured.');
      this.savingChanges = false;
    }
  }

  discardChanges(): void {
    this.dataChanged = false;
    this.$emit('discardChanges');
  }
}
