import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RESET_STATE_MUTATION, RootState } from './types';
import { auth } from './auth/index';
import { skillList } from './skills/index';
import { search } from './search/index';
import { curricula } from './curriculum/index';
import { classList } from './class/index';
import { myps } from './myps/index';
import { urls } from './urls/index';
import { initializeApp } from '@/api/init.api';
import { tutor } from './tutor/index';
import { notifications } from './notifications/index';
import { meta } from './meta/index';
import { attributions } from './attribution';
import { lti } from './lti/index';
import { insightsHub } from './insightshub/index';
import { LmsProviderType } from '@/domain/LmsProviderType';
import getAlertMessages from '../router/middleware/getAlertMessages';
import { isAuthenticated } from '../utils/cookie.util';
import { folder } from './folder/index';
import { content } from './content/index';

let initPromise: Promise<void> = Promise.resolve();

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  strict: process.env.NODE_ENV !== 'production',
  actions: {
    // Check out async vs non-asyc key word.
    initialize({ state, commit, dispatch }) {
      if (
        !state.auth.hasInitialized &&
        !state.auth.isInitializing &&
        isAuthenticated()
      ) {
        commit('auth/setIsInitializing', true);
        initPromise = initializeApp()
          .then((resp) => {
            if (resp) {
              const { user } = resp;
              commit('auth/setUser', user, { root: true });
              // TODO: Clean this up: currently ignores url sent from server and generates programatically here should
              // back end omit the url? Is there a reason to send it from back-end?
              // Future plan is to turn these urls into a singlular object OR to have these urls hard-coded in env files
              const tutorTemplateUrl = `https://tutor${process.env.VUE_APP_URL_SUB}.assistments.org/TutorClient/student/index.html`;
              commit('urls/setTutorTemplateUrl', tutorTemplateUrl, {
                root: true,
              });
              commit('auth/setHasInitialized', true);
              getAlertMessages();
              const promises = [];
              promises.push(dispatch('auth/getUserFolders'));
              if (user.lmsProviderType === LmsProviderType.LTI_ENABLED) {
                promises.push(dispatch('lti/getLaunch'));
              }
              return Promise.all(promises).then(() => {
                return;
              });
            }
          })
          .finally(() => {
            commit('auth/setIsInitializing', false);
          });
      }
      return initPromise;
    },
    reset({ state, commit }) {
      // FIXME: Figure out if/how we determine which modules to reset. Investigate:
      // https://github.com/vuejs/vuex/issues/1118
      // https://github.com/huybuidac/vuex-extensions#store-resets-to-initial-state
      // At the moment, will only clear if the module has the mutation to do so.
      for (const module in state) {
        // FIXME: Figure out if there is an easy way to check whether a mutation type/key
        // exists before calling commit. https://github.com/vuejs/vuex/issues/1638?
        commit(`${module}/${RESET_STATE_MUTATION}`);
      }
    },
  },
  modules: {
    auth,
    skillList,
    search,
    curricula,
    classList,
    myps,
    urls,
    tutor,
    notifications,
    meta,
    attributions,
    lti,
    insightsHub,
    folder,
    content,
  },
};

export default new Vuex.Store<RootState>(store);
