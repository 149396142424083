
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ChartDataset } from 'chart.js';

export type OverTimeChartDataSet = ChartDataset & {
  label: string;
  chartLevel: string;
  legendColors: Array<string[]>;
};

@Component
export default class OverTimeChartLegend extends Vue {
  @Prop({ required: true }) dataSets: OverTimeChartDataSet[];

  get hideNames(): boolean {
    return this.$store.state.insightsHub.anonymized;
  }

  capitalize(stringToCap: string): string {
    if (!stringToCap) {
      return '';
    }
    return stringToCap.charAt(0).toUpperCase() + stringToCap.slice(1);
  }
}
