import { Component, Vue } from 'vue-property-decorator';

@Component
export default class TutorMixins extends Vue {
  get firstTuteeColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.primary.lighten1;
  }

  get firstTuteeTextColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.primary.darken4;
  }

  get secondTuteeColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.yellow.lighten1;
  }

  get secondTuteeTextColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.primary.darken4;
  }

  get thirdTuteeColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.primary.darken1;
  }

  get thirdTuteeTextColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.neutral.lighten5;
  }

  get fourthTuteeColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.neutral.lighten1;
  }

  get fourthTuteeTextColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.primary.darken4;
  }

  get labelColors(): Array<string> {
    // eslint-disable-next-line
    // @ts-ignore
    return [
      this.firstTuteeColor,
      this.secondTuteeColor,
      this.thirdTuteeColor,
      this.fourthTuteeColor,
    ];
  }

  get textColors(): Array<string> {
    // eslint-disable-next-line
    // @ts-ignore
    return [
      this.firstTuteeTextColor,
      this.secondTuteeTextColor,
      this.thirdTuteeTextColor,
      this.fourthTuteeTextColor,
    ];
  }

  get classAverageColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.neutral.darken3;
  }
}
