
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Bar } from 'vue-chartjs';
import {
  BarElement,
  CategoryScale,
  Chart,
  ChartData,
  ChartDataset,
  ChartEvent,
  ChartOptions,
  Legend,
  LegendItem,
  LinearScale,
  Tooltip,
  TooltipItem,
} from 'chart.js';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';

export interface Data {
  value: number; // Y-axis value
  // unitSymbol?: string; // Append to axis value in tooltip
  numProblems?: number;
}

export interface DataSets {
  [xref: string]: Array<Data>;
}

export interface TickLabel {
  text: string; // Set property of label's text value
  value: string | number; // Set property of label's value
  unicodeSymbol?: string; // Prepend to label's text value in tick label
  totalProblems?: number;
  standardDescription?: string;
  // targetObject: any; // Look for text and value keys in Object
}

export interface BarChartData {
  tickLabels: Array<TickLabel>;
  datasets: DataSets;
}

export interface CustomDataSetLabel {
  xref: string; // Identify dataset
  label: string; // Display text for dataset
  backgroundColor: string;
}
// Only one of defaults needed to affect every chart
Chart.defaults.font.family = "'Montserrat',  'Material Design Icons";
Chart.register(CategoryScale, LinearScale, BarElement, Legend, Tooltip);

export enum FilterEmits {
  CORRECT = 'CORRECT',
  CORRECT_EVENTUALLY = 'CORRECT_EVENTUALLY',
  INCORRECT = 'INCORRECT',
  REDO = 'REDO',
}

@Component({
  components: { Bar },
})
export default class BarChartView extends Vue {
  @Prop({ required: true }) customChartLabels: Array<CustomDataSetLabel>;
  @Prop({ required: true }) customChartData: BarChartData;
  @Prop({ default: 'start' }) legendAlignment: 'center' | 'end' | 'start';
  @Prop({ default: null }) unitSymbol: '%' | null;
  @Prop({ default: null }) stepSize: number | null;
  @Prop({ required: false }) stackedChart: boolean;
  @Prop({ default: false }) isInTestMode: boolean;
  @Prop({ default: false }) hasRedo: boolean;

  //////////////////////////
  // Chart Configurations //
  //////////////////////////

  get chartData(): ChartData {
    const labels = this.customChartData.tickLabels.map(
      (label: TickLabel) => label.value
    );
    const datasets: Array<ChartDataset> = [];
    // Graphed in order of given in custom chart labels
    this.customChartLabels.forEach((label: CustomDataSetLabel) => {
      if (this.customChartData.datasets[label.xref]) {
        // Skip class average labels
        if (label.xref != 'peer') {
          // Get dataset
          datasets.push({
            barThickness: 28,
            categoryPercentage: 0.9,
            ...label,
            data: this.customChartData.datasets[label.xref].map(
              // Plot on value on Y-axis
              (data: Data) => data.value
            ),
          });
        }
      } else {
        // No dataset at all?
        // Might not happen at all.
        datasets.push({
          ...label,
          data: [],
        });
      }
    });
    return {
      labels: labels, // X-axis: any
      datasets: datasets, // Y-axis: number
    };
  }

  mixpanelLastHoveredLabel = null;
  mixpanelHoverTimeout = null;
  mixpanelHoverCount = 0;

  get options(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'bottom',
          display: true,

          onClick: (e, legendItem, legend) => {
            const index = legendItem.datasetIndex as number;
            if (index === FilterEmits.REDO) {
              // Hide/show redo columns
              this.$emit('toggleRedoColumns');
            } else {
              this.$emit('toggleFilter', FilterEmits[index]);
            }
          },
          labels: {
            color: this.fontColor,
            usePointStyle: true,
            padding: 25,
            font: { size: 16 },
            generateLabels: (): LegendItem[] => {
              const labels: LegendItem[] = [
                {
                  datasetIndex: FilterEmits.CORRECT,
                  text: 'Correct',
                  fillStyle: this.correctColor,
                  lineWidth: 0,
                },
                {
                  datasetIndex: FilterEmits.INCORRECT,
                  text: 'Incorrect',
                  fillStyle: this.incorrectColor,
                  lineWidth: 0,
                },
              ];

              if (!this.isInTestMode) {
                labels.splice(1, 0, {
                  datasetIndex: FilterEmits.CORRECT_EVENTUALLY,
                  text: 'Correct Eventually',
                  fillStyle: this.correctEventuallyColor,
                  lineWidth: 0,
                });
              }

              if (this.hasRedo) {
                labels.push({
                  datasetIndex: FilterEmits.REDO,
                  text: '\u{F0456}   Redo',
                  fillStyle: '#fafafa',
                  strokeStyle: '#fafafa',
                  lineWidth: 0,
                });
              }

              return labels;
            },
          },
        },
        tooltip: {
          displayColors: false,
          backgroundColor: this.tooltipColor,
          titleFont: { size: 16 },
          titleColor: '#FFFFFFBD',

          titleMarginBottom: 10,
          titleAlign: 'center',
          bodyFont: { size: 15 },
          bodyColor: '#FFFFFFBD',

          bodySpacing: 6,
          padding: 12,
          callbacks: {
            title: (tooltipItems) =>
              this.stackedChart
                ? this.getXTickLabel(tooltipItems[0].dataIndex as number) ?? ''
                : '',

            label: this.getHoverLabels,
          },
        },
      },
      elements: {
        bar: {
          // This is needed to make corners rounded when switching stacked modes
          borderRadius: this.stackedChart ? [6] : [{ topLeft: 6, topRight: 6 }],
          borderWidth: 1.5,
          borderColor: '#fafafa',
        },
      },
      scales: {
        x: {
          ticks: {
            fontSize: 15,
            color: this.fontColor,
            // eslint-disable-next-line
            // @ts-ignore
            // Include a focus skill icon
            callback: this.stackedChart
              ? this.getXTickLabelForStacked
              : this.getXTickLabel,
          },
          grid: {
            display: !this.stackedChart,
          },
          stacked: this.stackedChart,
        },
        y: {
          beginAtZero: true,
          ticks: {
            precision: 0,
            color: '#000',
          },
          grid: { borderDash: [20, 5] },
          stacked: this.stackedChart,
        },
      },
    };
  }

  //////////////////
  // Chart Colors //
  //////////////////

  get fontColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.primary.darken1;
  }

  get tooltipColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.neutral.darken4;
  }

  get xTickColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.primary.base;
  }

  get correctColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.correct;
  }

  get correctEventuallyColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.correctEventually;
  }

  get incorrectColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.incorrect;
  }

  /////////////
  // Methods //
  /////////////

  getXTickLabel(index: number): string | null {
    const targetLabel: TickLabel = this.customChartData.tickLabels[index];
    if (targetLabel) {
      const labelText = targetLabel.text;
      return labelText;
    }
    return null;
  }

  getXTickLabelForStacked(id: number, index: number): string | string[] | null {
    const redoSymbol = '\u{F0456}';
    const targetLabel: TickLabel = this.customChartData.tickLabels[index];
    if (targetLabel) {
      const labelText = targetLabel.text;

      const [problemLabel, partLabel] = labelText.split(':');

      if (partLabel) {
        // Is multi part or redo
        const [_, partLetter] = partLabel.trim().split(' ');
        if (partLabel.includes('Redo')) {
          // Is redo
          return [redoSymbol + problemLabel, ...[partLetter ?? []]];
        } else {
          // Is multi part
          return [problemLabel, `(${partLetter})`];
        }
      }
      // Not multi part or redo
      return [problemLabel];
    }
    return null;
  }

  getHoverLabels(tooltipItem: TooltipItem<'bar'>): string | string[] {
    const datasets = tooltipItem.chart.data.datasets;

    // Check if the hovered label has changed
    if (this.mixpanelLastHoveredLabel !== tooltipItem.label) {
      clearTimeout(this.mixpanelHoverTimeout); // Clear the existing timeout
      this.mixpanelHoverCount = 0; // Reset the hover count
      this.mixpanelLastHoveredLabel = tooltipItem.label; // Update the last hovered label
    } else {
      this.mixpanelHoverCount++; // Increment the hover count
      if (this.mixpanelHoverCount === 3) {
        // Check if hover count reaches 3
        // Set a timeout to call trackHoverEvent after a delay
        this.mixpanelHoverTimeout = setTimeout(() => {
          this.trackHoverEvent(tooltipItem);
        }, 1000); // 1.0 second delay
      }
    }

    if (!this.stackedChart) {
      const datasetIndex = tooltipItem.datasetIndex as number;
      return `${this.customChartLabels[datasetIndex].label}: ${
        datasets[datasetIndex].data[tooltipItem.dataIndex as number]
      }`;
    }

    const index = tooltipItem.dataIndex;
    let answerNum = 0;

    for (const dataset of datasets) {
      if (dataset.data) {
        answerNum += dataset.data[index] as number;
      }
    }
    return (
      datasets.map((set, i) => {
        return `${set.label}: ${set.data[index]}/${answerNum}`;
      }) ?? ''
    );
  }

  plugins = [
    {
      id: 'clickListener',
      afterEvent: (chart: Chart, args: { event: ChartEvent }) => {
        if (args.event.type === 'click') {
          const mousePoint = args.event;
          if (!mousePoint.x || !mousePoint.y) {
            return;
          }

          const xAxis = chart.scales['x'];

          const tickIndex = xAxis.getValueForPixel(mousePoint.x) as number;

          const textWidth = chart.ctx.measureText(
            xAxis.ticks[tickIndex] as unknown as string
          ).width;

          const labelMidpoint = xAxis.getPixelForTick(tickIndex);
          const labelLeftBound = labelMidpoint - textWidth / 2;
          const labelRightBound = labelMidpoint + textWidth / 2;

          const withinX =
            mousePoint.x > labelLeftBound - 5 &&
            mousePoint.x < labelRightBound + 5;
          const withinY =
            mousePoint.y > xAxis.top && mousePoint.y < xAxis.bottom;

          if (withinX && withinY) {
            const label = chart.scales['x'].getLabelForValue(tickIndex);
            this.$emit('clickedLabel', label);
          }
        }
      },
    },
  ];

  //////////////
  // Mixpanel //
  //////////////

  trackHoverEvent(tooltipItem: TooltipItem<'bar'>): void {
    const datasets = tooltipItem.chart.data.datasets;
    const index = tooltipItem.dataIndex;

    // Function to find the dataset by label
    const findDataset = (label: string) =>
      datasets.find((dataset) => dataset.label === label);

    // Extract the problemId from the tooltipItem
    const problemCode = tooltipItem.label;

    // Check if the Redo dataset is hovered
    const redoDataset = findDataset('Redo');
    const isRedoHovered = redoDataset && redoDataset.data[index] > 0;

    // Only proceed if Redo is not hovered or hasRedo is true
    if (!isRedoHovered || this.hasRedo) {
      // Get the datasets for each category
      const correctDataset = findDataset('Correct');
      const correctEventuallyDataset = findDataset('Correct Eventually');
      const incorrectDataset = findDataset('Incorrect');

      // Only include the scores if the datasets exist
      const trackingData: any = {
        problemCode: problemCode,
      };

      if (correctDataset)
        trackingData.correctScore = correctDataset.data[index];
      if (correctEventuallyDataset)
        trackingData.correctEventuallyScore =
          correctEventuallyDataset.data[index];
      if (incorrectDataset)
        trackingData.incorrectScore = incorrectDataset.data[index];
      if (isRedoHovered) trackingData.redoScore = redoDataset.data[index];
      // Emit the tracking data instead of calling trackMixpanel directly
      this.$emit('trackHoverEvent', trackingData);
      //trackMixpanel(EventType.assignmentReportPerfSumHover, trackingData);
    }
  }
}
