
import { Component, Prop, Vue } from 'vue-property-decorator';
import HintsOrExplanationView from './HintsOrExplanationView.vue';
import NoHintsOrExplanation from './NoHintsOrExplanation.vue';
import ChangeTypeDialog from './ChangeTypeDialog.vue';

import {
  ContentBuildStatus,
  ContentSaveOperationType,
  TutoringFilterParams,
  getTutorStrategy,
  deleteTutorStrategy,
} from '@/api/core/content.api';
import {
  Explanation,
  HintSet,
  ITutorStrategy,
  TutorStrategyType,
} from '@/domain/Tutoring';
import { ProblemDefinition } from '@/domain/Problem';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';
import {
  PersistableStateType,
  UserToContentRelationshipType,
} from '@/domain/Content';
import { AclPermissionType } from '@/domain/Acls';

@Component({
  components: {
    HintsOrExplanationView,
    NoHintsOrExplanation,
    ChangeTypeDialog,
  },
})
export default class StudentSupportAuthoring extends Vue {
  @Prop({ required: true }) problem!: ProblemDefinition;
  supports: ITutorStrategy[] = [];
  updatedSupportContent: ITutorStrategy[] = [];

  items = ['Hint', 'Explanation'];

  supportType = 'Hint';
  supportTypeOld = 'Hint';
  showConfirmationDialog = false;
  firstHintOrExplanation = true;

  isLoading = false;

  changeSupportType(): void {
    this.supports = [];
    this.isLoading = true;

    if (this.supportContent) {
      const xref = this.supportContent.xref;

      deleteTutorStrategy(xref).then(() => {
        this.refreshStudentSupportsForProblems();

        this.supportTypeOld =
          this.supportType === 'Hint' ? 'Explanation' : 'Hint';

        // Track support type change
        trackMixpanel(EventType.trackStudentSupportChanged, {
          problemXref: this.problem.xref,
          hintOrExplanation: this.supportTypeOld,
        });
      });
    }
    {
      this.noHintsOrExplanation();
      this.refreshStudentSupportsForProblems();
    }
  }

  refreshStudentSupportsForProblems(): void {
    if (this.supports.length === 0) {
      const filterParams: TutoringFilterParams = {
        targets: [this.problem.xref],
        psTypes: [PersistableStateType.PUBLISHED],
        user: 'me',
        filterBy: [UserToContentRelationshipType.OWNER],
      };

      this.$store
        .dispatch('content/getTutorStrategies', { filterParams })
        .then((supports: ITutorStrategy[]) => {
          if (this.supportType === 'Hint') {
            this.supportType = 'Explanation';
          } else {
            this.supportType = 'Hint';
          }

          this.supportTypeOld = this.supportType;
          this.createSupportDataStructure(supports);

          this.firstHintOrExplanation = false;
          this.isLoading = false;
        });
    }
  }

  get supportContent(): ITutorStrategy | null {
    return this.supports.length > 0 ? this.supports[0] : null;
  }

  inputSelect() {
    this.showConfirmationDialog = true;
    // reset value
    this.supportType = '';
    // set timeout here, if no set timeout so the code on the line above will be useless
    setTimeout(() => {
      this.supportType = this.supportTypeOld;
    }, 100);
    this.$forceUpdate();
  }

  noHintsOrExplanation() {
    this.firstHintOrExplanation = true;
  }

  addFirst(type: string) {
    this.firstHintOrExplanation = false;
    this.supportType = type;
  }

  created() {
    this.isLoading = true;

    const filterParams: TutoringFilterParams = {
      targets: [this.problem.xref],
      psTypes: [PersistableStateType.PUBLISHED],
      types: [TutorStrategyType.EXPLANATION, TutorStrategyType.HINT_SET],
      user: 'me',
      filterBy: [UserToContentRelationshipType.OWNER],
    };

    if (
      this.problem.permissions.includes(AclPermissionType.UPDATE) &&
      this.problem.mappedCeri
    ) {
      filterParams.targets?.push(this.problem.mappedCeri);
    }

    this.$store
      .dispatch('content/getTutorStrategies', { filterParams })
      .then((supports: ITutorStrategy[]) => {
        const problemSupports = supports.filter(
          (strategy) => strategy.tutoringTarget === this.problem.xref
        );

        this.createSupportDataStructure(problemSupports);
        this.isLoading = false;
      });
  }

  createSupportDataStructure(supports: ITutorStrategy[]) {
    const studentSupports = supports.filter(
      (support) =>
        support.tutorStrategyType === TutorStrategyType.HINT_SET ||
        support.tutorStrategyType === TutorStrategyType.EXPLANATION
    );
    this.supports.push(...studentSupports);
  }

  createStudentSupport(
    content: ITutorStrategy,
    tutorStrategyType: 'HINTS' | 'EXPLANATIONS'
  ) {
    let requestBody: Partial<ITutorStrategy> = {
      htmlMarker: 0,
      trusted: false,
    };

    if (this.supportType === 'Explanation') {
      requestBody.tutorStrategyType = TutorStrategyType.EXPLANATION;
      requestBody.explanation = content;
    } else {
      requestBody.tutorStrategyType = TutorStrategyType.HINT_SET;
      requestBody.hints = Array.isArray(content)
        ? content.map((hint) => ({
            hint: hint.hint,
            hasVideo: hint.hasVideo || false,
          }))
        : [{ hint: content, hasVideo: false }];
    }

    this.isLoading = true;

    this.$store
      .dispatch('content/saveTutorStrategy', {
        problemCeri: this.problem.xref,
        modifiedFields: requestBody,
        opType: ContentSaveOperationType.PUBLISH,
      })
      .then((response) => {
        const xref = response.ceri;
        if (xref) {
          getTutorStrategy(xref).then((data: ITutorStrategy) => {
            const supports: ITutorStrategy[] = [data];

            this.createSupportDataStructure(supports);
            this.supportTypeOld = this.supportType;
            this.isLoading = false;
          });
        }

        trackMixpanel(EventType.trackStudentSupportCreated, {
          problemXref: this.problem.xref,
          hintOrExplanation: tutorStrategyType,
        });
      });
  }

  updateStudentSupport(content: ITutorStrategy | null): void {
    if (!content) {
      return;
    }

    const { xref, tutorStrategyType, ...rest } = content;
    const modifiedFields: Partial<ITutorStrategy> = {
      tutorStrategyType,
    };

    switch (tutorStrategyType) {
      case TutorStrategyType.HINT_SET: {
        const hintSet = rest as HintSet;
        (modifiedFields as HintSet).hints = hintSet.hints;
        break;
      }
      case TutorStrategyType.EXPLANATION: {
        const explanation = rest as Explanation;
        (modifiedFields as Explanation).explanation = explanation.explanation;
        break;
      }
      default:
        break;
    }

    this.updateTutorStrategy<ITutorStrategy>(
      xref,
      modifiedFields,
      ContentSaveOperationType.PUBLISH
    ).then((response) => {
      const updatedXref = response.ceri;

      if (updatedXref) {
        return getTutorStrategy(updatedXref).then((data: ITutorStrategy) => {
          const updatedSupport: ITutorStrategy[] = [data];
          this.supports = updatedSupport;
          this.createSupportDataStructure(updatedSupport);
          this.isLoading = false;

          // Track support update
          trackMixpanel(EventType.trackStudentSupportUpdated, {
            problemXref: this.problem.xref,
            hintOrExplanation: tutorStrategyType,
          });
        });
      }
    });
  }

  updateTutorStrategy<T>(
    xref: string,
    modifiedFields: Partial<T>,
    ops?: ContentSaveOperationType.PUBLISH
  ): Promise<ContentBuildStatus> {
    return this.$store
      .dispatch('content/saveTutorStrategy', {
        xref,
        modifiedFields,
        opType: ops,
      })
      .then((response) => {
        return response;
      });
  }

  deleteHint(index: number): void {
    if (!this.supportContent || !Array.isArray(this.supportContent.hints)) {
      return;
    }

    const currentXref = this.supportContent.xref;

    this.supportContent.hints.splice(index, 1);

    if (this.supportContent.hints.length === 0) {
      deleteTutorStrategy(currentXref)
        .then(() => {
          delete this.supportContent?.hints;
          return this.$store.dispatch('content/deleteTutorStrategy', {
            xref: currentXref,
          });
        })
        .then(() => {
          this.noHintsOrExplanation();

          // Track hint deletion
          trackMixpanel(EventType.trackStudentSupportDeleted, {
            problemXref: this.problem.xref,
            hintOrExplanation: 'HINT_SET',
          });
        });
    } else {
      const updatedHints = this.supportContent.hints.slice();
      const updatedContent = { ...this.supportContent, hints: updatedHints };
      this.updateTutorStrategy<HintSet>(currentXref, {
        tutorStrategyType: TutorStrategyType.HINT_SET,
        hints: updatedHints,
      }).then(() => {
        this.updateStudentSupport(updatedContent);
      });
    }
  }

  deleteExplanation() {
    const explanationIndex = this.supports.findIndex(
      (support) => support.tutorStrategyType === TutorStrategyType.EXPLANATION
    );
    if (explanationIndex !== -1) {
      const deletedSupport = this.supports.splice(explanationIndex, 1)[0];
      deleteTutorStrategy(deletedSupport.xref).then(() => {
        this.noHintsOrExplanation();

        // Track explanation deletion
        trackMixpanel(EventType.trackStudentSupportDeleted, {
          problemXref: this.problem.xref,
          hintOrExplanation: 'EXPLANATION_SET',
        });
      });
    }
  }
}
