
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ProblemDefinition } from '@/domain/Problem';
import ProblemView from '@/components/base/ProblemView.vue';
import Editor from '@tinymce/tinymce-vue';
import StudentSupportAuthoring from './StudentSupportAuthoring.vue';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import ProblemViewSDK3 from '../Builder/ContentView/ProblemViewSDK3.vue';

@Component({
  components: {
    Editor,
    ProblemViewSDK3,
    StudentSupportAuthoring,
  },
})
export default class StudentSupportsDialog extends Vue {
  @Prop({ default: true }) value: boolean; // Whether the dialog is open or not
  @Prop({ required: true }) problem: ProblemDefinition;

  currentProblemIndex = 0;

  // get currentProblem(): ProblemDefinition {
  //   return this.problems[this.currentProblemIndex];
  // }

  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(value: boolean) {
    this.$emit('input', value);
  }

  // get lastProblem(): boolean {
  //   return this.currentProblemIndex === this.problems.length - 1;
  // }

  // goNext() {
  //   if (this.currentProblemIndex < this.problems.length - 1) {
  //     this.currentProblemIndex++;
  //   }
  // }

  created() {
    document.addEventListener('focusin', (e) => {
      if ((e.target as HTMLElement).closest('.tox-dialog')) {
        e.stopImmediatePropagation();
      }
    });
  }

  // Mixpanel tracker for student support dialog
  @Watch('showDialog')
  onStudentSupportDialogChanged(newVal: boolean, oldVal: boolean): void {
    if (newVal === true && oldVal === false) {
      // Track event with Mixpanel when the dialog is shown
      trackMixpanel(EventType.trackStudentSupportDialogShown, {
        problemXref: this.problem.xref,
      });
    } else if (newVal === false && oldVal === true) {
      // Track event with Mixpanel when the dialog is hidden
      trackMixpanel(EventType.trackStudentSupportDialogClosed, {
        problemXref: this.problem.xref,
      });
    }
  }
}
