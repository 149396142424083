var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"top-row"}},[_c('StrandSelector',{attrs:{"skillStrands":_vm.skillStrands,"gradeToStrand":_vm.gradeToStrand},on:{"unsetCoherenceStandard":_vm.unsetCoherenceStandard}}),(_vm.loading)?_c('div',{staticClass:"mb-5",staticStyle:{"width":"100%"}},[_c('HeatmapSkeletonLoader')],1):(_vm.selectedStrand && !_vm.isDownloadingData)?_c('div',{staticClass:"content-container"},[_c('ModeTabs'),_c('div',{staticClass:"mb-10 pb-4 elevation-1 table-container"},[_c('v-row',{staticClass:"bottom-dividers flex-nowrap px-8 py-4 ma-0",attrs:{"align":"center","justify":"space-around","dense":""}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{staticStyle:{"font-size":"18px"},attrs:{"items":_vm.heatMapTypes,"hide-details":"auto","solo":"","flat":"","data-cy":"heatmap-type-select","menu-props":"offset-y"},model:{value:(_vm.heatMapType),callback:function ($$v) {_vm.heatMapType=$$v},expression:"heatMapType"}})],1),_c('v-col',{staticClass:"ml-10",attrs:{"cols":"2"}},[(_vm.heatMapType === _vm.HeatMapDisplayType.COHERENCY)?_c('v-select',{ref:"coherenceStandardSelect",attrs:{"items":_vm.standardsDropdownOptions,"item-value":"value","item-text":"text","data-cy":"coherence-standard-select","label":"Select a Standard","hide-details":"auto","outlined":"","autofocus":"","isMenuActive":"true","menu-props":{
              offsetY: true,
              transition: 'scroll-y-transition',
            }},on:{"focus":function($event){return _vm.openCoherenceSelect()}},model:{value:(_vm.selectedCoherenceStandard),callback:function ($$v) {_vm.selectedCoherenceStandard=$$v},expression:"selectedCoherenceStandard"}}):_vm._e()],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"text":"","color":"primary","data-cy":"hide-names-toggle"},on:{"click":function($event){_vm.anonymized = !_vm.anonymized}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-eye-outline")]),_vm._v(" "+_vm._s(_vm.showOrHideNamesText)+" Names ")],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"medium":"","color":"primary"}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.downloadCsv}},[_vm._v(" Download .csv ")])],1)],1)],1)],1),(_vm.headerTitle.length > 20)?_c('div',{staticClass:"ma-4"},[_vm._v(" "+_vm._s(_vm.headerTitle)+" ")]):_vm._e(),(
          _vm.heatMapType == _vm.HeatMapDisplayType.COHERENCY &&
          !_vm.selectedCoherenceStandard
        )?[_c('v-row',{staticClass:"justify-center",attrs:{"align":"center","no-gutters":""}},[_c('v-img',{attrs:{"contain":"","max-width":"450","src":require('@/assets/insights_hub_standard_not_selected.svg'),"data-cy":"standard-not-selected-image"}}),_c('div',[_c('v-col',[_c('span',{staticStyle:{"font-size":"22px","font-weight":"400","line-height":"24px","letter-spacing":"0.15px","color":"var(--v-neutral-darken4)"},attrs:{"data-cy":"standard-not-selected-text"}},[_vm._v("Select a Standard")]),_c('span',{staticClass:"mt-4",staticStyle:{"display":"block","font-size":"20px","font-weight":"300","line-height":"24px","letter-spacing":"0.15px","width":"300px","color":"var(--v-neutral-darken2)"},attrs:{"data-cy":"standard-not-selected-text"}},[_vm._v("Coherency is reported by standard - select a standard from the dropdown menu.")])])],1)],1)]:_c('HeatMap',{attrs:{"color":_vm.modeColor,"headerTitle":_vm.headerTitle,"rowHeaders":_vm.computedHeaders,"rows":_vm.computedRows,"collapsedPaths":_vm.collapsedPaths,"collapsibleHeaders":_vm.heatMapType != _vm.HeatMapDisplayType.COHERENCY,"disablePopoutGraphs":false,"options":_vm.options,"anonymized":_vm.anonymized,"loading":_vm.loading,"tooltips":_vm.headerToolTips,"highlightedColumnHeader":_vm.selectedCoherenceStandard?.nodeCode,"hasStudentLevel":true},on:{"updateOptions":_vm.updateOptions,"updateCollapsedPaths":_vm.updateCollapsedPaths,"updateCsvData":_vm.updateCsvData,"toggleStudentRows":_vm.getStudentNamesForCourse}})],2)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }