
import { deleteFolder, getFolderStats } from '@/api/core/folders.api';
import { AclPermissionType } from '@/domain/Acls';
import { FolderDefinition, FolderStats } from '@/domain/Folder';
import { cloneDeep } from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';
import { GroupChildDepthType } from '@/domain/Group';

@Component
export default class DeleteFolderDialog extends Vue {
  @Prop({ required: true }) path: FolderDefinition[];

  AclPermissionType = AclPermissionType;

  stats: FolderStats | null = null;

  dialog = false;
  downloading = false;
  loading = false;

  // If there are children within the source folder, BAD REQUEST.
  mustBeEmpty = true;

  get folder(): FolderDefinition {
    return this.path[this.path.length - 1];
  }

  get parent(): FolderDefinition {
    return this.path[this.path.length - 2];
  }

  get isEmptyFolder(): boolean {
    // Must be an empty folder to delete.
    return (
      this.stats?.numFolders === 0 &&
      (this.stats?.numProblemSets ?? 0) === 0 &&
      (this.stats?.numAssignments ?? 0) === 0
    );
  }

  save(): void {
    this.loading = true;
    deleteFolder(this.folder.xref, this.mustBeEmpty)
      .then(() => {
        const children = cloneDeep(this.parent.children);
        const index = this.parent.children.findIndex(
          (xref) => xref == this.folder.xref
        );
        children.splice(index, 1);
        this.$store.commit('folder/setFolder', {
          ...this.parent,
          children,
        });
        this.dialog = false;

        // Track folder deletion event in Mixpanel
        trackMixpanel(EventType.trackPSFolderDeleted, {
          folderName: this.folder.name,
          folderPath: this.path.map((folder) => folder.name).join('/'),
          folderXref: this.folder.xref,
        });
      })
      .catch(() => {
        this.$notify(`Failed to delete folder ${this.folder.name}`);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  @Watch('dialog')
  onDialog(): void {
    if (this.dialog && !this.downloading) {
      this.downloading = true;
      getFolderStats(this.folder.xref, GroupChildDepthType.ANY_CHILD)
        .then((stats: FolderStats) => {
          this.stats = stats;
        })
        .finally(() => {
          this.downloading = false;
        });
    }
  }
}
