
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SkillStrand } from '@/domain/Skill';

@Component
export default class StrandSelector extends Vue {
  @Prop({ required: true }) skillStrands: Array<SkillStrand>;
  @Prop({ required: true }) gradeToStrand: Map<string, string>;

  get selectedStrand() {
    return this.$store.state.insightsHub.selectedStrandId;
  }

  setSelectedStrand(skillStrandId: number, active: boolean) {
    //emit event to parent
    this.$emit('unsetCoherenceStandard');
    this.$store.commit(
      'insightsHub/setSelectedStrand',
      active ? skillStrandId : null
    );
  }

  isGradeStrand(skillStrandCode: string): string {
    return this.gradeToStrand.get(skillStrandCode) || '';
  }
}
