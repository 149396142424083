
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import axios, { CancelTokenSource } from 'axios';
import { isEqual } from 'lodash';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import { PersistableStateType } from '@/domain/Content';
import { searchForProblemSets } from '@/api/core/content.api';
import { ObjectList } from '@/api/core/base.api';

@Component
export default class SelectProblemSetIdDialog extends Vue {
  @Prop({ default: [] }) psCeris: string[];
  @Prop() value: boolean;

  selectedCeri: string | null = null;
  dialog = false;
  initialized = false;
  downloading = false;
  psIndex: number | null = null;
  source: CancelTokenSource | null = null;

  psCeriToPs: { [ceri: string]: ProblemSetDefinition | null } = {};

  get showDialog(): boolean {
    return this.value ? this.value : this.dialog;
  }

  set showDialog(val: boolean) {
    this.dialog = val;

    this.$emit('input', val);
  }

  get hasLoadedSome(): boolean {
    return Object.values(this.psCeriToPs).some((x) => x != null);
  }

  get strippedCeris(): string[] {
    return this.psCeris.map((ceri) => ceri.split('.'))[0];
  }

  setSelectedCeri(ceri: string): void {
    this.selectedCeri = ceri;
  }

  downloadProblemSets(ceris: string[]): Promise<void> {
    // Stop whatever was previously not downloaded (or downloading)
    if (this.source) {
      // Cancel prior requests with this cancel token
      this.source.cancel();
    }

    // Download problem sets
    this.downloading = true;

    // Clear previously downloaded problem sets
    this.psCeriToPs = {};

    // Use share cancel token for Problem Set requests
    this.source = axios.CancelToken.source();

    // Download Problem Sets
    return searchForProblemSets(
      { ceris, psTypes: [PersistableStateType.PUBLISHED] },
      undefined,
      undefined,
      this.source?.token
    )
      .then((problemSets: ObjectList<ProblemSetDefinition>) => {
        this.downloading = false;

        // Record Problem Set for Ceri
        problemSets.data.forEach((problemSet) => {
          this.$set(this.psCeriToPs, problemSet.xref, problemSet);
        });

        return;
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return Promise.reject(e);
        }
        return;
      })
      .finally(() => {
        this.downloading = false;
      });
  }

  created(): void {
    // Download Problem Sets
    this.downloadProblemSets(this.psCeris);
  }

  @Watch('showDialog')
  onShowDialog(value: boolean) {
    if (value) {
      // Clear prior selection
      this.selectedCeri = null;
    }
  }

  @Watch('psCeris')
  onPsCeris(newValue: string[], oldValue: string[]): void {
    if (isEqual(newValue, oldValue)) {
      return;
    }

    this.downloadProblemSets(newValue);
  }
}
