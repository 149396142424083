
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Bar } from 'vue-chartjs';
import {
  Chart,
  ChartData,
  ChartDataset,
  ChartEvent,
  ChartOptions,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
export interface Data {
  value: number; // Y-axis value
  // unitSymbol?: string; // Append to axis value in tooltip
  numProblems?: number;
}
export interface DataSets {
  [xref: string]: Array<Data>;
}
export interface TickLabel {
  text: string; // Set property of label's text value
  value: string | number; // Set property of label's value
  unicodeSymbol?: string; // Prepend to label's text value in tick label
  totalProblems?: number;
  standardDescription?: string;
  // targetObject: any; // Look for text and value keys in Object
}
export interface BarChartData {
  tickLabels: Array<TickLabel>;
  datasets: DataSets;
}
export interface CustomDataSetLabel {
  xref: string; // Identify dataset
  label: string; // Display text for dataset
  backgroundColor: string;
}

@Component({ components: { Bar } })
export default class HorizontalBarChartView extends Vue {
  @Prop({ required: true }) customChartLabels: Array<CustomDataSetLabel>;
  @Prop({ required: true }) customChartData: BarChartData;
  @Prop({ default: 'start' }) legendAlignment: 'center' | 'end' | 'start';
  @Prop({ default: null }) unitSymbol: '%' | null;
  @Prop({ default: null }) stepSize: number | null;
  //////////////////////////
  // Chart Configurations //
  //////////////////////////
  get chartData(): ChartData {
    const labels = this.customChartData.tickLabels.map(
      (label: TickLabel) => label.value
    );
    const datasets: Array<ChartDataset> = [];
    // Graphed in order of given in custom chart labels
    this.customChartLabels.forEach((label: CustomDataSetLabel) => {
      if (this.customChartData.datasets[label.xref]) {
        // Skip class average labels
        if (label.xref != 'peer') {
          // Get dataset
          datasets.push({
            ...label,
            categoryPercentage: 0.75,
            barPercentage: 1,
            maxBarThickness: 30,
            borderRadius: 3,
            data: this.customChartData.datasets[label.xref].map(
              // Plot on value on Y-axis
              (data: Data) => data.value
            ),
          });
        }
      } else {
        // No dataset at all?
        // Might not happen at all.
        datasets.push({
          ...label,
          data: [],
        });
      }
    });
    return {
      labels: labels, // Y-axis: any
      datasets: datasets, // X-axis: number
    };
  }
  get options(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: 'y',
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            usePointStyle: true,
            font: { size: 14 },
            padding: 20,
            color: this.fontColor,
          },
        },
        tooltip: {
          mode: 'index',
          displayColors: false,
          backgroundColor: this.tooltipColor,
          bodyColor: this.fontColor,
          padding: 10,
          callbacks: {
            title: function (): string {
              return ''; // No title
            },
          },
        },
        datalabels: {
          anchor: 'end',
          align: 'left',
          clip: true,
          font: { weight: 'bold' },
          borderRadius: 12,
          color: this.dataLabelsColor,
          formatter: (value) => {
            if (value > 0) {
              return value + '%';
            } else {
              return '';
            }
          },
        },
      },
      scales: {
        x: {
          beginAtZero: true,
          min: 0,
          max: 100,
          ticks: {
            color: '#000',
            font: { size: 14 },
            stepSize: 10,
            callback: function (value, index) {
              return index % 5 === 0 ? value + '%' : 'I';
            },
          },
          grid: { borderDash: [20, 5] },
        },

        y: {
          ticks: {
            color: this.xTickColor,
            // eslint-disable-next-line
            // @ts-ignore
            callback: this.getYTickLabel,
          },
        },
      },
    };
  }

  //////////////////
  // Chart Colors //
  //////////////////
  get fontColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.primary.darken1;
  }
  get dataLabelsColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.primary.darken4;
  }
  get tooltipColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.neutral.lighten3;
  }
  get xTickColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.primary.base;
  }

  /////////////
  // Methods //
  /////////////

  trimLongStandardNames(standardDescription: string) {
    if (standardDescription.toString().length > 30) {
      standardDescription = standardDescription.toString().substring(0, 30);
      standardDescription += '...';
      return standardDescription;
    } else {
      return standardDescription;
    }
  }

  getYTickLabel(id: number, index: number): Array<string> | null {
    const targetLabel: TickLabel = this.customChartData.tickLabels[index];
    var labelName: string[] | null = [];
    if (targetLabel) {
      const labelText = targetLabel.text;
      // Commenting out the Unicode stuff because there's no isFocus on SkillDefinition
      // If it get's added back we can uncomment
      if (
        // targetLabel.unicodeSymbol &&
        targetLabel.totalProblems &&
        targetLabel.standardDescription
      ) {
        // Make note of the number of problems examined
        labelName.push(
          // targetLabel.unicodeSymbol +
          //   ' ' +
          labelText + ' [' + targetLabel.totalProblems + ']',
          this.trimLongStandardNames(targetLabel.standardDescription)
        );
        return labelName;
      } else if (targetLabel.unicodeSymbol) {
        labelName.push(targetLabel.unicodeSymbol + ' ' + labelText);
        return labelName;
      } else if (targetLabel.totalProblems) {
        labelName.push(labelText + ' [' + targetLabel.totalProblems + ']');
        return labelName;
      } else {
        labelName.push(labelText);
        return labelName;
      }
    }
    return null;
  }

  plugins = [
    ChartDataLabels,
    {
      id: 'clickListener',
      afterEvent: (chart: Chart, args: { event: ChartEvent }) => {
        if (args.event.type === 'click') {
          const mousePoint = args.event;
          if (!mousePoint.x || !mousePoint.y) {
            return;
          }

          const yAxis = chart.scales['y'];

          const tickIndex = yAxis.getValueForPixel(mousePoint.y) as number;

          const textWidth = chart.ctx.measureText(
            yAxis.ticks[tickIndex] as unknown as string
          ).width;

          const labelMidpoint = yAxis.getPixelForTick(tickIndex);
          const labelLeftBound = labelMidpoint - textWidth / 2;
          const labelRightBound = labelMidpoint + textWidth / 2;

          const withinX =
            mousePoint.x > yAxis.top && mousePoint.x < yAxis.bottom;
          const withinY =
            mousePoint.y > labelLeftBound - 5 &&
            mousePoint.y < labelRightBound + 5;

          if (withinX && withinY) {
            const label = chart.scales['y'].getLabelForValue(tickIndex);
            this.$emit('clickedLabel', label);
          }
        }
      },
    },
  ];
}
