
import { Component, Vue, Prop } from 'vue-property-decorator';
import TimeFromNow from '@/components/Builder/ContentView/TimeFromNow.vue';

@Component({
  components: {
    TimeFromNow,
  },
})
export default class ContentLabel extends Vue {
  @Prop({ required: true }) ceri: string;
  @Prop({ required: false, default: '' }) classes: string;
  @Prop({ required: false, default: true }) icon: boolean;

  get isWip(): boolean {
    return this.ceri.startsWith('W');
  }

  get validationErrors(): string[] {
    return this.$store.state.content.validationErrors[this.ceri] ?? [];
  }
}
