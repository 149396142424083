import { GetterTree } from 'vuex';
import { AuthenticatedUser } from '@/domain/User';
import { AuthState } from './types';
import { RootState } from '../types';
import { FolderDefinition } from '@/domain/Folder';

export const getters: GetterTree<AuthState, RootState> = {
  isAuthenticated(state: AuthState): boolean {
    return !!state.user;
  },
  getCurrentUser(state: AuthState): AuthenticatedUser | undefined {
    return state.user;
  },
  getMyProblemSetsFolder(
    state: AuthState,
    getters,
    rootState: RootState
  ): FolderDefinition | undefined {
    return state.MY_PROBLEM_SETS
      ? rootState.folder.folderMap[state.MY_PROBLEM_SETS]
      : undefined;
  },
  getMyProblemSetsAssignedFolder(
    state: AuthState,
    getters,
    rootState: RootState
  ): FolderDefinition | undefined {
    return state.MY_PROBLEM_SETS_ASSIGNED
      ? rootState.folder.folderMap[state.MY_PROBLEM_SETS_ASSIGNED]
      : undefined;
  },
};
