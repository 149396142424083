
import { Component, Vue } from 'vue-property-decorator';
import { Skill } from '@/domain/Skill';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';

@Component({})
export default class SearchResultsTopBar extends Vue {
  get skills(): Array<Skill> {
    return this.$store.state.skillList.skills;
  }
  get selectedSkillXref(): number | null {
    const skillXref = this.$store.getters['skillList/getSelectedSkill'];
    return skillXref ? skillXref : null;
  }
  get numProblems(): number {
    return this.$store.getters['search/getNumProblems'];
  }
  get numSkillBuilders(): number {
    return this.$store.state.search.skillBuilders.length;
  }
  selectSkill(skillXref: string): void {
    // Does nothing if skill was cleared
    if (skillXref) {
      const currentRouteName = this.$route.name
        ? this.$route.name
        : 'searchProblems';
      this.$router.push({
        name: currentRouteName,
        params: { skillXref },
      });

      // Mixpanel tracking for skill selection
      trackMixpanel(EventType.searchBarSkillSelected, {
        skillXref: skillXref,
      });
    }
  }
}
