
import { AnswerPart, AnswerSet, AnswerType } from '@/domain/Problem';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { StudentResponseFeedback } from '@/domain/Tutoring';
import { getChooseLabel } from '@/utils/builder.util';
import draggable from 'vuedraggable';

@Component({ components: { draggable } })
export default class SpecificFeedbackAnswers extends Vue {
  @Prop({ required: true }) answerSet: AnswerSet;
  @Prop({ required: true }) tutorStrategy: StudentResponseFeedback;
  @Prop({ default: false }) disabled: boolean;

  AnswerType = AnswerType;

  get answerType(): AnswerType {
    const answerPart = this.answerSet.members?.find((member) => {
      return member.memberType == 'ANSWER_PART';
    }) as AnswerPart;

    return answerPart?.answerType;
  }

  getLabel(labelStart: string | number, index: number): number | string | null {
    return getChooseLabel(labelStart, index);
  }

  updateChooseN(htmlMarker: number, newValue: string): void {
    const feedback = this.tutorStrategy;
    const hasValue =
      feedback.answerPartsToResponses[htmlMarker].includes(newValue);

    // Find the value, if it exists remove it (uncheck) else add it (check)
    if (hasValue) {
      const index =
        feedback.answerPartsToResponses[htmlMarker].indexOf(newValue);
      feedback.answerPartsToResponses[htmlMarker].splice(index, 1);
    } else {
      feedback.answerPartsToResponses[htmlMarker].push(newValue);
    }

    // Don't emit update if there's no new value, otherwise shows "success" message but that isn't true
    if (newValue) {
      this.$emit('update', feedback);
    }
  }

  updateSort(event: { newIndex: number; oldIndex: number }): void {
    // Only update if whatever was dragged is in a new position
    if (event.newIndex != event.oldIndex) {
      this.update();
    }
  }

  update(): void {
    this.$emit('update', this.tutorStrategy);
  }
}
