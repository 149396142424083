
import { Component, Prop, Vue } from 'vue-property-decorator';

export enum StudentResponseFeedbackType {
  SPECIFIC = 'SPECIFIC',
  NON_SPECIFIC = 'NON_SPECIFIC',
}

@Component({})
export default class FeedbackTypeDialog extends Vue {
  @Prop() value: boolean;
  @Prop() disableSpecificFeedback: boolean;
  StudentResponseFeedbackType = StudentResponseFeedbackType;

  feedbackType: StudentResponseFeedbackType =
    StudentResponseFeedbackType.NON_SPECIFIC;

  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(value: boolean) {
    this.$emit('input', value);
  }

  addFeedbackSupport(feedbackType: StudentResponseFeedbackType) {
    this.showDialog = false;
    this.$emit('add-support', feedbackType);
  }
}
