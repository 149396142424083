
import { ComposedError, openLP } from '@/plugins/axios';
import { AxiosError } from 'axios';
import { Component, Prop, Vue } from 'vue-property-decorator';

// FIXME: Better way of handling this rather than having this component
// sprinkled everywhere in the code?

@Component
export default class PopupBlockedDialog extends Vue {
  @Prop() value: boolean;
  @Prop({ default: null }) error: ComposedError | null;

  dialog = false;

  get showDialog(): boolean {
    // return this.value;
    return this.value ? this.value : this.dialog;
  }

  set showDialog(val: boolean) {
    this.dialog = val;

    this.$emit('input', val);
  }

  attemptAuthorization(): void {
    if (this.error) {
      openLP(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.error.error as AxiosError<any, any>).response?.data.destination
      )
        .then(() => {
          //if the popup was not blocked we can continue as we have been
          this.$notify('Authorization updated. Please try again');
        })
        .catch(() => {
          this.$notify('Authorization failed.');
        })
        .finally(() => {
          this.showDialog = false;
        });
    } else {
      this.showDialog = false;
    }
  }
}
