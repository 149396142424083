import { coreAxios } from '@/plugins/axios';

const END_POINT = '/content/migrate';

export interface V1Credentials {
  first: string;
  second: string;
}

export interface MigrationUrl {
  first: string;
  second: string;
}

export interface MigrationStatusRaw {
  migrationId: string;
  itemType: string;
  itemId: number;
  isFinished: boolean;
  elapsedTime: string;
  avgMsPerPSA: number;
  totalPsaToProcess: number;
  psaPublished: number;
  psaFailed: number;
  praPublished: number;
  praFailed: number;
  innerPsPublished: number;
  innerPsFailed: number;
  innerPrPublished: number;
  innerPrFailed: number;
  psaProcessedThusFar: number;
  psProcessedThusFar: number;
  praProcessedThusFar: number;
  prProcessedThusFar: number;
}

const beginMigration = (requestBody: V1Credentials): Promise<string> => {
  return coreAxios.post(`${END_POINT}/users/async`, requestBody).then((res) => {
    return res.data.uid;
  });
};

// Get whether or not a user has migrated
const getUserMigrationStatus = (): Promise<string> => {
  return coreAxios.get(`${END_POINT}/user/status_url`).then((res) => {
    return res.data.uid;
  });
};

// Get the current status of a migration
const getMigrationCompletionStatus = (
  migrationId: string
): Promise<MigrationStatusRaw> => {
  return coreAxios
    .get(`${END_POINT}/status/${migrationId}`, {
      params: { readable: false },
    })
    .then((res) => {
      // stuff
      return res.data;
    });
};

export { beginMigration, getUserMigrationStatus, getMigrationCompletionStatus };
