import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { InsightsHubState } from './types';
import { RootState } from '../types';
import { DataOptions } from 'vuetify';
import { TotalStandardStats } from '@/domain/ReportData/InsightsHub';

export const state: InsightsHubState = {
  anonymized: false,
  collapsedPaths: new Set<string>(),
  currentTimeCoherencyData: new Map<string, TotalStandardStats>(),
  currentTimeData: new Map<string, TotalStandardStats>(),
  curriculumGrade: null,
  dashboardLoading: false,
  dashboardSource: null,
  gradeFolders: [],
  hasDownloadedSkills: false,
  isDownloadingSkills: false,
  menteeCourses: [],
  menteeSchools: [],
  menteeTeachers: [],
  options: {} as DataOptions,
  selectedAssessmentType: null,
  selectedCurriculum: null,
  selectedGradeXref: null,
  selectedMode: null,
  selectedModuleId: null,
  selectedPsTypes: [],
  selectedStrandId: null,
  selectedTab: null,
  skills: [],
  skillStrands: [],
  studentDisplayNamesMap: new Map<string, Map<string, string>>(),
  teacherCurriculumStats: [],
  timeSelector: null,
};

const namespaced = true;

export const insightsHub: Module<InsightsHubState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
