
import {
  BarChartData,
  CustomDataSetLabel,
  DataSets,
  FilterEmits,
  TickLabel,
} from '@/components/Report/BarChartView.vue';
import BarChartViewForReport from '@/components/Report/BarChartViewForReport.vue';
import { ProblemTypeSDK3 } from '@/domain/Problem';
import { AssignmentDefinition } from '@/domain/Assignment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';
import { StudentData } from '@/domain/ReportData/AssignmentData';
import {
  ProblemForReport,
  ProblemStatsForReport,
  getProblemTitleForReport,
  isInTestMode,
} from '@/utils/report.util';

enum FilterOptions {
  CORRECT = 'Correct',
  CORRECT_EVENTUALLY = 'Correct Eventually',
  INCORRECT = 'Incorrect',
}

@Component({
  components: {
    BarChartViewForReport,
  },
})
export default class AssignmentBarChartViewForReport extends Vue {
  @Prop({ required: true }) assignment: AssignmentDefinition;
  @Prop({ default: null }) reportData: StudentData | null;
  @Prop({ default: () => [] }) problems: ProblemForReport[];
  @Prop({ default: () => ({}) }) problemStatsMap: Record<
    string,
    ProblemStatsForReport
  >;
  @Prop({ default: false }) isLoading: boolean;

  isInitializing = true;
  stackedChart = true;
  showRedo = true;
  isInitialLoad = true; // Add this flag to track initial load

  options: Record<FilterOptions, boolean> = {} as Record<
    FilterOptions,
    boolean
  >;

  get isInTestMode(): boolean {
    return isInTestMode(this.assignment);
  }

  get hasRedo(): boolean {
    const props = this.assignment?.properties;
    if (props) {
      const useRedo = props.find((p) => p.propertyKey === 'useRedo');
      return useRedo?.propertyValue === 'true';
    }
    return false;
  }

  get filteredProblems(): ProblemForReport[] {
    return this.problems.filter(
      (problem) => this.showRedo || !problem.redoParent
    );
  }

  get problemChartLabels(): TickLabel[] {
    const tickLabels: TickLabel[] = [];
    // Based on given order in list of problems (NOT problem logs).
    for (const problem of this.filteredProblems) {
      // Do not show open-ended responses in performance chart.
      if (problem.problemTypeSDK3 !== ProblemTypeSDK3.OPEN_RESPONSE) {
        const problemText = getProblemTitleForReport(problem, true);
        tickLabels.push({
          text: problemText,
          value: problem.xref,
        });
      }
    }
    return tickLabels;
  }

  get problemChartData(): DataSets {
    const dataSets: DataSets = {
      correct: [],
      correctEventually: [],
      incorrect: [],
    };
    for (const problem of this.filteredProblems) {
      // Do not include open-ended responses in performance chart.
      if (problem.problemTypeSDK3 !== ProblemTypeSDK3.OPEN_RESPONSE) {
        const stats = this.problemStatsMap[problem.xref];
        if (stats) {
          dataSets.correct.push({ value: stats.numCorrect });
          if (this.isInTestMode) {
            dataSets.correctEventually.push({ value: 0 });
            dataSets.incorrect.push({
              value: stats.numIncorrect + stats.numCorrectEventually,
            });
          } else {
            dataSets.correctEventually.push({
              value: stats.numCorrectEventually,
            });
            dataSets.incorrect.push({ value: stats.numIncorrect });
          }
        } else {
          // No stats for this Problem? Add placeholder to indicate no data for this Problem
          // so that we don't mess up the display for the next Problem.
          dataSets.correct.push({ value: 0 });
          dataSets.correctEventually.push({ value: 0 });
          dataSets.incorrect.push({ value: 0 });
        }
      }
    }
    return dataSets;
  }

  get correctColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.correct;
  }

  get correctEventuallyColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.correctEventually;
  }

  get incorrectColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.incorrect;
  }

  get correctChartLabel(): CustomDataSetLabel {
    return {
      xref: 'correct',
      label: 'Correct',
      backgroundColor: this.correctColor,
    };
  }

  get correctEventuallyChartLabel(): CustomDataSetLabel {
    return {
      xref: 'correctEventually',
      label: 'Correct Eventually',
      backgroundColor: this.correctEventuallyColor,
    };
  }

  get incorrectChartLabel(): CustomDataSetLabel {
    return {
      xref: 'incorrect',
      label: 'Incorrect',
      backgroundColor: this.incorrectColor,
    };
  }

  get customChartLabels(): CustomDataSetLabel[] {
    const options = this.stackedChart
      ? [
          FilterOptions.INCORRECT,
          FilterOptions.CORRECT_EVENTUALLY,
          FilterOptions.CORRECT,
        ]
      : [
          FilterOptions.CORRECT,
          FilterOptions.CORRECT_EVENTUALLY,
          FilterOptions.INCORRECT,
        ];

    const labels = [];
    for (const option of options) {
      if (this.options[option as FilterOptions]) {
        switch (option) {
          case FilterOptions.CORRECT:
            labels.push(this.correctChartLabel);
            break;
          case FilterOptions.CORRECT_EVENTUALLY:
            labels.push(this.correctEventuallyChartLabel);
            break;
          case FilterOptions.INCORRECT:
            labels.push(this.incorrectChartLabel);
            break;
        }
      }
    }

    return labels;
  }

  get customChartData(): BarChartData {
    return {
      tickLabels: this.problemChartLabels,
      datasets: this.problemChartData,
    };
  }

  toggleFilter(filter: FilterEmits): void {
    let option: FilterOptions;
    switch (filter) {
      case FilterEmits.CORRECT:
        option = FilterOptions.CORRECT;
        break;
      case FilterEmits.CORRECT_EVENTUALLY:
        option = FilterOptions.CORRECT_EVENTUALLY;
        break;
      case FilterEmits.INCORRECT:
        option = FilterOptions.INCORRECT;
        break;
      default:
        return;
    }
    this.options[option] = !this.options[option];
  }

  mounted() {
    const options: Record<FilterOptions, boolean> = {
      [FilterOptions.CORRECT]: true,
    } as Record<FilterOptions, boolean>;
    if (!this.isInTestMode) {
      options[FilterOptions.CORRECT_EVENTUALLY] = true;
    }
    options[FilterOptions.INCORRECT] = true;
    this.options = options;

    this.$nextTick(() => {
      this.isInitialLoad = false; // Set the flag to false after the initial load
    });
  }

  //////////////
  // Mixpanel //
  //////////////
  @Watch('options', { deep: true }) // changes not detected if not using
  onSelectedFilterOptionsChange(): void {
    if (!this.isInitialLoad) {
      // prevent initial load firing
      this.trackPerfSumFilter();
    }
  }

  trackPerfSumFilter(): void {
    trackMixpanel(EventType.assignmentReportPerfSumFilter, {
      assignmentXref: this.assignment.xref,
      selectedFilterOptions2: this.options, // different output type
    });
  }

  @Watch('stackedChart')
  onStackedChartChange(newVal: boolean): void {
    // Call the Mixpanel tracker when the stackedChart property changes
    trackMixpanel(EventType.assignmentReportPerfSumStacked, {
      assignmentXref: this.assignment.xref,
      selectedFilterOptions: newVal,
    });
  }

  trackHoverEvent(trackingData: any): void {
    trackMixpanel(EventType.assignmentReportPerfSumHover, {
      assignmentXref: this.assignment.xref,
      ...trackingData,
    });
  }

  @Watch('showRedo')
  trackPerfSumRedoToggle(newVal: boolean, oldVal: boolean): void {
    if (newVal !== oldVal) {
      trackMixpanel(EventType.assignmentReportPerfSumRedoToggle, {
        assignmentXref: this.assignment.xref,
        redoToggle: newVal,
      });
    }
  }
}
