export enum AclSidType {
  USER = 'USER',
  GROUP = 'GROUP',
  ROLE = 'ROLE',
}

export enum AclResourceType {
  GROUP = 'GROUP',
  ASSIGNMENT = 'ASSIGNMENT',
  SDK3_FOLDER = 'SDK3_FOLDER',
  SDK3_PROBLEM = 'SDK3_PROBLEM',
  SDK3_PROBLEM_SET = 'SDK3_PROBLEM_SET',
  SDK3_TUTORING_STRATEGY = 'SDK3_TUTORING_STRATEGY',
  LEGACY_FOLDER = 'LEGACY_FOLDER',
  LEGACY_PROBLEM = 'LEGACY_PROBLEM',
  LEGACY_PROBLEM_SET = 'LEGACY_PROBLEM_SET',
}

export enum AclPermissionType {
  READ = 'READ',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export interface SidPermission {
  sidXref: string;
  permissions: AclPermissionType[];
}

export interface IHasPermissions {
  resourceType: AclResourceType;
  permissions: AclPermissionType[];
}
