
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { SkillDefinition } from '@/domain/Skill';

export type SkillNameAndCode = {
  xref: string;
  display: string;
};

@Component
export default class EditProblemStandards extends Vue {
  @Prop() value: boolean;
  @Prop() currentStandards: string[];

  selectedStandards: string[] = [];
  loading = false;

  get showDialog() {
    return this.value;
  }
  set showDialog(value) {
    this.$emit('input', value);
  }

  get getSkills(): SkillDefinition[] {
    return this.$store.state.skillList.skills;
  }

  get getProblemSkillsNamePlusCode(): SkillNameAndCode[] {
    return this.getSkills.map((skills: SkillDefinition) => ({
      xref: skills.xref,
      display: `${skills.nodeCode} - ${skills.nodeName}`,
    }));
  }

  get getProblemSkillObjects(): Map<string, SkillDefinition> {
    return this.$store.getters['skillList/getSkillsMap'];
  }

  get getSkillNameSkillCodeFromSkillObject(): SkillDefinition[] {
    const result = [];
    for (const skill of this.selectedStandards) {
      if (this.getProblemSkillObjects.has(skill)) {
        const skillObject = this.getProblemSkillObjects.get(skill);
        if (skillObject) {
          result.push(skillObject);
        }
      }
    }
    return result;
  }

  @Watch('currentStandards')
  onCurrentStandardsChange(newVal: string[]) {
    this.selectedStandards = [...newVal];
  }

  created() {
    this.selectedStandards = [...this.currentStandards];
  }

  removeStandard(index: number) {
    this.selectedStandards.splice(index, 1);
  }

  save() {
    this.$emit('changeStandards', this.selectedStandards);
  }
}
