
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ProblemDefinition } from '@/domain/Problem';
import SaveToMyProblemSetsDialog from '@/components/MyProblemSets/SaveToMyProblemSetsDialog.vue';
import GoToParentProblemSetDialog from '@/components/base/GoToParentProblemSetDialog.vue';
import StudentSupportsDialog from '@/components/StudentSupportAuthoring/StudentSupportsDialog.vue';
import PreviewInformationPopup from './PreviewInformationPopup.vue';
import { ProblemSetNode } from '@/utils/problemSet.util';
import { EXPLORE_CONTENT } from '@/domain/Folder';
import { PageView } from '@/utils/navigation.util';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import { AclPermissionType } from '@/domain/Acls';

@Component({
  components: {
    SaveToMyProblemSetsDialog,
    GoToParentProblemSetDialog,
    StudentSupportsDialog,
    PreviewInformationPopup,
  },
})
export default class ProblemViewMenu extends Vue {
  @Prop({ required: true }) path: (ProblemSetDefinition | ProblemDefinition)[];
  @Prop() isFirst: boolean;
  @Prop() isLast: boolean;

  AclPermissionType = AclPermissionType;
  EXPLORE_CONTENT = EXPLORE_CONTENT;

  PageView = PageView;

  get parentPage(): PageView {
    return this.$route?.meta?.page;
  }

  get problem(): ProblemDefinition {
    return this.path[this.path.length - 1] as ProblemDefinition;
  }

  get parent(): ProblemSetDefinition | undefined {
    return this.path[this.path.length - 2] as ProblemSetDefinition;
  }

  saveToMyProblemSets = false;
  // For 'Go to Problem Set' dialog (when multiple options)
  navigateToProblemSet = false;
  editStudentSupports = false;
  isMenuOpen = false; // Track the open state of the menu

  get previewLink(): string {
    return `${process.env.VUE_APP_STUDENT_WORKBENCH_URL}/preview/problem/${this.problem.xref}`;
  }

  get selectedTree(): ProblemSetNode[] {
    return [{ xref: this.problem.xref }];
  }

  get isPartProblem(): boolean {
    return 'partLetter' in this.problem;
  }

  // Watcher for menu open state
  @Watch('isMenuOpen')
  onMenuOpenChange(newVal: boolean, oldVal: boolean): void {
    if (newVal !== oldVal) {
      this.$emit('menu-visibility-change', {
        problemXref: this.problem.xref,
        showDialog: newVal,
      });
    }
  }

  handlePreviewClick(): void {
    this.$emit('preview-as-student', { problemXref: this.problem.xref });
  }
}
