
import { Component, Prop, Vue } from 'vue-property-decorator';
import ProblemRowView, {
  CustomProblemRowHeader,
} from '@/components/Tutor/TutoringProblemsByStandard/ProblemRowView.vue';
import { ProblemRow } from '@/domain/ReportData/Tutor';
/**
 * Currently columns are set to 9-auto (inside the auto is set to 12/However many students+customheaders)
 *
 * This is not the best route if we want all the custom headers to be the same size but in the future if we ever want
 *  to change the size of the problems column to be only 8 or the class average column to be slightly larger than the students
 *  then this is the best way to handle that and we have more control over everything. This comment is here to explain this
 *  so we don't accidentally refactor into a single v-row and also as a reminder to potentially look at changing these column numbers
 */
@Component({
  components: {
    ProblemRowView,
  },
})
export default class ProblemTableView extends Vue {
  @Prop({ required: true }) customHeaders: Array<CustomProblemRowHeader>;
  @Prop({ required: true }) problems: Array<ProblemRow>;

  get cols(): number {
    return Math.floor(12 / this.customHeaders.length);
  }
}
