/* eslint-disable @typescript-eslint/ban-types */
import { Server } from 'miragejs';
import { faker } from '@faker-js/faker';

faker.seed(0);

export default function (server: Server): void {
  server.create('studentAndPeerDataPerSkill', {
    skillXref: '50146782-7d89-40ae-993f-504ab244e506',
    averageScorePeers: 0.89,
    numProblemsPeers: 100,
    studentData: [
      {
        studentXref: 'C9rUPIqxW7q2x9oLpSDG',
        averageScore: 0.9,
        numProblems: 5,
      },
      {
        studentXref: 'wNmUOMlw2AIqZ8bKUF9',
        averageScore: 0.75,
        numProblems: 15,
      },
      {
        studentXref: 'J5ihYVyU3yihj14QGdd5',
        averageScore: faker.number.float({
          max: 1,
          precision: 0.01,
        }),
        numProblems: faker.number.int({
          min: 1,
          max: 100,
        }),
      },
      {
        studentXref: 'qCEq425AKdzg4liLV7jV',
        averageScore: faker.number.float({
          max: 1,
          precision: 0.01,
        }),
        numProblems: faker.number.int({
          min: 1,
          max: 100,
        }),
      },
      {
        studentXref: 'hLVmvz6zgr6nmyEMW9qt',
        averageScore: faker.number.float({
          max: 1,
          precision: 0.01,
        }),
        numProblems: faker.number.int({
          min: 1,
          max: 100,
        }),
      },
      {
        studentXref: 'v0GfOR8e7W2v1EhL2iU1',
        averageScore: faker.number.float({
          max: 1,
          precision: 0.01,
        }),
        numProblems: faker.number.int({
          min: 1,
          max: 100,
        }),
      },
    ],
  } as object);
  server.create('studentAndPeerDataPerSkill', {
    skillXref: '3ecfa8b5-f44f-46d2-b280-adc2bf596d08',
    averageScorePeers: 0.88,
    numProblemsPeers: 115,
    studentData: [
      {
        studentXref: 'C9rUPIqxW7q2x9oLpSDG',
        averageScore: 0.77,
        numProblems: 20,
      },
      {
        studentXref: 'wNmUOMlw2AIqZ8bKUF9',
        averageScore: 0.98,
        numProblems: 1,
      },
      {
        studentXref: 'J5ihYVyU3yihj14QGdd5',
        averageScore: faker.number.float({
          max: 1,
          precision: 0.01,
        }),
        numProblems: faker.number.int({
          min: 1,
          max: 115,
        }),
      },
      {
        studentXref: 'qCEq425AKdzg4liLV7jV',
        averageScore: faker.number.float({
          max: 1,
          precision: 0.01,
        }),
        numProblems: faker.number.int({
          min: 1,
          max: 115,
        }),
      },
      {
        studentXref: 'hLVmvz6zgr6nmyEMW9qt',
        averageScore: faker.number.float({
          max: 1,
          precision: 0.01,
        }),
        numProblems: faker.number.int({
          min: 1,
          max: 115,
        }),
      },
      {
        studentXref: 'v0GfOR8e7W2v1EhL2iU1',
        averageScore: faker.number.float({
          max: 1,
          precision: 0.01,
        }),
        numProblems: faker.number.int({
          min: 1,
          max: 115,
        }),
      },
    ],
  } as object);
  server.create('studentAndPeerDataPerSkill', {
    skillXref: 'f1654496-4a4c-4a55-9b6e-a45444153439',
    averageScorePeers: 0.65,
    numProblemsPeers: 75,
    studentData: [
      {
        studentXref: 'C9rUPIqxW7q2x9oLpSDG',
        averageScore: 0.81,
        numProblems: 40,
      },
      {
        studentXref: 'wNmUOMlw2AIqZ8bKUF9',
        averageScore: 0.87,
        numProblems: 33,
      },
    ],
  } as object);
  server.create('studentAndPeerDataPerSkill', {
    skillXref: '80bd6b58-bae2-49e2-a88c-735edc0ebcd4',
    averageScorePeers: 0.79,
    numProblemsPeers: 50,
    studentData: [],
  } as object);
  server.create('studentAndPeerDataPerSkill', {
    skillXref: '5a02ea2c-8a86-4d52-ba72-edbcb497b395',
    averageScorePeers: 0.78,
    numProblemsPeers: 200,
    studentData: [
      {
        studentXref: 'wNmUOMlw2AIqZ8bKUF9',
        averageScore: 0.89,
        numProblems: 73,
      },
      {
        studentXref: 'J5ihYVyU3yihj14QGdd5',
        averageScore: faker.number.float({
          max: 1,
          precision: 0.01,
        }),
        numProblems: faker.number.int({
          min: 1,
          max: 50,
        }),
      },
      {
        studentXref: 'hLVmvz6zgr6nmyEMW9qt',
        averageScore: faker.number.float({
          max: 1,
          precision: 0.01,
        }),
        numProblems: faker.number.int({
          min: 1,
          max: 50,
        }),
      },
      {
        studentXref: 'v0GfOR8e7W2v1EhL2iU1',
        averageScore: faker.number.float({
          max: 1,
          precision: 0.01,
        }),
        numProblems: faker.number.int({
          min: 1,
          max: 50,
        }),
      },
    ],
  } as object);
  server.create('studentAndPeerDataPerSkill', {
    skillXref: 'b7512cc3-4305-4428-9f38-a50ebfd46eb3',
    averageScorePeers: 0.88,
    numProblemsPeers: 50,
    studentData: [
      {
        studentXref: 'C9rUPIqxW7q2x9oLpSDG',
        averageScore: 0.77,
        numProblems: 20,
      },
      {
        studentXref: 'wNmUOMlw2AIqZ8bKUF9',
        averageScore: 0.95,
        numProblems: 30,
      },
    ],
  } as object);
  server.create('studentAndPeerDataPerSkill', {
    skillXref: '2da6771c-44cd-47af-968f-7fb2301508af',
    averageScorePeers: 0.32,
    numProblemsPeers: 15,
    studentData: [
      {
        studentXref: 'C9rUPIqxW7q2x9oLpSDG',
        averageScore: 0.36,
        numProblems: 5,
      },
    ],
  } as object);

  const skills = server.schema.db.skills;

  for (let i = 1; i <= 30; i++) {
    // skip the skill id's already defined above
    if (
      [
        '50146782-7d89-40ae-993f-504ab244e506',
        '3ecfa8b5-f44f-46d2-b280-adc2bf596d08',
        'f1654496-4a4c-4a55-9b6e-a45444153439',
        '80bd6b58-bae2-49e2-a88c-735edc0ebcd4',
        '5a02ea2c-8a86-4d52-ba72-edbcb497b395',
        'b7512cc3-4305-4428-9f38-a50ebfd46eb3',
        '2da6771c-44cd-47af-968f-7fb2301508af',
      ].includes(skills[i].xref)
    ) {
      continue;
    }

    server.create('studentAndPeerDataPerSkill', {
      skillXref: skills[i].xref,
      averageScorePeers: faker.number.float({
        max: 1,
        precision: 0.01,
      }),
      numProblemsPeers: faker.number.int({
        min: 1,
        max: 300,
      }),
      studentData: [
        {
          studentXref: 'C9rUPIqxW7q2x9oLpSDG',
          averageScore: faker.number.float({
            max: 1,
            precision: 0.01,
          }),
          numProblems: faker.number.int({
            min: 1,
            max: 50,
          }),
        },
        {
          studentXref: 'wNmUOMlw2AIqZ8bKUF9',
          averageScore: faker.number.float({
            max: 1,
            precision: 0.01,
          }),
          numProblems: faker.number.int({
            min: 1,
            max: 50,
          }),
        },
        {
          studentXref: 'J5ihYVyU3yihj14QGdd5',
          averageScore: faker.number.float({
            max: 1,
            precision: 0.01,
          }),
          numProblems: faker.number.int({
            min: 1,
            max: 50,
          }),
        },
        {
          studentXref: 'qCEq425AKdzg4liLV7jV',
          averageScore: faker.number.float({
            max: 1,
            precision: 0.01,
          }),
          numProblems: faker.number.int({
            min: 1,
            max: 50,
          }),
        },
        {
          studentXref: 'hLVmvz6zgr6nmyEMW9qt',
          averageScore: faker.number.float({
            max: 1,
            precision: 0.01,
          }),
          numProblems: faker.number.int({
            min: 1,
            max: 50,
          }),
        },
        {
          studentXref: 'v0GfOR8e7W2v1EhL2iU1',
          averageScore: faker.number.float({
            max: 1,
            precision: 0.01,
          }),
          numProblems: faker.number.int({
            min: 1,
            max: 50,
          }),
        },
      ],
    } as object);
  }
}
