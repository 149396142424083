
import { Component, Vue, Watch } from 'vue-property-decorator';
import { RawLocation } from 'vue-router/types/router';
import { uniqBy } from 'lodash';
import NotFoundView from '@/components/base/NotFoundView.vue';
import UnauthorizedView from '@/components/Tutor/base/UnauthorizedView.vue';
import {
  CustomProblemRowHeader,
  ProblemRowHeaderValue,
} from '@/components/Tutor/TutoringProblemsByStandard/ProblemRowView.vue';
import ProblemTableView from '@/components/Tutor/TutoringProblemsByStandard/ProblemTableView.vue';
import { ProblemDefinition } from '@/domain/Problem';
import {
  AssignmentStudentAndClassDataWithProblems,
  AssignmentTable,
} from '@/domain/ReportData/Tutor';
import { SkillDefinition } from '@/domain/Skill';
import { TimeFrame } from '@/domain/Time';
import { User } from '@/domain/User';
import { getProblemMap } from '@/utils/tutor/getProblemMap';
import { transformToAssignmentTable } from '@/utils/tutor/transformToAssignmentTable';
import DateRangeSelectionDialog from '@/components/Tutor/base/DateRangeSelectionDialog.vue';
import { getProblemSetChildren } from '@/api/core/content.api';
import { ContentType } from '@/domain/Content';
import { AssignmentDefinition } from '@/domain/Assignment';
import { StudentData } from '@/domain/ReportData/AssignmentData';
import { RETURN_TEXT, RETURN_URL } from '@/domain/PageParams';

@Component({
  components: {
    NotFoundView,
    UnauthorizedView,
    ProblemTableView,
    DateRangeSelectionDialog,
  },
})
export default class TutorProblemsByStandardPage extends Vue {
  assignmentStudentClassDataWithProblems: AssignmentStudentAndClassDataWithProblems | null =
    null;
  skillXref = '';
  unauthorized = false;
  dataNotFound = false;
  ////////////////////
  // Loading States //
  ////////////////////

  // Page Data Loading
  isDownloadingProblemData = false;

  get hasDownloadedStudentAssignments(): boolean {
    return this.$store.state.tutor.hasDownloadedStudentAssignments;
  }

  get hasDownloadedAssignmentReports(): boolean {
    return this.$store.state.tutor.hasDownloadedAssignmentReports;
  }

  //////////
  // Grid //
  //////////

  get tuteeHeaders(): Array<CustomProblemRowHeader> {
    let tuteeLabels: Array<CustomProblemRowHeader> = [];

    this.selectedTutees.forEach((tutee: User) => {
      tuteeLabels.push({
        text: this.getDisplayLabel(tutee),
        value: tutee.xref,
      });
    });

    return tuteeLabels;
  }

  get customHeaders(): Array<CustomProblemRowHeader> {
    return [
      ...this.tuteeHeaders,
      {
        text: 'Class Average',
        value: ProblemRowHeaderValue.CLASS_AVERAGE,
      },
    ];
  }

  get assignmentTables(): Array<AssignmentTable> {
    if (
      this.assignmentStudentClassDataWithProblems?.problems &&
      this.hasDownloadedAssignmentReports &&
      this.hasDownloadedStudentAssignments &&
      !this.isDownloadingProblemData
    ) {
      const problemSetToProblemsMap: {
        [psXref: string]: Array<ProblemDefinition>;
      } = this.assignmentStudentClassDataWithProblems.problems;

      const tables: Array<AssignmentTable> = [];

      this.assignmentDefinitions.forEach((assignmentData) => {
        const problemSetCeri: string = assignmentData.problemSetCeri;

        let problems: Array<ProblemDefinition> | undefined =
          problemSetToProblemsMap[problemSetCeri];

        if (problems) {
          problems = uniqBy(problems, (prob) => prob.xref);
          const problemMap = getProblemMap(problems);

          tables.push(
            transformToAssignmentTable(
              this.assignmentReportData.get(assignmentData.xref),
              problemMap,
              assignmentData
            )
          );
        }
      });

      return tables;
    }

    return [];
  }

  //////////////
  // Watchers //
  //////////////

  @Watch('weekSelected')
  onWeekChange(value: TimeFrame | null, oldValue: TimeFrame | null): void {
    if (value === oldValue) {
      return;
    }

    // Download data
    this.downloadData();
  }

  //////////
  // Data //
  //////////

  get targetSkill(): SkillDefinition | null {
    const skillFound = this.skills.find(
      (skill) => skill.xref == this.skillXref
    );

    if (skillFound) {
      return skillFound;
    }

    return null;
  }

  get reportPage(): RawLocation {
    // Remove assignment xrefs from the query
    delete this.$route.query.assignments;

    return {
      name: 'tutorReport',
      query: this.$route.query,
    };
  }

  ///////////
  // Store //
  ///////////

  get weekSelected(): TimeFrame | null {
    return this.$store.getters['tutor/getSelectedTimeFrame'];
  }

  set weekSelected(timeFrame: TimeFrame | null) {
    // Update store of new selection
    this.$store.commit('tutor/setSelectedTimeFrame', timeFrame);
  }

  get selectedTuteeXrefs(): Array<string> {
    return this.$store.state.tutor.selectedTuteeXrefs;
  }

  get selectedTutees(): Array<User> {
    return this.$store.getters['tutor/getSelectedTutees'];
  }

  get skills(): Array<SkillDefinition> {
    return this.$store.state.skillList.skills;
  }

  get assignmentDefinitions(): Array<AssignmentDefinition> {
    return this.$store.state.tutor.assignmentDefinitions.filter(
      (def: AssignmentDefinition) => {
        return def.skills.includes(this.skillXref);
      }
    );
  }

  get assignmentReportData(): Map<string, StudentData> {
    return this.$store.state.tutor.assignmentReportData;
  }

  /////////////
  // View By //
  /////////////
  showDateRangeSelectDialog = false;

  /////////////
  // Methods //
  /////////////

  getDisplayLabel(user: User): string {
    return user.firstName + ' ' + user.lastName.charAt(0);
  }

  goToReportPage(): void {
    this.$router.push(this.reportPage);
  }

  ////////////////////
  // Initialization //
  ////////////////////

  created(): void {
    // Get skill id from path
    this.skillXref = this.$route.params.xref;

    this.initializeSelectedTutees();

    // Download data
    this.downloadData();
  }

  ///////////////////////////
  // Report Data & Helpers //
  ///////////////////////////

  // Download helpers
  initializeSelectedTutees(): void {
    if (this.selectedTuteeXrefs.length === 0) {
      // Go to dashboard to select tutees
      this.goToReportPage();
    }
  }

  downloadStudentData() {
    if (
      this.weekSelected &&
      !this.hasDownloadedAssignmentReports &&
      !this.hasDownloadedStudentAssignments
    ) {
      return this.$store.dispatch('tutor/requestAssignmentDefinitions', {
        tuteeXrefs: this.selectedTuteeXrefs,
        currentUserXref: this.$router.app.getCurrentUser.xref,
        weekSelected: this.weekSelected,
        skills: [this.skillXref],
      });
    }
    return Promise.resolve();
  }

  async getProblemsGroupedByAssignment() {
    if (
      this.skillXref &&
      this.selectedTuteeXrefs.length > 0 &&
      this.weekSelected
    ) {
      this.isDownloadingProblemData = true;
      this.dataNotFound = false;

      if (this.hasDownloadedStudentAssignments) {
        try {
          const problemData: { [key: string]: ProblemDefinition[] } = {};

          await Promise.all(
            this.assignmentDefinitions.map((assignment) => {
              return getProblemSetChildren(assignment.problemSetCeri).then(
                (res) => {
                  problemData[assignment.problemSetCeri] = res.filter(
                    (psChild): psChild is ProblemDefinition => {
                      return psChild.contentType === ContentType.PROBLEM;
                    }
                  );
                }
              );
            })
          );

          this.assignmentStudentClassDataWithProblems = {
            problems: problemData,
            reportData: this.assignmentReportData,
          };
        } catch (err) {
          this.dataNotFound = true;
          return Promise.reject(err);
        } finally {
          this.isDownloadingProblemData = false;
        }
      }
      return Promise.resolve();
    }
  }

  downloadData(): void {
    this.downloadStudentData().then(() => {
      this.download([this.getProblemsGroupedByAssignment()]);
    });
  }

  download(promises: Array<Promise<void>>): void {
    Promise.all(promises).catch((error) => {
      // Any errors
      if (error.response && error.response.status === 403) {
        // FIXME: Clear data?
        this.unauthorized = true;
      }
    });
  }

  goToAssignmentReportView(xref: string): void {
    this.$router.push({
      name: 'ReportLandingPage',
      params: {
        xref,
      },
      query: {
        [RETURN_TEXT]: 'Tutor Report',
        [RETURN_URL]: this.$router.currentRoute.fullPath,
      },
    });
  }
}
