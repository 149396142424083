
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProblemDefinition, ProblemTypeSDK3 } from '@/domain/Problem';
import ProblemViewSDK3 from './ProblemViewSDK3.vue';
import TimeFromNow from './TimeFromNow.vue';
import { ProblemSetDefinition, ProblemSetType } from '@/domain/ProblemSet';
import {
  getAnswerParts,
  getCorrectAnswer,
  getPartLetter,
} from '@/utils/problem.util';
import SupportsView from './SupportsView.vue';

@Component({
  components: { ProblemViewSDK3, TimeFromNow, SupportsView },
})
export default class ProblemViewForBuilder extends Vue {
  @Prop({ required: true }) problem: ProblemDefinition;
  @Prop({ required: false, default: null }) contextPath: string | null;
  @Prop({ required: false, default: true }) showAnswers: boolean;
  @Prop({ required: false, default: true }) showSupports: boolean;

  ProblemSetType = ProblemSetType;
  ProblemTypeSDK3 = ProblemTypeSDK3;

  get pathParts(): string[] {
    return this.contextPath?.split(',') ?? [];
  }

  get problemSet(): ProblemSetDefinition | undefined {
    const parent = this.pathParts[this.pathParts.length - 1];
    return this.problemSetMap[parent];
  }

  get partLetter(): string {
    const index =
      this.problemSet?.children.findIndex(
        (child) => child == this.problem.xref
      ) ?? -1;
    return getPartLetter(index + 1);
  }

  get targetPath(): string {
    return this.contextPath
      ? `${this.contextPath},${this.problem.xref}`
      : this.problem.xref;
  }

  get problemSetMap(): Record<string, ProblemSetDefinition> {
    return this.$store.state.content.problemSetMap;
  }

  get correctAnswer(): Record<number, string[]> | undefined {
    let response: Record<number, string[]> | undefined = undefined;
    if (this.showAnswers) {
      const answerParts = getAnswerParts(this.problem.answersSDK3);
      response = getCorrectAnswer(answerParts);
    }
    return response;
  }
}
