export class LmsProviderType {
  public static readonly GOOGLE_CLASSROOM = new LmsProviderType(
    1,
    'Google Classroom'
  );
  public static readonly EDMODO = new LmsProviderType(2, 'Edmodo');
  public static readonly SCHOOLOGY = new LmsProviderType(3, 'Schoology');
  public static readonly LTI_ENABLED = new LmsProviderType(4, 'LTI');
  public static readonly CANVAS = new LmsProviderType(5, 'Canvas');
  public static readonly EDLINK = new LmsProviderType(6, 'Edlink');

  readonly id: number;
  readonly displayName: string;

  private constructor(id: number, displayName: string) {
    this.id = id;
    this.displayName = displayName;
  }

  static get values(): LmsProviderType[] {
    return [
      this.GOOGLE_CLASSROOM,
      this.EDMODO,
      this.SCHOOLOGY,
      this.LTI_ENABLED,
      this.CANVAS,
      this.EDLINK,
    ];
  }

  //Figure out if this is what we want...
  public static find(id: number): LmsProviderType | null {
    for (const l of LmsProviderType.values) {
      if (l.id === id) {
        return l;
      }
    }
    return null;
  }

  // CORE API
  public static findByName(name: string): LmsProviderType | null {
    for (const lmsPtype in LmsProviderType) {
      if (lmsPtype === name) {
        // Cast to an enum object
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (<any>LmsProviderType)[lmsPtype];
      }
    }

    return null;
  }

  public toJSON(): string {
    return String(this.id);
  }
}
