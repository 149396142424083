import { teachleyAxios } from '@/plugins/axios';

const END_POINT = `/assistments_list`;

interface TeachleyList {
  success: boolean;
  problems: Record<string, boolean>;
}

const getTeachleyProblems = (): Promise<Record<string, boolean>> => {
  return teachleyAxios
    .get<TeachleyList>(`${END_POINT}`)
    .then((res) => {
      const { problems } = res.data;
      return problems ?? {};
    })
    .catch(() => {
      return {};
    });
};

export { getTeachleyProblems };
