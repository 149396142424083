var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.isAuthenticated)?_c('v-container',{staticClass:"app-container d-flex align-center py-6 elevation-1 neutral lighten-4 z1",attrs:{"fluid":""}},[_c('v-spacer'),(!_vm.isSkillBuilder)?_c('div',{staticClass:"text-h6 mr-8",attrs:{"data-cy":"selected-problems-text"}},[_vm._v(" "+_vm._s(_vm.numProblemsSelected)+" of "+_vm._s(_vm.numProblemsTotal)+" Problems Selected ")]):_vm._e(),_c('div',{staticClass:"elevation-1 d-flex rounded",attrs:{"id":"assign-wrapper"}},[_c('v-btn',{staticClass:"primary--text text--darken-3 rounded-l",attrs:{"height":"48","color":"yellow lighten-1","tile":"","depressed":"","data-cy":"assign-btn","disabled":!_vm.enableAssign},on:{"click":function($event){_vm.assignDialog = true}}},[_vm._v(" "+_vm._s(_vm.assignButtonText)+" ")]),_c('v-divider',{class:{
          'yellow darken-1': _vm.enableAssign,
          white: !_vm.enableAssign,
        },attrs:{"vertical":""}}),_c('v-menu',{attrs:{"attach":"#assign-wrapper","data-cy":"overflow-menu","top":"","left":"","offset-x":"","content-class":"neutral lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary--text text--darken-3 rounded-r",attrs:{"height":"48","min-width":"48","width":"48","depressed":"","tile":"","color":"yellow lighten-1","data-cy":"overflow-menu-btn","disabled":!_vm.enableAssign}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}],null,false,1068415515)},[_c('v-list',[(
              [_vm.PageView.LESSON_VIEW, _vm.PageView.SEARCH_RESULTS].includes(
                _vm.parentPage
              ) && !_vm.isSkillBuilder
            )?_c('v-list-item',{attrs:{"link":"","color":"primary darken-3"}},[_c('v-list-item-title',{on:{"click":function($event){_vm.saveToMyProblemSets = true}}},[_vm._v(" SAVE TO MY PROBLEM SETS ")])],1):_vm._e(),_c('v-list-item',{attrs:{"link":"","color":"primary darken-3","data-cy":"overflow-menu-assign-btn"},on:{"click":function($event){_vm.assignDialog = true}}},[_c('v-list-item-title',[_vm._v("ASSIGN TO CLASS")])],1)],1)],1)],1),_c('AssignDialog',{attrs:{"problemSet":_vm.problemSet,"selectedTree":_vm.selectedTree,"numTotalProblems":_vm.numProblemsTotal,"defaultName":_vm.defaultAssignmentName},model:{value:(_vm.assignDialog),callback:function ($$v) {_vm.assignDialog=$$v},expression:"assignDialog"}}),_c('SaveToMyProblemSetsDialog',{attrs:{"selectedTree":_vm.selectedTree},model:{value:(_vm.saveToMyProblemSets),callback:function ($$v) {_vm.saveToMyProblemSets=$$v},expression:"saveToMyProblemSets"}})],1):_c('v-container',{staticClass:"app-container d-flex align-center py-6 elevation-1 neutral lighten-4 z1",attrs:{"fluid":""}},[_c('span',[_vm._v("Create an account to save and assign problems")]),_c('v-spacer'),_c('LoginButton')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }