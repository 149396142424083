
import dayjs from 'dayjs';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AnswerPart, AnswerSet, AnswerValue } from '@/domain/Problem';
import { User } from '@/domain/User';
import TutorMixins from '@/mixins/tutor-mixins';
import {
  PartLogData,
  ProblemLogAndActions,
} from '@/domain/ReportData/AssignmentData';
import {
  ActionType,
  HintRequestedAction,
  IAction,
  StudentResponseAction,
  StudentSubmissionAction,
} from '@/domain/Action';

@Component
export default class StudentProblemView extends Mixins(TutorMixins) {
  @Prop({ required: true }) studentXref: string;
  @Prop({ required: true }) problemAverageTime: number;
  @Prop({ required: true }) problemAnswers: AnswerSet;
  @Prop({ required: true }) studentData: ProblemLogAndActions;
  @Prop({ type: Boolean }) collapsed: boolean;

  // Allows us access to the enum in the template.
  ActionType = ActionType;

  //////////
  // Data //
  //////////

  get colspan(): number {
    return this.collapsed ? 12 : 3;
  }

  get answerColspan(): number {
    const colsLeft = 12 - this.colspan;

    return colsLeft ? colsLeft : 12;
  }

  get student(): User | null {
    // Find user
    const studentFound = this.selectedTutees.find(
      ({ xref }) => xref === this.studentXref
    );

    return studentFound ? studentFound : null;
  }

  get studentInitials(): string | null {
    return this.student
      ? this.student.firstName.charAt(0) + this.student.lastName.charAt(0)
      : null;
  }

  get studentTimeSpent(): string {
    const timeSpent =
      this.studentData.prLog.endTime - this.studentData.prLog.startTime;
    return this.getTimeSpent(timeSpent);
  }

  get classAverageTime(): string {
    return this.getTimeSpent(this.problemAverageTime);
  }

  get hintText(): string[] {
    const ceri: string = this.hintsReceived[0].tutoringCeri;

    const strategies = this.$store.state.content.tutorStrategyMap;

    return strategies[ceri] ? strategies[ceri].hints : [];
  }

  get hintsReceived(): Array<HintRequestedAction> {
    return (
      this.studentData.actions?.filter(
        (action: IAction): action is HintRequestedAction => {
          return action.actionType === ActionType.HINT_REQUESTED_ACTION;
        }
      ) || []
    );
  }

  get teacherFeedback(): Record<number, PartLogData> {
    return this.studentData.prLog.partLogData;
  }

  get isComplete(): boolean {
    return this.studentData.prLog.continuousScore ? true : false;
  }

  get problemCorrectAnswers(): Array<AnswerValue> {
    const correctAnswers: Array<AnswerValue> = [];

    if (this.problemAnswers) {
      this.problemAnswers.members?.forEach(
        (answerSet: AnswerSet | AnswerPart) => {
          return answerSet.answerValues?.forEach((answerValue) => {
            if (answerValue.isCorrect) {
              correctAnswers.push(answerValue);
            }
          });
        }
      );
      return correctAnswers;
    }

    return [];
  }

  get studentAnswersSubmitted(): Array<
    StudentSubmissionAction | StudentResponseAction
  > {
    return (
      this.studentData.actions?.filter(
        (
          action: IAction
        ): action is StudentSubmissionAction | StudentResponseAction => {
          return (
            action.actionType == ActionType.STUDENT_RESPONSE_ACTION ||
            action.actionType == ActionType.STUDENT_SUBMISSION_ACTION
          );
        }
      ) || []
    );
  }

  get color(): string {
    // Color depends on the order in which this tutee is found
    const index = this.selectedTuteeXrefs.indexOf(this.studentXref);

    switch (index) {
      case 0:
        return this.firstTuteeColor;
      case 1:
        return this.secondTuteeColor;
      case 2:
        return this.thirdTuteeColor;
      case 3:
        return this.fourthTuteeColor;
      default:
        return 'incorrect'; // Well... that's very bad.
    }
  }

  ///////////
  // Store //
  ///////////

  get selectedTutees(): Array<User> {
    return this.$store.getters['tutor/getSelectedTutees'];
  }

  get selectedTuteeXrefs(): string[] {
    return this.$store.state.tutor.selectedTuteeXrefs;
  }

  /////////////
  // Methods //
  /////////////

  getTimeSpent(ms: number): string {
    // Format 00:00:00:00
    // return dayjs.duration(ms).format('DD:HH:mm:ss');
    return dayjs.duration(ms).format('HH:mm:ss');
  }

  mounted(): void {
    window.com.wiris.js.JsPluginViewer.parseDocument();
  }
}
