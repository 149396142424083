
import { ProblemDefinition, ProblemTypeSDK3 } from '@/domain/Problem';
import {
  Explanation,
  Hint,
  HintSet,
  ITutorStrategy,
  StudentResponseFeedback,
  TutorStrategyType,
} from '@/domain/Tutoring';
import { Component, Prop, Vue } from 'vue-property-decorator';
import SupportRow, { SupportMode } from './SupportRow.vue';
import {
  ContentBuildStatus,
  deleteTutorStrategy,
} from '@/api/core/content.api';
import { isPublished } from '@/utils/builder.util';
import draggable from 'vuedraggable';
import FeedbackTypeDialog, {
  StudentResponseFeedbackType,
} from './FeedbackTypeDialog.vue';
import { cloneDeep, isEmpty } from 'lodash';
import SpecificFeedbackAnswers from '../Answers/SpecificFeedbackAnswers.vue';
import { AclPermissionType } from '@/domain/Acls';
import ContentLabel from '../ContentView/ContentLabel.vue';

@Component({
  components: {
    ContentLabel,
    draggable,
    FeedbackTypeDialog,
    SupportRow,
    SpecificFeedbackAnswers,
  },
})
export default class ProblemSupports extends Vue {
  @Prop({ required: true }) problem: ProblemDefinition;
  @Prop({ default: () => SupportMode.EDIT }) mode: SupportMode;
  @Prop({ default: () => [] }) supports: ITutorStrategy[];

  TutorStrategyType = TutorStrategyType;
  ProblemTypeSDK3 = ProblemTypeSDK3;
  AclPermissionType = AclPermissionType;

  tabIndex = 0;
  focusedXref = '';
  focusedIndex = -1;
  showFeedbackTypeSelectionDialog = false;
  trustedPropertySettings = [
    { title: 'Public', value: true },
    { title: 'Private', value: false },
  ];

  get tab(): number {
    return this.tabIndex;
  }

  set tab(index: number) {
    this.tabIndex = index;
    this.resetAutofocus();
  }

  get types(): TutorStrategyType[] {
    const types = [TutorStrategyType.HINT_SET, TutorStrategyType.EXPLANATION];
    if (this.problem.problemTypeSDK3 != ProblemTypeSDK3.OPEN_RESPONSE) {
      types.push(TutorStrategyType.STUDENT_RESPONSE_FEEDBACK);
    }
    return types;
  }

  get type(): TutorStrategyType {
    return this.types[this.tab];
  }

  get tabs(): string[] {
    return this.types.map(this.getSupportDescription);
  }

  get container(): Record<TutorStrategyType, ITutorStrategy[]> {
    const res: Record<TutorStrategyType, ITutorStrategy[]> = {} as Record<
      TutorStrategyType,
      ITutorStrategy[]
    >;
    for (const ts of this.supports) {
      const tsType = ts.tutorStrategyType;
      if (
        isPublished(ts.xref) &&
        ts.permissions.includes(AclPermissionType.UPDATE) &&
        ts.mappedCeri
      ) {
        // Will render WIP version. Ignore Published version.
        continue;
      }
      const clone = cloneDeep(ts);
      if (res[tsType]) {
        res[tsType].push(clone);
      } else {
        res[tsType] = [clone];
      }
    }
    return res;
  }

  get explanations(): Explanation[] {
    const found = this.container[TutorStrategyType.EXPLANATION] ?? [];
    return found as Explanation[];
  }

  get hintSets(): HintSet[] {
    const found = this.container[TutorStrategyType.HINT_SET] ?? [];
    return found as HintSet[];
  }

  get nonSpecificFeedbacks(): StudentResponseFeedback[] {
    const feedbacks = (this.container[
      TutorStrategyType.STUDENT_RESPONSE_FEEDBACK
    ] ?? []) as StudentResponseFeedback[];
    return feedbacks.filter((support) =>
      isEmpty(support.answerPartsToResponses)
    );
  }

  get specificFeedbacks(): StudentResponseFeedback[] {
    const feedbacks = (this.container[
      TutorStrategyType.STUDENT_RESPONSE_FEEDBACK
    ] ?? []) as StudentResponseFeedback[];
    return feedbacks.filter(
      (support) => !isEmpty(support.answerPartsToResponses)
    );
  }

  get disableSpecificFeedback(): boolean {
    // Disable specific feedback if we don't support the type
    return ![
      ProblemTypeSDK3.CHOOSE_N,
      ProblemTypeSDK3.SORT,
      // ProblemTypeSDK3.FILL_IN,
    ].includes(this.problem.problemTypeSDK3);
  }

  // FIXME: Figure out if this should be a util method?
  getSupportDescription(type: TutorStrategyType): string {
    switch (type) {
      case TutorStrategyType.HINT_SET:
        return 'Hint';
      case TutorStrategyType.EXPLANATION:
        return 'Explanation';
      case TutorStrategyType.STUDENT_RESPONSE_FEEDBACK:
        return 'Response Feedback';
      default:
        return '';
    }
  }

  getNewHint(): Hint {
    return { hint: '', hasVideo: false };
  }

  addHint(setIndex: number): void {
    const hintSet = this.hintSets[setIndex];
    const hints = [...hintSet.hints, this.getNewHint()];
    this.update<HintSet>(hintSet.xref, {
      // MUST include for backend to instantiate the class.
      tutorStrategyType: TutorStrategyType.HINT_SET,
      hints,
    }).then((status) => {
      if (!status.failMessages && status.ceri) {
        // May be a new WIP CERI.
        this.focusedXref = status.ceri;
        this.focusedIndex = hints.length - 1;
      }
    });
  }

  addStudentResponseFeedback(type: StudentResponseFeedbackType) {
    const feedback: Partial<StudentResponseFeedback> = {};

    const isSpecific = type == StudentResponseFeedbackType.SPECIFIC;

    feedback.answerPartsToResponses = isSpecific ? { 1: [] } : {};

    if (isSpecific && this.problem.problemTypeSDK3 == ProblemTypeSDK3.SORT) {
      this.problem.answersSDK3?.members?.forEach((member) => {
        if (
          member.memberType == 'ANSWER_PART' &&
          feedback.answerPartsToResponses
        )
          feedback.answerPartsToResponses[member.htmlMarker] =
            member.answerValues?.map((answer) => {
              return answer.value;
            }) || [];
      });
    }

    this.addSupport(feedback);
  }

  deleteHint(setIndex: number, valueIndex: number): void {
    const hintSet = this.hintSets[setIndex];
    const hints = [...hintSet.hints];
    hints.splice(valueIndex, 1);
    // If we have no hints in set, delete the set. Otherwise, just remove the one at index.
    if (hints.length) {
      this.update<HintSet>(hintSet.xref, {
        // MUST include for backend to instantiate the class.
        tutorStrategyType: TutorStrategyType.HINT_SET,
        hints,
      });
    } else {
      // FIXME: What happens if WIP have a Published version? Do we delete the Published version too?
      this.deleteSupport(hintSet);
    }
  }

  addSupport(partialStrategy?: Partial<ITutorStrategy>): void {
    const modifiedFields: Partial<ITutorStrategy> = {
      // MUST include for backend to instantiate the class.
      tutorStrategyType: this.type,
      // Entire Problem.
      htmlMarker: 0,
      trusted: false,
      // Backend will NOT be accepting this field BUT we can set this flag here for internal
      // processing if needed in the future.
      // prAuthorWritten: this.problem.author === this.getCurrentUser.xref,
      ...partialStrategy,
    };
    if (this.isContentAdminUser || this.isTrustedBuilderUser) {
      modifiedFields.trusted = true;
    }
    switch (this.type) {
      case TutorStrategyType.HINT_SET:
        {
          const hintSet = modifiedFields as HintSet;
          const newHint = this.getNewHint();
          hintSet.hints = [newHint];
          hintSet.hasVideo = newHint.hasVideo;
        }
        break;
      case TutorStrategyType.EXPLANATION:
        {
          const explanation = modifiedFields as Explanation;
          explanation.explanation = '';
          explanation.hasVideo = false;
        }
        break;
      case TutorStrategyType.STUDENT_RESPONSE_FEEDBACK:
        {
          const feedback = modifiedFields as StudentResponseFeedback;
          feedback.responseFeedback = '';
          feedback.hasVideo = false;
        }
        break;
      default:
        this.$notify('To be implemented');
        return;
    }
    this.$store
      .dispatch('content/saveTutorStrategy', {
        problemCeri: this.problem.xref,
        modifiedFields,
      })
      .then(({ ceri: xref, failMessages: error }) => {
        if (error) {
          this.$notify(`Failed to create tutor strategy:  ${error}`);
        } else if (xref) {
          this.$notify(`Added ${xref} to ${this.problem.xref}.`);
          this.focusedXref = xref;
          if (modifiedFields.tutorStrategyType === TutorStrategyType.HINT_SET) {
            this.focusedIndex = 0;
          }
        }
      });
  }

  updateSupport(ts: ITutorStrategy): void {
    const { xref, tutorStrategyType, ...rest } = ts;
    // On update, the support should no longer be trusted, as it will require review from a
    // CONTENT_ADMIN or TRUSTED_BUILDER before it can be trusted again.
    let trusted = false;
    if (this.isContentAdminUser || this.isTrustedBuilderUser) {
      // Always include this so we do NOT reset this flag on an update.
      trusted = rest.trusted;
    }
    const modifiedFields: Partial<ITutorStrategy> = {
      // MUST include for backend to instantiate the class.
      tutorStrategyType,
      trusted,
    };
    switch (tutorStrategyType) {
      case TutorStrategyType.HINT_SET:
        {
          const hintSet = rest as HintSet;
          (modifiedFields as HintSet).hints = hintSet.hints;
          // FIXME: Figure out if this is the right thing to do?
          (modifiedFields as HintSet).hasVideo = hintSet.hints.some(
            (hint) => hint.hasVideo
          );
        }
        break;
      case TutorStrategyType.EXPLANATION:
        {
          const explanation = rest as Explanation;
          (modifiedFields as Explanation).explanation = explanation.explanation;
        }
        break;
      case TutorStrategyType.STUDENT_RESPONSE_FEEDBACK:
        {
          const feedback = rest as StudentResponseFeedback;
          (modifiedFields as StudentResponseFeedback).answerPartsToResponses =
            feedback.answerPartsToResponses;
          (modifiedFields as StudentResponseFeedback).responseFeedback =
            feedback.responseFeedback;
        }
        break;
      default:
        this.$notify('To be implemented');
        return;
    }
    this.update<ITutorStrategy>(xref, modifiedFields);
  }

  update<T>(
    xref: string,
    modifiedFields: Partial<T>
  ): Promise<ContentBuildStatus> {
    const wirisModalIsPresent = document.querySelectorAll('.wrs_stack').length;

    if (!wirisModalIsPresent) {
      this.resetAutofocus();
    }

    return this.$store
      .dispatch('content/saveTutorStrategy', {
        xref,
        modifiedFields,
      })
      .then((status) => {
        if (status.failMessages) {
          this.$notify(`Failed to update ${xref}: ${status.failMessages}`);
        } else if (status.ceri) {
          this.$notify(`Saved changes to ${status.ceri}.`);
        }
        return status;
      });
  }

  deleteSupport(ts: ITutorStrategy): void {
    const xref = ts.xref;
    const promises = [];
    promises.push(
      deleteTutorStrategy(xref).then(() => {
        return this.$store.dispatch('content/deleteTutorStrategy', { xref });
      })
    );
    // If WIP, delete WIP version AND delete Published version.
    // If Published, the backend will delete Published version but will unlink WIP version
    // SO the WIP version will STILL EXIST. We need to delete the standalone WIP version.
    if (ts.mappedCeri) {
      deleteTutorStrategy(ts.mappedCeri).then(() => {
        return this.$store.dispatch('content/deleteTutorStrategy', {
          xref: ts.mappedCeri,
        });
      });
    }
    Promise.all(promises).then(() => {
      this.$notify(`Removed ${xref} from ${this.problem.xref}.`);
    });
  }

  editSupport(xref: string, index?: number) {
    this.focusedXref = xref;
    if (typeof index === 'number') {
      this.focusedIndex = index;
    }
  }

  resetAutofocus(): void {
    this.focusedXref = '';
    this.focusedIndex = -1;
  }
}
