
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ChangeTypeDialog extends Vue {
  @Prop() value: boolean;
  @Prop() supportType: string;
  @Prop() xref: string;

  get computedValue(): boolean {
    return this.value;
  }

  set computedValue(val: boolean) {
    this.$emit('input', val);
  }

  confirm() {
    this.$emit('changeSupportType', this.xref);
    this.computedValue = false;
  }
  cancel() {
    this.computedValue = false;
  }
}
