
import { isEqual, orderBy } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataOptions, DataTableHeader } from 'vuetify';
import { convertHexToRGBA } from '@/utils/color.util';

export enum StaticHeader {
  NAME = 'name',
}

export enum RowType {
  SCHOOL = 'school',
  TEACHER = 'teacher',
  COURSE = 'course',
  STUDENT = 'student',
}

export enum CellType {
  RAW_VALUE = 'rawValue',
  PERCENT = 'percent',
}

interface Node {
  xref: string;
  parents?: string[] | null;
  children?: string[] | null;
}

export interface Fraction {
  numerator: number;
  denominator: number;
  type?: CellType;
}

export interface Row extends Node {
  type: RowType;
  sortOverride?: { [header: string]: string };
  [header: string]: unknown;
}

export interface GradientHeaders {
  averageHeader: DataTableHeader;
  itemHeaders: DataTableHeader[];
}

export interface GradientRows {
  averageRow: Row;
  itemRows: Map<string, Row>;
}

type OrderFunction = (rows: Row[]) => Row[];

@Component({
  components: {},
})
export default class TreeGradientTable extends Vue {
  // Table Configuration
  @Prop({ required: true }) color: string;
  @Prop({ default: false }) anonymized: boolean;
  @Prop({ default: true }) disablePopoutGraphs: boolean;
  @Prop({ required: false }) highlightedColumnHeader: string;

  // Header Configurations
  @Prop({ required: true }) headerTitle: string;

  // Table Data
  @Prop({ required: true }) rowHeaders: GradientHeaders;
  @Prop({ required: true }) rows: GradientRows;
  @Prop({ default: new Set<string>() }) collapsedPaths: Set<string>;
  @Prop({ required: false }) options: DataOptions;
  @Prop({ default: false }) hasStudentLevel: boolean;

  // Loading State
  @Prop({ default: false }) loading: boolean;

  StaticHeader = StaticHeader;
  RowType = RowType;

  convertHexToRGBA = convertHexToRGBA;

  rowPaths: string[] = [];

  prependStaticHeaders = [
    {
      text: 'Teacher',
      value: StaticHeader.NAME,
    },
  ];

  get tableHeaders(): DataTableHeader[] {
    return [
      ...this.prependStaticHeaders,
      this.rowHeaders.averageHeader,
      ...this.rowHeaders.itemHeaders,
    ];
  }

  get dataIncludesStudents(): boolean {
    return (
      this.hasStudentLevel ||
      this.itemRows.some((row) => row.type === RowType.STUDENT)
    );
  }

  get averageRow(): Row {
    return this.rows.averageRow ?? {};
  }

  get itemRows(): Row[] {
    const items = this.rows?.itemRows ?? new Map();

    return [...items.values()];
  }

  get highestTableValue(): number {
    const highestFraction = this.averageRow[
      this.rowHeaders.averageHeader.value
    ] as Fraction;

    // Tosses an error if no highestFraction. Return 0 if it doesn't have a value
    return highestFraction
      ? highestFraction.numerator / highestFraction.denominator
      : 0;
  }

  // Hidden rows are descendants of collapsed rows
  get hiddenPaths(): string[] {
    return this.rowPaths.filter((rowPath) => {
      return Array.from(this.collapsedPaths).some((collapsedPath) =>
        rowPath.startsWith(collapsedPath + '.')
      );
    });
  }

  togglePath(path: string): void {
    const collapsedPaths = new Set(this.collapsedPaths);

    collapsedPaths.has(path)
      ? collapsedPaths.delete(path)
      : collapsedPaths.add(path);

    this.$emit('updateCollapsedPaths', collapsedPaths);
  }

  updateOptions(options: DataOptions) {
    if (!isEqual(options, this.options)) {
      this.$emit('updateOptions', options);
    }
  }

  // Recursively ordering the rows of each level applying the order by function if given
  orderRows(items: Row[], orderFunction?: OrderFunction): Row[] {
    const res = [];
    let rows = [];
    // List of nodes at this level ordered by given function if any
    if (orderFunction) {
      rows = orderFunction(items);
    } else {
      // Walk the tree
      rows = items;
    }
    for (const row of rows) {
      row.path = row.path ?? row.xref;
      res.push(row);

      // Do the same to its children if subtree is expanded
      if (row.children) {
        const children = row.children as string[];

        const childRows = children.map((child) => {
          const childRow = {
            ...(this.rows.itemRows.get(child) ?? ({} as Row)),
          };
          childRow.path = `${row.path}.${childRow.xref}`;
          return childRow;
        }) as Row[];

        res.push(...this.orderRows(childRows, orderFunction));
      }
    }
    return res;
  }

  customSort(items: Row[], sortBy: string[], sortDesc: boolean[]): Row[] {
    let res: Row[] = [];
    const rootRows = items.filter(
      (row: Row) => (row.parents ?? []).length === 0
    );
    // Not default
    if (sortBy.length > 0) {
      // A column and order to sort by
      const order = sortDesc[0] ? 'desc' : 'asc';
      res = this.orderRows(rootRows, (rows: Row[]) => {
        return orderBy(
          rows,
          (row) => {
            // Override column values in sorting if specified
            if (row.sortOverride && row.sortOverride[sortBy[0]]) {
              return row.sortOverride[sortBy[0]];
            }
            // Otherwise default to using column value
            if (sortBy[0] === StaticHeader.NAME) {
              // Name Column
              return row[StaticHeader.NAME];
            } else {
              // Data Column
              const fraction = (row[sortBy[0]] as Fraction) ?? null;
              return fraction ? fraction.numerator / fraction.denominator : 0;
            }
          },
          [order]
        );
      });
    } else {
      // Walk the tree
      res = this.orderRows(rootRows);
    }

    this.rowPaths = res.map((row) => row.path) as string[];

    return res;
  }

  updateCsvData(items: Row[]): void {
    // The reason we had double average rows is because the unshift here was mutating the original array
    // Created a copy of the array to avoid this
    const itemsCopy = Array.from(items);
    itemsCopy.unshift(this.averageRow);

    this.$emit('updateCsvData', {
      items: itemsCopy,
      tableHeaders: this.tableHeaders,
    });
  }

  getValue(data: Fraction): string | number {
    if (data.type == CellType.RAW_VALUE) {
      return data.numerator / data.denominator;
    } else {
      const value = Math.floor((data.numerator / data.denominator) * 100);
      return `${Number.isFinite(value) ? `${value}%` : '--'}`;
    }
  }

  handleRowClick(row: Row) {
    if (row.type.toLowerCase() == 'course') {
      this.$emit('toggleStudentRows', row);
    } else {
      this.$emit('togglePath', row.path);
    }
  }

  getTableColor(header: string): string {
    const isHighlightedHeader =
      header.split('_')[0] == this.highlightedColumnHeader;

    // If there's no highlighted column, all columns get color
    // If there's a column to highlight, the one with a matching header gets color, all others get default color (gray)
    if (
      !this.highlightedColumnHeader ||
      (this.highlightedColumnHeader && isHighlightedHeader)
    ) {
      return this.color;
    } else {
      // eslint-disable-next-line
      // @ts-ignore
      return this.$vuetify.theme.themes.light.neutral.base;
    }
  }

  handleMapRowClick(
    item: Row,
    itemHeaders: Row,
    headerValue: string,
    event: MouseEvent
  ) {
    this.$emit('clickedMapRow', {
      item: item,
      itemHeaders: itemHeaders,
      headerValue: headerValue,
      event: event,
    });
  }
}
