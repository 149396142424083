
import { CourseDefinition } from '@/domain/Class';
import { Skill, SkillStrand } from '@/domain/Skill';
import { TimeSelector } from '@/domain/Time';
import { User } from '@/domain/User';
import { Component, Vue } from 'vue-property-decorator';
import UnauthorizedPage from '../UnauthorizedPage.vue';
import { CurriculumDefinition } from '@/domain/Curriculum';

@Component({
  components: {
    UnauthorizedPage,
  },
})
export default class InsightsHubParent extends Vue {
  get selectedCurriculum(): CurriculumDefinition | null {
    return this.$store.state.insightsHub.selectedCurriculum;
  }
  get menteeTeachers(): User[] {
    return this.$store.state.insightsHub.menteeTeachers;
  }
  get menteeCourses(): CourseDefinition[] {
    return this.$store.state.insightsHub.menteeCourses;
  }
  get timeSelector(): TimeSelector | null {
    return this.$store.state.insightsHub.timeSelector;
  }
  get skillStrands(): SkillStrand[] {
    return this.$store.state.insightsHub.skillStrands;
  }
  get skills(): Skill[] {
    return this.$store.state.insightsHub.skills;
  }

  created(): void {
    // Request curriculum definitions - no-op if already done or in-progress
    this.$store.dispatch('curricula/requestCurricula').then((curricula) => {
      const curriculumXref = this.$route.query.curriculumXref;

      if (curriculumXref && this.selectedCurriculum?.xref !== curriculumXref) {
        const curriculum = curricula.find(
          (curriculum: CurriculumDefinition) =>
            curriculum.xref === curriculumXref
        );

        // Set selected Curriculum if different from that in store
        this.$store.commit('insightsHub/setSelectedCurriculum', curriculum);
      }

      // Request Mentor Courses (of their Mentees) if not already
      if (this.menteeCourses.length === 0) {
        this.$store.dispatch('insightsHub/getMentorCourses');
      }

      // Request user's mentee teachers and their schools if not already
      if (this.menteeTeachers.length === 0) {
        this.$store.dispatch('insightsHub/getMenteeTeachers').then(() => {
          this.$store.dispatch('insightsHub/getMenteeSchools');
        });
      }

      if (this.skillStrands.length === 0) {
        this.$store.dispatch('insightsHub/getAllSkillStrands');
      }
      this.$store.dispatch('insightsHub/requestSkills');
    });
  }
}
