import { ProblemDefinition } from '@/domain/Problem';

export function getProblemMap(problems: Array<ProblemDefinition>): {
  [prXref: string]: { problem: ProblemDefinition; index: number };
} {
  const problemMap: {
    [prXref: string]: { problem: ProblemDefinition; index: number };
  } = {};

  problems.forEach((problem: ProblemDefinition, index: number) => {
    problemMap[problem.xref] = {
      problem,
      index,
    };
  });

  return problemMap;
}
