/**
 * // https://www.w3schools.com/js/js_cookies.asp
 * @param cname name of cookie
 * @returns cookie value, returns '' if missing
 */
function getCookieByName(cname: string): string {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}
/**
 * @returns auth-cookie value, returns '' if missing
 */
function getAuthCookie(): string {
  const cookieName = `ASSISTmentsService${
    process.env.VUE_APP_COOKIE_SUFFIX || ''
  }`;
  return getCookieByName(cookieName);
}

function removeCookie(cname: string): void {
  const expiredDate = 'Thu, 01 Jan 1970 00:00:00 UTC';
  document.cookie = `${cname}=; expires=${expiredDate}; path=/`;
}

export function removeAuthCookie(): void {
  const cookieName = `ASSISTmentsService${
    process.env.VUE_APP_COOKIE_SUFFIX || ''
  }`;
  removeCookie(cookieName);
}

export function isAuthenticated(): boolean {
  return !!getAuthCookie();
}
