
import { Component, Vue } from 'vue-property-decorator';
import timezones from '@/utils/timezones';
import ActionButtons from '@/components/Settings/ActionButtons.vue';
import { UpdateUserObject } from '@/domain/User';
import { inputValidationRules } from '@/utils/inputValidation.util';
import { AttributeNames } from '@/domain/Attributes';
import SchoolSelector from '@/components/Settings/SchoolSelector.vue';
import { cloneDeep, isEqual } from 'lodash';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';
import { getGradeLevelList } from '@/domain/Grade';
import { getCurriculumDefinitions } from '@/api/core/curricula.api';
import { CurriculumDefinition } from '@/domain/Curriculum';

@Component({
  components: {
    ActionButtons,
    SchoolSelector,
  },
})
export default class Preferences extends Vue {
  rules = inputValidationRules;

  // Defaults
  data: UpdateUserObject = cloneDeep(this.default);

  // Loading state
  loaded = false;

  // Attribute names
  AttributeNames = AttributeNames;

  // Timezones
  timezones = timezones;

  // Value options
  curricula: CurriculumDefinition[] = [];

  ///////////
  // Store //
  ///////////

  get default(): UpdateUserObject {
    return {
      user: {
        timeZone: this.$store.state.auth.user?.timeZone,
      },
      settings: {
        anonymizeReportsByDefault:
          this.$store.state.auth.user?.settings?.anonymizeReportsByDefault ??
          false,
      },
      attributes: this.$store.state.auth.user?.attributes ?? {},
    };
  }

  get gradeLevels(): { display: string; value: string }[] {
    return getGradeLevelList();
  }

  get dataChanged(): boolean {
    // Deep object comparison
    return !isEqual(this.data, this.default);
  }

  // FIXME:
  set dataChanged(value: boolean) {
    // Do nothing.
  }

  //////////////
  //  Methods //
  //////////////

  discardChanges(): void {
    // Make a new (deep) copy rather than editing the default
    // and avoid mutating store state outside mutation handlers
    this.data = cloneDeep(this.default);
  }

  created(): void {
    const curriculumPromise = getCurriculumDefinitions().then((response) => {
      this.curricula = response;
    });

    Promise.all([curriculumPromise]).then(() => {
      this.loaded = true;
    });
  }

  trackPreferencesSave(): void {
    trackMixpanel(EventType.accountSettingsPreferencesUpdated, {
      anonymizeReportsByDefault: this.data.settings.anonymizeReportsByDefault,
      timezone: this.data.user.timeZone,
      school: this.data.attributes[AttributeNames.NCES_SCHOOL],
      curriculumPreference: this.data.attributes[AttributeNames.CURRICULUM],
      gradesTaught: this.data.attributes[AttributeNames.GRADE_LEVEL],
    });
  }
}
