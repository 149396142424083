
import {
  getAssignmentAssignees,
  getAssignmentDefinition,
  getAssignmentReportData,
  updateStudentProblemLog,
} from '@/api/core/assignment.api';
import { AssignmentDefinition } from '@/domain/Assignment';
import { ProblemDefinition, ProblemTypeSDK3 } from '@/domain/Problem';
import { ProblemLog, PartLogData } from '@/domain/ReportData/AssignmentData';
import { User } from '@/domain/User';
import {
  getQuickComments,
  QuickCommentsRequestBody,
  QuickCommentsRequestParams,
} from '@/api/quickcomments.api';
import { QuickComment } from '@/domain/QuickComment';
import { Component, Vue, Watch } from 'vue-property-decorator';
import EssayScoringTable from '@/components/Report/EssayScoringTable.vue';
import { isEmpty, meanBy } from 'lodash';
import OpenResponseTextDialog from '@/components/Report/OpenResponseTextDialog.vue';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import {
  ProblemForReport,
  getProblemTitleForReport,
  getProblemsForReport,
} from '@/utils/report.util';

export interface ProblemLogForReport extends ProblemLog {
  assignee: string;
}

enum Direction {
  PREVIOUS = -1,
  NEXT = 1,
}

export enum QCStatus {
  NONE = 'NONE',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

@Component({
  components: {
    EssayScoringTable,
    OpenResponseTextDialog,
  },
})
export default class EssayScoringPage extends Vue {
  reportReady = false;

  showQuestion = false;
  placeholder = '-----';
  showResponse = false;
  response = '';
  updating = 0;

  Direction = Direction;

  assignment: AssignmentDefinition | null = null;
  pLogMap: Record<number, ProblemLogForReport> = {};
  // reportData: StudentData | null = null;
  assignees: User[] = [];

  quickCommentsDownloaded: QuickComment[] = [];
  quickCommentsStatus: QCStatus = QCStatus.NONE;
  numQuickComments = 3;
  numBatchRequests = 5;

  controller: AbortController | undefined = undefined;

  get isQCTeacher(): boolean {
    return this.getCurrentUser.settings?.quickCommentsTeacher ?? false;
  }

  get isQCLiveTeacher(): boolean {
    return this.getCurrentUser.settings?.useQuickCommentsLive ?? false;
  }

  get problemSetMap(): Record<string, ProblemSetDefinition> {
    return this.$store.state.content.problemSetMap;
  }

  get problemMap(): Record<string, ProblemDefinition> {
    return this.$store.state.content.problemMap;
  }

  get problemSetAssigned(): ProblemSetDefinition | undefined {
    return this.assignment
      ? this.problemSetMap[this.assignment.problemSetCeri]
      : undefined;
  }

  // FIXME: Only score assigned open responses, excluding redo open responses.
  get problemsAssigned(): ProblemForReport[] {
    let prs: ProblemForReport[];
    if (this.problemSetAssigned) {
      prs = getProblemsForReport(
        this.problemSetAssigned.xref,
        this.problemSetMap,
        this.problemMap
      );
    } else {
      prs = [];
    }
    // Should already be ordered.
    return prs.filter(
      (pr) => pr.problemTypeSDK3 == ProblemTypeSDK3.OPEN_RESPONSE
    );
  }

  get problem(): ProblemForReport | undefined {
    const xref = this.$route.params.problemXref;
    return this.problemsAssigned.find((pr) => pr.xref === xref);
  }

  set problem(pr: ProblemForReport) {
    this.$router.replace({
      name: 'essayScoringPage',
      params: {
        ...this.$route.params,
        problemXref: pr.xref,
      },
      query: this.$route.query,
    });
  }

  get index(): number {
    return this.problemsAssigned.findIndex(
      (pr) => pr.xref === this.problem?.xref
    );
  }

  set index(index: number) {
    const pr = this.problemsAssigned[index];
    this.problem = pr;
  }

  get title(): string {
    if (this.problem) {
      return getProblemTitleForReport(this.problem, true);
    } else {
      return this.placeholder;
    }
  }

  get problemLogs(): ProblemLogForReport[] {
    const pLogs = [];
    for (const pLogDbid in this.pLogMap) {
      const pLog = this.pLogMap[pLogDbid];
      if (this.problem?.xref == pLog.prCeri) {
        pLogs.push(pLog);
      }
    }
    return pLogs;
  }

  get quickCommentParams(): QuickCommentsRequestParams[] {
    const paramsList = [];
    if (this.assignment && this.problem) {
      let params = undefined;
      for (const pLog of this.problemLogs) {
        // FIXME: Figure out if/when we want to support multiple Open Response Parts?
        // Will the response list always be of size 1?
        const answerText = pLog.partLogData?.[1].response[0];
        if (answerText) {
          const body: QuickCommentsRequestBody = {
            userId: pLog.assignee,
            problemLogId: pLog.id,
            answerText,
            numOfComments: this.numQuickComments,
          };
          if (params && params.answers.length < this.numBatchRequests) {
            params.answers.push(body);
          } else {
            // New "batch".
            params = {
              problemId: this.problem.xref,
              teacherId: this.getCurrentUser.xref,
              assignmentId: this.assignment.xref,
              answers: [body],
            };
            paramsList.push(params);
          }
        }
      }
    }
    return paramsList;
  }

  async created(): Promise<void> {
    const assignmentXref = this.$route.params.xref;
    this.reportReady = false;

    // Download the Assignment.
    const assignmentPromise = getAssignmentDefinition(assignmentXref, {
      details: true,
    }).then((assignment) => {
      this.assignment = assignment;
      // Download the Problem Set.
      const problemSetPromise = this.$store.dispatch(
        'content/getProblemSetTree',
        {
          xref: assignment.problemSetCeri,
        }
      );
      const assigneePromise = getAssignmentAssignees(assignment.xref)
        .then((assignees: User[]) => {
          this.assignees = assignees;
        })
        .catch(() => {
          // No assignees found.
          this.assignees = [];
        });
      return Promise.all([problemSetPromise, assigneePromise]);
    });

    // Download assignment report data.
    const dataPromise = getAssignmentReportData(assignmentXref, {
      dTypes: ['ASSIGNMENT_LOGS', 'PROBLEM_LOGS'],
    })
      .then((reportData) => {
        this.pLogMap = {};
        if (reportData && !isEmpty(reportData)) {
          const sLogs = reportData.studentLogs ?? [];
          for (const sLog of sLogs) {
            const logData = sLog.problemLogAndActions ?? [];
            for (const data of logData) {
              const pLog = data.prLog;
              this.pLogMap[pLog.id] = { ...pLog, assignee: sLog.studentXref };
            }
          }
        }
      })
      .catch(() => {
        this.pLogMap = {};
      });

    try {
      await Promise.all([assignmentPromise, dataPromise]);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      error.handleGlobally && error.handleGlobally();
    }

    // Donwload Quick Comments for Assignee if any.
    this.downloadQuickComments();
    this.reportReady = true;
    this.trackEssayTableLoaded();
  }

  updateIndex(direction: Direction): void {
    this.index = this.index + direction;
  }

  toggleShowQuestion(): void {
    this.showQuestion = !this.showQuestion;
  }

  openResponseDialog(response: string): void {
    this.response = response;
    this.showResponse = true;
  }

  updateProblemPartLog({
    id,
    modifiedFields,
  }: {
    id: number;
    modifiedFields: Record<
      number,
      Partial<Pick<PartLogData, 'continuousScore' | 'teacherComment'>>
    >;
  }): void {
    const found = this.pLogMap[id];
    if (this.assignment && found) {
      this.updating = found.id;
      const modifiedLog = { ...found };
      for (const marker in modifiedFields) {
        modifiedLog.partLogData[marker] = {
          ...modifiedLog.partLogData[marker],
          ...modifiedFields[marker],
        };
      }
      // Update overall Problem Score.
      const partScores = [];
      for (const marker in modifiedLog.partLogData) {
        const score = modifiedLog.partLogData[marker].continuousScore;
        if (typeof score === 'number') {
          partScores.push(score);
        }
      }
      if (partScores.length) {
        modifiedLog.continuousScore = meanBy(partScores);
      }
      updateStudentProblemLog(this.assignment.xref, found.assignee, found.id, {
        continuousScore: modifiedLog.continuousScore,
        partLogData: modifiedFields as Record<number, PartLogData>,
      })
        .then(() => {
          this.pLogMap[id] = modifiedLog;
          this.$notify(`Score feedback updated!`);
        })
        .catch(() => {
          this.$notify('Something went wrong. Please try again.');
        })
        .finally(() => {
          this.updating = 0;
        });
    }
  }

  async downloadQuickComments(): Promise<void> {
    // Cancel previous request.
    if (this.controller) {
      this.controller.abort();
    }
    this.controller = new AbortController();
    // Reset previously downloaded QCs.
    this.quickCommentsDownloaded = [];
    if (this.isQCTeacher && this.quickCommentParams.length) {
      this.quickCommentsStatus = QCStatus.LOADING;
      const promises = this.quickCommentParams.map((param) => {
        return getQuickComments(param, this.controller);
      });
      try {
        const qcs = await Promise.all(promises);
        this.quickCommentsDownloaded = qcs.flat();
        this.quickCommentsStatus = QCStatus.SUCCESS;
      } catch (e) {
        this.quickCommentsStatus = QCStatus.FAILURE;
      }
    } else {
      // Reset state.
      this.quickCommentsStatus = QCStatus.NONE;
    }
  }

  @Watch('quickCommentParams')
  onProblemChange(): void {
    this.downloadQuickComments();
  }

  @Watch('$route.params.problemXref')
  trackEssayTableLoaded(): void {
    trackMixpanel(EventType.essayScoringViewed, {
      assignmentXref: this.assignment?.xref,
      problemCode: this.problem?.xref,
    });
  }

  trackBackToAssignmentReport(): void {
    trackMixpanel(EventType.essayReportBackToAssignmentReport, {
      assignmentXref: this.assignment?.xref,
      problemCode: this.problem?.xref,
    });
  }

  @Watch('showQuestion')
  trackEssayTableShowQuestion(): void {
    trackMixpanel(EventType.essayScoringShowQuestion, {
      assignmentXref: this.assignment?.xref,
      problemCode: this.problem?.xref,
      showQuestion: this.showQuestion,
    });
  }
}
