import { AssignmentDefinition } from '@/domain/Assignment';
import { ProblemDefinition } from '@/domain/Problem';
import { ProblemSetDefinition, ProblemSetType } from '@/domain/ProblemSet';
import { ActionType } from '@/domain/Action';
import { getContentType } from './builder.util';
import { ContentType } from '@/domain/Content';
import {
  ProblemLogAndActions,
  ProblemStats,
  StudentData,
} from '@/domain/ReportData/AssignmentData';
import { getPartLetter } from './problem.util';

const hasSeenAnswer = (actions: Array<ActionType>): boolean => {
  return (
    actions.includes(ActionType.ANSWER_REQUESTED_ACTION) ||
    actions.includes(ActionType.EXPLANATION_REQUESTED_ACTION)
  );
};

const isInTestMode = (assignment: AssignmentDefinition): boolean => {
  let testMode = false;
  const props = assignment?.properties;
  if (props) {
    const correctnessFeedback = props.find(
      (p) => p.propertyKey === 'correctnessFeedback'
    );
    const tutoringAccessible = props.find(
      (p) => p.propertyKey === 'tutoringAccessible'
    );
    // If the properties exist then make sure they are set to FALSE.
    // If they do not exist or are not set to FALSE then the Assignment is NOT in test mode.
    if (correctnessFeedback && tutoringAccessible) {
      testMode =
        correctnessFeedback.propertyValue === 'false' &&
        tutoringAccessible.propertyValue === 'false';
    }
  }
  return testMode;
};

//////////////////////////
// Numbers & Formatting //
//////////////////////////
// Multiplies by 100 and rounds to (max) two decimal places
// https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
const decimalToPercent = (decimal: number): number => {
  return Math.round((decimal + Number.EPSILON) * 10000) / 100;
};
const formatPercentage = (decimal: number): string => {
  const percent = Math.round(decimalToPercent(decimal));
  return `${percent}%`;
};

// FIXME: Will a Problem ever have multiple redo parent and path?
export interface ProblemStatsForReport extends ProblemStats {
  numCorrect: number;
  numCorrectEventually: number;
  numIncorrect: number;
  path: string;
  // May be Problem or Problem Set. Cannot be broken down to Problems because the redo average only overwrites
  // the first part < 100 of a multi-part Problem, which varies among Students.
  redoParent?: string;
}

// FIXME: Based on the fact that a single Problem will ever appear ONCE in the Problem Set assigned,
// including redo?
const getProblemStatsForReport = (
  reportData: StudentData
): Record<string, ProblemStatsForReport> => {
  const prStatsMap: Record<string, ProblemStatsForReport> = {};
  const prStats = reportData?.prAllStats ?? [];
  for (const stats of prStats) {
    prStatsMap[stats.prCeri] = {
      ...stats,
      numCorrect: 0,
      numCorrectEventually: 0,
      numIncorrect: 0,
    } as ProblemStatsForReport;
  }
  // Fill in the rest of the information from log data.
  const studentLogs = reportData.studentLogs;
  for (const studentLog of studentLogs) {
    const logData: ProblemLogAndActions[] =
      studentLog.problemLogAndActions ?? [];
    for (const logs of logData) {
      const prCeri = logs.prLog.prCeri;
      const prLog = logs.prLog;
      // MUST have stats?
      const stats = prStatsMap[prCeri];
      if (stats) {
        stats.path = prLog.pathInfo;
        stats.redoParent = prLog.redoParentXref;
        const score = prLog.continuousScore;
        // Only count student if their score is given, excluding Open Responses.
        if (typeof score === 'number') {
          // Perfect Score
          if (score >= 1) {
            stats.numCorrect += 1;
          }
          // Correct Eventually (partial credit)
          else if (!prLog.sawAnswer) {
            stats.numCorrectEventually += 1;
          }
          // Incorrect (answer requested)
          else {
            stats.numIncorrect += 1;
          }
        }
      }
    }
  }
  return prStatsMap;
};

export interface ProblemForReport extends ProblemDefinition {
  parent?: string;
  // Multiple if redo is a multi-part. May NOT be applicable if parent is multi-part.
  redoProblems?: string[];
  // May be Problem or Problem Set. Cannot be broken down to Problems because the redo average only overwrites
  // the first part < 100 of a multi-part Problem, which varies among Students.
  redoParent?: string;
  // Unfortunately, positions are computed.
  position: number;
}

// FIXME: What happens if a redo Problem is no longer a redo (deactivated)?
// Ordered Problems, including redos if applicable.
const getProblemsForReport = (
  psCeri: string,
  problemSetMap: Record<string, ProblemSetDefinition>,
  problemMap: Record<string, ProblemDefinition>,
  problemStatsMap?: Record<string, ProblemStatsForReport>,
  redoMap?: Record<string, string[]>
): ProblemForReport[] => {
  const ps = problemSetMap[psCeri];
  // Including redo Problems which we have stats for.
  const prs: ProblemForReport[] = [];
  const children = ps?.children ?? [];
  for (let index = 0; index < children.length; index++) {
    const psChild = children[index];
    const position = index + 1;
    const childType = getContentType(psChild);
    const childPrs: ProblemForReport[] = [];
    if (childType == ContentType.PROBLEM) {
      let partLetter = undefined;
      if (ps.problemSetType == ProblemSetType.MULTI_PART_PROBLEM_SET) {
        partLetter = getPartLetter(index + 1);
      }
      childPrs.push({
        ...problemMap[psChild],
        partLetter,
        parent: ps.xref,
      } as ProblemForReport);
    } else {
      childPrs.push(
        ...getProblemsForReport(
          psChild,
          problemSetMap,
          problemMap,
          problemStatsMap,
          redoMap
        )
      );
    }
    const childRedos = redoMap?.[psChild];

    if (childRedos?.length) {
      // Multiple redos found for Problem. ONLY include ones that are applicable.
      // To my understanding, every Student in the Class will get the same redo Problem.
      const redoPrs: ProblemForReport[] = [];
      for (const childRedo of childRedos) {
        const redoType = getContentType(childRedo);
        if (redoType == ContentType.PROBLEM) {
          const stats = problemStatsMap?.[childRedo];
          if (stats && stats.redoParent == psChild) {
            redoPrs.push({
              ...problemMap[childRedo],
            } as ProblemForReport);
            // FIXME: ONLY ONE redo PER Problem?
            break;
          }
        } else {
          const redoPs = problemSetMap[childRedo];
          if (redoPs?.problemSetType == ProblemSetType.MULTI_PART_PROBLEM_SET) {
            for (const redoChild of redoPs.children) {
              // Multi-part Problems MUST ONLY contain Problems.
              const stats = problemStatsMap?.[redoChild];
              // Only include if we have stats for it.
              if (
                stats &&
                stats.redoParent == psChild &&
                stats.path.includes(childRedo)
              ) {
                // NOT getting redos of redo.
                redoPrs.push(
                  ...getProblemsForReport(
                    childRedo,
                    problemSetMap,
                    problemMap,
                    problemStatsMap
                  )
                );
                // FIXME: ONLY ONE redo PER activated Problem?
                break;
              }
            }
          }
        }
      }

      if (redoPrs.length) {
        const redos = [];
        for (const redoPr of redoPrs) {
          redoPr.redoParent = psChild;
          redos.push(redoPr.xref);
        }
        for (const childPr of childPrs) {
          childPr.redoProblems = redos;
        }
        childPrs.push(...redoPrs);
      }
    }
    prs.push(
      ...childPrs.map((pr) => {
        return { ...pr, position };
      })
    );
  }
  return prs;
};

const getProblemTitleForReport = (
  pr: ProblemForReport,
  abbreviated: boolean
): string => {
  let title = abbreviated ? 'P' : 'Problem ';
  title += pr.position;
  if (pr.redoParent) {
    title += ' Redo';
  }
  if (pr.partLetter) {
    title += ': ' + pr.partLetter;
  }
  return title;
};

const getProblemAnswer = (answer: string): string => {
  let div: HTMLElement | null = document.getElementById('question-conversion');
  if (!div) {
    div = document.createElement('div');
    div.id = 'question-conversion';
  }
  div.innerHTML = answer;
  return div.textContent || div.innerText || '';
};

export {
  hasSeenAnswer,
  isInTestMode,
  formatPercentage,
  getProblemStatsForReport,
  getProblemsForReport,
  getProblemTitleForReport,
  getProblemAnswer,
};
