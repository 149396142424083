
import { Component, Prop, Vue } from 'vue-property-decorator';
import DonutChartView from '@/components/Report/DonutChartView.vue';
import { ProblemTypeSDK3 } from '@/domain/Problem';
import { SkillDefinition } from '@/domain/Skill';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';
import {
  ProblemForReport,
  ProblemStatsForReport,
  formatPercentage,
  getProblemTitleForReport,
} from '@/utils/report.util';
import { InstructionalRecommendation } from '@/domain/Tutoring';
import { CWAF } from '@/views/MyAssignments/ReportLandingPage.vue';
import ProblemViewSDK3 from '../Builder/ContentView/ProblemViewSDK3.vue';
import AnswersViewSDK3 from '../Builder/ContentView/AnswersViewSDK3.vue';
import { getAnswerParts, getCorrectAnswer } from '@/utils/problem.util';
import { isEmpty } from 'lodash';

@Component({
  components: {
    AnswersViewSDK3,
    DonutChartView,
    ProblemViewSDK3,
  },
})
export default class ProblemSideSheetForReport extends Vue {
  @Prop({ default: true }) value: boolean;
  @Prop({ required: true }) problemSet: string;
  @Prop({ required: true }) problem: ProblemForReport;
  @Prop({ default: 0 }) tab: number;
  @Prop({ required: false }) prStats: ProblemStatsForReport | undefined;
  @Prop({ default: () => [] }) IRs: InstructionalRecommendation[];
  @Prop({ default: () => [] }) CWAFs: CWAF[];
  @Prop({ default: true }) navigation: boolean;
  @Prop({ default: false }) disablePrevious: boolean;
  @Prop({ default: false }) disableNext: boolean;

  // Allows us access to the enum in the template.
  ProblemTypeSDK3 = ProblemTypeSDK3;
  formatPercentage = formatPercentage;

  //////////
  // Data //
  //////////
  isExpanded = false;
  problemDetails = false;

  openedTab = 0;

  get showDrawer(): boolean {
    return this.value;
  }

  set showDrawer(val: boolean) {
    this.$emit('input', val);
  }

  get title(): string {
    return getProblemTitleForReport(this.problem, false);
  }

  get matchedSkills(): SkillDefinition[] {
    const xrefs = this.problem.attributes?.skill ?? [];
    const skills = [];
    for (const xref of xrefs) {
      const skill = this.skillMap.get(xref);
      if (skill) {
        skills.push(skill);
      }
    }
    return skills;
  }

  get correctAnswer(): Record<number, string[]> | undefined {
    const answerParts = getAnswerParts(this.problem.answersSDK3);
    return getCorrectAnswer(answerParts);
  }

  get drawerOption(): string {
    return this.isExpanded ? '>> Collapse' : '<< Expand';
  }

  get hasNoLogStats(): boolean {
    if (this.prStats) {
      // No data to display
      return (
        this.prStats.numCorrect === 0 &&
        this.prStats.numCorrectEventually === 0 &&
        this.prStats.numIncorrect === 0
      );
    }
    // No stats given
    return true;
  }

  ///////////
  // Store //
  ///////////

  get skillMap(): Map<string, SkillDefinition> {
    return this.$store.getters['skillList/getSkillsMap'];
  }

  /////////////
  // Methods //
  /////////////

  toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
  }

  openProblemDetails(): void {
    // Open problem details dialog
    this.problemDetails = true;
  }

  created(): void {
    // Download skills if not already
    // Will be prevented to download again in store if already
    this.$store.dispatch('skillList/requestSkills');
    this.openedTab = this.tab;
  }

  irClicked(): void {
    trackMixpanel(EventType.irClick, {
      assignmentXref: this.$router.currentRoute.params.xref,
      problemCode: this.problem.xref,
    });
  }

  psideClicked(): void {
    trackMixpanel(EventType.psideClick, {
      assignmentXref: this.$router.currentRoute.params.xref,
      problemCode: this.problem.xref,
    });
  }
}
