
import { Component, Vue } from 'vue-property-decorator';
import { Notification } from '@/domain/Notification';

@Component
export default class NotificationsView extends Vue {
  get notifications(): Notification[] {
    return this.$store.state.notifications.notifications;
  }

  get showNotification(): boolean {
    return true;
  }
  set showNotification(show: boolean) {
    if (!show) {
      this.$store.commit('notifications/clear');
    }
  }
}
