
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class LtiDeepLinkResponsePage extends Vue {
  get jwt(): string {
    return this.$route.query.jwt as string;
  }

  get redirectUrl(): string {
    return this.$route.query.redirectUrl as string;
  }

  mounted(): void {
    document.forms[0].submit();
  }
}
