import {
  ContentType,
  PersistableStateType,
  UserToContentRelationshipType,
} from '@/domain/Content';
import { FolderMemberType } from '@/domain/Folder';
import {
  AnswerSet,
  ProblemDefinition,
  ProblemProperties,
  ProblemTypeSDK3,
} from '@/domain/Problem';
import {
  ProblemSetDefinition,
  ProblemSetProperties,
  ProblemSetType,
} from '@/domain/ProblemSet';
import { coreAxios } from '@/plugins/axios';
import { CancelToken } from 'axios';
import {
  DefinitionInclude,
  DefinitionParams,
  ObjectList,
  PagingParams,
} from './base.api';
import { getPersistableStateType } from '@/utils/builder.util';
import {
  HintSet,
  ITutorStrategy,
  ITutoringContainer,
  TutorStrategyType,
} from '@/domain/Tutoring';
import {
  AttributesMapDTO,
  transformAttributes,
  inverseTransformAttributes,
} from './attributes.api';
import { AclPermissionType, AclResourceType } from '@/domain/Acls';

const END_POINT = '/content';
const PROBLEM_SET_END_POINT = `${END_POINT}/problem-sets`;
const PROBLEM_END_POINT = `${END_POINT}/problems`;
const TUTORING_END_POINT = `${END_POINT}/tutorings`;

export interface ProblemSetDefinitionDTO {
  CERI: string;
  author: string;
  editor: string;
  owner: string;
  contentType: ContentType.PROBLEM_SET;
  enabled: boolean;
  name?: string;
  problemSetType: ProblemSetType;
  properties?: ProblemSetProperties;
  attributes?: AttributesMapDTO;
  createdAt: number;
  updatedAt: number;
  mappedCeri?: string;
  permissions?: AclPermissionType[];
}

export interface ProblemSetsFilterParams {
  ceris?: string[];
  psTypes?: (
    | PersistableStateType.PUBLISHED
    | PersistableStateType.WORK_IN_PROGRESS
  )[];
  types?: ProblemSetType[];
  problemTypes?: ProblemTypeSDK3[];
  contains?: string;

  // 'me' or xref
  user?: string;
  filterBy?: UserToContentRelationshipType[];

  name?: string;
  isAssessment?: boolean;
  skills?: string[];
  folders?: string[];
  isResearch?: boolean;
  isCertified?: boolean;
}

export interface ProblemDefinitionDTO {
  CERI: string;
  answersSDK3?: AnswerSet | null;
  author: string;
  editor: string;
  owner: string;
  contentType: ContentType.PROBLEM;
  enabled: boolean;
  name?: string;
  problemTypeSDK3: ProblemTypeSDK3;
  properties?: ProblemProperties;
  question: string;
  attributes?: AttributesMapDTO;
  createdAt: number;
  updatedAt: number;
  mappedCeri?: string;
  permissions?: AclPermissionType[];
}

export interface ProblemsFilterParams {
  ceris?: string[];
  psTypes?: (
    | PersistableStateType.PUBLISHED
    | PersistableStateType.WORK_IN_PROGRESS
  )[];
  types?: ProblemTypeSDK3[];
  problemSetTypes?: ProblemSetType[];

  // 'me' or xref
  user?: string;
  filterBy?: UserToContentRelationshipType[];

  name?: string;

  curricula?: string[];
  isTextbook?: boolean;
  skills?: string[];
  isResearch?: boolean;
  isCertified?: boolean;
}

export interface ITutorStrategyDTO {
  CERI: string;
  author: string;
  editor: string;
  owner: string;
  contentType: ContentType.TUTOR_STRATEGY;
  enabled: boolean;
  name?: string;
  tutoringTarget: string;
  tutorStrategyType:
    | TutorStrategyType.HINT_SET
    | TutorStrategyType.EXPLANATION
    | TutorStrategyType.STUDENT_RESPONSE_FEEDBACK
    | TutorStrategyType.INSTRUCTIONAL_RECOMMENDATIONS;
  createdAt: number;
  updatedAt: number;
  mappedCeri?: string;
  hasVideo: boolean;
  htmlMarker: number;
  trusted: boolean;
  prAuthorWritten: boolean;
  permissions?: AclPermissionType[];
}

export interface TutoringFilterParams {
  ceris?: string[];
  psTypes?: (
    | PersistableStateType.PUBLISHED
    | PersistableStateType.WORK_IN_PROGRESS
  )[];
  types?: TutorStrategyType[];

  // 'me' or xref
  user?: string;
  filterBy?: UserToContentRelationshipType[];

  targets?: string[];

  isCertified?: boolean;
}

// TODO: Figure out if we need hasVideo
export interface IHintSetDTO extends ITutorStrategyDTO {
  tutorStrategyType: TutorStrategyType.HINT_SET;
  hints: string[];
  // hasVideo: boolean[];
}

// TODO: Figure out if we need hasVideo
export interface IExplanationDTO extends ITutorStrategyDTO {
  tutorStrategyType: TutorStrategyType.EXPLANATION;
  explanation: string;
  // hasVideo: boolean[];
}

//TODO: Figure out if we need responseCount
export interface IStudentResponseFeedbackDTO extends ITutorStrategyDTO {
  tutorStrategyType: TutorStrategyType.STUDENT_RESPONSE_FEEDBACK;
  answerPartsToResponses: Map<number, string[]>;
  responseFeedback: string;
}

export interface IInstructionalRecommendationDTO extends ITutorStrategyDTO {
  tutorStrategyType: TutorStrategyType.INSTRUCTIONAL_RECOMMENDATIONS;
  recommendation: string;
}

// FIXME: Add other types
export type TutorStrategyDTO =
  | IHintSetDTO
  | IExplanationDTO
  | IStudentResponseFeedbackDTO
  | IInstructionalRecommendationDTO;

export interface ContentBuildStatus {
  ceri?: string;
  failMessages?: string[];
  // Internal processing only. Will not included in DTO if not set manually.
  failedCeris?: string[];
  passedCeris?: Record<string, string>;
}

export enum ContentSaveOperationType {
  VALIDATE = 'VALIDATE',
  PUBLISH = 'PUBLISH',
}

export enum CopyProblemTutoring {
  NONE = 'NONE',
  OWNED_BY_USER = 'OWNED_BY_USER',
  CERTIFIED = 'CERTIFIED',
}

const getProblemSetDefinition = (
  psceri: string,
  include?: DefinitionInclude[]
): Promise<ProblemSetDefinition> => {
  return coreAxios
    .get(`${PROBLEM_SET_END_POINT}/${psceri}`, {
      params: {
        include: include ?? [],
      },
    })
    .then((res) => {
      return transformProblemSet(res.data);
    });
};

const getProblemSetChildren = (
  xref: string,
  include?: DefinitionInclude[]
): Promise<(ProblemDefinition | ProblemSetDefinition)[]> => {
  return coreAxios
    .get(`${PROBLEM_SET_END_POINT}/${xref}/members`, {
      params: {
        include: include ?? [],
      },
    })
    .then((res) => {
      return res.data.map(transformProblemOrProblemSet);
    });
};

const getProblemSetChildrenXrefs = (xref: string): Promise<string[]> => {
  return coreAxios
    .get(`${PROBLEM_SET_END_POINT}/${xref}/memberXrefs`)
    .then((res) => {
      return res.data;
    });
};

const searchForProblemSets = (
  filterParams?: ProblemSetsFilterParams,
  pagingParams?: PagingParams,
  definitionParams?: DefinitionParams,
  cancelToken?: CancelToken
): Promise<ObjectList<ProblemSetDefinition>> => {
  return coreAxios
    .get(`${PROBLEM_SET_END_POINT}`, {
      params: { ...filterParams, ...pagingParams, ...definitionParams },
      cancelToken,
    })
    .then((res) => {
      return {
        nextPageToken: res.data.nextPageToken,
        data: res.data.data.map(transformProblemSet),
        count: res.data.count,
      };
    });
};

const createProblemSet = (
  request: Partial<ProblemSetDefinition>,
  ops?: ContentSaveOperationType
): Promise<ContentBuildStatus> => {
  return coreAxios
    .post(`${PROBLEM_SET_END_POINT}`, request, { params: { ops } })
    .then((result) => {
      return result.data;
    });
};

const getProblem = (
  xref: string,
  include?: DefinitionInclude[]
): Promise<ProblemDefinition> => {
  return coreAxios
    .get(`${PROBLEM_END_POINT}/${xref}`, {
      params: {
        include: include ?? [],
      },
    })
    .then((res) => {
      return transformProblem(res.data);
    });
};

const createProblem = (
  request: Partial<ProblemDefinition>,
  ops?: ContentSaveOperationType
): Promise<ContentBuildStatus> => {
  return coreAxios
    .post(`${PROBLEM_END_POINT}`, inverseTransformProblem(request), {
      params: { ops },
    })
    .then((result) => {
      return result.data;
    });
};

const updateProblem = (
  xref: string,
  request: Partial<ProblemDefinition>,
  ops?: ContentSaveOperationType
): Promise<ContentBuildStatus> => {
  return coreAxios
    .patch(`${PROBLEM_END_POINT}/${xref}`, inverseTransformProblem(request), {
      params: { ops },
    })
    .then((result) => {
      return result.data;
    });
};

const copyProblem = (
  prCeri: string,
  includeSimilarity?: boolean,
  includeTutoring?: CopyProblemTutoring
): Promise<ContentBuildStatus> => {
  return coreAxios
    .post(
      `${PROBLEM_END_POINT}/${prCeri}/copy`,
      {},
      {
        params: { includeSimilarity, includeTutoring },
      }
    )
    .then((result) => {
      return result.data;
    });
};

const updateProblemSet = (
  xref: string,
  request: Partial<ProblemSetDefinition>,
  ops?: ContentSaveOperationType
): Promise<ContentBuildStatus> => {
  return coreAxios
    .patch(`${PROBLEM_SET_END_POINT}/${xref}`, request, {
      params: { ops },
    })
    .then((result) => {
      return result.data;
    });
};

const copyProblemSet = (
  psCeri: string,
  includeSimilarity?: boolean
): Promise<ContentBuildStatus> => {
  return coreAxios
    .post(
      `${PROBLEM_SET_END_POINT}/${psCeri}/copy`,
      {},
      { params: { includeSimilarity } }
    )
    .then((result) => {
      return result.data;
    });
};

const deleteProblemSet = (xref: string): Promise<ContentBuildStatus> => {
  return coreAxios.delete(`${PROBLEM_SET_END_POINT}/${xref}`).then((result) => {
    return result.data;
  });
};

const addMembersToProblemSet = (
  psceri: string,
  members: string[]
): Promise<ContentBuildStatus> => {
  return coreAxios
    .post(`${PROBLEM_SET_END_POINT}/${psceri}/members`, {
      members,
    })
    .then((result) => {
      return result.data;
    });
};

const removeMembersFromProblemSet = (
  psceri: string,
  members: string[]
): Promise<ContentBuildStatus> => {
  return coreAxios
    .delete(`${PROBLEM_SET_END_POINT}/${psceri}/members`, {
      params: { members },
    })
    .then((result) => {
      return result.data;
    });
};

const replaceMembersInProblemSet = (
  psceri: string,
  members: string[]
): Promise<ContentBuildStatus> => {
  return coreAxios
    .put(`${PROBLEM_SET_END_POINT}/${psceri}/members`, {
      members,
    })
    .then((result) => {
      return result.data;
    });
};

const updateProblemSetMemberPosition = (
  psceri: string,
  member: string,
  position: number
): Promise<ContentBuildStatus> => {
  return coreAxios
    .patch(`${PROBLEM_SET_END_POINT}/${psceri}/members`, {
      member,
      position,
    })
    .then((result) => {
      return result.data;
    });
};

const getRedoXrefsMap = (
  xrefs: string[]
): Promise<Record<string, string[]>> => {
  return coreAxios
    .get(`${END_POINT}/similar/defnXrefs`, {
      params: {
        ceris: xrefs,
      },
    })
    .then((res) => res.data);
};

const getRedosMap = (
  xrefs: string[]
): Promise<Record<string, (ProblemDefinition | ProblemSetDefinition)[]>> => {
  return coreAxios
    .get(`${END_POINT}/similar/defns`, {
      params: {
        ceris: xrefs,
      },
    })
    .then((res) => {
      const map: Record<string, (ProblemDefinition | ProblemSetDefinition)[]> =
        {};
      for (const xref in res.data) {
        map[xref] = res.data[xref].map(transformProblemOrProblemSet);
      }
      return map;
    });
};

const searchForProblems = (
  filterParams?: ProblemsFilterParams,
  pagingParams?: PagingParams,
  definitionParams?: DefinitionParams,
  cancelToken?: CancelToken
): Promise<ObjectList<ProblemDefinition>> => {
  return coreAxios
    .get(`${PROBLEM_END_POINT}`, {
      params: { ...filterParams, ...pagingParams, ...definitionParams },
      cancelToken,
    })
    .then((res) => {
      return {
        nextPageToken: res.data.nextPageToken,
        data: res.data.data.map(transformProblem),
        count: res.data.count,
      };
    });
};

const createTutorStrategyForProblem = (
  xref: string,
  tutorStrategy: Partial<ITutorStrategy>,
  ops?: ContentSaveOperationType
): Promise<ContentBuildStatus> => {
  return coreAxios
    .post(`${PROBLEM_END_POINT}/${xref}/tutorings`, tutorStrategy, {
      params: { ops },
    })
    .then((result) => {
      return result.data;
    });
};

const updateTutorStrategy = (
  xref: string,
  tutorStrategy: Partial<ITutorStrategy>,
  ops?: ContentSaveOperationType
): Promise<ContentBuildStatus> => {
  return coreAxios
    .patch(`${TUTORING_END_POINT}/${xref}`, tutorStrategy, {
      params: { ops },
    })
    .then((result) => {
      return result.data;
    });
};

const deleteTutorStrategy = (xref: string): Promise<void> => {
  return coreAxios.delete(`${TUTORING_END_POINT}/${xref}`);
};

const getTutorStrategy = (xref: string): Promise<ITutorStrategy> => {
  return coreAxios.get(`${TUTORING_END_POINT}/${xref}`).then((res) => {
    return transformTutorStrategy(res.data);
  });
};

const getTutorStrategiesForProblem = (
  xref: string
): Promise<ITutoringContainer> => {
  return coreAxios.get(`${TUTORING_END_POINT}/problem/${xref}`).then((res) => {
    return transformTutorContainer(res.data);
  });
};

const getTutorStrategiesForUser = (
  xref: string
): Promise<ITutoringContainer> => {
  return coreAxios.get(`${TUTORING_END_POINT}/user/${xref}`).then((res) => {
    return transformTutorContainer(res.data);
  });
};

const searchForTutorStrategies = (
  filterParams?: TutoringFilterParams,
  pagingParams?: PagingParams,
  cancelToken?: CancelToken
): Promise<ObjectList<ITutorStrategy>> => {
  return coreAxios
    .get(`${TUTORING_END_POINT}`, {
      params: { ...filterParams, ...pagingParams },
      cancelToken,
    })
    .then((res) => {
      return {
        nextPageToken: res.data.nextPageToken,
        data: res.data.data.map(transformTutorStrategy),
        count: res.data.count,
      };
    });
};

/////////////
// Helpers //
/////////////

function inverseTransformProblem(
  problem: Partial<ProblemDefinition>
): Partial<ProblemDefinitionDTO> {
  const { attributes, ...rest } = problem;
  return {
    attributes: attributes ? inverseTransformAttributes(attributes) : undefined,
    ...rest,
  };
}

function transformProblem(problem: ProblemDefinitionDTO): ProblemDefinition {
  const { CERI, answersSDK3, attributes, permissions, ...rest } = problem;
  return {
    xref: CERI,
    // Always include for reactivity purposes.
    answersSDK3: answersSDK3 ?? null,
    attributes: attributes ? transformAttributes(attributes) : undefined,
    resourceType: AclResourceType.SDK3_PROBLEM,
    permissions: permissions ?? [],
    ...rest,
  };
}

function transformProblemSet(
  dto: ProblemSetDefinitionDTO
): ProblemSetDefinition {
  const { CERI, attributes, permissions, ...rest } = dto;
  const state = getPersistableStateType(CERI);
  let memberType = null;
  switch (state) {
    case PersistableStateType.PUBLISHED:
      memberType = FolderMemberType.PUB_PS;
      break;
    case PersistableStateType.WORK_IN_PROGRESS:
      memberType = FolderMemberType.WIP_PS;
      break;
    default:
      throw new Error(`Unexpected state: ${state}`);
  }
  return {
    xref: CERI,
    memberType,
    ...rest,
    attributes: attributes ? transformAttributes(attributes) : undefined,
    resourceType: AclResourceType.SDK3_PROBLEM_SET,
    permissions: permissions ?? [],
    // Internal processing only. Will not included in DTO if not set manually.
    children: [],
  };
}

const transformTutorStrategy = (dto: ITutorStrategyDTO): ITutorStrategy => {
  const { CERI: xref, permissions, ...rest } = dto;
  const tsType = rest.tutorStrategyType;
  switch (tsType) {
    case TutorStrategyType.HINT_SET:
      {
        // Always include for reactivity purposes.
        const hintSet = rest as HintSet;
        hintSet.hints = hintSet.hints ?? [];
      }
      break;
  }
  return {
    xref,
    resourceType: AclResourceType.SDK3_TUTORING_STRATEGY,
    permissions: permissions ?? [],
    ...rest,
  };
};

function transformProblemOrProblemSet(
  dto: ProblemDefinitionDTO | ProblemSetDefinitionDTO
): ProblemDefinition | ProblemSetDefinition {
  switch (dto.contentType) {
    case ContentType.PROBLEM:
      return transformProblem(dto);
    case ContentType.PROBLEM_SET:
      return transformProblemSet(dto);
  }
}

function transformTutorContainer(
  tutorStrategies: Record<TutorStrategyType, ITutorStrategyDTO[]>
) {
  const tsContainer: ITutoringContainer = {};
  for (const [tsType, tsList] of Object.entries(tutorStrategies)) {
    const tsKey = tsType as
      | TutorStrategyType.HINT_SET
      | TutorStrategyType.EXPLANATION
      | TutorStrategyType.STUDENT_RESPONSE_FEEDBACK
      | TutorStrategyType.INSTRUCTIONAL_RECOMMENDATIONS;
    // eslint-disable-next-line
    // @ts-ignore
    tsContainer[tsKey] = tsList.map(transformTutorStrategy);
  }
  return tsContainer;
}

export {
  getProblemSetDefinition,
  getProblemSetChildren,
  getProblemSetChildrenXrefs,
  searchForProblemSets,
  createProblemSet,
  updateProblemSet,
  deleteProblemSet,
  addMembersToProblemSet,
  removeMembersFromProblemSet,
  replaceMembersInProblemSet,
  updateProblemSetMemberPosition,
  getProblem,
  createProblem,
  updateProblem,
  getRedoXrefsMap,
  getRedosMap,
  transformProblemSet,
  transformProblem,
  transformProblemOrProblemSet,
  searchForProblems,
  createTutorStrategyForProblem,
  getTutorStrategiesForProblem,
  getTutorStrategiesForUser,
  searchForTutorStrategies,
  getTutorStrategy,
  updateTutorStrategy,
  deleteTutorStrategy,
  transformTutorStrategy,
  copyProblem,
  copyProblemSet,
};
