
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataOptions, DataTableHeader } from 'vuetify';
import { orderBy, shuffle } from 'lodash';
import { ProblemDefinition } from '@/domain/Problem';
import {
  ProblemLogAndActions,
  StudentLog,
  ProblemLog,
  StudentData,
} from '@/domain/ReportData/AssignmentData';
import { SkillDefinition } from '@/domain/Skill';
import { User } from '@/domain/User';
import { getSkills } from '@/api/core/skills.api';
import { scoreProblemDefinitionForStandardsReport } from '@/utils/problem.util';
import { appendGlobalHeaderOptions } from '@/utils/dataTables.utils';
import ScoreChip from './ScoreChip.vue';
import sortBySortableName from '@/utils/sortBySortableName.util';
import StandardsScoringDialog from './StandardsScoringDialog.vue';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';

interface SkillColumn {
  skillCode: string;
  value: number | null;
  totalProblemsCount: number;
  completedProblemsCount: number;
}

interface StandardsReportTableRow {
  student: User;
  averageScore: number;
  skills: Array<SkillColumn>;
}

@Component({
  components: {
    ScoreChip,
    StandardsScoringDialog,
  },
})
export default class StandardsReportTable extends Vue {
  @Prop({ default: null }) reportData: StudentData | null;
  @Prop({ default: () => [] }) problems: ProblemDefinition[];
  @Prop({ default: () => [] }) assignees: User[];

  rowsGenerated = false;
  problemCountsMap: Map<string, number> = new Map<string, number>();
  showScoringDialog = false;
  reportReady = false;

  anonymizeNames = false;

  prependStaticHeaders: Array<DataTableHeader> = [
    {
      text: 'Student Name/Problem',
      value: 'student',
      align: 'start',
      class: ['text-no-wrap', 'sticky-row', 'sticky-row-1'],
      cellClass: ['text-no-wrap'],
      sortable: this.anonymizeNames ? false : true,
      sort: sortBySortableName,
    },
    {
      text: 'Average Score',
      value: 'averageScore',
      align: 'center',
      class: ['text-no-wrap', 'sticky-row', 'sticky-row-1'],
    },
  ];

  /**
   * Maps problems ids to the problem objects
   * Used to check problem types when scoring problems
   */
  get problemXrefToProblemMap(): Map<string, ProblemDefinition> {
    const res = new Map();
    for (const prob of this.problems) {
      res.set(prob.xref, prob);
    }
    return res;
  }

  /**
   * Score an individual problem log based on problem type
   * Assign partial credit to open response questions
   * All other questions are 1 if correct 0 otherwise
   * @param prLog: ProblemLog - the log to score
   * @returns score 0-1
   */
  scoreProblem(prLog: ProblemLog): number {
    const problem = this.problemXrefToProblemMap.get(prLog.prCeri);
    return scoreProblemDefinitionForStandardsReport(prLog, problem);
  }

  get skillXrefToSkillMap(): Map<string, SkillDefinition> {
    return this.$store.getters['skillList/getSkillsMap'];
  }

  get skillXrefToProblemXrefMap(): Map<string, string[]> {
    const res: Map<string, string[]> = new Map();
    for (let i = 0; i < this.uniqueSkillXrefs.length; i++) {
      const skillXref = this.uniqueSkillXrefs[i];
      const problemXrefs = this.problems
        .filter((problem) => problem.attributes?.skill?.includes(skillXref))
        .map((problem) => problem.xref);
      if (problemXrefs.length > 0) {
        res.set(skillXref, problemXrefs);
      }
    }
    return res;
  }

  get studentXrefToStudentLogMap(): Map<string, StudentLog> {
    const res = new Map<string, StudentLog>();

    if (this.reportData) {
      this.reportData.studentLogs.forEach((log) => {
        res.set(log.studentXref, log);
      });
    }

    return res;
  }

  get headers(): Array<DataTableHeader> {
    return [
      ...this.prependStaticHeaders,
      ...this.skillCodeHeaders.map((header) => {
        const transformedHeader = appendGlobalHeaderOptions(header);
        return {
          ...transformedHeader,
          sortable: true,
        };
      }),
    ];
  }

  get uniqueSkillXrefs(): string[] {
    const skillXrefs: string[] = [];
    this.problems.forEach((problem) =>
      problem.attributes?.skill?.forEach((xref) => skillXrefs.push(xref))
    );
    // Remove duplicates.
    return Array.from(new Set(skillXrefs));
  }

  get skillCodeHeaders(): DataTableHeader[] {
    const headers: DataTableHeader[] = [];
    this.uniqueSkillXrefs.forEach((xref) => {
      const skill = this.skillXrefToSkillMap.get(xref);
      if (skill) {
        headers.push({
          text: skill.nodeCode,
          value: skill.nodeCode,
          align: 'center',
        });
      }
    });
    return headers;
  }

  getProblemCountPerStandard(skillCode: string): number | null {
    return this.problemCountsMap.get(skillCode) || null;
  }

  generateSkillColumns(studentLog?: StudentLog): SkillColumn[] {
    const res: Array<SkillColumn> = [];
    this.uniqueSkillXrefs.forEach((xref) => {
      const skill = this.skillXrefToSkillMap.get(xref) as SkillDefinition;
      const problemXrefs = this.skillXrefToProblemXrefMap.get(xref) as string[];
      const logs =
        studentLog?.problemLogAndActions || ([] as Array<ProblemLogAndActions>);

      const filteredLogs = logs.filter(
        (log) =>
          problemXrefs.includes(log.prLog.prCeri) &&
          log.prLog.endTime &&
          (typeof log.prLog.discreteScore === 'number' ||
            typeof log.prLog.continuousScore === 'number')
      );

      let value: number | null = null;

      if (filteredLogs.length === 0) {
        value = 0;
      } else {
        const sum = filteredLogs.reduce(
          (acc, curr) => (acc += this.scoreProblem(curr.prLog)),
          0
        );

        value = Math.round((sum / filteredLogs.length) * 100);

        this.problemCountsMap.set(skill.nodeCode, problemXrefs.length);
      }
      res.push({
        skillCode: skill.nodeCode || '',
        value: value,
        totalProblemsCount: problemXrefs.length,
        completedProblemsCount: filteredLogs.length,
      });
    });

    return res;
  }

  get rows(): Array<StandardsReportTableRow> {
    const rows: Array<StandardsReportTableRow> = [];

    for (let i = 0; i < this.assignees.length; i++) {
      // Get the average score **per standard**
      const student = this.assignees[i];

      const studentLog = this.studentXrefToStudentLogMap.get(
        student.xref
      ) as StudentLog;

      const skills: Array<SkillColumn> = this.generateSkillColumns(studentLog);

      const scores: Array<number> = (studentLog?.problemLogAndActions || [])
        .filter(
          (log) =>
            log.prLog.endTime &&
            (typeof log.prLog.discreteScore === 'number' ||
              typeof log.prLog.continuousScore === 'number')
        )
        .map((log) => {
          return this.scoreProblem(log.prLog);
        });

      const sum = scores.reduce((acc, curr) => (acc += curr), 0);
      const averageScore =
        scores.length > 0 ? Math.round((sum / scores.length) * 100) : 0;

      rows.push({
        student,
        averageScore: Math.round(averageScore),
        skills,
      });
    }

    rows.forEach((row) => {
      const { skills } = row;
      skills.forEach((skill) => {
        row = Object.assign(row, { [skill.skillCode]: skill.value });
      });
    });

    this.rowsGenerated = true;

    // Shuffle rows when anonymizing
    if (this.anonymizeNames) {
      return shuffle(rows);
    } else {
      return orderBy(
        rows,
        [
          (row) => {
            const student = row.student as User;
            return student.lastName;
          },
          (row) => {
            const student = row.student as User;
            return student.firstName;
          },
        ],
        ['asc', 'asc']
      );
    }
  }

  generateCsv(): string {
    let csv = '';

    // Header row
    csv += 'Student Name/Problem, Average Score, ';
    this.skillCodeHeaders.forEach(({ text }) => (csv += `${text}, `));
    csv += '\n';

    // Number of problems row
    csv += 'Number Of Problems, -, ';
    this.rows[0].skills.forEach(
      ({ totalProblemsCount }) => (csv += `${totalProblemsCount}, `)
    );
    csv += '\n';

    // Value rows
    this.rows.forEach((row) => {
      csv += `${row.student.displayName}, ${
        isNaN(row.averageScore) ? 0 : row.averageScore
      }, `;
      row.skills.forEach(({ value }) => (csv += `${value}, `));
      csv += '\n';
    });

    return csv;
  }

  downloadCsv(): void {
    const csv = this.generateCsv();

    // Create a hidden anchor
    const hiddenAnchor = document.createElement('a');

    // Set anchor attributes
    hiddenAnchor.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenAnchor.target = '_blank';
    hiddenAnchor.download = `${this.reportData?.contentInfo.xref}.csv`;

    // Click on the anchor to initiate the download
    hiddenAnchor.click();

    // Delete the anchor
    hiddenAnchor.remove();
  }

  created(): void {
    this.anonymizeNames =
      this.getCurrentUser.settings?.anonymizeReportsByDefault || false;

    if (this.skillXrefToSkillMap.size === 0) {
      getSkills().then((skills) => {
        this.$store.commit('skillList/setSkillList', skills);
        this.reportReady = true;
        this.trackStandardsTableLoaded();
      });
    } else {
      this.reportReady = true;
      this.trackStandardsTableLoaded();
    }
  }

  clickCSVActions(): void {
    this.downloadCsv();
    this.trackCSVDownloadSR();
  }

  //////////////
  // Mixpanel //
  //////////////

  trackStandardsTableLoaded(): void {
    trackMixpanel(EventType.assignmentReportSwitchToStandardsTable, {
      assignmentXref: this.reportData?.contentInfo.xref,
    });
  }

  trackHideElementsSR(elementHidden: string): void {
    trackMixpanel(EventType.hideInformationStandardsReport, {
      assignmentXref: this.reportData?.contentInfo.xref,
      elementSelected: elementHidden,
      anonymizeNames: this.anonymizeNames,
    });
  }

  trackCSVDownloadSR(): void {
    trackMixpanel(EventType.standardsReportCSVDownload, {
      assignmentXref: this.reportData?.contentInfo.xref,
    });
  }

  trackSortChangeSR(options: DataOptions): void {
    const sortByCol = options.sortBy[0]; // assuming single-column sort
    const sortByDesc = options.sortDesc[0]; // true for descending, false for ascending

    // Condition a: if sortByCol is NOT undefined, return existing values
    if (sortByCol !== undefined) {
      trackMixpanel(EventType.standardsReportSortOrder, {
        assignmentXref: this.reportData?.contentInfo.xref,
        sortedByCol: sortByCol,
        sortedByColOrder: sortByDesc ? 'descending' : 'ascending',
      });
    }
    // Condition b: if sortByCol is undefined and sortByDesc is undefined,
    // return sortByCol is default and sortByCol is reset
    else if (sortByCol === undefined && sortByDesc === undefined) {
      trackMixpanel(EventType.standardsReportSortOrder, {
        assignmentXref: this.reportData?.contentInfo.xref,
        sortedByCol: 'default',
        sortedByColOrder: 'reset',
      });
    }
    // Condition c: if sortByCol is undefined and sortByDesc is NOT undefined,
    // do not trigger mixpanel event
    // This condition is handled implicitly by not having an else/if statement for this case
  }
}
