
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { School } from '@/domain/School';
import { getSchool, searchSchools } from '@/api/core/schools.api';
import { PropType } from 'vue';

@Component
export default class SchoolSelector extends Vue {
  @Prop({ type: Array as PropType<string[]> }) value: string[];
  internal = '';

  schools: School[] = [];
  schoolSearch = '';
  loadingSchools = false;
  hideNoData = true;

  searchTimer = 0;
  timeoutTime = 500; //ms

  @Watch('schoolSearch')
  searchInput(searchStr: string): void {
    this.hideNoData = true; //Once they start typing hide the menu again?
    this.schools = [];
    if (this.searchTimer > 0) {
      window.clearTimeout(this.searchTimer);
      this.searchTimer = 0;
    }
    if (searchStr) {
      this.searchTimer = window.setTimeout(() => {
        this.getSchools(searchStr);
        this.loadingSchools = true;
      }, this.timeoutTime);
    }
  }

  getSchools(searchStr: string): void {
    searchSchools({ MZIP: searchStr })
      .then((schools) => {
        this.schools = schools;
        this.hideNoData = false; //show the no data if there is no data.
      })
      .finally(() => {
        this.loadingSchools = false;
      });
  }

  get internalValue(): string {
    return this.value && this.value[0];
  }
  set internalValue(newVal: string) {
    this.$emit('input', [newVal]);
  }

  created(): void {
    // Default
    if (this.value && this.value[0]) {
      this.loadingSchools = true;

      getSchool(this.value && this.value[0])
        .then((school) => {
          this.schools = [school];
        })
        .finally(() => {
          this.loadingSchools = false;
        });
    }
  }
}
