import { SkillDefinition, SkillStrand } from '@/domain/Skill';
import { coreAxios } from '@/plugins/axios';

const END_POINT = `skills`;

const getSkills = (standardId?: number): Promise<SkillDefinition[]> => {
  return coreAxios
    .get(`${END_POINT}/nodes`, {
      params: {
        standardId,
      },
    })
    .then((result) => {
      const data = result.data;
      data.sort((a: SkillDefinition, b: SkillDefinition) => {
        let comp = 0;
        if (a.nodeCode > b.nodeCode) {
          comp = 1;
        } else if (a.nodeCode < b.nodeCode) {
          comp = -1;
        }
        return comp;
      });
      return data;
    });
};

const getSkillStrands = (standardId?: number): Promise<Array<SkillStrand>> => {
  return coreAxios
    .get(`${END_POINT}/strands`, {
      params: {
        standardId,
      },
    })
    .then((res) => {
      return res.data;
    });
};

export { getSkills, getSkillStrands };
