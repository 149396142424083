
import { Component, Vue } from 'vue-property-decorator';
import { CurriculumDefinition } from '@/domain/Curriculum';
@Component({})
export default class CurriculaFilter extends Vue {
  selectedCurricula: string[] = [];
  /**
   * Converts the Map of Curricula in store into flat list of objects
   * Each entry is either a { header: string } shaped object, or Curriculum
   * (A header precedes each block of curricula of a given type e.g. State Test)
   */
  get curriculaFilters(): Array<CurriculumDefinition | { header: string }> {
    let res: Array<CurriculumDefinition | { header: string }> = [];
    const curriculaTypeMap =
      this.$store.getters['curricula/getTypeToCurriculaMap'];
    for (let [type, curricula] of curriculaTypeMap) {
      res.push({ header: type });
      res = [...res, ...curricula];
    }
    return res;
  }
}
