import { MutationTree } from 'vuex';
import { MyPsState } from './types';
import { ProblemSetDefinition } from '@/domain/ProblemSet';

export const mutations: MutationTree<MyPsState> = {
  setOriginalDownloadDone(state, downloadDone: boolean): void {
    state.originalDownloadDone = downloadDone;
  },
  setDownloading(state, downloading: boolean): void {
    state.downloading = downloading;
  },
  setProblemSets(state, psList: ProblemSetDefinition[]): void {
    if (state.problemSets.length === 0) {
      state.problemSets = psList;
    } else {
      state.problemSets = [...state.problemSets, ...psList];
    }
  },
  resetProblemSets(state): void {
    state.problemSets = [];
    state.nextPageToken = undefined;
    state.downloading = false;
    state.originalDownloadDone = false;
  },
  setNextPageToken(state, nextPageToken: string): void {
    state.nextPageToken = nextPageToken;
  },
  addProblemSet(state, newPs: ProblemSetDefinition): void {
    const alreadyInState = state.problemSets.find(
      (ps) => ps.xref === newPs.xref
    );

    if (!alreadyInState) {
      state.problemSets.push(newPs);
    }
  },
};
