
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class NotFoundView extends Vue {
  @Prop({
    default: 'primary',
  })
  textColor: string;
}
