import { coreAxios } from '@/plugins/axios';
import { AlertMessage } from '@/domain/AlertMessage';

const ENDPOINT = '/notifications';

interface RequestParams {
  permanently?: boolean;
}

const getAlertMessages = (): Promise<Array<AlertMessage>> => {
  return coreAxios
    .get(`${ENDPOINT}/alerts`)
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      // TODO: Handle this error properly.
      return [];
    });
};

const hideAlertMessage = (
  messageId: number,
  permanently: boolean
): Promise<void> => {
  const params: RequestParams = {
    permanently,
  };

  return coreAxios
    .post(
      `${ENDPOINT}/alerts/${messageId}/hide`,
      {},
      {
        params,
      }
    )
    .then((res) => {
      return res.data;
    });
};

export { getAlertMessages, hideAlertMessage };
