import { ActionTree } from 'vuex';
import { MyPsState } from './types';
import { RootState } from '../types';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import { CancelToken } from 'axios';
import {
  ProblemSetsFilterParams,
  searchForProblemSets,
} from '@/api/core/content.api';
import { UserToContentRelationshipType } from '@/domain/Content';
import { ObjectList, PagingParams } from '@/api/core/base.api';

// FIXME: Figure out if we still want this store or can we use the content store for this?
// If we use the content store, I think we need to clear it at load so we are not polluted with
// other previously downloaded Problem Sets NOT owned by User.
export const actions: ActionTree<MyPsState, RootState> = {
  requestMyProblemSets(
    { commit, state, rootState },
    payload: {
      name?: string;
      limit?: number;
      cancelToken?: CancelToken;
    }
  ): Promise<void> {
    const mpsf = rootState.auth.MY_PROBLEM_SETS;
    const filterParams: ProblemSetsFilterParams = {
      user: 'me',
      folders: mpsf ? [mpsf] : undefined,
      filterBy: [UserToContentRelationshipType.OWNER],
    };

    const pagingParams: PagingParams = { limit: payload.limit };

    // If the name's being passed, assume that we are calling off to the search endpoint
    if (payload.name) {
      filterParams.name = `ILIKE:%${payload.name}%`;
      return searchForProblemSets(
        filterParams,
        pagingParams,
        undefined,
        payload.cancelToken
      )
        .then((problemSetList: ObjectList<ProblemSetDefinition>) => {
          problemSetList.data.forEach((problemSet) =>
            commit('addProblemSet', problemSet)
          );
        })
        .catch(() => {
          //do nothing
        });
    }

    //If there is no more to download. Or we are already downloading (and you don't want to start over)
    // then leave
    if (
      (state.originalDownloadDone && !state.nextPageToken) ||
      state.downloading
    ) {
      return Promise.resolve();
    }

    if (state.nextPageToken) {
      pagingParams.nextPageToken = state.nextPageToken;
    }

    commit('setDownloading', true);
    return searchForProblemSets(
      filterParams,
      pagingParams,
      undefined,
      payload.cancelToken
    )
      .then((problemSetList: ObjectList<ProblemSetDefinition>) => {
        if (state.problemSets.length === 0) {
          commit('setProblemSets', problemSetList.data);
        } else {
          problemSetList.data.forEach((problemSet) =>
            commit('addProblemSet', problemSet)
          );
        }
        commit('setNextPageToken', problemSetList.nextPageToken);
      })
      .finally(() => {
        if (!state.originalDownloadDone) {
          commit('setOriginalDownloadDone', true);
        }
        commit('setDownloading', false);
      });
  },
};
