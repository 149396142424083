
import dayjs from 'dayjs';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ProgressCard, {
  ProgressRecord,
  ProgressState,
} from '@/components/Report/ProgressCard.vue';
import {
  ProblemLogAndActions,
  StudentData,
  StudentLog,
} from '@/domain/ReportData/AssignmentData';
import { User } from '@/domain/User';
import sortBySortableName from '@/utils/sortBySortableName.util';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';
import { AssignmentDefinition } from '@/domain/Assignment';
import {
  IProblemAction,
  IResponseAction,
  ITutoringRequestedAction,
} from '@/domain/Action';

interface ProgressSummary {
  assignees: string[];
  inProgress: ProgressRecord[];
  completed: ProgressRecord[];
}

@Component({
  components: {
    ProgressCard,
  },
})
export default class AssignmentProgressRow extends Vue {
  @Prop({ required: true }) assignment: AssignmentDefinition;
  @Prop({ default: () => [] }) assignees: User[];
  @Prop({ default: false }) reportData: StudentData | undefined;

  // Allows us access to the enum in the template.
  ProgressState = ProgressState;

  //////////
  // Data //
  //////////

  get xrefToAssigneeMap(): Record<string, User> {
    const assigneeMap: Record<string, User> = {};
    for (const assignee of this.assignees) {
      assigneeMap[assignee.xref] = assignee;
    }
    return assigneeMap;
  }

  get progressSummary(): ProgressSummary {
    let summary: ProgressSummary = {
      assignees: [],
      inProgress: [],
      completed: [],
    };
    const studentLogs = this.reportData?.studentLogs ?? [];
    for (const studentLog of studentLogs) {
      const xref = studentLog.studentXref;
      summary.assignees.push(xref);
      const assignee = this.xrefToAssigneeMap[xref];
      if (assignee) {
        const record: ProgressRecord = { assignee };
        const endTime = studentLog.asEndTime;
        if (endTime) {
          // Finished the Assignment.
          record.timestamp = this.getTimeStamp(endTime);
          summary.completed.push(record);
        } else {
          // In Progress.
          const lastWorkedOn: number | null = this.getLastWorkedOn(studentLog);
          if (lastWorkedOn) {
            record.timestamp = this.getTimeStamp(lastWorkedOn);
          } else {
            record.timestamp = '-';
          }
          summary.inProgress.push(record);
        }
      }
    }
    summary.inProgress = this.sortBySortableName(summary.inProgress);
    summary.completed = this.sortBySortableName(summary.completed);
    return summary;
  }

  get notStarted(): ProgressRecord[] {
    const started: string[] = this.progressSummary.assignees;
    // Assignees who do NOT have any Assignment Logs.
    const missing = this.assignees
      .filter((assignee) => {
        return !started.includes(assignee.xref);
      })
      .map((assignee: User) => {
        return { assignee };
      });
    return this.sortBySortableName(missing);
  }

  get assigned(): ProgressRecord[] {
    const records = this.assignees.map((assignee: User) => {
      return { assignee };
    });
    return this.sortBySortableName(records);
  }

  /////////////
  // Methods //
  /////////////

  sortBySortableName(records: ProgressRecord[]): ProgressRecord[] {
    return records.sort((a, b) => {
      return sortBySortableName(a.assignee, b.assignee);
    });
  }

  getTimeStamp(ms: number): string {
    // Parse UNIX timestamp in ms
    const date = dayjs(ms);
    return date.format('MMM D, h:mma');
  }

  getLastWorkedOn(studentLog: StudentLog): number | null {
    let lastWorkedOn: number | null = null;
    // Cannot assume problem logs are ordered by end time
    if (studentLog.problemLogAndActions) {
      studentLog.problemLogAndActions.forEach(
        (problemLogAndAction: ProblemLogAndActions) => {
          if (
            problemLogAndAction.actions &&
            problemLogAndAction.actions.length > 0
          ) {
            // Order by timestamp in ASC.
            const lastActionIndex: number =
              problemLogAndAction.actions.length - 1;
            const lastAction:
              | IProblemAction
              | IResponseAction
              | ITutoringRequestedAction =
              problemLogAndAction.actions[lastActionIndex];
            if (lastAction.timestamp) {
              if (lastWorkedOn == null) {
                // Initialize to timestamp.
                lastWorkedOn = lastAction.timestamp;
              } else if (lastAction.timestamp > lastWorkedOn) {
                // More recent than previously examined.
                lastWorkedOn = lastAction.timestamp;
              }
            }
          }
        }
      );
    }
    return lastWorkedOn;
  }

  //////////////
  // Mixpanel //
  //////////////
  // trackStudentsNotStartedClick(): void {
  //   trackMixpanel(EventType.assignmentReportStudNotStarted, {
  //     assignmentXref: this.assignment.xref,
  //   });
  // }
  // trackStudentsInProgressClick(): void {
  //   trackMixpanel(EventType.assignmentReportStudInProgress, {
  //     assignmentXref: this.assignment.xref,
  //   });
  // }
  // trackStudentsCompletedClick(): void {
  //   trackMixpanel(EventType.assignmentReportStudCompleted, {
  //     assignmentXref: this.assignment.xref,
  //   });
  // }
  // trackStudentsAssignedClick(): void {
  //   trackMixpanel(EventType.assignmentReportStudAssigned, {
  //     assignmentXref: this.assignment.xref,
  //   });
  // }

  recordProgressCardOpenClose(isOpen: boolean, state: ProgressState): void {
    const counts = {
      notStarted: this.notStarted.length,
      inProgress: this.progressSummary.inProgress.length,
      completed: this.progressSummary.completed.length,
      assigned: this.assigned.length,
    };

    if (isOpen) {
      // Track when the card is opened
      switch (state) {
        case ProgressState.NOT_STARTED:
          trackMixpanel(EventType.assignmentReportStudNotStarted, {
            assignmentXref: this.assignment.xref,
            ...counts,
          });
          break;
        case ProgressState.IN_PROGRESS:
          trackMixpanel(EventType.assignmentReportStudInProgress, {
            assignmentXref: this.assignment.xref,
            ...counts,
          });
          break;
        case ProgressState.COMPLETED:
          trackMixpanel(EventType.assignmentReportStudCompleted, {
            assignmentXref: this.assignment.xref,
            ...counts,
          });
          break;
        case ProgressState.ASSIGNED:
          trackMixpanel(EventType.assignmentReportStudAssigned, {
            assignmentXref: this.assignment.xref,
            ...counts,
          });
          break;
      }
    } else {
      // Track when the card is closed
      switch (state) {
        case ProgressState.NOT_STARTED:
          trackMixpanel(EventType.assignmentReportStudNotStartedClosed, {
            assignmentXref: this.assignment.xref,
            ...counts,
          });
          break;
        case ProgressState.IN_PROGRESS:
          trackMixpanel(EventType.assignmentReportStudInProgressClosed, {
            assignmentXref: this.assignment.xref,
            ...counts,
          });
          break;
        case ProgressState.COMPLETED:
          trackMixpanel(EventType.assignmentReportStudCompletedClosed, {
            assignmentXref: this.assignment.xref,
            ...counts,
          });
          break;
        case ProgressState.ASSIGNED:
          trackMixpanel(EventType.assignmentReportStudAssignedClosed, {
            assignmentXref: this.assignment.xref,
            ...counts,
          });
          break;
      }
    }
  }
}
