import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { TutorState } from './types';
import { RootState } from '../types';

export const state: TutorState = {
  hasDownloadedTutees: false,
  isDownloadingTutees: false,
  isDownloadingStudentAssignments: false,
  hasDownloadedStudentAssignments: false,
  isDownloadingAssignmentReports: false,
  hasDownloadedAssignmentReports: false,
  noAssignmentDataFound: false,
  tutees: [],
  selectedTuteeXrefs: [],
  selectedTimeFrame: null,
  selectedAssignments: [],
  selectedStandards: [],
  assignmentReportData: new Map(),
  assignmentDefinitions: [],
  studentToAssignmentDefinitionsMap: new Map(),
};

const namespaced = true;

export const tutor: Module<TutorState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
