
import { Component, Prop, Vue } from 'vue-property-decorator';
import ScoreChip from '@/components/Report/ScoreChip.vue';
import { ProblemDefinition, ProblemTypeSDK3 } from '@/domain/Problem';
import {
  ProblemLog,
  ProblemLogAndActions,
} from '@/domain/ReportData/AssignmentData';
import TeachleyDialog from './TeachleyDialog.vue';
import { Watch } from 'vue-property-decorator';
import {
  ActionType,
  HintRequestedAction,
  IProblemAction,
  IResponseAction,
  ITutoringRequestedAction,
  StudentResponseAction,
  WorkType,
} from '@/domain/Action';
import { getActionResponses } from '@/utils/actions.util';
import { isEmpty } from 'lodash';
import { ITutorStrategy } from '@/domain/Tutoring';
import AnswersViewSDK3 from '../Builder/ContentView/AnswersViewSDK3.vue';
import { getAnswerParts, getCorrectAnswer } from '@/utils/problem.util';

@Component({
  components: {
    AnswersViewSDK3,
    ScoreChip,
    TeachleyDialog,
  },
})
export default class StudentActionsMenu extends Vue {
  @Prop({ required: true }) studentLabel: string;
  @Prop({ required: true }) problemLabel: string;
  @Prop({ required: true }) problem: ProblemDefinition;
  @Prop({ required: true }) logActions: ProblemLogAndActions;
  @Prop({ default: false }) isInTestMode: boolean;

  showMenu = false;
  wirisLoaded = false;
  showTeachleyDialog = false;

  // Allows us access to the enum in the template.
  ActionType = ActionType;
  ProblemTypeSDK3 = ProblemTypeSDK3;

  // HTML placeholder blob for hints that failed to download
  // downloadFailureMessage =
  //   '<span class="error--text">Hint failed to download because it no longer exists.</span>';

  get prLog(): ProblemLog {
    return this.logActions.prLog;
  }

  get teacherComment(): Record<number, string> | undefined {
    const comments: Record<number, string> = {};
    const partLogData = this.prLog.partLogData;
    for (const marker in partLogData) {
      const comment = partLogData[marker].teacherComment;
      if (comment) {
        comments[marker] = comment;
      }
    }
    return !isEmpty(comments) ? comments : undefined;
  }

  get score(): number {
    return this.prLog.continuousScore * 100;
  }

  get redoAdjustedScore(): number {
    const redoAverageScore = this.prLog.redoAverageScore;
    if (typeof redoAverageScore === 'number') {
      return Math.max(redoAverageScore * 100, this.score);
    }
    return this.score;
  }

  // If actions are missing, convert to empty array.
  get actions(): (
    | IProblemAction
    | IResponseAction
    | ITutoringRequestedAction
  )[] {
    return this.logActions.actions ?? [];
  }

  get actionTypes(): ActionType[] {
    return this.actions.map((action) => {
      return action.actionType;
    });
  }

  get maxWidth(): number {
    return this.$vuetify.breakpoint.lgAndUp ? 500 : 400;
  }

  get maxHeight(): number {
    return this.$vuetify.breakpoint.lgAndUp ? 700 : 600;
  }

  get correctAnswer(): Record<number, string[]> | undefined {
    const answerParts = getAnswerParts(this.problem.answersSDK3);
    return getCorrectAnswer(answerParts);
  }

  get responseActions(): IResponseAction[] {
    return this.actions.filter((action) => {
      return (
        action.actionType == ActionType.STUDENT_RESPONSE_ACTION ||
        action.actionType == ActionType.STUDENT_SUBMISSION_ACTION
      );
    }) as IResponseAction[];
  }

  get actionResponses(): {
    response: Record<number, string[]>;
    correct?: boolean;
  }[] {
    return getActionResponses(this.responseActions);
  }

  get hintRequestActions(): HintRequestedAction[] {
    return this.actions.filter((action) => {
      return action.actionType === ActionType.HINT_REQUESTED_ACTION;
    }) as HintRequestedAction[];
  }

  get hasLiveTutoring(): boolean {
    return this.actions.some(
      (action) => action.actionType == ActionType.LIVE_TUTORING_REQUESTED_ACTION
    );
  }

  get sras(): StudentResponseAction[] {
    return this.responseActions.filter((action) => {
      return action.actionType == ActionType.STUDENT_RESPONSE_ACTION;
    }) as StudentResponseAction[];
  }

  get hasTeachleyWork(): boolean {
    return this.sras.some((action) => {
      return action.studentWork?.type == WorkType.TEACHLEY;
    });
  }

  // If we are open response, then we are never in test mode.
  get hasTestMode(): boolean {
    return (
      this.problem?.problemTypeSDK3 != ProblemTypeSDK3.OPEN_RESPONSE &&
      this.isInTestMode
    );
  }

  get tutorStrategyMap(): Record<string, ITutorStrategy> {
    return this.$store.state.content.tutorStrategyMap;
  }

  openTeachleyDialog() {
    this.showTeachleyDialog = true;
  }

  @Watch('showMenu')
  onShowMenuChanged(newVal: boolean, oldVal: boolean) {
    this.$emit('scoreChipOpened', { newVal, oldVal });
  }
}
