import { render, staticRenderFns } from "./FindProblemsPage.vue?vue&type=template&id=44db9b60&scoped=true&"
import script from "./FindProblemsPage.vue?vue&type=script&lang=ts&"
export * from "./FindProblemsPage.vue?vue&type=script&lang=ts&"
import style0 from "./FindProblemsPage.vue?vue&type=style&index=0&id=44db9b60&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44db9b60",
  null
  
)

export default component.exports