
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Doughnut } from 'vue-chartjs';
import {
  ArcElement,
  CategoryScale,
  Chart,
  ChartData,
  ChartDataset,
  ChartOptions,
  DoughnutController,
} from 'chart.js';

import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels';
import { ProblemStats } from './AssignmentBarChartViewForReport.vue';
import { Padding } from 'node_modules/chartjs-plugin-datalabels/types/options';

Chart.register(DoughnutController, CategoryScale, ArcElement);

@Component({ components: { Doughnut } })
export default class DonutChartView extends Vue {
  @Prop({ required: true }) problemStats: ProblemStats;

  //////////////////////////
  // Chart Configurations //
  //////////////////////////

  get correctColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.correct;
  }

  get correctEventuallyColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.correctEventually;
  }

  get incorrectColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.incorrect;
  }

  get chartLabels(): Array<string> {
    return ['Correct', 'Correct Eventually', 'Incorrect'];
  }

  get chartData(): ChartData {
    const datasets: Array<ChartDataset> = [];

    const dataset: ChartDataset = {
      data: [
        this.problemStats.numCorrect,
        this.problemStats.numCorrectEventually,
        this.problemStats.numIncorrect,
      ],
      backgroundColor: [
        this.correctColor,
        this.correctEventuallyColor,
        this.incorrectColor,
      ],
    };

    datasets.push(dataset);

    return {
      labels: this.chartLabels,
      datasets: datasets,
    };
  }

  options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          usePointStyle: true,
          color: this.fontColor,
          padding: 20,
        },
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        borderRadius: 12,
        color: this.labelFontColor,
        // FIXME: Better way to do this?
        padding: function (context: Context): Padding {
          const prependedPadding = {
            top: 4,
            bottom: 4,
          };

          if (context.dataset.data) {
            const count = String(context.dataset.data[context.dataIndex]);
            // Special cases for additional padding
            if (count === '1') {
              return {
                ...prependedPadding,
                left: 10,
                right: 10,
              };
            } else if (count.length === 1 || count === '11') {
              // One digit
              return {
                ...prependedPadding,
                left: 8,
                right: 8,
              };
            }
          }

          // Default padding
          return {
            ...prependedPadding,
            left: 6,
            right: 6,
          };
        },
        font: {
          family: 'Montserrat',
          size: 14,
        },
        backgroundColor: '#FFFFFF',
        display: function (context: Context): boolean {
          // Ignore 0
          if (context.dataset.data) {
            return context.dataset.data[context.dataIndex] !== 0;
          }

          return false;
        },
      },
    },
  };

  plugins = [ChartDataLabels];

  //////////////////
  // Chart Colors //
  //////////////////

  get fontColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.neutral.darken1;
  }

  get labelFontColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.neutral.darken4;
  }

  /////////////
  // Methods //
  /////////////

  //////////////
  // Watchers //
  //////////////
}
