const validateEmail = (value: string): boolean => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(value);
};

const validateString = (value: string): boolean => {
  return value ? !!value.trim() : false;
};

const inputValidationRules = {
  email: (value: string): boolean | string =>
    validateEmail(value) || 'Invalid e-mail.',
  required: (value: string): boolean | string =>
    validateString(value) || 'Required.',
};

export { validateEmail, validateString, inputValidationRules };
