// Want to change or update something?
// Stashing changes synchronously commit mutation
import { AttributionDefinition } from '@/domain/Attribution';
import { MutationTree } from 'vuex';
import { AttributionState } from './types';

export const mutations: MutationTree<AttributionState> = {
  setIsDownloadingAttributions(state: AttributionState, flag: boolean) {
    state.isDownloadingAttributions = flag;
  },
  setHasDownloadedAttributions(state: AttributionState, flag: boolean) {
    state.hasDownloadedAttributions = flag;
  },
  setDownloadedDefinitions(
    state: AttributionState,
    definitions: AttributionDefinition[]
  ) {
    state.downloadedDefinitions = definitions;
  },
};
