import _Vue from 'vue';
import { Notification } from '@/domain/Notification';
import { Store } from 'vuex';
import { RootState } from '@/store/types';

declare module 'vue/types/vue' {
  interface Vue {
    $notify: (
      notification: string | Notification | string[] | Notification[]
    ) => void;
  }
}

export default {
  install(Vue: typeof _Vue, store: Store<RootState>): void {
    Vue.prototype.$notify = (
      notification: string | Notification | string[] | Notification[]
    ) => {
      if (Array.isArray(notification)) {
        notification.forEach((notificationInstance: string | Notification) => {
          store.commit('notifications/addNotification', notificationInstance);
        });
      } else {
        store.commit('notifications/addNotification', notification);
      }
    };
  },
};
