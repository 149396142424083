import { quickCommentsAxios } from '@/plugins/axios';
import { QuickComment, QuickCommentFeedback } from '@/domain/QuickComment';

const END_POINT = '/v1/feedback/teacher/generate';

//////////
// DTOs //
//////////

export interface QuickCommentDTO {
  problem_id: number;
  problem_log_id: number;
  predicted_score: number;
  is_accurate: boolean;
  feedback: Array<QuickCommentFeedbackDTO>;
}

export interface QuickCommentFeedbackDTO {
  id: string;
  text: string;
  score: number;
  is_accurate: boolean;
}

export interface QuickCommentsRequestBody {
  userId: string;
  answerText: string;
  problemLogId: number;
  numOfComments: number;
}

export interface QuickCommentsRequestParams {
  teacherId: string;
  // FIXME: This needs to be ceri.
  problemId: number | string;
  assignmentId: string;
  answers: QuickCommentsRequestBody[];
}

const getQuickComments = (
  requestParams: QuickCommentsRequestParams,
  controller?: AbortController
): Promise<Array<QuickComment>> => {
  const payload = {
    teacher_id: requestParams.teacherId,
    problem_id: requestParams.problemId,
    assignment_id: requestParams.assignmentId,
    answers: requestParams.answers.map((answer) => ({
      problem_log_id: answer.problemLogId,
      answer_text: answer.answerText,
      user_id: answer.userId,
    })),
  };
  return quickCommentsAxios
    .post(`${END_POINT}`, payload, { signal: controller?.signal })
    .then((result) => {
      return buildQuickComments(result.data);
    });
};

function buildQuickComments(
  quickCommentsDTOs: Array<QuickCommentDTO>
): Array<QuickComment> {
  return quickCommentsDTOs.map((dto) => {
    const comments: Array<QuickCommentFeedback> = [];
    dto.feedback.forEach((feedbackdto) => {
      comments.push({
        id: feedbackdto.id,
        text: feedbackdto.text,
        score: feedbackdto.score,
      });
    });

    return {
      problemId: dto.problem_id,
      problemLogId: dto.problem_log_id,
      suggestedScore: dto.predicted_score,
      scoreIsAccurate: dto.is_accurate,
      commentIsAccurate: dto.feedback[0].is_accurate,
      comments: comments,
    };
  });
}

export { getQuickComments };
