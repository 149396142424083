
import { Component, Vue } from 'vue-property-decorator';
import NavBar from './components/base/NavBar.vue';
import NotificationsView from './components/base/notifications/NotificationsView.vue';
import AlertMessagesView from './components/base/notifications/AlertMessagesView.vue';
import userflow, { Attributes, IdentifyOptions } from 'userflow.js';
import dayjs from 'dayjs';
import { logOut } from './api/auth.api';

@Component({
  components: {
    NavBar,
    NotificationsView,
    AlertMessagesView,
  },
})
export default class App extends Vue {
  get isInitializing(): boolean {
    return this.$store.state.auth.isInitializing;
  }

  created(): void {
    this.$store.dispatch('initialize').finally(() => {
      if (this.$route.query.newUser === 'true') {
        //Copy the query object to a new one. Has to be done.
        let query = Object.assign({}, this.$route.query);

        //remove newUser
        delete query.newUser;

        //Resolve the current path without new User
        const resolved = this.$router.resolve({
          path: this.$route.path,
          query: query,
        });

        //go to second registration with current url as redirect.
        this.$router.push({
          name: 'select-school',
          query: {
            goTo: `${resolved.href}`,
          },
        });
      }
      if (this.isTeacherUser) {
        // Once the auth store has been initialized and we know the User is Teacher, we initialize Userflow
        this.initializeUserflow(this.getCurrentUser.xref);

        // Add watcher to current User's attributes (notify Userflow in the case where the User changes one or
        // more of these via the UI (their Settings Page, for example)
        this.$watch('userflowAttributes', () => {
          userflow.updateUser(
            { ...this.userflowAttributes },
            { ...this.userflowSignature }
          );
        });
      }
    });
  }
  get userflowAttributes(): Attributes {
    return {
      name: this.getCurrentUser?.displayName,
      email: this.getCurrentUser?.userName,
      signed_up_at: dayjs(this.getCurrentUser?.createdAt).toISOString(),
      has_curriculum:
        (this.getCurrentUser?.attributes?.curriculum ?? []).length > 0,
      has_grade_level:
        (this.getCurrentUser?.attributes?.gradeLevel ?? []).length > 0,
      // FIXME: Userflow might need to be updated that this attribute is renamed.
      // Lets keep this field here for now until we can get rid of it.
      has_nces_public_school:
        (this.getCurrentUser?.attributes?.ncesSchool ?? []).length > 0,
      has_nces_school:
        (this.getCurrentUser?.attributes?.ncesSchool ?? []).length > 0,
      lms_provider_type: this.getCurrentUser?.lmsProviderType?.displayName,
    };
  }
  get userflowSignature(): IdentifyOptions {
    return {
      signature: this.getCurrentUser?.userflowSignature,
    };
  }
  initializeUserflow(userXref: string): void {
    // https://userflow.com/
    userflow.init(process.env.VUE_APP_USERFLOW_TOKEN);
    userflow.identify(
      userXref,
      { ...this.userflowAttributes },
      { ...this.userflowSignature }
    );
  }
}
