
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ProblemSetTreeLevel from './ProblemSetTreeLevel.vue';
import ContentLabel from './ContentView/ContentLabel.vue';
import { isEqual } from 'lodash';
import { getContentType, isPublished } from '@/utils/builder.util';
import { ProblemDefinition } from '@/domain/Problem';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import { ContentType } from '@/domain/Content';
import { AclPermissionType } from '@/domain/Acls';

enum ScrollMode {
  MANUAL = 'manual',
  PROGRAMMATIC = 'programmatic',
}

@Component({ components: { ContentLabel, ProblemSetTreeLevel } })
export default class BuilderSideNav extends Vue {
  @Prop({ required: true }) psXref: string;
  @Prop({ required: false, default: 250 }) width: number;

  showCeri = true;
  mode = ScrollMode.MANUAL;
  observed: string | null = null;
  observer: IntersectionObserver | null = null;

  get selected(): string | null {
    return (this.$route.query.contentPath as string) ?? null;
  }

  set selected(value: string | undefined) {
    if (!isEqual(value, this.selected)) {
      // Update the URL.
      this.$router.replace({
        query: {
          ...this.$route.query,
          contentPath: value,
        },
      });
    }
  }

  get problemSetMap(): Record<string, ProblemSetDefinition> {
    return this.$store.state.content.problemSetMap;
  }

  get problemMap(): Record<string, ProblemDefinition> {
    return this.$store.state.content.problemMap;
  }

  mounted(): void {
    // IF invalid path, reset path here. Clean up the URL so we are NOT playing
    // with a broken path.
    if (!this.selected?.startsWith(this.psXref)) {
      this.fixInvalidPath();
    }
    // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    this.observer = new IntersectionObserver(
      ([entry]) => {
        // Prevent executing callback in programmatic scrolling and overwriting the
        // selected path.
        if (entry && entry.isIntersecting && this.mode == ScrollMode.MANUAL) {
          this.observed = entry.target.id;
          this.selected = entry.target.id;
        }
      },
      { threshold: 0.75 }
    );
    this.$nextTick(() => {
      const problemViews = document.querySelectorAll('div[problem-view]');
      for (const problemView of problemViews) {
        this.observer?.observe(problemView);
      }
      this.scrollToProblemView(this.selected);
    });
  }

  destroyed(): void {
    this.observer?.disconnect();
  }

  scrollToProblemView(problemPath: string | null): void {
    this.mode = ScrollMode.PROGRAMMATIC;
    // Scroll to Problem View.
    if (problemPath) {
      this.$nextTick(() => {
        try {
          this.$vuetify
            .goTo(`#${CSS.escape(problemPath)}`, {
              offset: 80,
              easing: 'easeInOutCubic',
            })
            .finally(() => (this.mode = ScrollMode.MANUAL));
        } catch (e) {
          // For some reason, no target view found. Lets reset path here to prevent us from
          // dealing with a broken path. That way the User can find and select the Target they
          // want. This is VERY important as the Builder page is dependent on the context (path)
          // to do most of its processings.
          // this.selected = undefined;
          // this.mode = ScrollMode.MANUAL;
        }
      });
    } else {
      this.mode = ScrollMode.MANUAL;
    }
  }

  // FIXME: Fix selected path on publish?
  fixInvalidPath(defaultPath?: string): void {
    // Attempt to "fix" path.
    if (this.selected) {
      const pathParts = this.selected?.split(',') ?? [];
      for (let i = 0; i < pathParts.length; i++) {
        const pathPart = pathParts[i];
        let contentMap = null;
        const type = getContentType(pathPart);
        switch (type) {
          case ContentType.PROBLEM:
            contentMap = this.problemMap;
            break;
          case ContentType.PROBLEM_SET:
            contentMap = this.problemSetMap;
            break;
        }
        if (contentMap) {
          let content = contentMap[pathPart];
          if (
            content &&
            isPublished(content.xref) &&
            content.permissions.includes(AclPermissionType.UPDATE) &&
            content.mappedCeri
          ) {
            pathParts[i] = content.mappedCeri;
          }
        }
      }
      const fixedPath = pathParts.join(',');
      if (!isEqual(fixedPath, this.selected)) {
        this.selected = fixedPath;
      } else if (defaultPath) {
        this.selected = defaultPath;
      } else {
        this.selected = undefined;
      }
    } else {
      const ps = this.problemSetMap[this.psXref];
      if (ps.children?.length) {
        // Default to first child.
        this.selected = `${ps.xref},${ps.children[0]}`;
      }
    }
  }

  @Watch('selected')
  onSelected(): void {
    // IF invalid path, reset path here. Clean up the URL so we are NOT playing
    // with a broken path.
    if (!this.selected?.startsWith(this.psXref)) {
      this.fixInvalidPath();
    } else if (!isEqual(this.selected, this.observed)) {
      this.scrollToProblemView(this.selected);
    }
  }
}
