
import { Component, Vue, Prop } from 'vue-property-decorator';
import { submitTeacherRoleCode } from '@/api/core/roleCode.api';
import GoogleClassroomInstructions from '@/components/Settings/HowToGetATeacherRole/GoogleClassroomInstructions.vue';
import {
  inputValidationRules,
  validateString,
} from '@/utils/inputValidation.util';
import { LmsProviderType } from '@/domain/LmsProviderType';

@Component({
  components: {
    GoogleClassroomInstructions,
  },
})
export default class HowToGetATeacherRoleDialog extends Vue {
  @Prop() value: boolean;
  inviteCode = '';
  rules = inputValidationRules;

  get isCodeEmpty(): boolean {
    return validateString(this.inviteCode);
  }

  get isCanvasUser(): boolean {
    return this.getCurrentUser.lmsProviderType === LmsProviderType.CANVAS;
  }

  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(val: boolean) {
    this.$emit('input', val);
  }

  submitCode(): void {
    submitTeacherRoleCode(this.inviteCode)
      .then(() => {
        this.$notify('Teacher role added successfully');
      })
      .catch(() => {
        this.$notify('The teacher invite code is invalid');
      })
      .finally(() => {
        this.showDialog = false;
      });
  }
}
