import { MutationTree } from 'vuex';
import { NotificationState } from './types';
import { Notification } from '@/domain/Notification';
import { AlertMessage } from '@/domain/AlertMessage';

export const mutations: MutationTree<NotificationState> = {
  removeNotification(state: NotificationState, id: number) {
    const indexToDelete = state.notifications.findIndex((n) => n.id === id);
    if (indexToDelete !== -1) {
      state.notifications.splice(indexToDelete, 1);
    }
  },
  addNotification(
    state: NotificationState,
    notification: string | Notification
  ) {
    if (typeof notification === 'string' || notification instanceof String) {
      notification = { message: notification } as Notification;
    }
    notification.id = state.counter++;
    state.notifications = [];
    state.notifications.push(notification);
  },
  clear(state: NotificationState) {
    state.notifications = [];
  },
  setAlertMessages(
    state: NotificationState,
    alertMessages: Array<AlertMessage>
  ) {
    state.alertMessages = alertMessages;
  },
};
