
import { Component, Prop, Vue } from 'vue-property-decorator';
import StudentProblemView from '@/components/Tutor/base/StudentProblemView.vue';
import ScoreChip from '@/components/Report/ScoreChip.vue';

import { ProblemRow } from '@/domain/ReportData/Tutor';
import { ActionType, HintRequestedAction } from '@/domain/Action';
import { hasSeenAnswer } from '@/utils/report.util';
import ProblemViewSDK3 from '@/components/Builder/ContentView/ProblemViewSDK3.vue';

export enum ProblemRowHeaderValue {
  CLASS_AVERAGE = 'classAverage',
}

export interface CustomProblemRowHeader {
  text: string;
  value: string;
  cols?: number;
}

interface ProblemRowData {
  actionTypes: Map<string, Array<ActionType>>;
  sawAnswer: Map<string, boolean>;
  scores: Map<string, number>;
  hints: Map<string, Array<HintRequestedAction>>;
}

@Component({
  components: {
    ProblemViewSDK3,
    StudentProblemView,
    ScoreChip,
  },
})
export default class ProblemRowView extends Vue {
  @Prop({ required: true }) problemRow: ProblemRow;
  @Prop({ required: true }) customHeaders: Array<CustomProblemRowHeader>;
  @Prop({ default: null }) customHeaderCols: number | null;

  isExpanded = false;

  // Allows us access to the enum in the template.
  ProblemRowHeaderValue = ProblemRowHeaderValue;

  get rowData(): ProblemRowData {
    const studentToActionTypes = new Map();
    const studentToSawAnswer = new Map();
    const studentXrefToScore = new Map();
    const hintsRequested = new Map();

    for (const [studentXref, prLogActions] of Object.entries(
      this.problemRow.studentData
    )) {
      // Map of students to score on problem
      const score = prLogActions.prLog.continuousScore;
      const roundedScore = score != undefined ? Math.round(score * 100) : null;
      studentXrefToScore.set(studentXref, roundedScore);

      // Collect actions in to arrays that will be map values
      const actionTypes: ActionType[] = [];
      const hints: HintRequestedAction[] = [];

      prLogActions.actions?.forEach((action) => {
        actionTypes.push(action.actionType);
        if (action.actionType == ActionType.HINT_REQUESTED_ACTION) {
          hints.push(action as HintRequestedAction);
        }
      });

      // Map student to a list of the types of actions taken
      studentToActionTypes.set(studentXref, actionTypes);

      // Map student to which/how many hint actions they requested
      if (hints.length > 0) {
        hintsRequested.set(studentXref, hints);
      }

      // Map of student to whether they've requested to see the answer
      studentToSawAnswer.set(studentXref, hasSeenAnswer(actionTypes));
    }

    return {
      actionTypes: studentToActionTypes,
      sawAnswer: studentToSawAnswer,
      scores: studentXrefToScore,
      hints: hintsRequested,
    };
  }

  get tutoringCeris(): Set<string> {
    const ceris: Set<string> = new Set();

    for (const [studentXref, hintActions] of this.rowData.hints) {
      hintActions.forEach((hintAction) => {
        if (!ceris.has(hintAction.tutoringCeri)) {
          ceris.add(hintAction.tutoringCeri);
        }
      });
    }

    return ceris;
  }

  /////////////
  // Methods //
  /////////////

  getDisplayScore(averageScore?: number): string {
    const score =
      averageScore != undefined ? Math.round(averageScore * 100) : null;

    return score ? score + '%' : '-';
  }

  expandRow() {
    this.isExpanded = !this.isExpanded;

    if (this.isExpanded && this.tutoringCeris.size > 0) {
      this.tutoringCeris.forEach((tutoringCeri) => {
        this.$store.dispatch('content/getTutorStrategy', {
          xref: tutoringCeri,
        });
      });
    }
  }
}
