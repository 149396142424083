
import { User } from '@/domain/User';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';

export enum ProgressState {
  NOT_STARTED = 1,
  IN_PROGRESS = 2,
  COMPLETED = 3,
  ASSIGNED = 4,
}

export interface ProgressRecord {
  assignee: User;
  timestamp?: string;
}

@Component
export default class ProgressCard extends Vue {
  @Prop({ required: true }) state: ProgressState;
  @Prop({ default: () => [] }) records: ProgressRecord[];

  placeholder = '-----';
  open = false;

  // Allows us access to the enum in the template.
  ProgressState = ProgressState;

  /////////////
  // Watchers //
  /////////////

  @Watch('open') // Add a watcher for the open property
  onProgressDetailsChange(newVal: boolean): void {
    // Emit an event when the open property changes
    this.$emit('toggle-progress-card', newVal);
  }

  // onProgressDetailsChange(newVal: boolean, oldVal: boolean): void {
  //   if (newVal === false && oldVal === true) {
  //     if (this.state === ProgressState.NOT_STARTED) {
  //       trackMixpanel(EventType.assignmentReportStudNotStartedClosed);
  //     } else if (this.state === ProgressState.IN_PROGRESS) {
  //       trackMixpanel(EventType.assignmentReportStudInProgressClosed);
  //     } else if (this.state === ProgressState.COMPLETED) {
  //       trackMixpanel(EventType.assignmentReportStudCompletedClosed);
  //     } else if (this.state === ProgressState.ASSIGNED) {
  //       trackMixpanel(EventType.assignmentReportStudAssignedClosed);
  //     }
  //   }
  // }

  /////////////
  // Display //
  /////////////

  get statusText(): string {
    switch (this.state) {
      case ProgressState.NOT_STARTED:
        return 'Not Started';
      case ProgressState.IN_PROGRESS:
        return 'In Progress';
      case ProgressState.COMPLETED:
        return 'Completed';
      case ProgressState.ASSIGNED:
        return 'Assigned';
      default:
        return this.placeholder;
    }
  }

  get iconName(): string | null {
    switch (this.state) {
      case ProgressState.NOT_STARTED:
        return 'clipboard-alert';
      case ProgressState.IN_PROGRESS:
        return 'clipboard-text';
      case ProgressState.COMPLETED:
        return 'clipboard-check';
      case ProgressState.ASSIGNED:
        return 'clipboard-account';
      default:
        return null;
    }
  }

  get timestampText(): string {
    switch (this.state) {
      case ProgressState.IN_PROGRESS:
        return 'Last Worked On';
      case ProgressState.COMPLETED:
        return 'Completed On';
      case ProgressState.ASSIGNED:
        return 'Assigned On';
      default:
        return this.placeholder;
    }
  }
}
