import { ContentType, IBuilderContent } from './Content';

export interface ITutorStrategy extends IBuilderContent {
  // containsAnswer: boolean;
  contentType: ContentType.TUTOR_STRATEGY;
  tutoringTarget: string;
  tutorStrategyType:
    | TutorStrategyType.EXPLANATION
    | TutorStrategyType.HINT_SET
    | TutorStrategyType.STUDENT_RESPONSE_FEEDBACK
    | TutorStrategyType.INSTRUCTIONAL_RECOMMENDATIONS;
  hasVideo: boolean;
  htmlMarker: number;
  trusted: boolean;
  prAuthorWritten: boolean;
}

export enum TutorStrategyType {
  HINT_SET = 'HINTS',
  EXPLANATION = 'EXPLANATIONS',
  STUDENT_RESPONSE_FEEDBACK = 'STUDENT_RESPONSE_FEEDBACK',
  INSTRUCTIONAL_RECOMMENDATIONS = 'INSTRUCTIONAL_RECOMMENDATIONS',
}

export interface Hint {
  hint: string;
  hasVideo: boolean;
}

export interface HintSet extends ITutorStrategy {
  tutorStrategyType: TutorStrategyType.HINT_SET;
  hints: Hint[];
}

export interface Explanation extends ITutorStrategy {
  tutorStrategyType: TutorStrategyType.EXPLANATION;
  explanation: string;
}

export interface StudentResponseFeedback extends ITutorStrategy {
  tutorStrategyType: TutorStrategyType.STUDENT_RESPONSE_FEEDBACK;
  answerPartsToResponses: Record<number, string[]>;
  responseFeedback: string;
}

export interface InstructionalRecommendation extends ITutorStrategy {
  tutorStrategyType: TutorStrategyType.INSTRUCTIONAL_RECOMMENDATIONS;
  recommendation: string;
}

// TODO: Add the other support types
export interface ITutoringContainer {
  [TutorStrategyType.EXPLANATION]?: Explanation[];
  [TutorStrategyType.HINT_SET]?: HintSet[];
  [TutorStrategyType.STUDENT_RESPONSE_FEEDBACK]?: StudentResponseFeedback[];
  [TutorStrategyType.INSTRUCTIONAL_RECOMMENDATIONS]?: InstructionalRecommendation[];
}
