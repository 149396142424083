
import CopyTextDialog from '@/components/Builder/CopyTextDialog.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import MoveToFolderDialog from './MoveToFolderDialog.vue';
import CopyFolderDialog from './CopyFolderDialog.vue';
import PermissionsDialog from './PermissionsDialog.vue';
import EditFolderDialog, {
  EditMode,
} from '@/components/Builder/EditFolderDialog.vue';
import DeleteFolderDialog from './DeleteFolderDialog.vue';
import CreateProblemSetDialog from './CreateProblemSetDialog.vue';
import { FolderDefinition } from '@/domain/Folder';
import { addMembersToFolder } from '@/api/core/folders.api';
import { PageView, getPathParam } from '@/utils/navigation.util';
import { AclPermissionType } from '@/domain/Acls';

@Component({
  components: {
    CopyFolderDialog,
    CopyTextDialog,
    DeleteFolderDialog,
    EditFolderDialog,
    PermissionsDialog,
    MoveToFolderDialog,
    CreateProblemSetDialog,
  },
})
export default class FolderFileMenu extends Vue {
  @Prop({ required: true }) path: FolderDefinition[];

  createPSDialog = false;

  EditMode = EditMode;
  PageView = PageView;
  AclPermissionType = AclPermissionType;

  get parentPage(): PageView {
    return this.$route?.meta?.page;
  }

  get folderPath(): string {
    return getPathParam(this.path);
  }

  get parent(): FolderDefinition | undefined {
    return this.path[this.path.length - 2];
  }

  get folder(): FolderDefinition {
    return this.path[this.path.length - 1];
  }

  // FIXME: Determine how/if we are going to the Lesson Page.
  get url(): string {
    return `${process.env.VUE_APP_TNG_URL}/find?p=${this.folder.xref}`;
  }

  get folderMap(): Record<string, FolderDefinition> {
    return this.$store.state.folder.folderMap;
  }

  get isRoot(): boolean {
    // Containing ONLY itself.
    return this.path.length === 1;
  }

  addProblemSet(ceri: string): void {
    addMembersToFolder(this.folder.xref, [ceri])
      .then(() => {
        this.$emit('open-problem-set', ceri);
      })
      .catch(() => {
        this.$notify(`Failed to add ${ceri} to ${this.folder.name}.`);
      });
  }
}
