var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0 mt-0"},[_c('div',{staticClass:"d-flex flex-column align-center mb-6 px-0"},[(_vm.supportContent?.tutorStrategyType === _vm.TutorStrategyType.HINT_SET)?_c('v-container',{staticClass:"pa-0 mt-0"},[_vm._l((_vm.supportContent.hints),function(hint,index){return _c('v-container',{key:index,staticClass:"pa-0 mt-3"},[(!(_vm.showEditor && index === _vm.currentIndex))?_c('div',{class:[_vm.showEditor || _vm.supportType !== 'Hint' ? '' : 'draggable'],attrs:{"draggable":_vm.showEditor || _vm.supportType === 'Hint'},on:{"dragstart":function($event){return _vm.dragStart(index)},"dragenter":function($event){return _vm.dragEnter(index)},"dragend":_vm.dragEnd,"dragover":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"d-flex flex-row"},[(
                !_vm.showEditor &&
                _vm.supportContent.tutorStrategyType ===
                  _vm.TutorStrategyType.HINT_SET
              )?_c('v-icon',{staticClass:"pa-4 pl-0"},[_vm._v(" mdi-reorder-horizontal ")]):_vm._e(),(_vm.supportContent.hints[index].hint)?_c('v-card',{ref:"dragHints",refInFor:true,staticClass:"px-3 py-3 rounded-lg pr-5",class:[
                _vm.showEditor || _vm.supportType !== 'Hint' ? '' : 'draggable',
              ],staticStyle:{"border-left":"solid 12px","width":"100%"},style:('border-left-color: #ffcb17;'),attrs:{"id":'hintCard-' + index,"outlined":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pl-4 align-center",attrs:{"cols":"2"}},[(!_vm.showEditor)?_c('v-icon',{staticClass:"pa-4 pl-0",attrs:{"color":'yellow'}},[_vm._v(" mdi-lightbulb-on ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"ba-0",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pa-0"},[_vm._v(" "+_vm._s(_vm.showTitle(index))+" ")]),_c('v-card-text',{staticClass:"pa-0 content-body",domProps:{"innerHTML":_vm._s(hint.hint)}})],1)])],1)],1):_vm._e()],1),_c('div',{attrs:{"flat":"","tile":""}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.editSupport(index)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" Edit ")],1),_c('v-btn',{staticClass:"ml-2",attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.deleteSupport(index)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")]),_vm._v(" Delete ")],1)],1)]):_c('SupportEditor',{attrs:{"problem":_vm.problem,"supportContent":_vm.supportContent.hints[index].hint,"title":_vm.supportType},on:{"publish":_vm.publishSupport,"remove":_vm.deleteSupport,"cancelEditor":_vm.cancelEditor}})],1)}),(
          !_vm.showEditor &&
          _vm.supportContent.tutorStrategyType === _vm.TutorStrategyType.HINT_SET
        )?_c('v-container',{staticClass:"px-0 py-4"},[_c('v-row',{},[_c('v-col',{attrs:{"cols":"6"}}),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.addNew()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(" ADD HINT ")],1)],1)],1)],1):_vm._e()],2):(
        _vm.supportContent?.tutorStrategyType === _vm.TutorStrategyType.EXPLANATION
      )?_c('v-container',{staticClass:"pa-0 mt-0"},[_c('v-card',{staticClass:"px-3 py-3 rounded-lg pr-5",staticStyle:{"border-left":"solid 12px","width":"100%"},style:({ borderLeftColor: '#b91e60' }),attrs:{"outlined":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pl-4 align-center",attrs:{"cols":"2"}},[(!_vm.showEditor)?_c('v-icon',{staticClass:"text-h1",attrs:{"color":"incorrect"}},[_vm._v(" mdi-lightning-bolt ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"ba-0",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pa-0"},[_vm._v(" Explanation ")]),_c('v-card-text',{staticClass:"pa-0 content-body",domProps:{"innerHTML":_vm._s(_vm.supportContent.explanation)}}),_c('div',{staticClass:"d-flex",attrs:{"flat":"","tile":""}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.editSupport()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" Edit ")],1),_c('v-btn',{staticClass:"ml-2",attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.deleteSupport()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")]),_vm._v(" Delete ")],1)],1),(_vm.showEditor)?_c('SupportEditor',{attrs:{"problem":_vm.problem,"supportContent":_vm.supportContent.explanation,"title":_vm.supportType},on:{"publish":_vm.publishSupport,"remove":_vm.deleteSupport,"cancelEditor":_vm.cancelEditor}}):_vm._e()],1)])],1)],1)],1):_vm._e(),(_vm.addNewSupport)?_c('SupportEditor',{attrs:{"problem":_vm.problem,"title":_vm.supportType},on:{"publish":_vm.publishSupport,"cancelEditor":_vm.noHintsOrExplanation}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }