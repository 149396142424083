
import { Component, Vue } from 'vue-property-decorator';
import { CancelTokenSource } from 'axios';
import ScrollObserver from '@/components/base/ScrollObserver.vue';
import PrintProblemSetsView from '@/components/Print/PrintProblemSetsView.vue';
import { PrintDefinition } from '@/domain/PrintDefinition';
import { FolderDefinition, FolderMemberType } from '@/domain/Folder';
import FolderExplorer, {
  Mode,
  SortBy,
} from '@/components/Builder/FolderExplorer.vue';
import EditFolderDialog, {
  EditMode,
} from '@/components/Builder/EditFolderDialog.vue';
import TimeFromNow from '@/components/Builder/ContentView/TimeFromNow.vue';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';
import { ProblemSetDefinition } from '@/domain/ProblemSet';

@Component({
  components: {
    EditFolderDialog,
    FolderExplorer,
    // FIXME: Support pagination and sort.
    ScrollObserver,
    PrintProblemSetsView,
    TimeFromNow,
  },
})
export default class MyProblemSetsPage extends Vue {
  initialized = false;
  open = false;

  EditMode = EditMode;
  Mode = Mode;
  FolderMemberType = FolderMemberType;

  // Filtering/paging
  limit = 20;
  source: CancelTokenSource | null;

  printData: PrintDefinition = {
    problemSet: {} as ProblemSetDefinition,
    name: '',
    className: '',
    status: '',
    date: '',
    contentType: 'ASSIGNMENT',
  };

  // List of ways to sort
  sortOptions: { text: string; value: SortBy }[] = [
    {
      text: 'Date Created (Descending)',
      value: { attribute: 'createdAt', direction: 'desc' },
    },
    {
      text: 'Date Created (Ascending)',
      value: { attribute: 'createdAt', direction: 'asc' },
    },
    // {
    //   text: 'Last Modified (Descending)',
    //   value: { attribute: 'updatedAt', direction: 'desc' },
    // },
    // {
    //   text: 'Last Modified (Ascending)',
    //   value: { attribute: 'updatedAt', direction: 'asc' },
    // },
    {
      text: 'Name (Descending)',
      value: { attribute: 'name', direction: 'desc' },
    },
    {
      text: 'Name (Ascending)',
      value: { attribute: 'name', direction: 'asc' },
    },
  ];

  get folderMap(): Record<string, FolderDefinition> {
    return this.$store.state.folder.folderMap;
  }

  get userFolderXref(): string {
    return this.$store.state.auth.MY_PROBLEM_SETS;
  }

  get myProblemSetsFolder(): FolderDefinition | undefined {
    return this.$store.getters['auth/getMyProblemSetsFolder'];
  }

  print(printData: PrintDefinition): void {
    this.printData = printData;
  }

  goToEditProblemSetPage(xref: string): void {
    this.$router.push({
      name: 'editMyPS',
      params: {
        xref,
      },
      query: { ...this.$route.query },
    });
  }

  created(): void {
    this.initialized = false;
    this.$store
      .dispatch('folder/getFolders', [this.userFolderXref])
      .then(() => {
        this.open = true;
        this.initialized = true;
      });
  }

  mounted(): void {
    this.trackMyProblemSetsLoaded();
  }

  trackMyProblemSetsLoaded(): void {
    trackMixpanel(EventType.navMyProblemSetsLoaded);
  }

  childFolderOpened(xref: string): void {
    this.trackPSFolderOpen(xref);
  }

  trackPSFolderOpen(xref: string): void {
    const eventData = {
      folderXref: xref,
    };
    trackMixpanel(EventType.trackPSFolderOpen, eventData);
  }
}
