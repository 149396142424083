
import { Vue, Component } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { SkillDefinition } from '@/domain/Skill';
import { AssignmentDefinition } from '@/domain/Assignment';

@Component
export default class ViewByFilters extends Vue {
  viewBy = this.storedTimeFrame;
  viewByOptions = ['Date', 'Assignment', 'Standard'];

  // Format the dates selected to the string we want to display
  get storedTimeFrame(): string {
    let storeDates = this.$store.getters['tutor/getSelectedTimeFrame'];

    if (storeDates) {
      const startDate = dayjs(storeDates.startDateTime).format('MM/DD/YYYY');
      const endDate = dayjs(storeDates.endDateTime).format('MM/DD/YYYY');
      return `${startDate} - ${endDate}`;
    }

    return '';
  }

  get storedAssignmentSelections(): AssignmentDefinition[] {
    return this.$store.state.tutor.selectedAssignments;
  }

  get storedStandards(): SkillDefinition[] {
    return this.$store.state.tutor.selectedStandards;
  }

  // Signals 'Date' or 'Assignment' up to the parent
  viewByChanged(menuOption: string): void {
    this.$emit('viewByChanged', menuOption);
  }

  updateAssignmentSelections(index: number): void {
    // Copied the array to avoid mutating the state directly
    const storeSelectionCopy = Array.from(this.storedAssignmentSelections);
    storeSelectionCopy.splice(index, 1);

    this.$store.commit('tutor/setSelectedAssignments', storeSelectionCopy);
  }

  updateStoredStandards(index: number): void {
    const storeSelectionCopy = Array.from(this.storedStandards);
    storeSelectionCopy.splice(index, 1);

    this.$store.commit('tutor/setSelectedStandards', storeSelectionCopy);
  }
}
