
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class SkillBuilderInfoDialog extends Vue {
  @Prop() value: boolean;
  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(val: boolean) {
    this.$emit('input', val);
  }
}
