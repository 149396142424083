
import { isEqual } from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class PublishContentDialog extends Vue {
  @Prop({ default: true }) value: boolean;
  @Prop({ required: true }) ceri: string;

  publishing = false;
  failed: string[] = [];
  success = false;

  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(value: boolean) {
    this.$emit('input', value);
  }

  get selected(): string | null {
    return (this.$route.query.contentPath as string) ?? null;
  }

  set selected(value: string | undefined) {
    if (!isEqual(value, this.selected)) {
      // Update the URL.
      this.$router.replace({
        query: {
          ...this.$route.query,
          contentPath: value,
        },
      });
    }
  }

  // Root Problem Set.
  get rootPsXref(): string {
    return this.$route.params.psXref as string;
  }

  set rootPsXref(value: string) {
    if (!isEqual(value, this.rootPsXref)) {
      // Update the URL.
      this.$router.replace({
        params: {
          psXref: value,
        },
      });
    }
  }

  publish() {
    this.failed = [];
    this.publishing = true;
    const selected = this.selected;
    this.success = false;

    this.$store
      .dispatch('content/publish', { xref: this.ceri })
      .then(({ ceri, failedCeris, passedCeris }) => {
        // FIXME: Notify which items failed validation.
        this.failed = failedCeris ?? [];
        if (this.failed.length) {
          this.$notify('Failed to publish. Please fix validation errors.');
          this.showDialog = false;
        } else if (ceri) {
          this.success = true;

          this.$emit('published-content', ceri);
          this.$notify(`${this.ceri} has been published.`);
        }
        if (passedCeris) {
          // Attempt to "fix" the URL.
          if (selected) {
            const pathParts = selected?.split(',') ?? [];
            for (let i = 0; i < pathParts.length; i++) {
              const pathPart = pathParts[i];
              let newCeri = passedCeris[pathPart];
              if (newCeri) {
                pathParts[i] = newCeri;
              }
            }
            const fixedPath = pathParts.join(',');
            this.selected = fixedPath;
          }
          if (passedCeris[this.rootPsXref]) {
            this.rootPsXref = passedCeris[this.rootPsXref];
          }
        }
        this.publishing = false;
      })
      .catch(() => {
        this.failed = [this.ceri];
        this.$notify('Something went wrong. Failed to publish.');
        this.publishing = false;
      })
      .finally(() => {
        this.showDialog = false;
      });
  }

  @Watch('showDialog')
  resetState(): void {
    this.publishing = false;
    this.failed = [];
    this.success = false;
  }
}
