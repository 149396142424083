var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pb-8"},[(_vm.isAuthenticated && !_vm.unauthorized)?_c('div',[_c('div',{staticClass:"neutral lighten-4 pb-4",attrs:{"id":"dashboard-student-selection"}},[_c('v-row',_vm._l((4),function(i){return _c('v-col',{key:`student-${i}`,staticClass:"pb-0"},[_c('v-select',{attrs:{"items":_vm.tutees,"item-text":"displayName","item-value":"xref","label":`Student ${i}`,"clearable":"","filled":"","flat":"","data-cy":`tutee-dropdown-${i}`,"menu-props":{
              offsetY: true,
              offsetOverflow: true,
              contentClass: `tutee-dropdown-${i}-select-list`,
            },"loading":_vm.isDownloadingTutees},model:{value:(_vm.tuteeXrefs[i - 1]),callback:function ($$v) {_vm.$set(_vm.tuteeXrefs, i - 1, $$v)},expression:"tuteeXrefs[i - 1]"}})],1)}),1)],1),(!_vm.noTuteeSelected)?_c('v-row',{staticClass:"mx-9"},[_c('ViewByFilters',{on:{"viewByChanged":_vm.onViewByChanged}})],1):_vm._e(),(!_vm.noTuteeSelected)?_c('div',{staticClass:"neutral lighten-4 elevation-1 mt-6",attrs:{"id":"dashboard-content-overview"}},[_c('DateRangeSelectionDialog',{model:{value:(_vm.showDateRangeSelectDialog),callback:function ($$v) {_vm.showDateRangeSelectDialog=$$v},expression:"showDateRangeSelectDialog"}}),_c('AssignmentSelectionDialog',{attrs:{"data":_vm.assignmentDefinitions},model:{value:(_vm.showAssignmentSelectionDialog),callback:function ($$v) {_vm.showAssignmentSelectionDialog=$$v},expression:"showAssignmentSelectionDialog"}}),_c('StandardSelectionDialog',{attrs:{"assignmentSkills":_vm.assignmentSkills,"allSkills":_vm.skills},model:{value:(_vm.showStandardSelectionDialog),callback:function ($$v) {_vm.showStandardSelectionDialog=$$v},expression:"showStandardSelectionDialog"}}),_c('SkillBarChartViewForReport',{staticClass:"elevation-1 px-8",attrs:{"customChartLabels":_vm.customChartLabels,"customClassAverageColor":_vm.classAverageColor,"skillData":_vm.filteredSkillData,"isLoading":_vm.isDownloadingSkillData || !_vm.hasDownloadedSkillData,"noSkillDataFound":_vm.noSkillDataFound}}),(!_vm.noAssignmentDataFound)?_c('AssignmentDataTable',{attrs:{"customHeaderLabels":_vm.customTableLabels,"loading":_vm.isDownloadingAssignmentReports ||
          _vm.isDownloadingStudentAssignments ||
          !_vm.hasDownloadedAssignmentReports ||
          !_vm.hasDownloadedStudentAssignments,"filteredTuteeXrefs":_vm.filteredTuteeXrefs,"assignmentDefinitions":_vm.filteredAssignments,"assignmentReportData":_vm.assignmentReportData}}):_vm._e()],1):_vm._e(),(_vm.noTuteeSelected)?_c('NoStudentSelectedView',{attrs:{"students":_vm.tutees},model:{value:(_vm.tuteeXrefs[0]),callback:function ($$v) {_vm.$set(_vm.tuteeXrefs, 0, $$v)},expression:"tuteeXrefs[0]"}}):(_vm.noDataFoundWhatsoever)?_c('NotFoundView',{attrs:{"text-color":"neutral"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Didn't find any problems for this time frame ")]},proxy:true}],null,false,3386287624)}):_vm._e()],1):(_vm.unauthorized)?_c('UnauthorizedView'):_c('UnauthenticatedView',{attrs:{"button-color":"primary","text-color":"neutral"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("Login Required")]},proxy:true},{key:"button-text",fn:function(){return [_vm._v("Login")]},proxy:true}])},[_vm._v(" You'll need to login to access ASSISTments Tutor. ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }