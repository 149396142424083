
import { Component, Vue } from 'vue-property-decorator';
import { Skill } from '@/domain/Skill';
import SearchPsByPsaDialog from '@/components/FindProblems/FindProblemsPage/SearchPsByPsaDialog.vue';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';

@Component({
  components: {
    SearchPsByPsaDialog,
  },
})
export default class FindProblemsTopBar extends Vue {
  dense = false;
  isDownloading = false;
  isDownloaded = false;

  get skills(): Array<Skill> {
    return this.$store.state.skillList.skills;
  }
  get selectedSkillXref(): number | null {
    const skillXref = this.$store.getters['skillList/getSelectedSkill'];
    return skillXref ? skillXref : null;
  }

  /**
   * Request skills from server and store them in this.skills
   * Does nothing if run a second time (once downloaded)
   */
  showSkills(): void {
    if (!this.isDownloaded && !this.isDownloading) {
      this.isDownloading = true;
      this.$store.dispatch('skillList/requestSkills').then(() => {
        this.isDownloaded = true;
        this.isDownloading = false;
      });
    }
  }

  /**
   * Navigates to search-skills problem view with id of chosen skill
   */
  selectSkill(skillXref: string): void {
    // Does nothing if skill was cleared
    if (skillXref) {
      this.$store.commit('search/resetProblemSearch');
      this.$store.commit('skillList/setSelectedSkill', skillXref);
      this.$router.push({
        name: 'searchProblems',
        params: {
          skillXref,
        },
      });

      // Mixpanel tracking for skill selection
      trackMixpanel(EventType.searchBarSkillSelected, {
        skillXref: skillXref,
      });
    }
  }

  openAndFocus(): void {
    this.dense = false;
    //I'd rather this be marked "as VTextField" but that doesn't work apparently.
    //So for now this comment will do.
    this.$nextTick(() => (this.$refs.searchBar as HTMLElement).focus());
  }

  mounted(): void {
    this.$store.commit('skillList/setSelectedSkill', null);
  }
}
