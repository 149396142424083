
import { CurriculumDefinition, ModuleDefinition } from '@/domain/Curriculum';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';
import dayjs from 'dayjs';
import { TimeSelector, TimeSelectorType } from '@/domain/Time';
import { getSchoolYears } from '@/utils/InsightsHub/schoolyear.util';
import { FolderDefinition } from '@/domain/Folder';
import axios, { CancelTokenSource } from 'axios';

@Component({})
export default class DashboardHeader extends Vue {
  downloading = false;
  source: CancelTokenSource | null = null;
  schoolYearChoices: { text: string; value: TimeSelector }[] = [];
  gradeFolders: FolderDefinition[];
  selectedCurriculumXref = this.$route.query.curriculumXref || '';

  // Selected Curriculum
  get selectedCurriculum(): CurriculumDefinition | undefined {
    return this.curriculaChoices.find((curricula) => {
      return curricula.xref === this.selectedCurriculumXref;
    });
  }
  set timeSelector(timeSelector: TimeSelector) {
    this.$store.commit('insightsHub/setTimeSelector', timeSelector);
  }
  get timeSelector(): TimeSelector {
    return this.$store.state.insightsHub.timeSelector;
  }
  get curriculumSelectionVisibility(): boolean {
    var curriculum = false;
    if (
      this.$route.path.includes('/report/curricula/activity') ||
      this.$route.path.includes('/report/curricula/achievement')
    ) {
      curriculum = true;
    }
    return curriculum;
  }
  get selectedGradeXref(): number | null {
    return this.$store.state.insightsHub.selectedGradeXref;
  }
  get curriculumName(): string {
    const curricula = this.$store.state.curricula.downloadedDefinitions;

    const target = curricula.find(
      (curriculum: CurriculumDefinition) =>
        curriculum === this.selectedCurriculum
    );

    return target?.name ?? '';
  }
  get gradeName(): string {
    return (
      this.$store.getters['insightsHub/selectedGradeFolder']?.name ||
      this.$route.query.grade
    );
  }

  get selectedModule(): ModuleDefinition | null {
    return this.$store.getters['insightsHub/selectedModuleDefinition'];
  }
  get moduleTitle(): string {
    return this.$store.state.insightsHub.curriculumGrade.moduleTitle ?? '';
  }
  get headerTitle(): string {
    if (this.selectedModule) {
      return `${this.moduleTitle} ${this.selectedModule.moduleNumber}: ${this.selectedModule.moduleName}`;
    }
    return this.gradeName;
  }

  get selectedSchoolYear(): string {
    return `${dayjs(
      this.$store.state.insightsHub.timeSelector.lowerLimit
    ).format('YY')}/${dayjs(
      this.$store.state.insightsHub.timeSelector.upperLimit
    ).format('YY')}`;
  }

  get curriculaChoices(): CurriculumDefinition[] {
    return this.$store.getters['insightsHub/supportedCurricula'];
  }

  clearSelectedModule(): void {
    trackMixpanel(EventType.selectedInishgtsAchievementTab, {
      curriculumSelected: this.curriculumName,
      gradeSelected: this.gradeName,
    });
    this.$store.commit('insightsHub/setSelectedModuleId', null);
    this.$store.commit('insightsHub/setSelectedTab', null);
  }

  @Watch('selectedCurriculumXref')
  onCurriculumChange(): void {
    // Stop whatever was previously not downloaded (or downloading)
    if (this.source) {
      // Cancel prior requests with this cancel token
      this.source.cancel();
    }

    this.downloading = true;
    this.source = axios.CancelToken.source();

    const rootFolderXref = this.selectedCurriculum?.rootFolderXref;

    // Request Grade Folders
    this.$store
      .dispatch('insightsHub/getCurriculumGradeFolders', rootFolderXref)
      .then(() => {
        this.downloading = false;

        const gradeFolder = this.$store.state.insightsHub.gradeFolders.find(
          (gradeFolder: { name: string | (string | null)[] }) => {
            return (
              gradeFolder.name.toString().toUpperCase() ==
              this.$route.query.grade.toString().toUpperCase()
            );
          }
        );
        if (gradeFolder) {
          this.$router
            .replace({
              query: {
                ...this.$route.query,
                timeSelector: this.timeSelector.toArray() as string[],
                curriculumXref: this.selectedCurriculum?.xref,
                gradeFolderXref: gradeFolder.xref,
                rootFolderXref: this.selectedCurriculum?.rootFolderXref,
              },
            })
            .catch((error) => {
              // Ignore the vuex error regarding navigating to the page you are already on.
              if (
                error.name !== 'NavigationDuplicated' &&
                !error.message.includes(
                  'Avoided redundant navigation to current location'
                )
              ) {
                // But handle any other errors the usual way
                error.handleGlobally && error.handleGlobally();
              }
            });

          if (this.selectedGradeXref !== gradeFolder) {
            // Set Grade Folder
            this.$store.commit(
              'insightsHub/setSelectedGradeXref',
              gradeFolder.xref
            );

            // Request Dashboard Data
            this.$store.dispatch(
              'insightsHub/getDashboardData',
              this.selectedCurriculum?.xref
            );
          }
        } else {
          // Return to the landing page if selected grade does not exist inside of grade folders for selected curriculum
          this.$router.replace({
            path: '',
            name: 'insightsHubLanding',
          });
          this.$notify(
            `Curriculum does contain a folder for the selected grade.`
          );
        }
      });
  }

  @Watch('timeSelector')
  onTimeSelectorChange(): void {
    if (this.selectedCurriculum) {
      this.$router.push({
        query: {
          ...this.$route.query,
          timeSelector: this.timeSelector.toArray() as string[],
          curriculumXref: this.selectedCurriculum.xref,
        },
      });
    }
    this.$store.dispatch(
      'insightsHub/getDashboardData',
      this.selectedCurriculum?.xref
    );
  }

  clearSelections(): void {
    this.$store.commit('insightsHub/setSelectedModuleId', null);
    this.$store.commit('insightsHub/setSelectedMode', null);
    this.$store.commit('insightsHub/setSelectedPsTypes', []);
    this.$store.commit('insightsHub/setAnonymized', false);
    this.$store.commit('insightsHub/setSelectedTab', null);
    this.$store.commit('insightsHub/setCollapsedPaths', new Set<string>());
    this.$store.commit('insightsHub/setOptions', {});
  }

  created(): void {
    this.clearSelections();

    const schoolYears = getSchoolYears();

    for (const schoolYear of schoolYears) {
      this.schoolYearChoices.push({
        text: `${schoolYear.startDateTime.format(
          'YY'
        )}/${schoolYear.endDateTime.format('YY')}`,
        value: new TimeSelector(
          TimeSelectorType.INCLUSIVE,
          schoolYear.startDateTime.valueOf(),
          schoolYear.endDateTime.valueOf()
        ),
      });
    }

    this.timeSelector =
      this.$store.state.insightsHub.timeSelector ??
      this.schoolYearChoices[0].value;
  }
}
