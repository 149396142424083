
import { Vue, Component, Prop } from 'vue-property-decorator';
import { getStandardOverTime } from '@/api/core/sdata.api';
import { TimeSelector, TimeSelectorType } from '@/domain/Time';
import {
  OverTimeGraphType,
  StandardOverTimeDateData,
} from '@/domain/ReportData/InsightsHub';
import LineChartView from '@/components/Report/LineChartView.vue';
import { Row } from './TreeGradientTable.vue';
import OverTimeChartLegend, {
  OverTimeChartDataSet,
} from '../base/OverTimeChartLegend.vue';
import dayjs from 'dayjs';
import { Point, TooltipCallbacks, TooltipItem } from 'chart.js';

import localeData from 'dayjs/plugin/localeData';

dayjs.extend(localeData);

export interface OverTimeChartPoint extends Point {
  assigned?: number;
}

@Component({
  components: {
    LineChartView,
    OverTimeChartLegend,
  },
})
export default class OverTimePopOut extends Vue {
  @Prop({ required: true }) itemData: Row;
  @Prop({ required: true }) values: Row;

  loading = true;
  chartData: StandardOverTimeDateData[] = [];

  xAxisLabels = dayjs
    .monthsShort()
    .concat(dayjs.monthsShort().splice(0, dayjs.monthsShort().indexOf('Jul')));

  get currentMode(): string {
    return this.$store.state.insightsHub.selectedMode;
  }

  get titleText(): string {
    if (this.currentMode == 'Completed') {
      return 'Average Problems Completed Over Time';
    } else if (this.currentMode == 'Score') {
      return 'Average Score Over Time';
    } else {
      return 'Problems Assigned Over Time';
    }
  }

  get chartType(): string {
    if (this.currentMode == 'Completed') {
      return OverTimeGraphType.COMPLETED_OVER_TIME;
    } else if (this.currentMode == 'Score') {
      return OverTimeGraphType.SCORE_OVER_TIME;
    } else {
      return OverTimeGraphType.ASSIGNED_OVER_TIME;
    }
  }

  get chartColors() {
    if (this.currentMode == 'Completed') {
      return {
        borderColor: '#F8951D',
        backgroundColor: '#F8951D',
        legendColors: [['#F8951D', '#f8951d4d', '#f8951d4d']],
      };
    } else if (this.currentMode == 'Score') {
      return {
        borderColor: '#2A7663',
        backgroundColor: '#2A7663',
        legendColors: [['#2A7663', '#2a76634d', '#2a76634d']],
      };
    } else {
      return {
        borderColor: '#2554FF',
        backgroundColor: '#2554FF',
        legendColors: [['#2554FF']],
      };
    }
  }

  get rollingAverages() {
    const averages: {
      district: OverTimeChartPoint[];
      comparison: OverTimeChartPoint[];
    } = {
      district: [],
      comparison: [],
    };

    this.chartData.forEach((entry, index) => {
      // If the mode is completed or score, we want to show the average of the previous 7 days
      if (this.currentMode == 'Completed' || this.currentMode == 'Score') {
        const previousSeven = this.chartData.slice(
          index < 7 ? 0 : index - 7,
          index
        );

        averages.district.push({
          x: entry.date,
          y:
            previousSeven.reduce((acc, cur) => {
              return acc + cur.districtNumerator / cur.districtDenominator;
            }, 0) / previousSeven.length,
          assigned: entry.districtDenominator,
        });
        averages.comparison.push({
          x: entry.date,
          y:
            previousSeven.reduce((acc, cur) => {
              return acc + cur.comparisonNumerator / cur.comparisonDenominator;
            }, 0) / previousSeven.length,
          assigned: entry.comparisonDenominator,
        });
      } else {
        // If the mode is assigned, we want to show the total assigned for that day
        averages.district.push({
          x: entry.date,
          y: entry.districtNumerator / entry.districtDenominator,
        });
        averages.comparison.push({
          x: entry.date,
          y: entry.comparisonNumerator / entry.comparisonDenominator,
        });
      }
    });

    return averages;
  }

  get comparisonDataSet(): OverTimeChartDataSet {
    return {
      label: this.itemData.name as string,
      chartLevel: this.itemData.type,
      data: this.rollingAverages.comparison,
      fill: false,
      borderWidth: 1,
      ...this.chartColors,
    };
  }

  get districtDataSet(): OverTimeChartDataSet {
    return {
      label: 'District Average',
      chartLevel: '',
      data: this.rollingAverages.district,
      fill: false,
      borderWidth: 1,
      borderColor: '#000',
      backgroundColor: '#000',
      legendColors: [['#000']],
    };
  }

  get dataSets(): OverTimeChartDataSet[] {
    // Start with the single data set that should always be there
    const sets = [this.comparisonDataSet];
    // If the mode is completed or score there should be district data, add that to the array
    if (this.currentMode == 'Completed' || this.currentMode == 'Score') {
      sets.push(this.districtDataSet);
    }
    return sets;
  }

  get selectedStrand() {
    return this.$store.state.insightsHub.selectedStrandId;
  }

  // Get timestamp for July 1 of the selected school year
  get oldestTimeStamp() {
    const year = dayjs(
      this.$store.state.insightsHub.timeSelector.lowerLimit
    ).year();

    return dayjs(`${year}-07-01`).valueOf();
  }

  // Get timestamp for June 30 of the selected school year
  get newestTimeStamp() {
    const year = dayjs(
      this.$store.state.insightsHub.timeSelector.upperLimit
    ).year();

    return dayjs(`${year}-06-30`).valueOf();
  }

  get tooltipCallbacks(): Partial<TooltipCallbacks<'line'>> {
    const mode = this.currentMode;

    return {
      title: function (tooltipItems: TooltipItem<'line'>[]) {
        const datasets = tooltipItems[0].chart.data.datasets;
        if (tooltipItems[0].dataIndex) {
          // If the mode is assigned the top line of the tooltip is "{number} assigned today"
          // if the mode is completed the top line of the tooltip is "{dataset}: {percentage} of {totalCount} assigned"
          // If the mode is score the top line of the tooltip is "{dataset}: {percentage}"
          if (mode == 'Completed' || mode == 'Score') {
            const dataSet = datasets[0].data as OverTimeChartPoint[];
            const dataSetLabel = datasets[0].label;

            const percentage =
              (dataSet[tooltipItems[0].dataIndex].y * 100).toFixed(0) + '%'; // convert it to percentage

            const totalAssigned = dataSet[tooltipItems[0].dataIndex].assigned;

            const append =
              mode == 'Completed' ? ` of ${totalAssigned} assigned` : '';

            return `${dataSetLabel}: ${percentage}${append}`;
          } else {
            const dataSet = datasets[0].data as OverTimeChartPoint[];

            const todayTotal = parseInt(tooltipItems[0].formattedValue);
            const yesterdayIndex = tooltipItems[0].dataIndex - 1;
            const yesterdayTotal = dataSet[yesterdayIndex].y as number;

            const assignedToday = todayTotal - yesterdayTotal;

            return `${assignedToday} assigned today`;
          }
        }
        return '';
      },
      label: function (tooltipItem: TooltipItem<'line'>) {
        const datasets = tooltipItem.chart.data.datasets;
        // If the mode is assigned the 2nd line of the tooltip is "{number} total assigned"
        // if the mode is completed the 2nd line of the tooltip is "{dataset}: {percentage} of {totalCount} assigned"
        // If the mode is score the 2nd line of the tooltip is "{dataset}: {percentage}"
        if (tooltipItem.dataIndex) {
          if (mode == 'Completed' || mode == 'Score') {
            const dataSet = datasets[1].data as OverTimeChartPoint[];
            const dataSetLabel = datasets[1].label;

            const percentage =
              (dataSet[tooltipItem.dataIndex].y * 100).toFixed(0) + '%'; // convert it to percentage

            const totalAssigned = dataSet[tooltipItem.dataIndex].assigned;

            const append =
              mode == 'Completed' ? ` of ${totalAssigned} assigned` : '';

            return `${dataSetLabel}: ${percentage}${append}`;
          } else {
            return `${tooltipItem.formattedValue} total assigned`;
          }
        }
        return '';
      },
      footer: function (tooltipItems: TooltipItem<'line'>[]) {
        // The third line of the tooltip is the date, which is the label property of the tooltipItem
        return tooltipItems[0].label || '';
      },
    };
  }

  created(): void {
    this.loading = true;

    getStandardOverTime(this.selectedStrand, {
      xrefs:
        this.itemData.type == 'school' && this.itemData.children
          ? this.itemData.children
          : [this.itemData.xref],
      gradeLevelXref: this.$store.state.auth.user.attributes.gradeLevel[0],
      overTimeGraphType: this.chartType,
      timeSelector: new TimeSelector(
        TimeSelectorType.INCLUSIVE,
        this.oldestTimeStamp,
        this.newestTimeStamp
      ),
    }).then((res) => {
      this.loading = false;
      this.chartData = res;
    });
  }
}
