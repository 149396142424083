
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class UnsavedChangesDialog extends Vue {
  @Prop() value: boolean;

  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(val: boolean) {
    this.$emit('input', val);
  }

  discardChangesInDialog(): void {
    this.showDialog = false;
    this.$emit('discardChangesInDialog');
  }
}
