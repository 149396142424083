
import ProblemBuilder, {
  getNewProblem,
} from '@/components/Builder/ProblemBuilder.vue';
import UpdateProblemSetDialog from '@/components/Builder/UpdateProblemSetDialog.vue';
import BuilderToolbar, {
  BuilderMode,
} from '@/components/Builder/BuilderToolbar.vue';
import BuilderSideNav from '@/components/Builder/BuilderSideNav.vue';
import { ProblemDefinition } from '@/domain/Problem';
import {
  ProblemSetDefinition,
  ProblemSetMember,
  ProblemSetType,
} from '@/domain/ProblemSet';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { isEqual } from 'lodash';
import ProblemSetTypeView from '@/components/Builder/ProblemSetTypeView.vue';
import ProblemSetBuilderMenu from '@/components/Builder/ProblemSetBuilderMenu.vue';
import TimeFromNow from '@/components/Builder/ContentView/TimeFromNow.vue';
import ContentLabel from '@/components/Builder/ContentView/ContentLabel.vue';
import ProblemSetViewForBuilder from '@/components/Builder/ContentView/ProblemSetViewForBuilder.vue';
import { ContentType } from '@/domain/Content';
import { getContentType, isPublished } from '@/utils/builder.util';
import {
  CopyProblemTutoring,
  copyProblem,
  copyProblemSet,
} from '@/api/core/content.api';
import CopyProblemDialog from '@/components/Builder/CopyProblemDialog.vue';
import { AclPermissionType } from '@/domain/Acls';
import { RETURN_URL } from '@/domain/PageParams';

@Component({
  components: {
    ProblemBuilder,
    UpdateProblemSetDialog,
    BuilderToolbar,
    BuilderSideNav,
    ProblemSetTypeView,
    ContentLabel,
    TimeFromNow,
    ProblemSetBuilderMenu,
    ProblemSetViewForBuilder,
    CopyProblemDialog,
  },
})
export default class ContentBuilderPage extends Vue {
  openEditPSDialog = false;
  isLoading = true;
  saving = false;
  showSideNav = true;
  showAnswers = true;
  showSupports = true;
  BuilderMode = BuilderMode;
  ProblemSetType = ProblemSetType;
  showEditStandards = false;
  ContentType = ContentType;
  showCopyProblemDialog = false;
  copyingdata = {};
  sideNavWidthPx = 250;
  AclPermissionType = AclPermissionType;

  // Root Problem Set.
  get rootPsXref(): string {
    return this.$route.params.psXref as string;
  }

  set rootPsXref(value: string) {
    if (!isEqual(value, this.rootPsXref)) {
      // Update the URL.
      this.$router.replace({
        params: {
          psXref: value,
        },
        query: {
          ...this.$route.query,
        },
      });
    }
  }

  get rootProblemSet(): ProblemSetDefinition | undefined {
    return this.problemSetMap[this.rootPsXref];
  }

  get selectedPath(): string | null {
    return (this.$route.query.contentPath as string) ?? null;
  }

  set selectedPath(value: string | null) {
    let pathParam = undefined;
    if (value) {
      pathParam = value;
    }
    // Update the URL.
    this.$router.replace({
      params: {
        ...this.$route.params,
      },
      query: {
        ...this.$route.query,
        contentPath: pathParam,
      },
    });
  }

  get mode(): BuilderMode.READ | BuilderMode.EDIT {
    const mode = this.$route.query.mode;
    if (mode === BuilderMode.EDIT) {
      return BuilderMode.EDIT;
    } else {
      return BuilderMode.READ;
    }
  }

  set mode(value: BuilderMode) {
    if (!isEqual(value, this.mode)) {
      // Update the URL.
      this.$router.replace({
        query: {
          ...this.$route.query,
          mode: value,
        },
      });
    }
  }

  get selectedPathParts(): string[] {
    // Default to root Problem Set if not specified.
    return this.selectedPath?.split(',') ?? [this.rootPsXref];
  }

  get selectedCeri(): string {
    return this.selectedPathParts[this.selectedPathParts.length - 1];
  }

  // Content under examination. This may be the root Problem Set, or any child Problem Set or child Problem.
  get openedContent(): ProblemSetMember | undefined {
    return this.getMemberByXref(this.selectedCeri);
  }

  // Problem Set Context of opened content. This may be the root Problem Set or any child Problem Set that is parent.
  get problemSetContext(): ProblemSetDefinition {
    if (this.openedContent?.contentType == ContentType.PROBLEM_SET) {
      return this.openedContent;
    }
    // In the case of deep nesting of Problem Sets, we need the direct parent Problem Set as opposed to the root
    // Problem Set here. Default to root Problem Set.
    const problemSetCeri =
      this.selectedPathParts[this.selectedPathParts.length - 2];
    let problemSet = this.problemSetMap[problemSetCeri];
    if (
      problemSet &&
      isPublished(problemSet.xref) &&
      problemSet.permissions.includes(AclPermissionType.UPDATE) &&
      problemSet.mappedCeri
    ) {
      problemSet = this.problemSetMap[problemSet.mappedCeri];
    }
    return problemSet ?? this.rootProblemSet;
  }

  get problemSetContextPath(): string {
    let contextPath = [this.rootPsXref];
    if (this.openedContent?.contentType == ContentType.PROBLEM_SET) {
      // Opened Problem Set is the context. This may be the root Problem Set or any child Problem Set.
      contextPath = this.selectedPathParts;
    } else {
      // Everything except for the last element which is the opened, non-Problem Set target.
      contextPath = this.selectedPathParts.slice(0, -1);
    }
    return contextPath.join(',');
  }

  get problemSetContextWipChildren(): string[] {
    const children: string[] = [];
    const ceris = this.problemSetContext?.children ?? [];
    for (const ceri of ceris) {
      if (isPublished(ceri)) {
        let contentMap = null;
        const type = getContentType(ceri);
        switch (type) {
          case ContentType.PROBLEM:
            contentMap = this.problemMap;
            break;
          case ContentType.PROBLEM_SET:
            contentMap = this.problemSetMap;
            break;
        }
        if (contentMap) {
          let content = contentMap[ceri];
          if (
            isPublished(content.xref) &&
            content.permissions.includes(AclPermissionType.UPDATE) &&
            content.mappedCeri
          ) {
            children.push(content.mappedCeri);
          } else {
            children.push(ceri);
          }
        }
      } else {
        children.push(ceri);
      }
    }
    return children;
  }

  get openedWipChildIndex(): number {
    return this.problemSetContextWipChildren.findIndex(
      (child) => child == this.openedContent?.xref
    );
  }

  set openedWipChildIndex(value: number) {
    const sibling = this.problemSetContextWipChildren[value];
    if (sibling) {
      this.selectedPath = `${this.problemSetContextPath},${sibling}`;
    }
  }

  get problemSetMap(): Record<string, ProblemSetDefinition> {
    return this.$store.state.content.problemSetMap;
  }

  get problemMap(): Record<string, ProblemDefinition> {
    return this.$store.state.content.problemMap;
  }

  openCopyPRDialog(params: { type: string; replace: boolean }): void {
    const isMultiPart = params.type == 'PS';

    this.copyingdata = {
      ceri: isMultiPart
        ? this.problemSetContext.xref
        : this.openedContent?.xref,
      type: params.type,
      replace: params.replace,
    };
    this.showCopyProblemDialog = true;
  }

  createProblem(contextPath: string): void {
    this.$store
      .dispatch('content/saveProblem', {
        modifiedFields: getNewProblem(),
      })
      .then(({ ceri: xref, failMessages: error }) => {
        if (xref) {
          this.$notify(`Created Problem ${xref}`);
          this.addMemberToProblemSet(contextPath, this.problemMap[xref]);
        } else {
          this.$notify(`Failed to create Problem: ${error}`);
        }
      })
      .catch(() => {
        this.$notify('Something went wrong. Failed to create Problem.');
      });
  }

  copyProblem(replace?: boolean): void {
    const includeSimilarity =
      this.isContentAdminUser || this.isTrustedBuilderUser;

    const includeTutoring = includeSimilarity
      ? CopyProblemTutoring.CERTIFIED
      : CopyProblemTutoring.OWNED_BY_USER;

    if (this.openedContent?.xref) {
      copyProblem(this.openedContent?.xref, includeSimilarity, includeTutoring)
        .then(({ ceri: xref, failMessages: error }) => {
          if (xref && this.openedContent?.xref) {
            this.$notify(`Created Problem ${xref}`);

            // Add the new problem to the list of children
            const members = [...this.problemSetContext.children];

            const originalProblemIndex = members.indexOf(
              this.openedContent.xref
            );

            members.splice(originalProblemIndex + 1, 0, xref);

            // If replacing with copy, remove the original problem
            if (replace) {
              members.splice(originalProblemIndex, 1);
            }

            // Replace the members in the original problem set
            this.$store.dispatch('content/replaceProblemSetMembers', {
              xref: this.problemSetContext.xref,
              members,
            });
          } else {
            this.$notify(`Failed to copy Problem: ${error}`);
          }
        })
        .catch((e) => {
          this.$notify('Something went wrong. Failed to copy Problem.');
        });
    }
  }

  copyProblemSet(): void {
    const includeSimilarity =
      this.isContentAdminUser || this.isTrustedBuilderUser;

    if (this.problemSetContext?.xref) {
      copyProblemSet(this.problemSetContext?.xref, includeSimilarity)
        .then(({ ceri: xref, failMessages: error }) => {
          if (xref && this.openedContent?.xref) {
            this.$notify('A copy has been added to the end of the problem set');

            // Add the new problem set to the list of children of the root problem
            if (this.rootProblemSet) {
              const members = [...this.rootProblemSet.children];

              members.push(xref);

              // Replace the members in the original problem set
              this.$store.dispatch('content/replaceProblemSetMembers', {
                xref: this.rootProblemSet.xref,
                members,
              });
            }
          } else {
            this.$notify(`Failed to copy multi-part Problem: ${error}`);
          }
        })
        .catch((e) => {
          this.$notify(
            'Something went wrong. Failed to copy multi-part Problem.'
          );
        });
    }
  }

  created(): void {
    const mode = this.$route.query.mode;
    // Initialize to READ in the URL if not set.
    if (mode === BuilderMode.EDIT) {
      this.mode = BuilderMode.EDIT;
    } else {
      this.mode = BuilderMode.READ;
    }
    this.isLoading = true;
    this.$store
      .dispatch('content/getWipProblemSetTree', {
        xref: this.rootPsXref,
      })
      .then((ps) => {
        if (ps.permissions.includes(AclPermissionType.UPDATE)) {
          this.rootPsXref = ps.xref;
        } else {
          this.$router.replace({
            path: '/403',
            query: { [RETURN_URL]: this.$route.fullPath },
          });
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
    this.$store.dispatch('skillList/requestSkills');
  }
  getMemberByXref(xref: string): ProblemSetMember | undefined {
    return getContentType(xref) == ContentType.PROBLEM
      ? this.problemMap[xref]
      : this.problemSetMap[xref];
  }

  // FIXME: Avoid User from opening another PS before this code runs?
  addMemberToProblemSet(contextPath: string, member: ProblemSetMember): void {
    const pathParts = contextPath.split(',');
    const context = pathParts[pathParts.length - 1];
    this.$store
      .dispatch('content/addProblemSetMembers', {
        xref: context,
        members: [member.xref],
      })
      .then(({ ceri: xref, failMessages: error }) => {
        if (error) {
          this.$notify(`Failed to add ${member.xref} to ${context}: ${error}`);
        } else if (xref) {
          this.$notify(`Added ${member.xref} to ${xref}.`);
          // Open newly-added member.
          this.selectedPath = `${contextPath},${member.xref}`;
        }
      })
      .catch(() => {
        this.$notify(`Failed to add ${member.xref} to ${context}.`);
      });
  }

  toggleSideNav(showSideNav: boolean): void {
    this.showSideNav = showSideNav;
  }

  @Watch('rootProblemSet.mappedCeri')
  onRootProblemSet(newValue: string, oldValue: string): void {
    if (isPublished(this.rootPsXref) && newValue) {
      this.rootPsXref = newValue;
    }
  }
}
