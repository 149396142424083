var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.folder.permissions.includes(_vm.AclPermissionType.UPDATE))?_c('div',[_c('v-dialog',{attrs:{"width":"700","height":"600","persistent":_vm.loading},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_vm._t("activator",function(){return [(_vm.mode === _vm.EditMode.CREATE)?_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_vm._v(" Add new subfolder ")]):(_vm.mode === _vm.EditMode.UPDATE)?_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_vm._v(" Edit Settings ")]):_vm._e()]},{"on":on,"attrs":attrs})]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"px-2 py-2"},[_c('v-card-title',{staticClass:"text-h5 d-flex justify-space-between mb-4 px-4"},[(_vm.mode === _vm.EditMode.CREATE)?_c('span',[_vm._v("Add New Subfolder")]):(_vm.mode === _vm.EditMode.UPDATE)?_c('span',[_vm._v("Folder Settings")]):_vm._e(),_c('v-icon',{staticClass:"align-self-start",on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" mdi-close ")])],1),_c('v-card-text',{staticClass:"px-4 py-2"},[(_vm.parentPage == _vm.PageView.MAIN_HIERARCHY)?_c('v-text-field',{staticClass:"mb-4",attrs:{"label":"Folder Header Title","outlined":"","messages":['Optional']},model:{value:(_vm.header),callback:function ($$v) {_vm.header=$$v},expression:"header"}}):_vm._e(),_c('v-text-field',{attrs:{"label":"Folder Name","outlined":"","messages":['Required *'],"hide-details":"","required":""},model:{value:(_vm.fields.name),callback:function ($$v) {_vm.$set(_vm.fields, "name", $$v)},expression:"fields.name"}}),_c('v-switch',{attrs:{"color":"primary","loading":_vm.loading,"disabled":_vm.loading,"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"font-weight-medium",class:_vm.fields.properties.OPEN_IN_LESSON_PAGE
                  ? 'neutral--text text--darken-3'
                  : 'neutral--text'},[_vm._v(" Display children as tabs (lesson view) ")])]},proxy:true}],null,false,1245912893),model:{value:(_vm.fields.properties.OPEN_IN_LESSON_PAGE),callback:function ($$v) {_vm.$set(_vm.fields.properties, "OPEN_IN_LESSON_PAGE", $$v)},expression:"fields.properties.OPEN_IN_LESSON_PAGE"}}),(_vm.fields.properties.OPEN_IN_LESSON_PAGE && _vm.stats?.numFolders)?_c('span',{staticClass:"error--text text-caption"},[_vm._v(" Folder contains subfolders. To enable this setting, remove all subfolders. ")]):_vm._e(),(
            _vm.mode === _vm.EditMode.CREATE && _vm.parentPage == _vm.PageView.MAIN_HIERARCHY
          )?_c('v-checkbox',{attrs:{"color":"neutral darken-1"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"font-weight-medium",class:_vm.inheritPermissionsLocal
                  ? 'neutral--text text--darken-3'
                  : 'neutral--text'},[_vm._v(" Inherit permissions from "+_vm._s(_vm.folder.name)+" ")])]},proxy:true}],null,false,336316552),model:{value:(_vm.inheritPermissionsLocal),callback:function ($$v) {_vm.inheritPermissionsLocal=$$v},expression:"inheritPermissionsLocal"}}):_vm._e()],1),_c('v-card-actions',{staticClass:"mb-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":_vm.loading},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","elevation":"0","loading":_vm.loading,"disabled":!_vm.validFields},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }