import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { RootState } from '../types';
import { AttributionState } from './types';

export const state: AttributionState = {
  isDownloadingAttributions: false,
  hasDownloadedAttributions: false,
  downloadedDefinitions: [],
};

const namespaced = true;

export const attributions: Module<AttributionState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
