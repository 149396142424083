import { GetterTree } from 'vuex';
import { Class } from '@/domain/Class';
import { ClassState } from './types';
import { RootState } from '../types';

export const getters: GetterTree<ClassState, RootState> = {
  getHasDownloaded(state: ClassState): boolean {
    return state.hasDownloaded;
  },
  getIsDownloading(state: ClassState): boolean {
    return state.isDownloading;
  },
  getClasses(state: ClassState): Array<Class> {
    return state.classes;
  },
};
