
import { Vue, Component, Prop } from 'vue-property-decorator';
import { AssignmentDefinition } from '@/domain/Assignment';

@Component
export default class AssignmentSelectionDialog extends Vue {
  @Prop({ required: true }) value: boolean;
  @Prop({ required: true }) data: Array<AssignmentDefinition>;

  get selected(): Array<AssignmentDefinition> {
    return this.$store.getters['tutor/getSelectedAssignments'] || [];
  }

  set selected(select: Array<AssignmentDefinition>) {
    this.$store.commit(
      'tutor/setSelectedAssignments',
      this.assignmentOptions.filter((selection) => {
        return select.some(
          (assignment: AssignmentDefinition) =>
            assignment.xref == selection.xref
        );
      })
    );
  }

  // Takes the xrefs from selected and makes an array with the other relevant data
  get selectedAssignments(): Array<AssignmentDefinition> {
    return this.assignmentOptions.filter((selection) => {
      return this.selected.some(
        (s: AssignmentDefinition) => s.xref == selection.xref
      );
    });
  }

  get assignmentOptions(): Array<AssignmentDefinition> {
    const options: Array<AssignmentDefinition> = [];
    const checked: Array<AssignmentDefinition> = [];

    this.data.forEach((assignment) => {
      // If the option is selected then put in array that will go on top of list
      this.selected.some((s: AssignmentDefinition) => s.xref == assignment.xref)
        ? checked.push(assignment)
        : options.push(assignment);
    });

    // Put the two arrays together. Checked ones on top, the rest sorted by release date
    return checked
      .sort((a, b) => a.releaseDate - b.releaseDate)
      .concat(options.sort((a, b) => a.releaseDate - b.releaseDate));
  }

  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(val: boolean) {
    this.$emit('input', val);
  }

  setSelectedAssignments() {
    this.$store.commit(
      'tutor/setSelectedAssignments',
      this.selectedAssignments
    );
    this.showDialog = false;
  }

  updateAssignmentSelections(index: number): void {
    this.selectedAssignments.splice(index, 1);

    this.$store.commit(
      'tutor/setSelectedAssignments',
      this.selectedAssignments
    );
  }
}
