
import { Component, Vue, Prop } from 'vue-property-decorator';
import FullAttributionDialog from '@/components/base/FullAttributionDialog.vue';
import { AttributionDefinition } from '@/domain/Attribution';

@Component({
  components: {
    FullAttributionDialog,
  },
})
export default class Attribution extends Vue {
  @Prop({ default: [] }) attributionXrefs: string[];

  /**
   * Computed props: curricula from store
   */
  get attributions(): AttributionDefinition[] {
    return this.$store.state.attributions.downloadedDefinitions;
  }

  /**
   * Computed props: populating view
   */
  get targetAttributions(): AttributionDefinition[] {
    return this.attributions.filter((attribution: AttributionDefinition) => {
      return this.attributionXrefs
        ? this.attributionXrefs.includes(attribution.xref)
        : false;
    });
  }

  get attributionsToDisplay(): AttributionDefinition[] {
    // Only ONE attribution to display
    if (this.targetAttributions.length === 1) {
      return this.targetAttributions;
    }

    // Only attribution with the highest precedence will be displayed
    const precedences = this.targetAttributions
      .filter((attribution: AttributionDefinition) => {
        return attribution.attributionPrecedence != null;
      })
      .map((attribution: AttributionDefinition) => {
        return attribution.attributionPrecedence;
      });

    if (precedences.length === 0) {
      // TODO: Cannot determine which to display
      // return [];
      return this.targetAttributions; // FIXME: Either display ALL or NONE
    }

    var highest = Math.max.apply(null, precedences);

    return this.targetAttributions.filter(
      (attribution: AttributionDefinition) => {
        // NOTE: Null precedence is treated as LOWEST priority (ignored)
        // Text attribution to display
        return (
          attribution.attributionPrecedence === highest &&
          (attribution.attributionAbbreviated ||
            attribution.attributionExtended)
        );
      }
    );
  }

  get hasAttributionsToDisplay(): boolean {
    return this.attributionsToDisplay.length > 0;
  }

  created(): void {
    // Download attributions if not already
    this.$store.dispatch('attributions/requestAttributions');
  }
}
