
import {
  AnswerPart,
  AnswerSet,
  AnswerSetProperties,
  AnswerType,
  AnswerValue,
  ShowCorrectnessType,
} from '@/domain/Problem';
import BuilderEditor from '@/components/Builder/BuilderEditor.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { cloneDeep, differenceWith, isEqual, uniqWith } from 'lodash';
import { ResponseBox } from '@/utils/tinyMCE/problemBuildertinyMCEConfig';

interface AnswerPoolValue extends AnswerValue {
  count: number;
}

@Component({
  components: { BuilderEditor },
})
export default class DragDropAnswers extends Vue {
  @Prop() answerSet: AnswerSet | null;
  @Prop() question: string;
  @Prop({ default: () => [] }) responseBoxes: ResponseBox[];
  @Prop({ default: false }) multipleCorrect: boolean;
  @Prop() dropCount: number;
  @Prop({ default: false }) disabled: boolean;

  rootAnswerSetMarker = 0;
  localAnswerSet: AnswerSet | null = null;
  answerPoolValues: AnswerPoolValue[] = [];
  markerToDropTargetRefMap: Record<number, AnswerSet | AnswerPart> = {};
  dragged: HTMLElement | null = null;

  get properties(): AnswerSetProperties {
    return this.answerSet?.properties ?? {};
  }

  set properties(value: AnswerSetProperties) {
    this.$emit('answersChanged', {
      memberType: 'ANSWER_SET',
      ...this.answerSet,
      properties: value,
    });
  }

  get partialCorrectness(): boolean {
    const partial =
      this.properties?.SHOW_CORRECTNESS ?? ShowCorrectnessType.PARTIAL;
    return partial === ShowCorrectnessType.PARTIAL;
  }

  set partialCorrectness(value: boolean) {
    this.properties = {
      ...this.properties,
      SHOW_CORRECTNESS: value
        ? ShowCorrectnessType.PARTIAL
        : ShowCorrectnessType.FULL,
    };
  }

  get partialCorrectnessDisabled(): boolean {
    return this.multipleCorrect && this.duplicateResponses;
  }

  get interchangeable(): boolean {
    return this.properties?.INTERCHANGEABLE ?? false;
  }

  set interchangeable(value: boolean) {
    this.properties = { ...this.properties, INTERCHANGEABLE: value };
  }

  get duplicateResponses(): boolean {
    return this.properties?.ANSWER_POOL_REUSE ?? false;
  }

  set duplicateResponses(value: boolean) {
    this.properties = { ...this.properties, ANSWER_POOL_REUSE: value };
  }

  get duplicateResponsesDisabled(): boolean {
    return (
      this.multipleCorrect &&
      (this.partialCorrectness ||
        (this.localAnswerSet?.members ?? []).length > 1)
    );
  }

  getAnswerPoolValues(answerSet: AnswerSet | null): AnswerPoolValue[] {
    const pool = [];
    const answerSetValues = (answerSet?.answerValues ?? []).map((answer) => ({
      ...answer,
      count: 1,
    }));
    pool.push(...answerSetValues);
    if (answerSet?.members) {
      const subpool = [];
      for (const member of answerSet.members) {
        switch (member.memberType) {
          case 'ANSWER_SET':
            subpool.push(...this.getAnswerPoolValues(member));
            break;
          case 'ANSWER_PART':
            {
              const answerPartValues = member.answerValues ?? [];
              const dropCorrectMap = member.properties?.DROP_CORRECT ?? {};
              for (const answerValue of answerPartValues) {
                subpool.push({
                  ...answerValue,
                  count: dropCorrectMap[answerValue.value] ?? 1,
                });
              }
            }
            break;
        }
      }
      // Remove duplicate correct answers. Leave incorrect ones (distractors) alone because
      // we want to distinguish between previously associated Answer Values (correct) vs
      // newly-added ones (incorrect) IF they happen to have the same value (empty, for example).
      // What we want here: Answer Pool = Set (Answer Part Values) + List (Answer Set Values)
      const answerSetValues = [];
      const answerPartValues: Record<string, AnswerPoolValue> = {};
      for (const poolValue of subpool) {
        if (poolValue.isCorrect) {
          if (answerPartValues[poolValue.value]) {
            // Increment count to indicate number of usage.
            answerPartValues[poolValue.value].count += poolValue.count;
          } else {
            answerPartValues[poolValue.value] = poolValue;
          }
        } else {
          answerSetValues.push(poolValue);
        }
      }
      pool.push(...answerSetValues);
      pool.push(...Object.values(answerPartValues));
    }
    return pool;
  }

  // This may be Answer Set Value (from the Answer Bank) or Answer Part Value from any
  // Answer Part (from any one in the Problem question).
  onDrag(event: DragEvent): void {
    if (event.target && event.dataTransfer) {
      const source = this.getTargetTypeFrom(
        event.target as HTMLElement,
        'answerValue'
      );
      if (source) {
        // Show and indicate moving target.
        source.classList.add('dragging');
      }
      // Show all Answer Parts in Question as droppable target.
      const question = document.getElementById('problem-question');
      const answerParts = question?.getElementsByTagName('ast-r') ?? [];
      for (const answerPart of answerParts) {
        const numChildren = answerPart.children.length;
        if (numChildren < this.dropCount || this.dropCount == 0) {
          answerPart.classList.add('droppable');
        }
      }
      // Show Answer Bank as droppable target.
      document.getElementById('answer-bank')?.classList.add('droppable');
      this.dragged = source;
    }
  }

  // New location may be in the Answer Bank or in any of the Answer Parts of the Problem question.
  // FIXME: Account for multiple responses！
  onDrop(event: DragEvent): void {
    if (this.dragged) {
      const sourceMarker = Number(this.dragged.getAttribute('marker'));
      const source: AnswerSet | AnswerPart | undefined =
        this.markerToDropTargetRefMap[sourceMarker];
      const index = Number(this.dragged.getAttribute('index'));
      // Only move to a valid destination.
      let dropTarget = null;
      if (event.target) {
        dropTarget = this.getTargetTypeFrom(
          event.target as HTMLElement,
          'dropTarget'
        );
      }
      if (source.answerValues && dropTarget) {
        const droppedMarker = Number(dropTarget.getAttribute('marker'));
        // Not dropped on itself.
        if (droppedMarker !== sourceMarker) {
          const destination = this.markerToDropTargetRefMap[droppedMarker];
          if (destination) {
            if (!destination.answerValues) {
              // Initialize if not already.
              destination.answerValues = [];
            }
            const poolValue: AnswerPoolValue = this.answerPoolValues[index];
            // Add to destination.
            if (destination.memberType == 'ANSWER_PART') {
              // Allowed to make the move.
              if (destination.answerValues.length < 1) {
                destination.answerValues.push({
                  value: poolValue.value,
                  isCorrect: true,
                });
                // FIXME: Figure out IF we allow SINGLE, DUPLICATE where one Answer Part has ONE correct
                // Answer Value with N counts? But this seems weird to allow for single response option?
              } else if (
                destination.answerValues.length < this.dropCount ||
                this.dropCount === 0
              ) {
                if (!destination.properties) {
                  destination.properties = {};
                }
                if (!destination.properties.DROP_CORRECT) {
                  destination.properties.DROP_CORRECT = {};
                  // Create drop correct map to be in sync with Answer Part Values.
                  for (const answerValue of destination.answerValues) {
                    destination.properties.DROP_CORRECT[answerValue.value] = 1;
                  }
                }
                const dropCorrectMap = destination.properties.DROP_CORRECT;
                if (dropCorrectMap[poolValue.value]) {
                  dropCorrectMap[poolValue.value]++;
                } else {
                  destination.answerValues.push({
                    value: poolValue.value,
                    isCorrect: true,
                  });
                  dropCorrectMap[poolValue.value] = 1;
                }
              } else {
                // Not allowed to move to an occupied Answer Part.
                return;
              }
            } else if (destination.memberType == 'ANSWER_SET') {
              // Only add to Answer Set IF no longer a correct answer (not in use by other Answer Part(s)).
              // There may be a duplicate value in the Answer Pool (empty, for example, newly-added values vs
              // previously marked correct answer). We will NOT clean this up rather let the handle this.
              // Sanity Check. SHOULD always be correct answer moved here.
              if (poolValue.count == 1) {
                destination.answerValues.push({
                  value: poolValue.value,
                  isCorrect: false,
                });
              }
            }
            let removeFromSource = false;
            if (source.memberType == 'ANSWER_PART') {
              const dropCorrectMap = source.properties?.DROP_CORRECT;
              if (dropCorrectMap) {
                const dropCorrect = dropCorrectMap[poolValue.value] ?? 1;
                if (dropCorrect > 1) {
                  dropCorrectMap[poolValue.value]--;
                } else {
                  delete dropCorrectMap[poolValue.value];
                  const left = Object.keys(dropCorrectMap);
                  if (
                    left.length == 0 ||
                    (left.length == 1 && dropCorrectMap[left[0]] == 1)
                  ) {
                    // No need for a drop correct map.
                    delete source.properties?.DROP_CORRECT;
                  }
                  // Used up all counts so remove from Answer Part Values.
                  removeFromSource = true;
                }
              } else {
                removeFromSource = true;
              }
            } else if (source.memberType == 'ANSWER_SET') {
              if (!poolValue.isCorrect) {
                // Now it is! No longer a distractor... Remove from Answer Set.
                removeFromSource = true;
              }
            }
            if (removeFromSource) {
              // Should ONLY (and better) find ONE match?
              const valueIndex = source.answerValues.findIndex(
                (answer) =>
                  answer.value == poolValue.value &&
                  answer.isCorrect == poolValue.isCorrect
              );
              source.answerValues.splice(valueIndex, 1);
            }
            // Ugh... call this here to clear out drag stylings.
            this.onEnd(event);
            // Notify update to Answer Set. As a result of this, initialize() will be called, and the Problem Body
            // SHOULD be updated accordingly. Thus, we do NOT need to do any DOM updates here.
            this.$emit('answersChanged', this.localAnswerSet);
          }
        }
      }
    }
  }

  getAnswerValueElement(
    marker: number,
    index: number,
    answerValue: AnswerValue
  ): HTMLElement {
    const answerValueContainer = document.createElement('div');
    const answerValueElement = document.createElement('span');
    answerValueContainer.setAttribute('marker', String(marker));
    answerValueContainer.setAttribute('index', String(index));
    answerValueContainer.setAttribute('type', 'answerValue');
    answerValueContainer.draggable = !this.disabled;
    answerValueContainer.addEventListener('dragstart', this.onDrag);
    answerValueElement.innerHTML = answerValue.value;
    answerValueContainer.appendChild(answerValueElement);
    return answerValueContainer;
  }

  onDragover(event: DragEvent): void {
    if (event.target) {
      let dropTarget = this.getTargetTypeFrom(
        event.target as HTMLElement,
        'dropTarget'
      );
      if (dropTarget) {
        const numChildren = dropTarget.children.length;
        // Highlight droppable (current drop target) IF allow multiple.
        if (numChildren < 1) {
          dropTarget.classList.add('dragover');
        } else if (numChildren < this.dropCount || this.dropCount === 0) {
          dropTarget.classList.add('dragover');
          // Only expand (show droppable IF multiple) on Answer Parts.
          const marker = dropTarget.getAttribute('marker');
          if (Number(marker) > 0) {
            dropTarget.classList.add('expand');
          }
        }
      }
    }
  }

  onDragleave(event: DragEvent): void {
    if (event.target) {
      let dropTarget = this.getTargetTypeFrom(
        event.target as HTMLElement,
        'dropTarget'
      );
      if (dropTarget) {
        dropTarget.classList.remove('dragover');
        dropTarget.classList.remove('expand');
      }
    }
  }

  onEnd(event: DragEvent): void {
    this.dragged?.classList.remove('dragging');
    // Do NOT show Answer Parts in Question as droppable target.
    const question = document.getElementById('problem-question');
    const answerParts = question?.getElementsByTagName('ast-r') ?? [];
    for (const answerPart of answerParts) {
      answerPart.classList.remove('droppable');
      answerPart.classList.remove('dragover');
      answerPart.classList.remove('expand');
    }
    // Do NOT show Answer Bank as droppable target.
    const answerBank = document.getElementById('answer-bank');
    if (answerBank) {
      answerBank.classList.remove('droppable');
      answerBank.classList.remove('dragover');
    }
    this.dragged = null;
  }

  getTargetTypeFrom(
    element: HTMLElement,
    targetType: 'dropTarget' | 'answerValue'
  ): HTMLElement | null {
    let target = null;
    let type = element.getAttribute('type');
    if (type != targetType) {
      // Recursively lookup parent drop target where this resides. Stop at root containers.
      while (!['problem-question', 'answer-bank'].includes(element.id)) {
        if (element.parentElement) {
          element = element.parentElement;
          type = element.getAttribute('type');
          if (type == targetType) {
            target = element;
            break;
          }
        } else {
          // No parent found. Nothing more to do here.
          break;
        }
      }
    } else {
      target = element;
    }

    return target;
  }

  initialize(): void {
    // Initialize and compute stats based on passed in Answer Set.
    const localAnswerSet = cloneDeep(this.answerSet) ?? null;
    const markerToDropTargetRefMap: Record<number, AnswerSet | AnswerPart> = {};
    if (localAnswerSet) {
      markerToDropTargetRefMap[this.rootAnswerSetMarker] = localAnswerSet;
    }
    if (localAnswerSet?.members) {
      for (const member of localAnswerSet.members) {
        // FIXME: Figure out if we need to handle nested Answer Sets in this component.
        if (member.memberType === 'ANSWER_PART') {
          markerToDropTargetRefMap[member.htmlMarker] = member;
        }
      }
    }
    const answerPoolValues = this.getAnswerPoolValues(localAnswerSet);
    this.localAnswerSet = localAnswerSet;
    this.markerToDropTargetRefMap = markerToDropTargetRefMap;
    this.answerPoolValues = answerPoolValues;
    this.renderQuestion();
  }

  renderQuestion(): void {
    // Render and update drop targets based on passed in Answer Set.
    const correctValueToIndex: Record<string, number> = {};
    for (let index = 0; index < this.answerPoolValues.length; index++) {
      const poolValue = this.answerPoolValues[index];
      if (poolValue.isCorrect) {
        correctValueToIndex[poolValue.value] = index;
      }
    }
    const question = document.getElementById('problem-question');
    const answerPartElements = question?.getElementsByTagName('ast-r') ?? [];
    for (const answerPartElement of answerPartElements) {
      const marker = Number(answerPartElement.getAttribute('marker'));
      const answerPart = this.markerToDropTargetRefMap[marker];
      answerPartElement.innerHTML = '';
      // Sanity Check. This SHOULD ALWAYS be an Answer Part.
      if (answerPart?.memberType == 'ANSWER_PART') {
        const answerValues = answerPart?.answerValues ?? [];
        const answerValueElements = [];
        for (const answerValue of answerValues) {
          const poolIndex = correctValueToIndex[answerValue.value] ?? null;
          const dropCorrectMap = answerPart.properties?.DROP_CORRECT ?? {};
          const count = dropCorrectMap[answerValue.value] ?? 1;
          answerValueElements.push(
            ...Array(count)
              .fill(answerValue)
              .map((answerValue) =>
                this.getAnswerValueElement(marker, poolIndex, answerValue)
              )
          );
        }
        answerPartElement.append(...answerValueElements);
      }
    }
    window.com.wiris.js.JsPluginViewer.parseDocument();
  }

  getNewAnswerPartFromResponseBox(responseBox: ResponseBox): AnswerPart {
    return {
      memberType: 'ANSWER_PART',
      answerType: AnswerType.DRAG_DROP,
      htmlMarker: responseBox.marker,
      answerValues: [],
      properties: { DROP_COUNT: this.multipleCorrect ? this.dropCount : 1 },
    };
  }

  updateDroppedAnswers(newValue: boolean, oldValue: boolean) {
    // Make sure Answer Part Values are in sync.
    if (!isEqual(newValue, oldValue)) {
      const localAnswerSet: AnswerSet = cloneDeep(this.localAnswerSet) ?? {
        memberType: 'ANSWER_SET',
      };
      if (localAnswerSet.members) {
        const returnToPoolValues: string[] = [];
        const answerPartValues: string[] = [];
        for (const dropTarget of localAnswerSet.members) {
          // Sanity Check. Better all be Answer Parts.
          if (dropTarget.memberType == 'ANSWER_PART') {
            if (!dropTarget.properties) {
              dropTarget.properties = {};
            }
            // Ugh... update all Answer Parts drop count prop to be in sync.
            if (this.multipleCorrect) {
              dropTarget.properties.DROP_COUNT = this.dropCount;
            } else {
              dropTarget.properties.DROP_COUNT = 1;
            }
            let dropTargetValues: string[] =
              dropTarget.answerValues?.map((answer) => answer.value) ?? [];
            if (!this.multipleCorrect) {
              if (dropTarget.properties?.DROP_CORRECT) {
                // Single value count > 1 OR multiple correct values. Return values to Answer Pool.
                delete dropTarget.properties?.DROP_CORRECT;
                dropTarget.answerValues = [];
                returnToPoolValues.push(...dropTargetValues);
              } else {
                // Should better ONLY contain ONE Answer Part Value?
                answerPartValues.push(...dropTargetValues);
              }
            } else {
              if (dropTargetValues.length > dropTarget.properties.DROP_COUNT) {
                delete dropTarget.properties?.DROP_CORRECT;
                dropTarget.answerValues = [];
                returnToPoolValues.push(...dropTargetValues);
              } else {
                answerPartValues.push(...dropTargetValues);
              }
            }
          }
        }
        // Exclude validated Answer Part Values. An Answer Value CANNOT be marked as correct AND
        // incorrect.
        let answerSetValues = differenceWith(
          returnToPoolValues,
          answerPartValues,
          isEqual
        );
        // Duplicated Answer Values across invalid Answer Parts are to be added ONCE here to the
        // Answer Pool.
        answerSetValues = uniqWith(answerSetValues, isEqual);
        if (answerSetValues.length) {
          localAnswerSet.answerValues = answerSetValues.map((setValue) => ({
            value: setValue,
            isCorrect: false,
          }));
        }
      }
      if (newValue) {
        delete localAnswerSet.properties?.INTERCHANGEABLE;
        if (localAnswerSet.properties?.SHOW_CORRECTNESS) {
          delete localAnswerSet.properties?.ANSWER_POOL_REUSE;
        }
      }
      this.$emit('answersChanged', localAnswerSet);
    }
  }

  mounted(): void {
    this.initialize();
  }

  @Watch('answerSet')
  onUpdateAnswerSet(): void {
    // Make sure local version and computed stats are up-to-date.
    this.initialize();
  }

  @Watch('responseBoxes')
  onUpdateResponseBoxes(
    newValue: ResponseBox[],
    oldValue: ResponseBox[]
  ): void {
    if (!isEqual(newValue, oldValue)) {
      // Make sure response boxes and Answer Parts in sync.
      const localAnswerSet: AnswerSet = cloneDeep(this.localAnswerSet) ?? {
        memberType: 'ANSWER_SET',
      };
      const invalidAnswerParts = cloneDeep(this.markerToDropTargetRefMap);
      delete invalidAnswerParts[this.rootAnswerSetMarker];
      localAnswerSet.members = [];
      // May contain duplicate values IF duplicated ACROSS Answer Parts.
      const answerPartValues: string[] = [];
      for (const responseBox of this.responseBoxes) {
        const answerPart = invalidAnswerParts[responseBox.marker] as AnswerPart;
        if (answerPart) {
          // Existing Answer Part.
          // FIXME: What if answerType does NOT match that of response box?
          localAnswerSet.members.push(answerPart);
          const partValues =
            answerPart.answerValues?.map((partValue) => partValue.value) ?? [];
          answerPartValues.push(...partValues);
          delete invalidAnswerParts[answerPart.htmlMarker];
        } else {
          // Newly-added Answer Part. HTML marker needs to be accounted for.
          localAnswerSet.members.push(
            this.getNewAnswerPartFromResponseBox(responseBox)
          );
        }
      }
      // Invalid Answer Part Values (Answer Part no longer exists, for example) that need to be
      // returned to Answer Pool.
      const returnToPoolValues: string[] = [];
      for (const invalidAnswerPart of Object.values(invalidAnswerParts)) {
        const partValues =
          invalidAnswerPart.answerValues?.map((partValue) => partValue.value) ??
          [];
        returnToPoolValues.push(...partValues);
      }
      // Exclude validated Answer Part Values. An Answer Value CANNOT be marked as correct AND
      // incorrect.
      let answerSetValues = differenceWith(
        returnToPoolValues,
        answerPartValues,
        isEqual
      );
      // Duplicated Answer Values across invalid Answer Parts are to be added ONCE here to the
      // Answer Pool.
      answerSetValues = uniqWith(answerSetValues, isEqual);
      if (answerSetValues.length) {
        localAnswerSet.answerValues = answerSetValues.map((setValue) => ({
          value: setValue,
          isCorrect: false,
        }));
      }
      if (this.multipleCorrect && this.responseBoxes.length > 1) {
        delete localAnswerSet.properties?.ANSWER_POOL_REUSE;
      }
      this.$emit('answersChanged', localAnswerSet);
    } else {
      // Problem Question needs to be re-rendered?
      this.renderQuestion();
    }
  }

  @Watch('multipleCorrect')
  onUpdateMultipleCorrect(newValue: boolean, oldValue: boolean): void {
    this.updateDroppedAnswers(newValue, oldValue);
  }

  @Watch('dropCount')
  onUpdateDropCount(newValue: boolean, oldValue: boolean): void {
    this.updateDroppedAnswers(newValue, oldValue);
  }

  @Watch('duplicateResponses')
  onUpdateDuplicateResponses(newValue: boolean, oldValue: boolean): void {
    // Make sure Answer Part Values are in sync.
    if (!isEqual(newValue, oldValue) && !newValue) {
      const localAnswerSet: AnswerSet = cloneDeep(this.localAnswerSet) ?? {
        memberType: 'ANSWER_SET',
      };
      if (localAnswerSet.members) {
        const correctValueToCount: Record<string, number> = {};
        for (const poolValue of this.answerPoolValues) {
          if (poolValue.isCorrect) {
            correctValueToCount[poolValue.value] = poolValue.count;
          }
        }
        let returnToPoolValues: string[] = [];
        for (const dropTarget of localAnswerSet.members) {
          if (dropTarget.memberType == 'ANSWER_PART') {
            let dropTargetValues: string[] =
              dropTarget.answerValues?.map((answer) => answer.value) ?? [];
            const answerPartValues = [];
            for (const dropTargetValue of dropTargetValues) {
              if (correctValueToCount[dropTargetValue] > 1) {
                returnToPoolValues.push(dropTargetValue);
                if (dropTarget.properties?.DROP_CORRECT) {
                  const dropCorrectMap = dropTarget.properties.DROP_CORRECT;
                  delete dropCorrectMap[dropTargetValue];
                  if (Object.keys(dropCorrectMap).length <= 1) {
                    delete dropTarget.properties.DROP_CORRECT;
                  }
                }
              } else {
                answerPartValues.push(dropTargetValue);
              }
            }
            dropTarget.answerValues = answerPartValues.map((partValue) => ({
              value: partValue,
              isCorrect: true,
            }));
          }
        }
        // Duplicated Answer Values across invalid Answer Parts are to be added ONCE here to the
        // Answer Pool.
        returnToPoolValues = uniqWith(returnToPoolValues, isEqual);
        localAnswerSet.answerValues?.push(
          ...returnToPoolValues.map((value) => ({ value, isCorrect: false }))
        );
        if (this.duplicateResponsesDisabled) {
          delete localAnswerSet.properties?.ANSWER_POOL_REUSE;
        }
        this.$emit('answersChanged', localAnswerSet);
      }
    }
  }
}
