import { GroupDefinition } from '@/domain/Group';
import { IsActiveFilter } from '@/domain/State';
import { User } from '@/domain/User';
import { coreAxios } from '@/plugins/axios';
import { transformUserProfileToUser } from '@/utils/user.util';

const END_POINT = '/mentees';

interface GroupSearchParams {
  owner?: string;
  activeFilter?: IsActiveFilter;
}

interface MemberSearchParams {
  roles?: boolean;
  attributes?: boolean;
  activeFilter?: IsActiveFilter;
}

const findMenteeGroup = (
  filterParams: GroupSearchParams
): Promise<GroupDefinition> => {
  return coreAxios
    .get(`${END_POINT}`, {
      params: filterParams,
    })
    .then((res) => {
      return res.data;
    });
};

const getMentees = (
  menteeGroupXref: string,
  searchCriteria?: MemberSearchParams
): Promise<User[]> => {
  return coreAxios
    .get(`${END_POINT}/${menteeGroupXref}/members`, {
      params: searchCriteria,
    })
    .then((res) => {
      return res.data.map(transformUserProfileToUser);
    });
};

export { findMenteeGroup, getMentees };
