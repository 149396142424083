
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  Explanation,
  HintSet,
  ITutorStrategy,
  TutorStrategyType,
} from '@/domain/Tutoring';
import SupportRow, { SupportMode } from '../Supports/SupportRow.vue';
import { ProblemDefinition } from '@/domain/Problem';
import { orderBy } from 'lodash';
import { TutoringFilterParams } from '@/api/core/content.api';
import {
  PersistableStateType,
  UserToContentRelationshipType,
} from '@/domain/Content';
import { AclPermissionType } from '@/domain/Acls';

@Component({ components: { SupportRow } })
export default class SupportsView extends Vue {
  @Prop({ required: true }) problem: ProblemDefinition;

  SupportMode = SupportMode;

  loading = false;

  get supports(): ITutorStrategy[] {
    const supports = [];
    const tutorStrategiesMap =
      this.$store.getters['content/targetToTutorStrategiesMap'];
    let tses = tutorStrategiesMap[this.problem.xref];
    if (tses) {
      supports.push(...tses);
    }
    if (
      this.problem.permissions.includes(AclPermissionType.UPDATE) &&
      this.problem.mappedCeri
    ) {
      tses = tutorStrategiesMap[this.problem.mappedCeri];
      if (tses) {
        supports.push(...tses);
      }
    }
    return orderBy(supports, (ts) => ts.createdAt, 'asc');
  }

  get container(): Record<TutorStrategyType, ITutorStrategy[]> {
    const res: Record<TutorStrategyType, ITutorStrategy[]> = {} as Record<
      TutorStrategyType,
      ITutorStrategy[]
    >;
    for (const ts of this.supports) {
      const tsType = ts.tutorStrategyType;
      if (res[tsType]) {
        res[tsType].push(ts);
      } else {
        res[tsType] = [ts];
      }
    }
    return res;
  }

  get explanations(): Explanation[] {
    const found = this.container[TutorStrategyType.EXPLANATION] ?? [];
    return found as Explanation[];
  }

  get hintSets(): HintSet[] {
    const found = this.container[TutorStrategyType.HINT_SET] ?? [];
    return found as HintSet[];
  }

  created(): void {
    if (this.supports.length === 0) {
      this.loading = true;
      const filterParams: TutoringFilterParams = {
        targets: [this.problem.xref],
        psTypes: [
          PersistableStateType.PUBLISHED,
          PersistableStateType.WORK_IN_PROGRESS,
        ],
      };
      if (
        this.problem.permissions.includes(AclPermissionType.UPDATE) &&
        this.problem.mappedCeri
      ) {
        filterParams.targets?.push(this.problem.mappedCeri);
      }
      const promises = [];
      if (this.isContentAdminUser || this.isTrustedBuilderUser) {
        promises.push(
          this.$store.dispatch('content/getTutorStrategies', {
            filterParams: { ...filterParams, isCertified: true },
          })
        );
      }
      promises.push(
        this.$store.dispatch('content/getTutorStrategies', {
          filterParams: {
            ...filterParams,
            // Supports owned by Builder.
            user: 'me',
            // Should be default but...
            filterBy: [UserToContentRelationshipType.OWNER],
          },
        })
      );
      Promise.all(promises).finally(() => {
        this.loading = false;
      });
    }
  }
}
