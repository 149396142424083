
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ValidateContentDialog extends Vue {
  @Prop({ default: true }) value: boolean;
  @Prop({ required: true }) ceri: string;

  validating = false;
  failed: string[] = [];

  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(val: boolean) {
    this.$emit('input', val);
  }

  get failedDetails(): string {
    if (this.failed.length == 0) {
      // FIXME: Return an empty string?
      return this.ceri;
    } else if (this.failed.length == 1) {
      return this.failed[0];
    } else if (this.failed.length == 2) {
      return `${this.failed[0]} and ${this.failed[1]}`;
    } else {
      const remaining = this.failed.length - 2;
      return `${this.failed[0]}, ${this.failed[1]}, and ${remaining} more`;
    }
  }

  // created(): void {
  //   this.validate();
  // }

  validate(): void {
    this.failed = [];
    this.validating = true;
    this.$store
      .dispatch('content/validate', { xref: this.ceri })
      .then(({ failedCeris }) => {
        // Notify which items failed validation.
        this.failed = failedCeris ?? [];
      })
      .catch(() => {
        // FIXME: Figure out if we want to close this dialog and notify User via snackbar OR
        // notify User here.
        this.failed = [this.ceri];
        this.$notify('Something went wrong. Failed to complete validation.');
        this.showDialog = false;
      })
      .finally(() => {
        this.validating = false;
      });
  }

  @Watch('showDialog')
  onDialog(): void {
    if (this.showDialog) {
      this.validate();
    }
  }
}
