
import { Component, Prop, Vue } from 'vue-property-decorator';
import ScoreChip from '@/components/Report/ScoreChip.vue';
import { DataTableHeader } from 'vuetify';

interface GradingRow {
  symbol: number;
  score: number;
  meaning: string;
  sawAnswer: boolean;
}

@Component({
  components: {
    ScoreChip,
  },
})
export default class EssayScoringDialog extends Vue {
  @Prop() value: boolean;
  headers: Array<string> = ['Symbol', 'Score', 'Meaning'];
  gradingRows: Array<GradingRow> = [
    {
      symbol: 100,
      score: 4,
      meaning: 'Fully Correct',
      sawAnswer: false,
    },
    {
      symbol: 75,
      score: 3,
      meaning: 'Partially Correct',
      sawAnswer: false,
    },
    {
      symbol: 50,
      score: 2,
      meaning: 'Partially Correct',
      sawAnswer: false,
    },
    {
      symbol: 25,
      score: 1,
      meaning: 'Partially Correct',
      sawAnswer: false,
    },
    {
      symbol: 0,
      score: 0,
      meaning: 'Incorrect',
      sawAnswer: true,
    },
  ];

  ///////////////////
  // Table Headers //
  ///////////////////

  get staticHeaders(): Array<DataTableHeader> {
    const buildStaticHeader = (text: string): DataTableHeader => ({
      text,
      value: text.toLowerCase(),
      align: 'start',
      class: ['text-no-wrap', 'sticky-row', 'sticky-row-1', 'text-subtitle-2'],
      cellClass: ['text-no-wrap'],
    });

    return ['Symbol', 'Score', 'Meaning'].map(buildStaticHeader);
  }

  /**
   * Returns max width for dialog based on current breakpoint
   */
  get maxWidth(): number {
    return this.$vuetify.breakpoint.lgAndUp ? 800 : 600;
  }

  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(val: boolean) {
    this.$emit('input', val);
  }
}
