
import {
  BarChartData,
  CustomDataSetLabel,
  DataSets,
  TickLabel,
} from '@/components/Report/BarChartView.vue';
import BarChartViewForReport from '@/components/Report/BarChartViewForReport.vue';
import {
  StudentAndPeerDataPerSkill,
  StudentDataPerSkill,
} from '@/domain/ReportData/Tutor';
import { SkillDefinition } from '@/domain/Skill';
import { orderBy } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    BarChartViewForReport,
  },
})
export default class SkillBarChartViewForReport extends Vue {
  @Prop({ required: true }) customChartLabels: Array<CustomDataSetLabel>;
  @Prop({ required: true }) customClassAverageColor: string;
  @Prop({ required: true }) skillData: Array<StudentAndPeerDataPerSkill>;
  @Prop({ default: false }) isLoading: boolean;
  @Prop({ default: false }) noSkillDataFound: boolean;
  tab = null;
  assignmentReport = false;

  ////////////////////
  // Loading States //
  ////////////////////
  get isDownloadingSkills(): boolean {
    return this.$store.state.skillList.isDownloading;
  }

  ///////////
  // Store //
  ///////////

  get skills(): Array<SkillDefinition> {
    // We should already have this downloaded
    return this.$store.state.skillList.skills;
  }

  ////////////////
  // Graph Data //
  ////////////////
  get sortedSkillData(): Array<StudentAndPeerDataPerSkill> {
    return orderBy(this.skillData, (skill) => skill.numProblemsPeers, 'desc');
  }

  get customChartLabelXrefs(): Array<string> {
    return this.customChartLabels.map(
      (label: CustomDataSetLabel) => label.xref
    );
  }

  get customChartLabelsWithDefault(): Array<CustomDataSetLabel> {
    const classAverageLabel = {
      xref: 'peer',
      label: 'Class Average',
      backgroundColor: this.customClassAverageColor,
    };

    return [...this.customChartLabels, classAverageLabel];
  }

  get customChartData(): BarChartData {
    // Transform data
    let skillLabels: Array<TickLabel> = [];
    let datasets: DataSets = {};

    this.sortedSkillData.forEach((perSkill: StudentAndPeerDataPerSkill) => {
      const targetSkill: SkillDefinition | null = this.getTargetSkill(
        perSkill.skillXref
      );

      if (targetSkill) {
        // Skill information
        const skillLabel: TickLabel = {
          text: targetSkill.nodeCode,
          value: targetSkill.xref,
          totalProblems: perSkill.numProblemsPeers,
          standardDescription: targetSkill.nodeName,
        };

        // if (targetSkill.isFocus) {
        //   skillLabel.unicodeSymbol = '\u272A';
        // }

        skillLabels.push(skillLabel);

        if (!datasets['peer']) {
          // Initialize class dataset
          datasets['peer'] = [];
        }

        datasets['peer'].push({
          value: Math.round(perSkill.averageScorePeers * 100),
          numProblems: perSkill.numProblemsPeers,
        });

        const processedXrefs: Array<string> = [];

        perSkill.studentData.forEach((data: StudentDataPerSkill) => {
          processedXrefs.push(data.studentXref);

          if (!datasets[data.studentXref]) {
            // Initialize student dataset
            datasets[data.studentXref] = [];
          }

          datasets[data.studentXref].push({
            value: Math.round(data.averageScore * 100),
            numProblems: data.numProblems,
          });
        });

        // Missing data point for skill?
        const missingDataPoint = this.customChartLabelXrefs.filter(
          (studentXref: string) => {
            return !processedXrefs.includes(studentXref);
          }
        );

        missingDataPoint.forEach((studentXref: string) => {
          if (!datasets[studentXref]) {
            // Initialize student dataset
            datasets[studentXref] = [];
          }

          datasets[studentXref].push({
            value: 0,
            numProblems: 0,
          });
        });
      }
    });
    return {
      tickLabels: skillLabels,
      datasets: datasets,
    };
  }

  /////////////
  // Methods //
  /////////////

  openProblemsByStandard(xref: string): void {
    this.$router.push({
      name: 'tutorProblemsByStandard',
      params: {
        xref,
      },
      query: this.$route.query, // Save previous selections
    });
  }

  getTargetSkill(skillXref: string): SkillDefinition | null {
    const skillFound = this.skills.find((skill) => skill.xref == skillXref);

    if (skillFound) {
      return skillFound;
    }

    return null;
  }

  created(): void {
    // Download skills if not already
    // Will be prevented to download again in store if already
    this.$store.dispatch('skillList/requestSkills');
  }
}
